import React, { useState, useRef, useEffect, useCallback } from "react";
import "./ChangePhone.css";
import { connect } from "react-redux";
import { setOTPVerified } from "../../../store/slices/userSlice";

// import "bootstrap/dist/css/bootstrap.min.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API_BASE_URL } from "../../../config";
import { toggleOverlay } from "../../../store/slices/headerSlice";
import {
  fetchUserAsync,
  selectUser,
  setToken,
} from "../../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

function ChangePhone({
  handleChangeVerify,
  phoneNumber,
  setPhoneNumber,
  setOTPVerified,
  toggleOverlay,
  setToken,
}) {
  const userDetail = useSelector(selectUser);
  const [openPhoneOTP, setOpenPhoneOTP] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [newPN, setNewPN] = useState(null);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef(
    Array.from({ length: 6 }).map(() => React.createRef())
  );

  const handleInputChange = useCallback(
    (index, value) => {
      // Accept only numeric values
      const numericValue = value.replace(/\D/g, "");

      const newOTP = [...otp];
      newOTP[index] = numericValue.slice(-1); // Only take the last digit

      setOTP(newOTP);

      // Move to the previous input when deleting a digit
      if (numericValue !== "" && index < 5 && inputRefs?.current[index + 1]) {
        inputRefs?.current[index + 1]?.current?.focus();
      }
    },
    [otp]
  );

  const handleOpenOTP = async () => {
    if (!newPN || newPN.length < 10) {
      toast.error('Enter phone number correctly')
    } else {
      handleOpen();
      try {
        const response = await fetch(
          `${API_BASE_URL}update_new_number/${userDetail?.UserID}/${newPN}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          setOpenPhoneOTP(!openPhoneOTP);
        } else {
          console.error("API request failed:", response.statusText);
          toast.error('Invalid Phone number')
        }
      } catch (error) {
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        toast.error('Please try again later');
      }
      handleClose();
    }
  };

  const handleVerifyOTP = async () => {
    handleOpen();
    const enteredOTP = otp.join("");
    // Perform OTP verification logic here using enteredOTP

    if (!enteredOTP || enteredOTP.length < 6) {
      handleClose();
      toast.error('Enter OTP correctly')
    } else {
      try {
        const response = await fetch(
          `${API_BASE_URL}verify_new_number_otp/${userDetail?.UserID}/${enteredOTP}/${newPN}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          dispatch(fetchUserAsync(userDetail?.UserID));
          handleClose();

          toast.success(`${data.message}`)
          handleChangeVerify();
        } else {
          handleClose();
          console.error("API request failed:", response.statusText);
          toast.error('Invalid OTP');
        }
      } catch (error) {
        handleClose();
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        toast.error('Please try again later');
      }
    }
  };

  const formattPhoneNumber = (phoneNumber) => {
    if (phoneNumber) {
      // Remove any existing dashes and spaces from the phone number
      phoneNumber = phoneNumber.replace(/[-\s]/g, "");

      // Insert dashes at appropriate positions (e.g., XXX-XXX-XXXX)
      phoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
      return phoneNumber;
    }
  };

  useEffect(() => {
    const enteredOTP = otp.join("");
    if (enteredOTP.length === 6) {
      handleVerifyOTP();
    }
  }, [otp]);

  const handleSendOTPAgain = async () => {
    handleOpen();
    try {
      const response = await fetch(
        `${API_BASE_URL}update_new_number/${userDetail?.UserID}/${newPN}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (response.ok) {
        handleClose();

        toast.success('OTP sent Again')
      } else {
        handleClose();
        console.error("API request failed:", response.statusText);
        toast.error('OTP not Sent')
      }
    } catch (error) {
      handleClose();
      console.error("An error occurred during the API request:", error.message);
      toast.error('Please try again later')
    }
  };

  const handleOTPPaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData.getData("text/plain");
    const pastedDigits = clipboardData.replace(/\D/g, "").slice(0, 6); // Filter out non-numeric characters and take the first 6 digits
    const newOTP = [...otp];
    pastedDigits.split("").forEach((digit, index) => {
      newOTP[index] = digit;
    });
    setOTP(newOTP);
    inputRefs.current[5].current.focus();
  };

  const handleKeyDown = (index, value, e) => {
    if (e.keyCode === 8 && value === "" && index > 0) {
      inputRefs.current[index - 1].current.focus();
    } else if (e.keyCode === 8 && index > 0 && value !== "") {
      inputRefs.current[index].current.focus();
    }
  };
  const handleFocus = (index) => {
    // Move the cursor to the last digit if there's already a value entered
    if (index === 5 && otp[index] !== "") {
      inputRefs.current[index].current?.focus();
    }
  };
  const handlePhoneNumberChange = (e) => {
    // Allow only numbers (0-9) and backspace (8)
    const phoneNumber = e.target.value.replace(/[^\d]/g, "");
    setNewPN(phoneNumber);
  };
  return (
    <>
      <div className="overlay"></div>
      {!openPhoneOTP ? (
        <div className="change-phone-container">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <span className="change-phone-heading">Change Phone Number</span>
          <div className="change-phone-main-container">
            <span className="change-phone-label">Enter New Phone Number</span>
            <input
              type="text"
              value={newPN}
              onChange={handlePhoneNumberChange}
              onKeyPress={(e) => {
                // Allow only numbers (0-9) and backspace (8)
                if (!/[0-9\b]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              maxLength="10"
              placeholder="Enter New Phone Number"
            />
            <span className="change-phone-send-otp-btn" onClick={handleOpenOTP}>
              Send OTP
            </span>
            <span
              className="change-phone-cancel-btn"
              onClick={handleChangeVerify}
            >
              Cancel
            </span>
          </div>
        </div>
      ) : (
        <div className="change-phone-enter-otp-container">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <span className="change-phone-enter-otp-top-heading">
            We have send you a code
          </span>
          <span className="change-phone-enter-otp-next-heading">
            Phease enter it below to verify your phone number
          </span>
          <span className="change-phone-enter-otp-phone-number">
            {formattPhoneNumber(newPN)}
          </span>
          <div className="change-phone-enter-otp-phone-number-otp-container">
            <div className="change-phone-otp-input-boxes">
              {otp.map((digit, index) => (
                <input
                  type="text"
                  maxlength="1"
                  oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                  key={index}
                  // maxLength={index === 5 ? 1 : 0}
                  value={digit}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e.target.value, e)}
                  ref={inputRefs.current[index]}
                  onFocus={() => handleFocus(index)}
                  onPaste={handleOTPPaste}
                  className={digit ? "filled" : ""}
                  required
                />
              ))}
            </div>
          </div>
          <span
            className="change-phone-enter-otp-verify-btn"
            onClick={handleVerifyOTP}
          >
            Verify
          </span>
          <div className="change-phone-enter-otp-dont-receive-otp-div">
            <span className="change-phone-enter-otp-dont-receive-text">
              Don't receive code yet?
            </span>
            <span
              className="change-phone-enter-otp-send-again-btn"
              onClick={handleSendOTPAgain}
            >
              send again
            </span>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  // You can map any state needed for the OTPVerification component here
});

const mapDispatchToProps = {
  setOTPVerified,
  toggleOverlay,
  setToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePhone);
