import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API_BASE_URL } from "../../../config";
import { VerifiedGST } from "../../../mComponent/VerifiedDocument";
import { OTPVerified, VerifyOTP } from "../../../mComponent/OTPVerification";
import toast from "react-hot-toast";

function MobileBusinessRegistration({
  updateFinalData,
  finalData,
  GSTVerify,
  setGSTVerify,
}) {
  const [errors, setErrors] = useState({});
  const [gstExists, setGstExists] = useState(false);
  const [verify, setVerify] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    updateFinalData({ [name]: value });
  };

  const handleGSTINChange = async (e) => {
    const { name, value } = e.target;
    const updateValue = value?.toUpperCase();
    updateFinalData({ [name]: updateValue });

    if (name === "GST" && value.length === 15) {
      handleGSTBlur(e);
    } else if (name === "GST" && value.length < 15) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        GST: "",
      }));
    }
  };

  useEffect(() => {
    if (finalData?.GST?.length < 15) {
      setGSTVerify(false);
      updateFinalData({ ["GST_DATA"]: null });
    }
  }, [finalData?.GST?.length]);

  const handleVerifyGST = async () => {
    handleOpen();
    if (!finalData.GST || finalData.GST.length < 15) {
      handleClose();
      toast.error('Enter GSTIN correctly')
    } else {
      try {
        const response = await fetch(
          `${API_BASE_URL}gst_verify/${finalData.GST}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (data.GST.message === "GSTIN Exists") {
          setGSTVerify(true);
          setVerify(true);
          updateFinalData({ GST_DATA: data.GST });
          handleClose();
        } else {
          handleClose();
          toast.error("GSTIN Doesn't Exist")
        }
      } catch (error) {
        handleClose();
        toast.error("GSTIN Doesn't Exist")
        console.error(
          "An error occurred during the API request:",
          error.message
        );
      }
    }
  };

  function validateGSTIN(gstin) {
    const regex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    return regex.test(gstin);
  }

  const handleGSTBlur = async (e) => {
    const gst = e?.target?.value?.toUpperCase();
    if (!validateGSTIN(gst)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        GST: "Please enter a valid 15-digit GST number",
      }));
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}singup_gst_verify/${gst}`);
      const data = await response.json();

      if (data.status === "error") {
        setGstExists(true);
        setErrors((prevErrors) => ({
          ...prevErrors,
          GST: "GST number already registered with Cyclecircle",
        }));
      } else {
        setGstExists(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          GST: "",
        }));
      }
    } catch (error) {
      console.error("Error checking GST number existence:", error);
    }
  };

  return (
    <div className="w-full min-h-[72vh] flex flex-col items-start justify-start py-4 px-3 pb-[50px] bg-white-1">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <span className="text-[13px] text-black-1/70 font-semibold my-2">
        We require this information to verify your business
      </span>
      <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
        <TextField
          label="Registered Business Name"
          variant="outlined"
          id="businessNameInput"
          placeholder="Ente your business name"
          fullWidth
          name="business_name"
          value={finalData?.business_name || ""}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="w-full flex flex-row items-center justify-start mb-[5px] pl-3">
        <FormControl className="w-full" required>
          <FormLabel
            id="demo-radio-buttons-group-label"
            sx={{
              fontSize: "18px",
            }}
          >
            GSTIN
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <FormControlLabel
              name="Have_GSTIN"
              id="Have_GSTIN"
              value="yes"
              checked={finalData?.Have_GSTIN === "yes"}
              onChange={handleInputChange}
              control={<Radio />}
              label="I have GSTIN"
              sx={{
                "& .MuiFormControlLabel-label": {
                  color: "rgba(0, 0, 0, 0.8)",
                  fontSize: "16px",
                },
              }}
            />
            <FormControlLabel
              control={<Radio />}
              label="I haven't GSTIN"
              name="Have_GSTIN"
              id="Have_GSTIN"
              value="no"
              checked={finalData?.Have_GSTIN === "no"}
              onChange={handleInputChange}
              sx={{
                "& .MuiFormControlLabel-label": {
                  color: "rgba(0, 0, 0, 0.8)",
                  fontSize: "16px",
                },
              }}
            />
          </RadioGroup>
        </FormControl>
      </div>

      {finalData?.Have_GSTIN && finalData?.Have_GSTIN === "yes" && (
        <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[25px]">
          <TextField
            label="GSTIN Number"
            variant="outlined"
            id="GST"
            placeholder="Ex:55XE15VD9823EF5"
            fullWidth
            maxLength="15"
            color={validateGSTIN(finalData?.GST) && "success"}
            name="GST"
            value={finalData?.GST || ""}
            onBlur={handleGSTBlur}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/\s+/g, "");
              if (inputValue.length <= 15) {
                handleGSTINChange({
                  target: {
                    name: e.target.name,
                    value: inputValue,
                  },
                });
              }
            }}
            error={
              (errors["GST"] || gstExists) && !validateGSTIN(finalData?.GST)
            }
            helperText={errors["GST"] || ""}
          />
          {!GSTVerify &&
            !errors["GST"] && !gstExists &&
            finalData?.GST &&
            finalData?.GST?.length === 15 && (
              <VerifyOTP handleVerify={handleVerifyGST} />
            )}
          {GSTVerify && <OTPVerified />}
        </div>
      )}
      <div className="w-full flex flex-row items-center justify-start mb-[5px] pl-3">
        <FormControl className="w-full" required>
          <FormLabel
            id="demo-radio-buttons-group-label"
            sx={{
              fontSize: "18px",
            }}
          >
            Choose which ever is applicable
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <FormControlLabel
              name="applicable"
              id="applicable"
              value="1"
              checked={finalData?.applicable === "1"}
              onChange={handleInputChange}
              control={<Radio />}
              label="My annual turnover is less than GST prescribed limit."
              sx={{
                "& .MuiFormControlLabel-label": {
                  color: "rgba(0, 0, 0, 0.8)",
                  fontSize: "14px",
                },
              }}
            />
            <FormControlLabel
              control={<Radio />}
              label="My business category is exempted from GST"
              name="applicable"
              id="applicable"
              value="2"
              checked={finalData?.applicable === "2"}
              onChange={handleInputChange}
              defaultChecked
              sx={{
                "& .MuiFormControlLabel-label": {
                  color: "rgba(0, 0, 0, 0.8)",
                  fontSize: "14px",
                },
              }}
            />
          </RadioGroup>
        </FormControl>
      </div>
      {GSTVerify && <VerifiedGST finalData={finalData} />}
    </div>
  );
}

export default MobileBusinessRegistration;
