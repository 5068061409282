import React from "react";
import "./PopularSearch.css";
import { useNavigate } from "react-router-dom";
import { fetchThroughSearchAsync, setSubCategories } from "../../features/listingPage/listingPageSlice";
import {
  fetchAllCategoryProductsAsync,
  fetchAllSubCategoryProductsAsync,
} from "../../features/listingPage/listingPageSlice";
import { useDispatch } from "react-redux";
import { setSelectedSubcategory } from "../../store/slices/subCategorySlice";
import { setBreadcrumb } from "../../store/slices/breadCrumbsSlice";

function PopularSearch() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSearchNavigate = (value) => {
      dispatch(fetchThroughSearchAsync(value.trim()));
      dispatch(setSubCategories(null));
      dispatch(setSelectedSubcategory(null));
      dispatch(
        setBreadcrumb({
          categoryID: 0,
          categoryName: "",
          subCategoryID: 0,
          subCategoryName: "",
          ProductID: 0,
          productName: "",
        })
      );
      navigate(`/search/${value}`);

  };
  const handleCategoryNavigate = (value) => {
    dispatch(`/listing-page/funn`);
    dispatch(fetchAllCategoryProductsAsync(value.trim()));
  };
  const handleSubCategoryNavigate = (value) => {
    navigate(`/listing-page/funn`);
    dispatch(fetchAllSubCategoryProductsAsync(value.trim()));
  };
  return (
    <div className="popular-search-container">
      <div className="popular-search-upper-div">
        <h3>Popular Searches:</h3>
        <div className="popular-search-upper-main-div">
          <span onClick={()=>handleSearchNavigate("parts")}>Bicycle parts</span> |{" "}
          <span onClick={()=>handleSearchNavigate("accessories")}>
            Bike accessories
          </span>{" "}
          |{" "}
          <span onClick={()=>handleSearchNavigate("maintenance")}>
            Bike maintenance
          </span>{" "}
          | <span onClick={()=>handleSearchNavigate("lights")}>Bike Lights</span> |
          <span onClick={()=>handleSearchNavigate("helmets")}>Helmets</span> |
          <span onClick={()=>handleSearchNavigate("maintenance")}>
            Cycling Tools
          </span>{" "}
          | <span onClick={()=>handleSearchNavigate("wheels")}>MTB wheelset</span> |
          <span onClick={()=>handleSearchNavigate("clothing")}>
            Cycling Clothes
          </span>{" "}
          |<span onClick={()=>handleSearchNavigate("parts")}>Spare parts</span> |{" "}
          <span onClick={()=>handleSearchNavigate("funn")}>Best MTB parts</span>|{" "}
          <span onClick={()=>handleSearchNavigate("granite")}>Mini Tools</span>|{" "}
          <span onClick={()=>handleSearchNavigate("drivetrain")}>
            Bicycle gears
          </span>
        </div>
      </div>
      <div className="popular-search-bottom-div">
        <div className="bottom-upper">
          <h3>Cockpit:</h3>
          <div className="inner-main">
            <span onClick={()=>handleSearchNavigate("handlebar")}>Handlebar</span> |
            <span onClick={()=>handleSearchNavigate("stem")}>Stem</span> |
            <span onClick={()=>handleSearchNavigate("seatpost")}>Seatpost</span> |{" "}
            <span onClick={()=>handleSearchNavigate("grips")}>Grips</span> |
            <span onClick={()=>handleSearchNavigate("headset")}>Headset</span> |
            <span onClick={()=>handleSearchNavigate("pedal")}>Pedal</span>
          </div>
        </div>
        <div className="bottom-upper">
          <h3>Drivetrain:</h3>
          <div className="inner-main">
            <span onClick={()=>handleSearchNavigate("bottom bracket")}>
              Bottom Bracket
            </span>{" "}
            | <span onClick={()=>handleSearchNavigate("chain")}>Chain</span> |
            <span onClick={()=>handleSearchNavigate("crankset")}>Crankset</span> |{" "}
            <span onClick={()=>handleSearchNavigate("cassette")}>
              Cassette and freely Brake Rotor
            </span>{" "}
            |
            <span onClick={()=>handleSearchNavigate("derailluer")}>Derailluer</span>{" "}
            |<span onClick={()=>handleSearchNavigate("shifter")}>Shifter</span> |
            <span onClick={()=>handleSearchNavigate("chainring")}>Chainring</span>
          </div>
        </div>
        <div className="bottom-upper">
          <h3>Hydration:</h3>
          <div className="inner-main">
            <span onClick={()=>handleSearchNavigate("bottles")}>Bottles</span> |{" "}
            <span onClick={()=>handleSearchNavigate("bags")}>Hydration Bags</span> |{" "}
            <span onClick={()=>handleSearchNavigate("bottle holders")}>
              Bottle Holders
            </span>{" "}
            |{" "}
          </div>
        </div>
        <div className="bottom-upper">
          <h3>Helmets:</h3>
          <div className="inner-main">
            <span onClick={()=>handleSearchNavigate("mtb")}>MTB</span> |{" "}
            <span onClick={()=>handleSearchNavigate("road")}>Road</span> |{" "}
            <span onClick={()=>handleSearchNavigate("city")}>City</span> |{" "}
            <span onClick={()=>handleSearchNavigate("enduro")}>Enduro</span>
          </div>
        </div>
        <div className="bottom-upper">
          <h3>Wheels and Tires:</h3>
          <div className="inner-main">
            <span onClick={()=>handleSearchNavigate("wheel")}>wheels</span> |{" "}
            <span onClick={()=>handleSearchNavigate("rim")}>Rim</span> |{" "}
            <span onClick={()=>handleSearchNavigate("hub")}>Hub</span> |{" "}
            <span onClick={()=>handleSearchNavigate("axles")}>Axles & QR</span>
          </div>
        </div>
        <div className="bottom-upper">
          <h3>Cable and Housing:</h3>
          <div className="inner-main">
            <span onClick={()=>handleSearchNavigate("brake housing")}>
              Brake housing
            </span>{" "}
            |{" "}
            <span onClick={()=>handleSearchNavigate("brake wire")}>Brake wire</span>{" "}
            |{" "}
          </div>
        </div>
        <div className="bottom-upper">
          <h3>Brakes and Brake Parts:</h3>
          <div className="inner-main">
            <span onClick={()=>handleSearchNavigate("lever")}>Brake Lever</span> |{" "}
            <span onClick={()=>handleSearchNavigate("disc brake")}>Disc Brake</span>{" "}
            | <span onClick={()=>handleSearchNavigate("rotor")}>Disc Rotor</span> |{" "}
          </div>
        </div>
        <div className="bottom-upper">
          <h3>Lights:</h3>
          <div className="inner-main">
            <span onClick={()=>handleSearchNavigate("front light")}>
              Front Light
            </span>{" "}
            |{" "}
            <span onClick={()=>handleSearchNavigate("rear light")}>Rear Light</span>{" "}
            | <span onClick={()=>handleSearchNavigate("light")}>Others</span> |{" "}
          </div>
        </div>
        <div className="bottom-upper">
          <h3>Small Parts:</h3>
          <div className="inner-main">
            <span onClick={()=>handleSearchNavigate("chain guide")}>
              Chain Guide
            </span>{" "}
            |{" "}
            <span onClick={()=>handleSearchNavigate("bearing and spacers")}>
              Bearing & Spacers
            </span>{" "}
            | <span onClick={()=>handleSearchNavigate("clamps")}>Clamps</span> |{" "}
          </div>
        </div>
        <div className="bottom-upper">
          <h3>Pumps & Inflation:</h3>
          <div className="inner-main">
            <span onClick={()=>handleSearchNavigate("air pump")}>Air Pump</span> |{" "}
            <span onClick={()=>handleSearchNavigate("cartridges")}>Cartridges</span>{" "}
            |{" "}
          </div>
        </div>
        <div className="bottom-upper">
          <h3>Stands & Storage:</h3>
          <div className="inner-main">
            <span onClick={()=>handleSearchNavigate("stands")}>Stands</span> |{" "}
            <span onClick={()=>handleSearchNavigate("storage")}>Storage</span> |{" "}
            <span onClick={()=>handleSearchNavigate("mounts")}>Mounts</span> |{" "}
          </div>
        </div>
        <div className="bottom-upper">
          <h3>Clothing:</h3>
          <div className="inner-main">
            <span onClick={()=>handleSearchNavigate("cycling socks")}>
              Cycling Socks
            </span>{" "}
            | <span onClick={()=>handleSearchNavigate("tshirts")}>Tshirts</span> |{" "}
          </div>
        </div>
        <div className="bottom-upper">
          <h3>Sealants & Tubeless Kits:</h3>
          <div className="inner-main">
            <span onClick={()=>handleSearchNavigate("kit and valves")}>
              Kit & Valves
            </span>{" "}
            | <span onClick={()=>handleSearchNavigate("rim tapes")}>Rim Tapes</span>{" "}
            |{" "}
          </div>
        </div>
        <div className="bottom-upper">
          <h3>Tools:</h3>
          <div className="inner-main">
            <span onClick={()=>handleSearchNavigate("mini tools")}>Mini Tools</span>{" "}
            |{" "}
          </div>
        </div>
        <div className="bottom-upper">
          <h3>Fork:</h3>
          <div className="inner-main">
            <span onClick={()=>handleSearchNavigate("fork")}>Suspension Fork</span>{" "}
            |{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopularSearch;
