import React, { useEffect, useState } from "react";
import "./ViewOrderDetail.css";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import GppGoodIcon from "@mui/icons-material/GppGood";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  selectViewOrderDetail,
  viewOrderDetailAsync,
} from "../../features/cartPage/OrderSlice";
import { API_BASE_URL, IMAGE_BASE, TRACKING_ORDER } from "../../config";
import { selectUser } from "../../features/auth/authSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { formatWithCommasWithDecimal } from "../../utils/utils";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  borderRadius: "10px",
  margin: "10px",
  "&:not(:last-child)": {
    borderRadius: "10px",
  },
  "&::before": {
    display: "none",
    borderRadius: "10px",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  borderRadius: "5px",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function ViewOrderDetail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const orderDetail = useSelector(selectViewOrderDetail);
  const [expanded, setExpanded] = React.useState("");
  const order_ID = useParams();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    const fetchData = async () => {
      handleOpen();
      dispatch(viewOrderDetailAsync(order_ID && order_ID?.order_ID));
      handleClose();
    };
    fetchData();
    return () => clearTimeout(2000);
  }, [dispatch]);

  const steps = [
    {
      label: "Order Confirmed",
      icon: <CheckSharpIcon />,
      date:
        orderDetail && new Date(orderDetail?.order_date).toLocaleDateString(),
    },
    { label: "Quality Check", icon: <GppGoodIcon />, date: "Est..." },
    { label: "Dispatched Item", icon: <LocalShippingIcon />, date: "Est..." },
    { label: "Product Delivered", icon: <AllInboxIcon />, date: "Est..." },
  ];

  const [complete, setComplete] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const handleStepClick = (stepIndex) => {
    if (stepIndex <= currentStep || complete) {
      setCurrentStep(stepIndex);
    }
  };

  const calculateShippingCost = (products) => {
    let totalCost = 0;
    if (products) {
      for (const product of products) {
        totalCost +=
          parseInt(product?.Offer_price && product?.Offer_price) ||
          parseInt(product?.NDP_price && product?.NDP_price);
        // *product?.quantity;
      }
    }
    return totalCost >= 3000 ? 0 : 250;
  };

  const calculateTotalProductCost = (products) => {
    let totalCost = 0;
    if (products) {
      for (const product of products) {
        totalCost +=
          parseInt(product?.Offer_price && product?.Offer_price) ||
          parseInt(product?.NDP_price && product?.NDP_price);
      }
    }
    return totalCost;
  };
  const calculateTotalGST = (products) => {
    let totalGST = 0;
    if (products) {
      for (const product of products) {
        // Use offer price if available, otherwise use NDP price
        const price = product?.Offer_price
          ? parseFloat(product?.Offer_price)
          : parseFloat(product?.NDP_price);
        const gstRate = product?.GST;

        // Calculate the price excluding GST
        const priceExcludingGST = price / (1 + gstRate / 100);

        // Calculate the GST amount
        const gstAmount = price - priceExcludingGST;

        // Accumulate total GST
        totalGST += gstAmount;
      }
    }

    return totalGST;
  };

  const handleDownloadInvoice = async () => {
    try {
      // Construct the URL for downloading the PDF invoice
      const invoiceUrl = `https://cyclecircle.blr1.digitaloceanspaces.com/${IMAGE_BASE}_INVOICE/${orderDetail?.order_no}.pdf`;

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = invoiceUrl;
      link.setAttribute("download", "invoice.pdf");

      // Simulate a click event to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up resources
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading invoice:", error);
    }
  };
  return (
    <div className="view-order-detail-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open} // Show backdrop when either open or filtering state is true
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="view-order-detail-header-container">
        <div className="view-order-detail-header-left-div">
          <ChevronLeftIcon onClick={() => navigate("/user/orders")} />
          <span className="view-order-detail-header-orderID">
            Order ID: #{orderDetail && orderDetail?.order_no}
          </span>
        </div>
        {orderDetail &&
          orderDetail?.orderStatus &&
          orderDetail?.orderStatus?.tracking_no && (
            <div className="view-order-detail-header-right-div">
              <div
                className="view-order-detail-header-right-invoice-div"
                onClick={handleDownloadInvoice}
              >
                <ReceiptIcon />
                <span>Invoice</span>
              </div>
            </div>
          )}
      </div>
      <div className="view-order-order-tracking-detail-div">
        <div className="view-order-order-tracking-date-delivery-div">
          <span className="view-order-order-date-span">
            Order Date:{" "}
            <b>
              {orderDetail &&
                new Date(orderDetail?.order_date).toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
            </b>
          </span>{" "}
          |
          <div className="view-order-order-estimated-delivery-span">
            <AirplanemodeActiveIcon />{" "}
            <span>
              Estimated delivery:{" "}
              {new Date(
                new Date(orderDetail?.order_date).getTime() +
                  5 * 24 * 60 * 60 * 1000
              ).toLocaleDateString("en-US", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </span>{" "}
            {orderDetail && orderDetail?.order_no && (
              <a
                href={`${TRACKING_ORDER}${orderDetail?.order_no}`}
                target="_blank"
                rel="noreferrer"
                className="track-order-btn"
              >
                Track Order
              </a>
            )}
          </div>
        </div>
        {/* <div className="view-order-order-tracking-visual-div">
          <div className="view-order-order-tracking-visual-order-confirm-breadcrum-container">
            {steps?.map((step, i) => (
              <div
                key={i}
                className={`view-order-order-tracking-visual-order-confirm-step-item ${
                  currentStep === i + 1 && "active"
                } ${
                  (i + 1 < currentStep || complete) &&
                  "view-order-order-tracking-visual-order-confirm-complete"
                } `}
                onClick={() => handleStepClick(i + 1)}
              >
                <div
                  className={`view-order-order-tracking-visual-order-confirm-step ${
                    currentStep === i + 1 && "active"
                  }`}
                >
                  {step.icon}
                </div>
                <p className="view-order-order-tracking-visual-order-confirm-text-gray">
                  {step.label}
                </p>
                <p className="view-order-order-tracking-visual-order-confirm-text-date">
                  {step.date}
                </p>
              </div>
            ))}
          </div>
        </div> */}
      </div>
      <div className="view-order-items-and-payment-summary-detail-container">
        <div className="view-order-items-and-address-left-container">
          <div className="view-order-items-main-container">
            <div className="view-order-items-main-header-div">
              <span className="view-order-items-number">
                Items (
                {orderDetail &&
                  orderDetail?.orderDetails &&
                  orderDetail?.orderDetails?.length}
                )
              </span>
              <span className="view-order-items-order-again">Order Again</span>
            </div>
            {orderDetail &&
              orderDetail?.orderDetails &&
              orderDetail?.orderDetails?.map((product, index) => (
                <div className="view-order-items-each-orders-div" key={index}>
                  <div className="view-order-items-each-orders-left-div">
                    <div
                      className="view-order-items-img-div"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/product/${product?.product_id}`)
                      }
                    >
                      <LazyLoadImage
                        src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                          product && product?.image
                        }`}
                        alt=""
                        effect="blur"
                        wrapperProps={{
                          // If you need to, you can tweak the effect transition using the wrapper style.
                          style: { transitionDelay: "1s" },
                        }}
                      />
                    </div>
                    <div className="view-order-items-product-details-div">
                      <LazyLoadImage
                        src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                          product && product?.manufacture_images
                        }`}
                        alt=""
                        className="view-order-items-brands-logo"
                        effect="blur"
                        wrapperProps={{
                          // If you need to, you can tweak the effect transition using the wrapper style.
                          style: { transitionDelay: "1s" },
                        }}
                      />
                      <span className="view-order-items-product-title-span">
                        {product && product?.prod_name}
                      </span>
                      {/* <span className="view-order-items-product-desc-span">
                        Rigel Max Front Light-1500 Lumnens | Advanced LED Tech |
                        Rotatable Anti-Glare | Elegant Black Design
                      </span> */}
                    </div>
                  </div>
                  <div className="view-order-items-each-product-variant-div">
                    <span className="view-order-items-each-product-qty">
                      Qty: <b>{product && product?.quantity}</b>
                    </span>
                    {product?.size_name && (
                      <span className="view-order-items-each-product-size">
                        Size:{" "}
                        <b>
                          {
                            product && product?.size_name
                            // ? product?.size_name?.slice(0, 10)
                            // : "---"}
                          }
                        </b>
                      </span>
                    )}
                    {product?.colour_name && (
                      <span className="view-order-items-each-product-color">
                        Color:{" "}
                        <b>
                          {
                            product && product?.colour_name
                            // ? product?.colour_name?.slice(0, 10)
                            // : "---"}
                          }
                        </b>
                      </span>
                    )}
                    {/* <span className="view-order-items-each-product-variant">
                      Variant: <b>Diamond</b>
                    </span> */}
                  </div>
                  <div className="view-order-items-each-product-write-review-return-div">
                    <span className="view-order-items-each-product-write-review-span">
                      Write a review
                    </span>
                    <span className="view-order-items-each-product-return-item-span">
                      Return this Item
                    </span>
                  </div>
                </div>
              ))}
          </div>
          <div className="view-order-items-address-div">
            <div className="view-order-items-booking-address-div">
              <h3>Support Address</h3>
              <div className="view-order-items-booking-address-main-div">
                Radkaat Ventures Private Limited, 1st Floor, Unit no.124, Paras
                Trade Centre, Gurgaon Faridabad Road, Gwal Pahari, Gurugram,
                District, Gurugram, Haryana 122003
              </div>
            </div>
            <div className="view-order-items-Delivery-address-div">
              <h3>Delivery Address</h3>
              <div className="view-order-items-Delivery-address-main-div">
                {orderDetail && orderDetail?.Address?.FirstName}{" "}
                {orderDetail && orderDetail?.Address?.LastName} ,{" "}
                {orderDetail && orderDetail?.Address?.address_1},{" "}
                {orderDetail && orderDetail?.Address?.address_2},{" "}
                {orderDetail && orderDetail?.Address?.city},<br />
                {orderDetail && orderDetail?.Address?.district},{" "}
                {orderDetail && orderDetail?.Address?.pin_code},{" "}
                {orderDetail && orderDetail?.Address?.stateName},{" "}
                {orderDetail && orderDetail?.Address?.PhoneNumber}
              </div>
            </div>
          </div>
          <div className="view-order-items-FAQ-questions-container">
            <div className="view-order-need-help-faq-container ">
              <h3>FAQ for Checkout Process</h3>
              <div className="accordion-width" style={{ width: "80%" }}>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography>How can I create an account?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      You can create your account in Signup by providing
                      necessary details.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                  >
                    <Typography>Can I track my order status?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Yes,you can track your order whenever you want by
                      navigating to your My orders page in Your profile section.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                  >
                    <Typography>
                      What shipping options are available?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Cyclecircle provides you two shipping options: 1. Standard
                      shipping In Standard shipping you will get free delivery
                      on purchasing above $3000.00. 2. Express Shipping In
                      Express shipping you should pay a certain amount according
                      to your delivery address even you purchase above $3000.00
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                >
                  <AccordionSummary
                    aria-controls="panel4d-content"
                    id="panel4d-header"
                  >
                    <Typography>Do you ship internationally?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      No, now we do not ship internationally.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel5"}
                  onChange={handleChange("panel5")}
                >
                  <AccordionSummary
                    aria-controls="panel5d-content"
                    id="panel5d-header"
                  >
                    <Typography>
                      What happens if my package is damaged or lost?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      We have trusted return policy. You can raise the complaint
                      to return the product.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel6"}
                  onChange={handleChange("panel6")}
                >
                  <AccordionSummary
                    aria-controls="panel6d-content"
                    id="panel6d-header"
                  >
                    <Typography>What is your return policy?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      We offer a 30-day return policy for most items. You can
                      return your items for a full refund or exchange by
                      following the instructions provided on our returns page.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel7"}
                  onChange={handleChange("panel7")}
                >
                  <AccordionSummary
                    aria-controls="panel7d-content"
                    id="panel7d-header"
                  >
                    <Typography>What payment methods do you accept?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      We accept all major credit cards, debit cards, and PayPal.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel8"}
                  onChange={handleChange("panel8")}
                >
                  <AccordionSummary
                    aria-controls="panel8d-content"
                    id="panel8d-header"
                  >
                    <Typography>Do you charge any additional fees?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      We only charge the shipping and tax rates applicable to
                      your order.There are no hidden or surcharges.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <div className="view-order-payment-and-order-summary-right-container">
          <div className="view-order-payment-main-div">
            <h3>Payment Method</h3>
            <LazyLoadImage
              src="https://myhq.s3.amazonaws.com/benefits/5dfba19e8236fc88159e1a2d.png"
              alt=""
              effect="blur"
              wrapperProps={{
                // If you need to, you can tweak the effect transition using the wrapper style.
                style: { transitionDelay: "1s" },
              }}
            />
          </div>
          <div className="view-order-order-summary-right-container">
            <h3>Order Summary</h3>
            <hr />
            <div className="view-order-summary-right-each-div">
              <span>Order status</span>
              <span>
                {orderDetail && orderDetail?.orderStatus?.status === "pending"
                  ? "Confirmed"
                  : orderDetail?.orderStatus?.status}
              </span>
            </div>
            <div className="view-order-summary-right-each-div">
              <span>Order ID</span>
              <span>#{orderDetail && orderDetail?.order_no}</span>
            </div>
            <div className="view-order-summary-right-each-div">
              <span>Order Placed</span>
              <span>
                {orderDetail &&
                  orderDetail?.order_date &&
                  new Date(orderDetail?.order_date).toLocaleDateString(
                    "en-US",
                    {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    }
                  )}
              </span>
            </div>
            {/* <div className="view-order-summary-right-each-div">
              <span>Shipping Mode</span>
              <span>Standard</span>
            </div> */}
            <div className="view-order-summary-right-each-div">
              <span>Estimated Delivery Date</span>
              <span>
                {new Date(
                  new Date(orderDetail?.order_date).getTime() +
                    5 * 24 * 60 * 60 * 1000
                ).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </span>
            </div>
            <div className="view-order-summary-right-each-div">
              <span>GST</span>
              <span>{userDetail && userDetail?.GST}</span>
            </div>
            <hr />
            <div className="view-order-summary-right-each-div">
              <span>Total Products</span>
              <span>{orderDetail && orderDetail?.orderDetails?.length}</span>
            </div>
            <div className="view-order-summary-right-each-div">
              <span>
                Total Product Cost{" "}
                <b style={{ fontWeight: "400", fontSize: "10px" }}>
                  (incl. of all Taxes)
                </b>
              </span>
              <span>
                ₹
                {formatWithCommasWithDecimal(
                  calculateTotalProductCost(
                    orderDetail &&
                      orderDetail?.orderDetails &&
                      orderDetail?.orderDetails
                  )
                )}
              </span>
            </div>
            <div className="view-order-summary-right-each-div">
              <span>Shipping & Handling</span>
              <span>
                ₹
                {formatWithCommasWithDecimal(
                  orderDetail && orderDetail?.Shipping_Amount === null
                    ? "0.00"
                    : orderDetail?.Shipping_Amount
                )}
              </span>
            </div>
            <div className="view-order-summary-right-each-div">
              <span>Discount</span>
              <span>
                -₹
                {formatWithCommasWithDecimal(
                  orderDetail && orderDetail?.Discount_Amount === null
                    ? "0.00"
                    : orderDetail?.Discount_Amount
                )}
              </span>
            </div>
            <div className="view-order-summary-right-each-div">
              <span>Credit</span>
              <span>
                -₹
                {formatWithCommasWithDecimal(
                  orderDetail && orderDetail?.Credit === null
                    ? "0.00"
                    : orderDetail?.Credit
                )}
              </span>
            </div>
            <div className="view-order-summary-right-each-div">
              <span>GST</span>
              <span>
                ₹
                {formatWithCommasWithDecimal(
                  calculateTotalGST(orderDetail && orderDetail?.orderDetails)
                )}
              </span>
            </div>
            <hr />
            <div className="view-order-summary-right-each-div view-order-total-price">
              <span>Total (₹)</span>
              <span>
                ₹
                {formatWithCommasWithDecimal(
                  orderDetail && orderDetail?.TotalAmount
                )}
              </span>
            </div>
          </div>
          <div className="have-question-about-your-order-div">
            <h3>Have Questions About Your Order?</h3>
            <span className="supporting-text">
              Our Friendly support team is here for question about status of
              your order,billing,shipping and other order related questions
            </span>
            <Link
              to="https://wa.me/9429693000"
              target="_blank"
              className="contact-support-span"
            >
              Contact Support
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewOrderDetail;
