import { formatAadharNumber } from "../utils/utils";

export const VerifiedPAN = ({ finalData }) => {
  return (
    <div className="w-full flex flex-col items-center p-3 rounded-md mt-[50px] bg-[#f7f7f7] border-[1px] border-[#ddd]">
      <h3 className="text-16 font-bold mx-2 text-black-1/80 tracking-wider">
        PAN is Valid
      </h3>
      <div className="w-full flex flex-col items-center p-2 pb-0">
        <div className="w-full flex justify-between my-2">
          <span className="w-[35%] text-start text-14 text-black-1/80 font-bold">
            PAN No.
          </span>
          <span className="w-[65%] text-end text-14 text-black-1/50 font-semibold">
            {finalData?.PAN_DATA?.pan}
          </span>
        </div>
        <div className="w-full flex justify-between my-2">
          <span className="w-[35%] text-start text-14 text-black-1/80 font-bold">
            Name
          </span>
          <span className="w-[65%] text-end text-14 text-black-1/50 font-semibold">
            {finalData?.PAN_DATA?.registered_name}
          </span>
        </div>
        <div className="w-full flex justify-between my-2">
          <span className="w-[35%] text-start text-14 text-black-1/80 font-bold whitespace-nowrap">
            Reference ID
          </span>
          <span className="w-[65%] text-end text-14 text-black-1/50 font-semibold">
            {finalData?.PAN_DATA?.reference_id}
          </span>
        </div>
        <div className="w-full flex justify-between my-2">
          <span className="w-[35%] text-start text-14 text-black-1/80 font-bold">
            Valid
          </span>
          <span className="w-[65%] text-end text-14 text-black-1/50 font-semibold">
            {"True"}
          </span>
        </div>
        <div className="w-full flex justify-between my-2">
          <span className="w-[35%] text-start text-14 text-black-1/80 font-bold">
            Type
          </span>
          <span className="w-[65%] text-end text-14 text-black-1/50 font-semibold">
            {finalData?.PAN_DATA?.type}
          </span>
        </div>
      </div>
    </div>
  );
};

export const VerifiedAadhar = ({ finalData }) => {
  return (
    <div className="w-full flex flex-col items-center p-3 rounded-md mt-[50px] bg-[#f7f7f7] border-[1px] border-[#ddd]">
      <h3 className="text-16 font-bold mx-2 text-black-1/80 tracking-wider">
        Aadhar is Valid
      </h3>
      <div className="w-full flex flex-col items-center p-2 pb-0">
        <div className="w-full flex justify-between my-2">
          <span className="w-[35%] text-start text-14 text-black-1/80 font-bold">
            Aadhar No.
          </span>
          <span className="w-[65%] text-end text-14 text-black-1/50 font-semibold">
            {formatAadharNumber(finalData?.aadhar)}
          </span>
        </div>
        <div className="w-full flex justify-between my-2">
          <span className="w-[35%] text-start text-14 text-black-1/80 font-bold">
            DOB
          </span>
          <span className="w-[65%] text-end text-14 text-black-1/50 font-semibold">
            {finalData?.Aadhar_DATA?.dob}
          </span>
        </div>
        <div className="w-full flex justify-between my-2">
          <span className="w-[35%] text-start text-14 text-black-1/80 font-bold">
            Name
          </span>
          <span className="w-[65%] text-end text-14 text-black-1/50 font-semibold">
            {finalData?.Aadhar_DATA?.name}
          </span>
        </div>
        <div className="w-full flex justify-between my-2">
          <span className="w-[35%] text-start text-14 text-black-1/80 font-bold">
            Address
          </span>
          <span className="w-[65%] text-end text-14 text-black-1/50 font-semibold">
            {finalData?.Aadhar_DATA?.address}
          </span>
        </div>
        <div className="w-full flex justify-between my-2">
          <span className="w-[35%] text-start text-14 text-black-1/80 font-bold">
            Reference ID
          </span>
          <span className="w-[65%] text-end text-14 text-black-1/50 font-semibold">
            {finalData?.Aadhar_DATA?.ref_id}
          </span>
        </div>
        <div className="w-full flex justify-between my-2">
          <span className="w-[35%] text-start text-14 text-black-1/80 font-bold">
            Year of Birth
          </span>
          <span className="w-[65%] text-end text-14 text-black-1/50 font-semibold">
            {finalData?.Aadhar_DATA?.year_of_birth}
          </span>
        </div>
      </div>
    </div>
  );
};

export const VerifiedGST = ({ finalData }) => {
  return (
    <div className="w-full flex flex-col items-center p-3 rounded-md mt-[50px] bg-[#f7f7f7] border-[1px] border-[#ddd]">
      <h3 className="text-16 font-bold mx-2 text-black-1/80 tracking-wider">
        GSTIN is Valid
      </h3>
      <div className="w-full flex flex-col items-center p-2 pb-0">
        <div className="w-full flex justify-between my-2">
          <span className="w-[35%] text-start text-12 text-black-1/80 font-bold">
            GSTIN
          </span>
          <span className="w-[65%] text-end text-12 text-black-1/50 font-semibold">
            {finalData?.GST_DATA?.GSTIN}
          </span>
        </div>
        <div className="w-full flex justify-between my-2">
          <span className="w-[35%] text-start text-14 text-black-1/80 font-bold">
            Legal Name of Business
          </span>
          <span className="w-[65%] text-end text-14 text-black-1/50 font-semibold">
            {finalData?.GST_DATA?.legal_name_of_business}
          </span>
        </div>
        <div className="w-full flex justify-between my-2">
          <span className="w-[35%] text-start text-14 text-black-1/80 font-bold">
            GST.Ref.ID
          </span>
          <span className="w-[65%] text-end text-14 text-black-1/50 font-semibold">
            {finalData?.GST_DATA?.reference_id}
          </span>
        </div>
        <div className="w-full flex justify-between my-2">
          <span className="w-[35%] text-start text-14 text-black-1/80 font-bold">
            Trade Name of Business
          </span>
          <span className="w-[65%] text-end text-14 text-black-1/50 font-semibold">
            {finalData?.GST_DATA?.trade_name_of_business}
          </span>
        </div>
        <div className="w-full flex justify-between my-2">
          <span className="w-[35%] text-start text-14 text-black-1/80 font-bold">
            Tax payment type
          </span>
          <span className="w-[65%] text-end text-14 text-black-1/50 font-semibold">
            {finalData?.GST_DATA?.taxpayer_type}
          </span>
        </div>
        <div className="w-full flex justify-between my-2">
          <span className="w-[35%] text-start text-14 text-black-1/80 font-bold">
            Date of Registration
          </span>
          <span className="w-[65%] text-end text-14 text-black-1/50 font-semibold">
            {finalData?.GST_DATA?.date_of_registration}
          </span>
        </div>
        <div className="w-full flex justify-between my-2">
          <span className="w-[35%] text-start text-14 text-black-1/80 font-bold">
            GST Status
          </span>
          <span className="w-[65%] text-end text-14 text-black-1/50 font-semibold">
            {finalData?.GST_DATA?.gst_in_status}
          </span>
        </div>
        <div className="w-full flex justify-between my-2">
          <span className="w-[35%] text-start text-14 text-black-1/80 font-bold">
            Place Address
          </span>
          <span className="w-[65%] text-end text-14 text-black-1/50 font-semibold">
            {finalData?.GST_DATA?.principal_place_address}
          </span>
        </div>
      </div>
    </div>
  );
};
