import React, { useState } from "react";
import "./ListingHeader.css";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import KeyboardArrowUpSharpIcon from "@mui/icons-material/KeyboardArrowUpSharp";
import SortBy from "../../SortBy/SortBy";
import BrandSelect from "./BrandSelect";
import MaterialComponent from "./MaterialComponent";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import {
  setBrands,
  setMaterials,
} from "../../../../store/slices/allFilterSlice";
import { connect } from "react-redux";
import {
  setShippingMode,
  setPriceRange,
  setRatings,
  clearAllFilters,
  setDealerMargins,
  setShopByOffers,
} from "../../../../store/slices/listingPageFilterSlice";
import {
  setRating,
  setDealerMargin,
  setShopByOffer,
  setMinPrice,
  setMaxPrice,
  setShipping,
} from "../../../../store/slices/allFilterSlice";

/** MOBILE VIEW IMPORT */
import SortSharpIcon from "@mui/icons-material/SortSharp";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";
import FilterComponent from "../FilterComponent";

function ListingHeader({
  shippingMode,
  priceRange,
  ratings,
  dealerMargins,
  shopByOffers,
  setShippingMode,
  setPriceRange,
  setRatings,
  setDealerMargins,
  setShopByOffers,
  clearAllFilters,
}) {
  const dispatch = useDispatch();
  const brands = useSelector((state) => state.allFilter.brands);
  const materials = useSelector((state) => state.allFilter.materials);
  const sortPrice = useSelector((state) => state.allFilter.sortPrice);
  const sortRating = useSelector((state) => state.allFilter.sortRating);

  const [openFilter, setOpenFilter] = useState(null);

  const [filterOpen, setFilterOpen] = useState(false);

  const handleFilterOpen = () => {
    setFilterOpen(!filterOpen);
  };

  const toggleFilter = (filter) => {
    setOpenFilter((prevFilter) => (prevFilter === filter ? null : filter));
  };

  const handleSelection = (selectedOption) => {
    setOpenFilter(null);

    if (selectedOption.brands) {
      dispatch(setBrands(selectedOption.brands));
    }

    if (selectedOption.materials) {
      dispatch(setMaterials(selectedOption.materials));
    }
  };

  const handleClearAll = () => {
    clearAllFilters();
    setRatings("");
    setDealerMargins("");
    setShopByOffers("");
    dispatch(setRating(0));
    dispatch(setDealerMargin(0));
    dispatch(setShopByOffer(""));
    setPriceRange("");
    dispatch(setMinPrice(0));
    dispatch(setMaxPrice(0));
    setShippingMode("");
    dispatch(setShipping(""));
    dispatch(setBrands([]));
    dispatch(setMaterials([]));
  };

  const getTotalFiltersCount = () => {
    let totalFilters = 0;
    if (shippingMode) totalFilters++;
    if (priceRange) totalFilters++;
    if (ratings) totalFilters++;
    if (dealerMargins) totalFilters++;
    totalFilters += brands.length;
    totalFilters += materials.length;
    return totalFilters;
  };

  return (
    <>
      <div className="listing-header-page-merge-filter">
        <div className="listing-header-page-left-container">
          <span className="listing-header-page-left-heading">Filter:</span>
        </div>
        <div className="listing-header-page-concatinate">
          <div className="listing-header-container">
            <div className="listing-header-filter-container">
              <div className="filter-div">
                <span onClick={() => toggleFilter("brands")}>Brands</span>
                {openFilter === "brands" ? (
                  <KeyboardArrowUpSharpIcon onClick={() => toggleFilter("")} />
                ) : (
                  <KeyboardArrowDownSharpIcon
                    onClick={() => toggleFilter("brands")}
                  />
                )}
                {openFilter === "brands" && (
                  <BrandSelect
                    onSelect={handleSelection}
                    closeBrandSelect={() => setOpenFilter(null)}
                    previouslySelectedBrands={brands}
                  />
                )}
              </div>

              <div className="filter-div">
                <span onClick={() => toggleFilter("material")}>Material</span>
                {openFilter === "material" ? (
                  <KeyboardArrowUpSharpIcon onClick={() => toggleFilter("")} />
                ) : (
                  <KeyboardArrowDownSharpIcon
                    onClick={() => toggleFilter("material")}
                  />
                )}
                {openFilter === "material" && (
                  <MaterialComponent
                    onSelect={handleSelection}
                    closeMaterialSelect={() => setOpenFilter(null)}
                    previouslySelectedMaterials={materials}
                  />
                )}
              </div>
            </div>
            <div className="listing-header-sortBy">
              <div
                className="filter-div"
                style={{
                  height: "30px",
                  marginRight: "0",
                  border: "2px solid rgba(0,0,0,0.4)",
                  padding: "0 0.5vw",
                }}
                onClick={() => toggleFilter("sortBy")}
              >
                <span>Sort By</span>
                {openFilter === "sortBy" ? (
                  <KeyboardArrowUpSharpIcon />
                ) : (
                  <KeyboardArrowDownSharpIcon />
                )}
                {openFilter === "sortBy" && (
                  <SortBy
                    onSelect={handleSelection}
                    closeSortBySelect={() => setOpenFilter(null)}
                    previouslySortPrice={sortPrice}
                    previouslySortRating={sortRating}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="all-filter-added-component">
            <div className="all-filter-added-main-container">
              {shippingMode && (
                <div className="all-filter-shipping-mode-container">
                  <>
                    <span>{shippingMode} </span>
                    <CloseSharpIcon
                      onClick={() => {
                        setShippingMode("");
                        dispatch(setShipping(""));
                      }}
                    />
                  </>
                </div>
              )}
              {priceRange && (
                <div className="all-filter-price-range-container">
                  <>
                    <span>{priceRange} </span>
                    <CloseSharpIcon
                      onClick={() => {
                        setPriceRange("");
                        dispatch(setMinPrice(0));
                        dispatch(setMaxPrice(0));
                      }}
                    />
                  </>
                </div>
              )}
              {ratings && (
                <div className="all-filter-rating-range-container">
                  <>
                    <span>{ratings}+</span>
                    <StarRoundedIcon
                      style={{
                        color: "gold",
                        fontSize: "14px",
                        marginBottom: "0vh",
                        marginLeft: "-0.1vw",
                        cursor: "default",
                      }}
                    />
                    <CloseSharpIcon
                      onClick={() => {
                        setRatings("");
                        dispatch(setRating(0));
                      }}
                    />
                  </>
                </div>
              )}
              {dealerMargins && (
                <div className="all-filter-rating-range-container">
                  <>
                    <span>{dealerMargins}%+ Dealer Margin</span>
                    <CloseSharpIcon
                      onClick={() => {
                        setDealerMargins("");
                        dispatch(setDealerMargin(0));
                      }}
                    />
                  </>
                </div>
              )}
              {shopByOffers && (
                <div className="all-filter-rating-range-container">
                  <>
                    <span>{shopByOffers}</span>
                    <CloseSharpIcon
                      onClick={() => {
                        setShopByOffers("");
                        dispatch(setShopByOffer(""));
                      }}
                    />
                  </>
                </div>
              )}
              <div className="all-filter-brands-container">
                {brands &&
                  brands.map((brand, index) => (
                    <div className="all-filter-brand-container" key={index}>
                      <span>{brand}</span>
                      <CloseSharpIcon
                        onClick={() => {
                          dispatch(
                            setBrands(brands.filter((b, i) => i !== index))
                          );
                        }}
                      />
                    </div>
                  ))}
              </div>
              <div className="all-filter-materials-container">
                {materials &&
                  materials.map((material, index) => (
                    <div className="all-filter-material-container" key={index}>
                      <span>{material}</span>
                      <CloseSharpIcon
                        onClick={() => {
                          dispatch(
                            setMaterials(
                              materials.filter((m, i) => i !== index)
                            )
                          );
                        }}
                      />
                    </div>
                  ))}
              </div>
              {shippingMode ||
              priceRange ||
              ratings ||
              dealerMargins ||
              shopByOffers ||
              brands.length > 0 ||
              materials.length > 0 ? (
                <div className="all-filter-clear-container">
                  <span
                    className="allFilterComponent-clearAll"
                    onClick={handleClearAll}
                  >
                    Clear all
                  </span>
                  {/* <DeleteForeverSharpIcon onClick={handleClearAll} /> */}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-filter-sortby-upper-container">
        <div
          className="mobile-sortby-upper-div"
          onClick={() => toggleFilter("sortBy")}
        >
          <SortSharpIcon />
          <span>Sort By:</span>
        </div>
        {openFilter === "sortBy" && (
          <SortBy
            onSelect={handleSelection}
            closeSortBySelect={() => setOpenFilter(null)}
            previouslySortPrice={sortPrice}
            previouslySortRating={sortRating}
          />
        )}
        <span className="mobile-filter-seperate-line">|</span>
        <div className="mobile-filter-upper-div" onClick={handleFilterOpen}>
          <FilterListSharpIcon />
          <span>Filter</span>
          {getTotalFiltersCount() > 0 && (
            <span className="filter-added-number">
              {getTotalFiltersCount()}
            </span>
          )}
        </div>
      </div>
      {filterOpen && (
        <FilterComponent
          setFilterOpen={setFilterOpen}
          onSelect={handleSelection}
          closeBrandSelect={() => setOpenFilter(null)}
          previouslySelectedBrands={brands}
          closeBikesSelect={() => setOpenFilter(null)}
          closeMaterialSelect={() => setOpenFilter(null)}
          previouslySelectedMaterials={materials}
          handleClearAll={handleClearAll}
        />
      )}
      <div className="mobile-all-filter-added-component">
        <div className="mobile-all-filter-added-main-container">
          {shippingMode && (
            <div className="mobile-all-filter-shipping-mode-container">
              <>
                <span>{shippingMode} </span>
                <CloseSharpIcon
                  onClick={() => {
                    setShippingMode("");
                    dispatch(setShipping(""));
                  }}
                />
              </>
            </div>
          )}
          {priceRange && (
            <div className="mobile-all-filter-price-range-container">
              <span>{priceRange} </span>
              <CloseSharpIcon
                onClick={() => {
                  setPriceRange("");
                  dispatch(setMinPrice(0));
                  dispatch(setMaxPrice(0));
                }}
              />
            </div>
          )}
          {ratings && (
            <div className="mobile-all-filter-rating-range-container">
              <span>{ratings}+</span>
              <StarRoundedIcon
                style={{
                  color: "gold",
                  fontSize: "1.2rem",
                  marginLeft: "0",
                  cursor: "default",
                }}
              />
              <CloseSharpIcon
                onClick={() => {
                  setRatings("");
                  dispatch(setRating(0));
                }}
              />
            </div>
          )}
          <div className="mobile-all-filter-brands-container">
            {brands &&
              brands.map((brand, index) => (
                <div className="mobile-all-filter-brand-container" key={index}>
                  <span>{brand}</span>
                  <CloseSharpIcon
                    onClick={() => {
                      dispatch(setBrands(brands.filter((b, i) => i !== index)));
                    }}
                  />
                </div>
              ))}
          </div>
          <div className="mobile-all-filter-materials-container">
            {materials &&
              materials.map((material, index) => (
                <div
                  className="mobile-all-filter-material-container"
                  key={index}
                >
                  <span>{material}</span>
                  <CloseSharpIcon
                    onClick={() => {
                      dispatch(
                        setMaterials(materials.filter((m, i) => i !== index))
                      );
                    }}
                  />
                </div>
              ))}
          </div>
          {shippingMode ||
          priceRange ||
          ratings ||
          brands.length > 0 ||
          materials.length > 0 ? (
            <div className="mobile-all-filter-clear-container">
              <span
                className="mobile-allFilterComponent-clearAll"
                onClick={handleClearAll}
              >
                Clear all
              </span>
              {/* <DeleteForeverSharpIcon onClick={handleClearAll} /> */}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  shippingMode: state.listingPageFilter.shippingMode,
  priceRange: state.listingPageFilter.priceRange,
  ratings: state.listingPageFilter.ratings,
  dealerMargins: state.listingPageFilter.dealerMargins,
  shopByOffers: state.listingPageFilter.shopByOffers,
});

const mapDispatchToProps = {
  setShippingMode,
  setPriceRange,
  setRatings,
  setDealerMargins,
  setShopByOffers,
  clearAllFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingHeader);
