import { API_BASE_URL } from "../../config";
export function fetchAllTopSearch() {
    return new Promise(async (resolve) => {
        const response = await fetch(`${API_BASE_URL}top_search`);
        const data = await response.json();
        resolve({ data });
    });
}

export function fetchAllRecommendSearch(term) {
    return new Promise(async (resolve) => {
        const response = await fetch(`${API_BASE_URL}auto_search/${term}`);
        const data = await response.json();
        resolve({ data });
    });
}
