import React from "react";
import { useSelector } from "react-redux";
import { selectAllCategoryProducts } from "../features/listingPage/listingPageSlice";

const CtgProductCount = ({ categoryName }) => {
  const pdts = useSelector(selectAllCategoryProducts);
  const products = pdts.Data;
  return (
    <div className="w-full flex items-center justify-between py-[6px] px-3 bg-white-1">
      <span className="text-16 text-blue font-extrabold tracking-wider">
        {categoryName}
      </span>
      <span className="text-14 text-black-1/70 font-semibold tracking-wide">
        Showing{" "}
        {products?.product?.length > 16 ? "16" : products?.product?.length} of{" "}
        {products?.product?.length} products
      </span>
    </div>
  );
};

export default CtgProductCount;
