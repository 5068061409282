import React from "react";
import "./AuthenticationPage.css";
import LoginPage from "./LoginPage";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { services } from "../../data";
import loginVideo from "../../img/banners/login-video.mp4";
import websiteLogo from "../../img/parts/Cycle-Circle-Logo-Orange-1.png";
import { useMediaQuery } from "react-responsive";
import MobileAuthentication from "../../mobileComponent/MobileAuthentication/MobileAuthentication";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

function AuthenticationPage() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    vertical: false,
    arrows: false,
  };
  // Comment

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 501px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  return (
    <>
      {isDesktopOrLaptop && (
        <div className="AuthenticationPage-container">
          <div className="video-background">
            <video autoPlay muted loop>
              <source src={loginVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="AuthenticationPage-content">
            <div className="AuthenticationPage-left-container">
              <LazyLoadImage
                src={websiteLogo}
                alt=""
                className="left-container-logo"
                effect="blur"
                wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
                }}
              />
            </div>
            <div className="AuthenticationPage-right-container">
              <LoginPage />
            </div>
          </div>
        </div>
      )}
      {isTabletOrMobile && <MobileAuthentication />}
    </>
  );
}

export default AuthenticationPage;
