import React from 'react';
import "./Proride.css";

function Proride() {
  return (
    <div className="proride-container">
      <div className="proride-brand-info">
        <p><strong>Proride</strong> is the renowned brand of Dhanoa, a distinguished bicycle parts manufacturer in India, established in 1986. Proride products are crafted with a blend of modern management, human touch, sheer hard work, dedication, and a strong commitment to producing quality products that ensure ultimate consumer satisfaction.</p>
      </div>
      <div className="proride-core-values">
        <h2>Core Values and Practices:</h2>
        <ul>
          <li><strong>Expert Design:- </strong> Talented professionals precisely design Proride products using optimal methodologies, adhering to stringent quality standards.</li>
          <li><strong>Customer Focus:- </strong> These professionals are sincere and cordial, ensuring optimal satisfaction for esteemed clients.</li>
          <li><strong>Top-Notch Quality:- </strong> Proride products are designed with a deep understanding of cyclists' needs, ensuring they are of the highest quality.</li>
          <li><strong>Chemical-Free:- </strong> Products are free from harsh chemicals, ensuring safety and durability.</li>
          <li><strong>Affordable Pricing:- </strong> High-quality products are offered at affordable prices, making them accessible to a wide range of cyclists.</li>
          <li><strong>Collaboration:- </strong> Proride is working closely with Cyclecircle to introduce more advanced products at affordable prices in India.</li>
        </ul>
      </div>
      <p style={{fontSize:"14px"}}>Proride by Dhanoa is dedicated to delivering superior bicycle parts that meet the evolving needs of cyclists, combining innovation with affordability for the ultimate cycling experience.</p>
    </div>
  );
}

export default Proride;
