import React, { useEffect, useState } from "react";
import "./MobileInsight.css";
import ReactApexChart from "react-apexcharts";
import { API_BASE_URL } from "../../../config";

const ApexChart = ({ insightsData }) => {
  const [seriesData, setSeriesData] = useState([]);
  const [optionsData, setOptionsData] = useState({});

  useEffect(() => {
    if (insightsData && insightsData?.ranks && insightsData?.ranks?.priceRows) {
      const priceRanges = insightsData?.ranks?.priceRows.map(
        (row) => row.Price_Range
      );
      const productCounts = insightsData?.ranks?.priceRows.map(
        (row) => row.No_of_Product
      );
      const dealerMargins = insightsData?.ranks?.priceRows.map((row) =>
        parseInt(row.Avg_GST_Price_Percentage?.slice(0, -1))
      );

      setSeriesData([
        {
          name: "Price Range",
          type: "column",
          data: productCounts,
        },
        {
          name: "Dealer Margin in %",
          type: "line",
          data: dealerMargins,
        },
      ]);

      setOptionsData({
        chart: {
          height: 350,
          type: "line",
        },
        stroke: {
          width: [0, 4],
        },
        title: {
          text: "Product Price v/s Dealer Margin",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
        },
        labels: priceRanges,
        xaxis: {
          type: "range",
        },
        yaxis: [
          {
            title: {
              text: "No. of Products",
            },
            forceNiceScale: true,
          },
          {
            opposite: true,
            title: {
              text: "Dealer Margin",
            },
            // forceNiceScale: true,
          },
        ],
      });
    }
  }, [insightsData]);

  return (
    <div className="chart-container">
      <ReactApexChart
        options={optionsData}
        series={seriesData}
        type="line"
        height={350}
      />
    </div>
  );
};

function MobileInsight({ productDesc }) {
  const [insightsData, setInsightsData] = useState(null);

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}insight/${productDesc && productDesc[0].prod_ID}`
        );
        const data = await response.json();
        setInsightsData(data);
      } catch (error) {
        console.error("Error fetching insights data:", error);
      }
    };

    fetchData();
  }, [productDesc]);

  return (
    <div className="mobile-insight-container">
      <h2>
        Insights for{" "}
        {productDesc && productDesc[0] && productDesc[0]?.prod_name}
      </h2>
      <div className="mobile-insight-product-ranking-container">
        <span className="mobile-insight-ranking-in-categories">
          <b>
            #{" "}
            {insightsData &&
              insightsData?.ranks &&
              insightsData?.ranks?.subcategoryRank}
          </b>{" "}
          in {productDesc && productDesc[0] && productDesc[0]?.subcategoryName}
        </span>
        <span className="mobile-insight-ranking-in-categories">
          <b>
            #{" "}
            {insightsData &&
              insightsData?.ranks &&
              insightsData?.ranks?.categoryRank}
          </b>{" "}
          in {productDesc && productDesc[0] && productDesc[0]?.categoryName}
        </span>
        <span className="mobile-insight-ranking-in-categories">
          <b>
            #{" "}
            {insightsData &&
              insightsData?.ranks &&
              insightsData?.ranks?.verticalRank}
          </b>{" "}
          in {productDesc && productDesc[0] && productDesc[0]?.verticalName}
        </span>
      </div>
      <div className="mobile-insight-graph-container">
        {insightsData && <ApexChart insightsData={insightsData} />}
      </div>
    </div>
  );
}

export default MobileInsight;
