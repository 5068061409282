import React from 'react';
import { moonData } from '../../../data';
import "./Moon.css";

function Moon() {
    const { brand, slogan, description, innovation, design, safety, versatility, sustainability } = moonData
  return (
    <div className="moon-container">
    <h2>{`Welcome to ${brand}`}</h2>
    <h3>{slogan}</h3>
    <p>{description}</p>

    <div className="innovation">
      <h3>Innovative Technology</h3>
      <p>{innovation.description}</p>
    </div>

    <div className="design">
      <h3>Sleek and Aerodynamic Design</h3>
      <p>{design.description}</p>
    </div>

    <div className="safety">
      <h3>Safety First</h3>
      <p>{safety.description}</p>
    </div>

    <div className="versatility">
      <h3>Versatility for Every Rider</h3>
      <p>{versatility.description}</p>
    </div>

    <div className="sustainability">
      <h3>Commitment to Sustainability</h3>
      <p>{sustainability.description}</p>
    </div>
  </div>
  )
}

export default Moon