import React from "react";
import StarIcon from "@mui/icons-material/Star";
import { services } from "../../data";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ScrollPromices() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    vertical: false,
    arrows: false,
    prevArrow: <></>,
    nextArrow: <></>,
  };
  return (
    <div className="scroll-promices-container bg-[gold] py-1 flex-center w-full h-8">
      <Slider {...settings} className="w-[70%] h-full flex items-center justify-center">
        {services.map((data, index) => (
          <div key={index} className="!flex items-center justify-between !max-w-[60%] !w-auto h-full">
            <StarIcon className="text-black-1/80 mx-5" style={{fontSize:"18px"}}/>
            <div className="w-full h-full flex-center">
              <img loading="lazy" src={data.icon} alt="icon" className="h-5" />
              <span className="text-black-1/80 text-[14px] tracking-wide ml-3 font-bold">{data.heading}</span>
            </div>
            <StarIcon className="text-black-1/80 mx-5" style={{fontSize:"18px"}}/>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ScrollPromices;

