import React, { useState, useEffect, useRef } from "react";
import "./FilterComponent.css";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
// import { filterData } from "../../../data";
import delivery_light from "../../../img/delivery_light_black.svg";
import delivery_green from "../../../img/delivery_light_green.svg";
import bolt_black from "../../../img/bolt_black.svg";
import bolt_red from "../../../img/bolt_black_red.svg";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import ChevronLeftSharpIcon from "@mui/icons-material/ChevronLeftSharp";
import PriceRangeSlider from "./PriceRangeSlider";
import BrandSelect from "./FilterHeader/BrandSelect";
import MaterialComponent from "./FilterHeader/MaterialComponent";
import {
  setShippingMode,
  setPriceRange,
  setRatings,
  clearAllFilters,
  setDealerMargins,
  setShopByOffers,
} from "../../../store/slices/listingPageFilterSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  setRating,
  setMinPrice,
  setMaxPrice,
  setShipping,
  setDealerMargin,
  setShopByOffer,
} from "../../../store/slices/allFilterSlice";
import { connect } from "react-redux";
import { setBrands, setMaterials } from "../../../store/slices/allFilterSlice";
import { Bounce, Slide } from "react-reveal";

function FilterComponent({
  shippingMode,
  priceRange,
  ratings,
  setShippingMode,
  setPriceRange,
  setRatings,
  dealerMargins,
  setDealerMargins,
  shopByOffers,
  setShopByOffers,
  clearAllFilters,
  setFilterOpen,
  onSelect,
  closeBrandSelect,
  previouslySelectedBrands,
  // closeBikesSelect,
  closeMaterialSelect,
  previouslySelectedMaterials,
  handleClearAll,
}) {
  const [showShippingInfo, setShowShippingInfo] = useState(false);

  const dispatch = useDispatch();
  // const [filterDatas, setFilterDatas] = useState(filterData.brands);
  const [selectedCategory, setSelectedCategory] = useState("priceRange");
  const [selectedSubCtg, setSelectedSubCtg] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  // const [filteredSubCtg, setFilteredSubCtg] = useState(filterData.brands);

  useEffect(() => {
    if (ratings) {
      dispatch(setRating(parseInt(ratings)));
    }
    if (dealerMargins) {
      dispatch(setDealerMargin(parseInt(dealerMargins)));
    }
    if (shopByOffers) {
      dispatch(setShopByOffer(shopByOffers));
    }
    if (priceRange) {
      const prices = priceRange
        .replace(/₹/g, "") // Remove "₹" from the string
        .split(" - ");

      // Convert the values to numbers
      const minPrice = parseInt(prices[0], 10);
      const maxPrice = parseInt(prices[1], 10);
      dispatch(setMinPrice(minPrice));
      dispatch(setMaxPrice(maxPrice));
    }
    if (shippingMode) {
      dispatch(setShipping(shippingMode));
    }
  }, [
    dispatch,
    ratings,
    dealerMargins,
    shopByOffers,
    priceRange,
    shippingMode,
  ]);

  const handleRatingChange = (e) => {
    setRatings(e.target.value);
  };

  const handleDealerMarginChange = (e) => {
    if (e.target.value === dealerMargins) {
      setDealerMargins(null);
      dispatch(setDealerMargin(0));
    } else {
      setDealerMargins(e.target.value);
    }
  };
  const handleShopByOffers = (e) => {
    if (e.target.value === shopByOffers) {
      setShopByOffers(null);
      dispatch(setShopByOffer(""));
    } else {
      setShopByOffers(e.target.value);
    }
  };

  const selectCategories = (ctg) => {
    setSelectedCategory(ctg);

    setSelectedSubCtg([]);
    setSearchQuery(""); // Reset search input
  };

  // Create a ref to the component
  const filterSelectRef = useRef(null);

  return (
    <Slide right>
      <div ref={filterSelectRef} className="filter-component-container">
        <div className="filter-name-container">
          <div className="filter-name-back-btn">
            <ChevronLeftSharpIcon onClick={() => setFilterOpen(false)} />
            <span>Filters</span>
          </div>

          <span
            className="mobile-filter-clearall"
            onClick={() => {
              handleClearAll();
              setFilterOpen(false);
            }}
          >
            Clear All
          </span>
        </div>
        <div className="filter-main-container">
          <div className="filter-categories-container">
            <span
              onClick={() => selectCategories("priceRange")}
              className={selectedCategory === "priceRange" ? "sselected" : ""}
            >
              Price Range
            </span>
            <span
              onClick={() => selectCategories("customerRating")}
              className={
                selectedCategory === "customerRating" ? "sselected" : ""
              }
            >
              Customer Rating
            </span>
            <span
              onClick={() => selectCategories("dealerMargins")}
              className={
                selectedCategory === "dealerMargins" ? "sselected" : ""
              }
            >
              Dealer Margin
            </span>
            <span
              onClick={() => selectCategories("showByOffers")}
              className={selectedCategory === "showByOffers" ? "sselected" : ""}
            >
              Shop By Offer
            </span>
            <span
              onClick={() => selectCategories("brands")}
              className={selectedCategory === "brands" ? "sselected" : ""}
            >
              Brands
            </span>
          </div>
          <div className="filter-categories-option-container">
            {selectedCategory === "priceRange" && (
              <div className="allFilterComponent-price-range-filter-select-container">
                <PriceRangeSlider />
              </div>
            )}
            {selectedCategory === "customerRating" && (
              <div className="allFilterComponent-customer-ratings-div-container">
                {[4, 3, 2, 1].map((value) => (
                  <div
                    key={value}
                    className="allFilterComponent-customer-ratings-div-row"
                  >
                    <input
                      type="radio"
                      name="ratings"
                      value={value}
                      checked={parseInt(ratings) === value}
                      onChange={handleRatingChange}
                    />
                    <div className="input-star-div-container">
                      {value}
                      <StarRoundedIcon />
                    </div>
                    <span> & Above</span>
                  </div>
                ))}
              </div>
            )}
            {selectedCategory === "dealerMargins" && (
              <div className="allFilterComponent-dealer-margin-div-container">
                {[50, 40, 30, 20, 10].map((value) => (
                  <div
                    key={value}
                    className="allFilterComponent-dealer-margin-div-row"
                  >
                    <input
                      type="radio"
                      name="dealerMargins"
                      value={value}
                      checked={parseInt(dealerMargins) === value}
                      onClick={handleDealerMarginChange}
                    />
                    <span style={{ fontWeight: "600" }}>
                      {" "}
                      {value}% &nbsp; & Above{" "}
                    </span>
                  </div>
                ))}
              </div>
            )}
            {selectedCategory === "brands" && (
              <BrandSelect
                onSelect={onSelect}
                closeBrandSelect={closeBrandSelect}
                previouslySelectedBrands={previouslySelectedBrands}
              />
            )}
            {selectedCategory === "showByOffers" && (
              <div className="allFilterComponent-dealer-margin-div-container">
                <div className="allFilterComponent-dealer-margin-div-row">
                  <input
                    type="radio"
                    name="showByOffers"
                    value="Exclusive"
                    checked={shopByOffers === "Exclusive"}
                    onClick={handleShopByOffers}
                  />
                  <span style={{ fontWeight: "600" }}> Exclusive</span>
                </div>
                <div className="allFilterComponent-dealer-margin-div-row">
                  <input
                    type="radio"
                    name="showByOffers"
                    value="New Launch"
                    checked={shopByOffers === "New Launch"}
                    onClick={handleShopByOffers}
                  />
                  <span style={{ fontWeight: "600" }}> New Launch</span>
                </div>
                <div className="allFilterComponent-dealer-margin-div-row">
                  <input
                    type="radio"
                    name="showByOffers"
                    value="Price Drop"
                    checked={shopByOffers === "Price Drop"}
                    onClick={handleShopByOffers}
                  />
                  <span style={{ fontWeight: "600" }}> Price Drop</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="filter-btn-container">
          <button
            className="filter-btn-apply"
            onClick={() => setFilterOpen(false)}
          >
            Apply
          </button>
        </div>
      </div>
    </Slide>
  );
}

const mapStateToProps = (state) => ({
  shippingMode: state.listingPageFilter.shippingMode,
  priceRange: state.listingPageFilter.priceRange,
  ratings: state.listingPageFilter.ratings,
  dealerMargins: state.listingPageFilter.dealerMargins,
  shopByOffers: state.listingPageFilter.shopByOffers,
});

const mapDispatchToProps = {
  setShippingMode,
  setPriceRange,
  setRatings,
  setDealerMargins,
  setShopByOffers,
  clearAllFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterComponent);
