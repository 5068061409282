import React, { useState } from "react";
import "./Security.css";
import { API_BASE_URL } from "../../config";

import { Link } from "react-router-dom";
import { selectUser } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import ViaPhone from "./Verification/ViaPhone";
import ViaEmail from "./Verification/ViaEmail";
import toast from "react-hot-toast";

function Security() {
  const userDetail = useSelector(selectUser);
  const [verificationMethod, setVerificationMethod] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [verify, setVerify] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleBackClick = () => {
    setVerificationMethod(null);
  };
  const handlePhoneVerificationClick = async () => {
    const phoneNumber = userDetail && userDetail?.PhoneNumber;
    if (!phoneNumber || phoneNumber.length < 10) {
      toast.error('Please fill number correctly')
    } else {
      handleOpen();

      try {
        const response = await fetch(
          `${API_BASE_URL}update_phone_number/${userDetail?.UserID}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          toast.success(`${data.message}`)
          setVerificationMethod("phone");
        } else {
          console.error("API request failed:", response.statusText);
          toast.error('OTP not Sent')
        }
      } catch (error) {
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        toast.error('Please try again later');
      }
      handleClose();
    }
  };

  const handleEmailVerificationClick = async () => {
    const email = userDetail && userDetail?.Email;
    if (!email || !email.includes("@")) {
      toast.error('Please fill email correctly')
    } else {
      handleOpen();
      try {
        const response = await fetch(
          `${API_BASE_URL}update_email/${userDetail?.UserID}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          toast.success(`${data.message}`)
          setVerificationMethod("email");
        } else {
          console.error("API request failed:", response.statusText);
          toast.error('OTP not Sent')
        }
      } catch (error) {
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        toast.error('Please try again later');
      }
      handleClose();
    }
  };
  return (
    <div className="my-profile-security-container">
      <div className="security-verification-sign-up-main-container">
        <fieldset className="security-basic-detail-phone-email-verfication">
          <legend>Verification*</legend>
          {verificationMethod ? (
            verificationMethod === "phone" ? (
              <ViaPhone
                handleBackClick={handleBackClick}
                phoneNumber={userDetail && userDetail?.PhoneNumber}
                verify={verify}
                setVerify={setVerify}
              />
            ) : (
              <ViaEmail
                handleBackClick={handleBackClick}
                email={userDetail && userDetail?.Email}
                verify={verify}
                setVerify={setVerify}
              />
            )
          ) : (
            <>
              <div className="security-basic-detial-phone-em-verification-main-cont">
                <span>
                  To Protect your Account,you have to verify either Phone number
                  or Email
                </span>
                <div className="security-basic-detail-phone-email-verfication-main-div">
                  <span>Verify via</span>
                  <div className="security-basic-detail-phone-email-verification-btn">
                    <Link to="#" onClick={handlePhoneVerificationClick}>
                      Phone number
                    </Link>
                    <span>- or -</span>
                    <Link to="#" onClick={handleEmailVerificationClick}>
                      Email id
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </fieldset>
      </div>
    </div>
  );
}

export default Security;
