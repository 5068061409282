import React from "react";
// import "./MobileFooter.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import cyclecircleLogo from "../../img/parts/Cycle-Circle-Logo-White-1.png";
import {
  FaCcMastercard,
  FaCcPaypal,
  FaCcAmex,
  FaCcVisa,
  FaCcDiscover,
} from "react-icons/fa";
import { setSelectedSupport } from "../../store/slices/supportSlice";

function MobileFooter({ setSelectedSupport }) {
  return (
    <div className="w-full flex flex-col items-center justify-start bg-blue relative z-50">
      <div className="w-full flex items-start justify-between p-5">
        <div className="flex flex-col items-start">
          <h3 className="text-20 text-white font-extrabold tracking-wide mb-3">
            About Us
          </h3>
          <Link
            to="/aboutUs"
            className="text-[13px] font-bold text-white my-[6px] hover:text-white-2"
          >
            Know About Us
          </Link>
          <Link
            to="/aboutUs"
            className="text-[13px] font-bold text-white my-[6px] hover:text-white-2"
          >
            Become a member
          </Link>
          <Link
            to="/refund"
            className="text-[13px] font-bold text-white my-[6px] hover:text-white-2"
          >
            Exclusively on CC
          </Link>
          <Link
            to="/our_belief_system"
            className="text-[13px] font-bold text-white my-[6px] hover:text-white-2"
          >
            Our Belief System
          </Link>
          <Link
            to="/privacypolicy"
            className="text-[13px] font-bold text-white my-[6px] hover:text-white-2"
          >
            Privacy Policy
          </Link>
          <Link
            to="/copyright"
            className="text-[13px] font-bold text-white my-[6px] hover:text-white-2"
          >
            CopyRight
          </Link>
        </div>
        <div className="flex flex-col items-start">
          <h3 className="text-20 text-white font-extrabold tracking-wide mb-3">
            Cyclecircle Support
          </h3>
          <Link
            to="/support"
            className="text-[13px] font-bold text-white my-[6px] hover:text-white-2"
            onClick={() => setSelectedSupport(1)}
          >
            Track My Order
          </Link>
          <Link
            to="/support"
            className="text-[13px] font-bold text-white my-[6px] hover:text-white-2"
            onClick={() => setSelectedSupport(5)}
          >
            Returns and Refunds
          </Link>
          <Link
            to="/support"
            className="text-[13px] font-bold text-white my-[6px] hover:text-white-2"
            onClick={() => setSelectedSupport(4)}
          >
            User Agreement
          </Link>
          <Link
            to="/support"
            className="text-[13px] font-bold text-white my-[6px] hover:text-white-2"
            onClick={() => setSelectedSupport(3)}
          >
            Data Privacy Policy
          </Link>
          <Link
            to="/support"
            className="text-[13px] font-bold text-white my-[6px] hover:text-white-2"
            onClick={() => setSelectedSupport(2)}
          >
            Shipping Policy
          </Link>
        </div>
      </div>
      <div className="w-full flex items-start justify-between p-5 pt-0">
        <div className="w-[25%]">
          <img loading="lazy" src={cyclecircleLogo} alt="" className="h-[50px] mt-2" />
        </div>
        <div className="flex flex-col items-start w-[75%]">
          <h3 className="text-20 text-white font-extrabold tracking-wide mb-3">
            Contact
          </h3>
          <div className="flex items-start text-white-1">
            <LocationOnIcon className="text-16 text-white-1 mr-2" />
            <p className="text-14 text-white-1 font-bold leading-tight">
              <b className="text-white-1 text-[13px]">
                RADKAAT VENTURES PRIVATE LIMITED
              </b>
              &nbsp;1st Floor, Unit no.124, Paras Trade Centre, Gurgaon
              Faridabad Road, Gwal Pahari, Gurugram, District, Gurugram, Haryana
              122003
              <br />
              <b className="text-white-1 text-14">GST: 02AAMCR8372H1Z4</b>
            </p>
          </div>
          <div className="flex items-center mt-2">
            <PhoneIcon className="text-16 text-white-1 mr-2" />
            <p className="text-16 text-white font-bold">+91-9429693000 </p>
          </div>
          <div className="flex items-center mt-2">
            <MailIcon className="text-16 text-white-1 mr-2" />
            <p className="text-16 text-white font-bold">
              support@cyclecircle.one{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-start bg-blue-dark pt-2 pb-[75px]">
        <h3 className="text-20 text-white font-extrabold tracking-wide mb-2">Stay Connected</h3>
        <div className="flex justify-center space-x-[15px]">
          <Link to="https://www.instagram.com/cyclecircle.one" target="_black">
            <img loading="lazy"
              src="https://cdn-icons-png.flaticon.com/128/2111/2111463.png"
              alt="instagram"
              className="w-[20px]"
            />
          </Link>
          <Link
            to="https://www.facebook.com/profile.php?id=100083101911813&_rdr"
            target="_black"
          >
            <img loading="lazy"
              src="https://cdn-icons-png.flaticon.com/128/733/733547.png"
              alt="fb"
              className="w-[20px]"
            />
          </Link>
          <Link to="https://www.youtube.com/@cyclecircle5922" target="_black">
            {" "}
            <img loading="lazy"
              src="https://cdn-icons-png.flaticon.com/128/3938/3938026.png"
              alt="youtube"
              className="w-[20px]"
            />
          </Link>
        </div>
        <div className="w-full flex flex-col items-center justify-center mt-3">
          <h3 className="text-18 text-white font-extrabold tracking-wide mb-2">
            Secure Online Payment
          </h3>
          <div className="flex space-x-[15px]">
            <FaCcMastercard className="text-[30px]"/>
            <FaCcAmex className="text-[30px]"/>
            <FaCcVisa className="text-[30px]"/>
            <FaCcDiscover className="text-[30px]"/>
          </div>
        </div>
        <div className="w-full flex justify-center mt-6">
          <span className="text-12 text-white font-bold">
            © Radkaat Ventures Private Limited @2024, All Rights Reserved
          </span>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  setSelectedSupport,
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileFooter);
