import React from "react";
import "./ShopByCategories.css";
import { useDispatch } from "react-redux";
import {
  fetchAllCategoryProductsAsync,
} from "../../features/listingPage/listingPageSlice";
import { useNavigate } from "react-router";
import cratoniLogo from "../../img/banners/cratoni-category.png";
import funnLogo from "../../img/banners/funn-category.png";
import moonLogo from "../../img/banners/moon-category.png";
import { Slide } from "react-reveal";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { selectUser } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";


function ShopByCategories() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);

  const handleFetchCategoryProduct = (catID,catName) =>{
    dispatch(fetchAllCategoryProductsAsync(catID)).then(()=>{
      navigate(`/listing-page/${catID}/${catName}`);
    })
  }
  
  const handleBrandNavigate = (brand,e)=>{
    e.preventDefault();
    navigate(`/listing-page/${brand}`);
    e.stopPropagation();
  }

  return (
    <div className="shop-by-categories-container">
      <div className="shop-by-categories-heading-container">
        <span className="shop-by-categories-heading-span">
          Shop By Category
        </span>
      </div>
      <div className="shop-by-categories-main-container">
        <div className="shop-by-categories-each-category-container" onClick={()=>handleFetchCategoryProduct(9,"Helmets")}>
          <img loading="lazy"
            src="https://www.mtb-mag.com/wp-content/uploads/2019/05/unnamed-2-1-1100x1100.jpg"
            alt=""
            className="shop-by-categories-each-category-image"
          />
          <img loading="lazy" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQohbUwx79PkQDbs4YO695jU0OXIAmfqRB-0jAxz05cag&s" alt="" className="shop-by-categories-each-category-img" onClick={(e)=>handleBrandNavigate("cratoni",e)}/>
          <span className="shop-by-categories-each-category-name">Helmets</span>
          <span className="shop-by-category-each-category-offers">
          {Object.keys(userDetail).length > 0 ? "30-35":<VisibilityOffOutlinedIcon style={{margin:"0 0.2vw",fontSize:"1.5vw"}}/>}% Dealer Margin
          </span>
        </div>
        <div className="shop-by-categories-each-category-container" onClick={()=>handleFetchCategoryProduct(8,"Lights")}>
          <img loading="lazy"
            src="https://www.knog.com/cdn/shop/files/knog_big_cobber_12195_lifestyle_10_b6f8094a-9f10-4f4e-ad4e-a83f405db5ab_1800x.jpg?v=1689214371"
            alt=""
            className="shop-by-categories-each-category-image"
          />
          <img loading="lazy" src="https://www.handshake.fi/wp-content/uploads/2018/06/logo-moon.png" alt="" className="shop-by-categories-each-category-img" onClick={(e)=>handleBrandNavigate("moon sports",e)}/>
          <span className="shop-by-categories-each-category-name">Lights</span>
          <span className="shop-by-category-each-category-offers">
          {Object.keys(userDetail).length > 0 ? "25-30":<VisibilityOffOutlinedIcon style={{margin:"0 0.2vw",fontSize:"1.5vw"}}/>}% Dealer Margin
          </span>
        </div>
        <div className="shop-by-categories-each-category-container" onClick={()=>handleFetchCategoryProduct(1,"Cockpit")}>
          <img loading="lazy"
            src="https://i.pinimg.com/550x/87/dc/ba/87dcbae94ff203025785c6fb52a42c1c.jpg"
            alt=""
            className="shop-by-categories-each-category-image" 
          />
          <img loading="lazy" src="https://images.bike24.com/i/mb/0c/67/20/logofunn-3903.jpg" alt="" className="shop-by-categories-each-category-img" onClick={(e)=>handleBrandNavigate("funn",e)}/>
          <span className="shop-by-categories-each-category-name">cockpit</span>
          <span className="shop-by-category-each-category-offers">
          {Object.keys(userDetail).length > 0 ? "25-30":<VisibilityOffOutlinedIcon style={{margin:"0 0.2vw",fontSize:"1.5vw"}}/>}% Dealer Margin
          </span>
        </div>
      </div>
    </div>
  );
}

export default ShopByCategories;
