import React from "react";
import "./SignupSideBarStatus.css";
import DoneIcon from "@mui/icons-material/Done";
import CyclecircleLogo from "../../../img/parts/Cycle-Circle-Logo-Orange-1.png";
import { useNavigate } from "react-router";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function SignUpSideBarStatus({ currentStep, steps }) {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };
  return (
    <div className="signup-sidebar-container">
      <div className="sign-up-website-logo-container">
        <LazyLoadImage
          src={CyclecircleLogo}
          alt=""
          className="sign-up-website-logo-img"
          onClick={handleNavigate}
          effect="blur"
          wrapperProps={{
            // If you need to, you can tweak the effect transition using the wrapper style.
            style: { transitionDelay: "1s" },
          }}
        />
      </div>
      <div className="signup-sidebar-main-container">
        {steps.map((step, index) => (
          <div key={index} className="signup-sidebar-each-information-div">
            <div
              className={`signup-sidebar-each-info-dot-div ${
                index < currentStep ? "completed" : ""
              } ${index === currentStep ? "active-step" : ""}`}
            >
              {index < currentStep ? (
                <DoneIcon />
              ) : (
                <span
                  className={`signup-sidebar-each-info-dot ${
                    index < currentStep ? "completed" : ""
                  } ${index === currentStep ? "active-step" : ""}`}
                ></span>
              )}
            </div>
            <span
              className={`signup-sidebar-each-info-name ${
                index < currentStep ? "completed" : ""
              } ${index === currentStep ? "active-step" : ""}`}
            >
              {step.name}
            </span>
            {index !== steps.length - 1 && (
              <hr
                className={`signup-sidebar-each-info-hr ${
                  index < currentStep ? "completed" : ""
                }  ${index === currentStep ? "active-step" : ""}`}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SignUpSideBarStatus;
