import React, { useState, useEffect, useRef, useCallback } from "react";
import "./ProductSlider.css";
import "./Detail.css";
import ProductPromices from "./ProductPromices";
import { IMAGE_BASE } from "../../config";
import ReactImageMagnify from "react-image-magnify";
import { useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function ProductSlider({ selectProduct }) {
  const selectedVariant = useSelector(
    (state) => state.productDetail?.selectedVariant
  );
  // if selectedVariant.SKU_ID is available into selectProduct[0].SKU_Images,then show this image, if not available then show  selectProduct[0].images array
  const [sliderState, setSliderState] = useState({
    slideIndex: 1,
    images: [],
    width: 0,
    start: 0,
    change: 9,
    hovering: false,
    bigImage: "",
  });

  const [isMagnified, setIsMagnified] = useState(false);
  const [open, setOpen] = useState(false);
  const handleCloses = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const slideRef = useRef();

  useEffect(() => {
    handleOpen();
    if (selectProduct && selectProduct?.length > 0) {
      const selectedImages = selectProduct[0]?.SKU_Images?.find(
        (product) => product?.SKU_ID === selectedVariant?.SKU_ID
      );
      let imagesArray = [];
      if (selectedImages) {
        imagesArray = selectedImages?.images.map(
          (imageUrl) =>
            `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${imageUrl}`
        );
        handleCloses();
      } else if (selectProduct[0]?.images) {
        imagesArray = selectProduct[0]?.images[0]?.map(
          (imageUrl) =>
            `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${imageUrl}`
        );
        handleCloses();
      }
      setSliderState((prevState) => ({
        ...prevState,
        images: imagesArray?.slice(0, 5),
        bigImage: imagesArray && imagesArray.length > 0 && imagesArray[0],
        slideIndex: 1,
      }));
    }
  }, [selectProduct, selectedVariant]);

  useEffect(() => {
    if (!slideRef.current) return;

    const scrollWidth = slideRef.current.scrollWidth;
    const childrenElementCount = slideRef.current.childElementCount;
    const width = scrollWidth / childrenElementCount;

    setSliderState((prevState) => ({
      ...prevState,
      width: width,
    }));
  }, []);

  const plusSlides = useCallback((n) => {
    if (sliderState.images.length === 0) {
      // Handle the case when images are not yet loaded
      return;
    }

    const newIndex =
      ((sliderState.slideIndex - 1 + n + sliderState.images.length) %
        sliderState.images.length) +
      1;
    setSliderState((prevState) => ({
      ...prevState,
      slideIndex: newIndex,
      bigImage: sliderState.images[newIndex - 1],
    }));
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (!sliderState.hovering) {
        plusSlides(1);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [sliderState.hovering, sliderState.slideIndex, plusSlides]);

  function handleDragStart(e) {
    setSliderState((prevState) => ({
      ...prevState,
      start: e.clientX,
    }));
  }

  function handleDragOver(e) {
    const touch = e.clientX;
    setSliderState((prevState) => ({
      ...prevState,
      change: prevState.start - touch,
    }));
  }

  function handleDragEnd() {
    if (sliderState.change > 0) {
      slideRef.current.scrollLeft += sliderState.width;
    } else {
      slideRef.current.scrollLeft -= sliderState.width;
    }
  }

  useEffect(() => {
    if (!slideRef.current || !sliderState.width) return;

    const numOfThumb = Math.round(
      slideRef.current.offsetWidth / sliderState.width
    );
    slideRef.current.scrollLeft =
      sliderState.slideIndex > numOfThumb
        ? (sliderState.slideIndex - 1) * sliderState.width
        : 0;
  }, [sliderState.width, sliderState.slideIndex]);

  return (
    <div className="product-page-img">
      <div className="big-images">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open} // Show backdrop when either open or filtering state is true
          onClick={handleCloses}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="mySlider-div">
          <div
            className="mySlides"
            onMouseEnter={() =>
              setSliderState((prevState) => ({ ...prevState, hovering: true }))
            }
            onMouseLeave={() =>
              setSliderState((prevState) => ({ ...prevState, hovering: false }))
            }
          >
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: "Wristwatch by Ted Baker London",
                  isFluidWidth: true,
                  src: sliderState.bigImage,
                },
                largeImage: {
                  src: sliderState.bigImage,
                  width: 1800,
                  height: 1200,
                },
                enlargedImageContainerClassName: "largeImageContainer",
                className: "largeImageStyle",
                enlargedImageClassName: "largeImageImg",
                imageClassName: "smallImageImg",
                isHintEnabled: true,
                shouldHideHintAfterFirstActivation: true,
                isActivatedOnTouch: true,
                shouldHideMagnifierHint: () => setIsMagnified(true),
                shouldShowHint: () => !isMagnified,
              }}
            />
          </div>
          <div
            className="mobile-mySlides"
            onMouseEnter={() =>
              setSliderState((prevState) => ({ ...prevState, hovering: true }))
            }
            onMouseLeave={() =>
              setSliderState((prevState) => ({ ...prevState, hovering: false }))
            }
          >
            <LazyLoadImage
              className="mobile-big-image"
              src={sliderState.bigImage}
              alt=""
              effect="blur"
              wrapperProps={{
                // If you need to, you can tweak the effect transition using the wrapper style.
                style: { transitionDelay: "1s" },
              }}
            />
          </div>
        </div>
        <span className="prev" onClick={() => plusSlides(-1)}>
          &#10094;
        </span>
        <span className="next" onClick={() => plusSlides(1)}>
          &#10095;
        </span>
      </div>

      <div
        className="slider-img"
        draggable={true}
        ref={slideRef}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        {sliderState.images.map((image, index) => (
          <div
            key={index}
            className={`slider-box ${
              index + 1 === sliderState.slideIndex ? "active" : ""
            }`}
            onClick={() => {
              setSliderState((prevState) => ({
                ...prevState,
                slideIndex: index + 1,
                bigImage: image,
              }));
            }}
          >
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open} // Show backdrop when either open or filtering state is true
              onClick={handleCloses}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <LazyLoadImage
              src={image}
              alt=""
              effect="blur"
              wrapperProps={{
                // If you need to, you can tweak the effect transition using the wrapper style.
                style: { transitionDelay: "1s" },
              }}
            />
          </div>
        ))}
      </div>
      <ProductPromices />
    </div>
  );
}

export default ProductSlider;
