import React from "react";
import "./NeedHelp.css";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  borderRadius: "10px",
  margin: "10px",
  "&:not(:last-child)": {
    borderRadius: "10px",
  },
  "&::before": {
    display: "none",
    borderRadius: "10px",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  borderRadius: "5px",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function NeedHelp() {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleMailShare = () => {
    const subject = encodeURIComponent("Subject");
    const body = encodeURIComponent("Body ");
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  };
  return (
    <div className="need-help-container">
      <div className="need-help-linear-line">
        <span className="need-help-linear-line-span">
          Need some help?{" "}
          <Link to="https://wa.me/9429693000" target="_blank" className="need-help-chat-now">
            Chat Now
          </Link>{" "}
          or Shoot an <span className="need-help-email" onClick={handleMailShare}>Email</span>
        </span>
      </div>
      <div className="need-help-faq-container">
        <h3>FAQ for Checkout Process</h3>
        <div className="accordion-width">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>How can I create an account?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You can create your account in Signup by providing necessary
                details.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>Can I track my order status?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes,you can track your order whenever you want by navigating to
                your My orders page in Your profile section.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>What shipping options are available?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Cyclecircle provides you two shipping options: 1. Standard
                shipping In Standard shipping you will get free delivery on
                purchasing above $3000.00. 2. Express Shipping In Express
                shipping you should pay a certain amount according to your
                delivery address even you purchase above $3000.00
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Typography>Do you ship internationally?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>No, now we do not ship internationally.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              aria-controls="panel5d-content"
              id="panel5d-header"
            >
              <Typography>
                What happens if my package is damaged or lost?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We have trusted return policy. You can raise the complaint to
                return the product.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              aria-controls="panel6d-content"
              id="panel6d-header"
            >
              <Typography>What is your return policy?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We offer a 30-day return policy for most items. You can return
                your items for a full refund or exchange by following the
                instructions provided on our returns page.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <AccordionSummary
              aria-controls="panel7d-content"
              id="panel7d-header"
            >
              <Typography>What payment methods do you accept?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We accept all major credit cards, debit cards, and PayPal.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <AccordionSummary
              aria-controls="panel8d-content"
              id="panel8d-header"
            >
              <Typography>Do you charge any additional fees?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We only charge the shipping and tax rates applicable to your
                order.There are no hidden or surcharges.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default NeedHelp;
