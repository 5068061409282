import React from "react";
import { Dialog, DialogContent, Button } from "@mui/material";

const Fulfilled = ({fFilled, handleFulfilled, wantToFulfilled, user }) => {
  return (
    <Dialog
      open={fFilled}
      onClose={handleFulfilled}
      fullWidth
      maxWidth="xs"
    >
      <DialogContent className="bg-white p-6 rounded-md">
        <h3 className="text-lg font-semibold text-center">Fulfilled?</h3>
        <span className="block text-sm text-gray-600 text-center my-4">
          Are you sure this package is fulfilled?
        </span>
        <div className="flex justify-center space-x-4 mt-4">
          {/* No Button */}
          <Button
            onClick={() => handleFulfilled(null)}
            variant="contained"
            className="bg-gray-300 text-black py-2 px-4 rounded-full hover:bg-gray-400"
            sx={{
              textTransform: "none",
              fontWeight: "500",
              background:"rgba(0,0,0,0.1)",
              color:"rgba(0,0,0,0.8)",
              "&:hover":{
                background:"rgba(0,0,0,0.1)",
                color:"rgba(0,0,0,0.8)"
              }
            }}
          >
            No
          </Button>
          {/* Yes Button */}
          <Button
            onClick={() => wantToFulfilled(user?.UserID)}
            variant="contained"
            className="bg-[#ba3737] text-white py-2 px-4 rounded-full hover:bg-[#a13232]"
            sx={{
              textTransform: "none",
              fontWeight: "500",
              background:"#ba3737",
              "&:hover":{
                background:"#ba3737",
              }
            }}
          >
            Yes
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Fulfilled;
