import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchUser,
  sendEmail,
  verifyOTP,
  setPassword,
  signUp,
  updateProfileImage,updateProfileDetail
} from "./authAPI";

const initialState = {
  user: {},
  status: "idle",
  token: null,
  phoneNumber:null,
  saveData:null
};

export const signUpAsync = createAsyncThunk(
  "user/signUp",
  async (signUpData) => {
    try {
      const response = await signUp(signUpData);

      const data = await response;
      return data;
    } catch (error) {
      // console.error("Error fetching user data:", error);
      throw error;
    }
  }
);
export const updateProfileImageAsync = createAsyncThunk(
  "user/updateProfileImage",
  async (imageData) => {
    try {
      const response = await updateProfileImage(imageData);

      const data = await response;
      return data;
    } catch (error) {
      // console.error("Error fetching user data:", error);
      throw error;
    }
  }
);
export const updateProfileDetailAsync = createAsyncThunk(
  "user/updateProfileDetail",
  async (updateData) => {
    try {
      const response = await updateProfileDetail(updateData);

      const data = await response;
      return data;
    } catch (error) {
      // console.error("Error fetching user data:", error);
      throw error;
    }
  }
);
export const fetchUserAsync = createAsyncThunk(
  "user/fetchUser",
  async (userID, { getState }) => {
    const token = selectToken(getState()); // Using the selectToken selector from the auth slice
    try {
      const response = await fetchUser(token, userID);

      // if (!response.ok) {
      //   throw new Error("Failed to fetch user data");
      // }

      const data = await response[0];
      return data;
    } catch (error) {
      // console.error("Error fetching user data:", error);
      throw error;
    }
  }
);
export const sendEmailAsync = createAsyncThunk(
  "user/sendEmail",
  async (emailData) => {
    try {
      const response = await sendEmail(emailData);

      const data = await response;
      return data;
    } catch (error) {
      // console.error("Error fetching user data:", error);
      throw error;
    }
  }
);
export const verifyOTPAsync = createAsyncThunk(
  "user/verifyOTP",
  async (otpData) => {
    try {
      const response = await verifyOTP(otpData);

      const data = await response;
      return data;
    } catch (error) {
      // console.error("Error fetching user data:", error);
      throw error;
    }
  }
);
export const setPasswordAsync = createAsyncThunk(
  "user/setPassword",
  async (passwordData) => {
    try {
      const response = await setPassword(passwordData);

      const data = await response;
      return data;
    } catch (error) {
      // console.error("Error fetching user data:", error);
      throw error;
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUser:(state,action) =>{
      state.user = action.payload;
    },
    setPhoneNumbers:(state,action)=>{
      state.phoneNumber = action.payload;
    },
    setSaveData:(state,action)=>{
      state.saveData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload;
      })
      .addCase(sendEmailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendEmailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // Handle the action payload if needed
      })
      .addCase(verifyOTPAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyOTPAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // Handle the action payload if needed
      })
      .addCase(setPasswordAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(setPasswordAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // Handle the action payload if needed
      })
      .addCase(signUpAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signUpAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // state.user = action.payload;
      })
      .addCase(updateProfileDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProfileDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // state.user = action.payload;
      })
      .addCase(updateProfileImageAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProfileImageAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // state.user = action.payload;
      });
  },
});

export const { setToken,setUser,setPhoneNumbers,setSaveData } = authSlice.actions;

export const selectUser = (state) => state.auth.user;
export const selectToken = (state) => state.auth.token;
export const selectPhoneNumber = (state) =>state.auth.phoneNumber;
export const selectSaveData = (state) => state.auth.saveData;

export default authSlice.reducer;
