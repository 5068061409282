import React, { Suspense, lazy, useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Link,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import whatsappIcon from "./img/parts/whatsapp-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserAsync,
  selectPhoneNumber,
  selectUser,
} from "./features/auth/authSlice";
import { ccHundred } from "./features/cartPage/walletAPI";
import { ADMIN_USER } from "./config";
import { Toaster } from "react-hot-toast";
import { useMediaQuery } from "react-responsive";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { AboutUs } from "./components/EndPages/AboutUs";
import { Refund } from "./components/EndPages/Refund";
import { Shipping_Policy } from "./components/EndPages/Shipping_Policy";
import { UserAgreement } from "./components/EndPages/UserAgreement";
import { CopyRight } from "./components/EndPages/CopyRight";
import { Policy } from "./components/EndPages/Policy";

import ProductDescription from "./pages/productDescriptionPage";
import Wishlist from "./pages/wishlist";
import Carts from "./pages/carts";
import SharePopUp from "./components/ProductDescription/SharePopUp";
import ListingPage from "./pages/listingPage";
import SignUp from "./components/Authentication/SignUp/SignUp";
import OpenPdf from "./components/ProductDescription/OpenPdf";
import MyProfilepage from "./pages/MyProfilepage"
import ViewOrderPage from "./pages/ViewOrderPage";
import Supports from "./pages/supports"
import OurBeliefSystem from "./components/EndPages/OurBeliefSystem"
import MobileSignUp from "./mobileComponent/MobileAuthentication/MobileSignUp/MobileSignUp";
import EasyOnboarding from "./components/EasyOnboarding/EasyOnboarding";
import AdminDashbd from "./pages/AdminDashbd";

const Home = lazy(() => import("./pages/home"));

export const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return null;
};
function App() {
  const [loading, setLoading] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(1000);
  const [isScrolled, setIsScrolled] = useState(false);
  const [premUser, setPremUser] = useState(false);
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);

  function getCookie(name) {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith(`${name}=`));

    return cookieValue ? cookieValue.split("=")[1] : null;
  }

  const UserID = getCookie("UserID");

  useEffect(() => {
    if (UserID) {
      dispatch(fetchUserAsync(UserID));
    }
  }, [dispatch, UserID]);

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
  };

  useEffect(() => {
    const responsePromise = ccHundred(UserID);
    responsePromise
      .then((response) => {
        // console.log(response); // Ensure response is as expected
        if (response && response?.data && response?.data?.message) {
          setPremUser(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle error if necessary
      });
  }, [UserID]);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const bottomOffset = documentHeight - windowHeight - window.pageYOffset;
      setScrollPosition(bottomOffset);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const phoneNumber = useSelector(selectPhoneNumber);

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 501px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" });

  // Pre-loader effect
  useEffect(() => {
    window.addEventListener("load", () => {
      setTimeout(() => {
        setLoading(false); // Set loading to false after 1.5 seconds
      }, 1500);
    });
  }, []);

  if (loading) {
    return (
      <div id="pre-loader" className="pre-loader">
        Loading...
      </div>
    );
  }

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Toaster position="top-right" reverseOrder={true} />
        {window.location.pathname !== "/sign-up" &&
          window.location.pathname !== "/admin/dashboard" &&
          isDesktopOrLaptop && (
            <>
              <Link
                to="https://wa.me/9429693000"
                target="_blank"
                className="whatapp_button_link_to"
                style={{
                  bottom:
                    scrollPosition <= 400
                      ? Math.max(490, scrollPosition)
                      : "12vh",
                }}
              >
                <img loading="lazy" src={whatsappIcon} alt="WhatsApp Button"/>
              </Link>
              <div
                className="move-top-btn"
                onClick={() => scrollToTop()}
                style={{
                  bottom:
                    scrollPosition <= 400
                      ? Math.max(430, scrollPosition)
                      : "3.5vh",
                }}
              >
                <KeyboardArrowUpIcon id="move-top-arrow" />
                <span className="move-top-text">TOP</span>
              </div>
            </>
          )}
        {window.location.pathname !== "/sign-up" &&
          window.location.pathname !== "/admin/dashboard" &&
          window.location.pathname !== "/carts" &&
          isTabletOrMobile && (
            <>
              <Link
                to="https://wa.me/9429693000"
                target="_blank"
                className="whatapp_button_link_to"
                style={{
                  bottom: isScrolled ? "17vh" : "10vh",
                }}
              >
                <img loading="lazy" src={whatsappIcon} alt="WhatsApp Button"/>
              </Link>
              <div
                className={`move-top-btn-mobile ${
                  isScrolled ? "scrolled" : ""
                }`}
                onClick={() => scrollToTop()}
              >
                <KeyboardArrowUpIcon id="move-top-arrow-mobile" />
                <span className="move-top-text-mobile">TOP</span>
              </div>
            </>
          )}
        <div className="app-routes">
          <Suspense
            fallback={
              <div style={{ textAlign: "center", marginTop: "50px" }}>
                Loading...
              </div>
            }
          >
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/aboutUs" element={<AboutUs />} />
              <Route exact path="/refund" element={<Refund />} />
              <Route
                exact
                path="/our_belief_system"
                element={<OurBeliefSystem />}
              />
              <Route
                exact
                path="/shipping_policy"
                element={<Shipping_Policy />}
              />
              <Route exact path="/user_agreement" element={<UserAgreement />} />
              <Route exact path="/copyright" element={<CopyRight />} />
              <Route exact path="/privacypolicy" element={<Policy />} />
              <Route
                exact
                path="/product/:prod_ID"
                element={<ProductDescription />}
              />
              <Route
                exact
                path="/wishlist"
                element={UserID ? <Wishlist /> : <Navigate to="/" />}
              />
              <Route
                exact
                path="/carts"
                element={UserID ? <Carts /> : <Navigate to="/" />}
              />
              <Route exact path="/share" element={<SharePopUp />} />
              <Route
                exact
                path="/listing-page/:CategoryID/:categoryName"
                element={<ListingPage />}
              />
              <Route
                exact
                path="/listing-page/:brand"
                element={<ListingPage />}
              />

              <Route
                exact
                path="/sign-up"
                element={
                  !phoneNumber ? (
                    <Navigate to="/" />
                  ) : (
                    <>
                      {isDesktopOrLaptop && <SignUp />}
                      {isTabletOrMobile && <MobileSignUp />}
                    </>
                  )
                }
              />

              <Route exact path="/search/:text" element={<ListingPage />} />
              <Route exact path="/manual/:pdfFile" element={<OpenPdf />} />
              <Route
                exact
                path="/user/:profileUrl"
                element={UserID ? <MyProfilepage /> : <Navigate to="/" />}
              />
              <Route
                exact
                path="/user/orders/view_order/:order_ID"
                element={<ViewOrderPage />}
              />
              <Route exact path="/support" element={<Supports />} />
              <Route
                path="/admin/dashboard"
                element={
                  UserID && ADMIN_USER.includes(Number(UserID)) ? (
                    <AdminDashbd />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route exact path="/onboarding" element={<EasyOnboarding />} />
            </Routes>
          </Suspense>
        </div>
      </div>
    </Router>
  );
}

export default App;
