import React, { useEffect } from "react";
import "./Detail.css";
import ProductSlider from "./ProductSlider";
import ProductAllInfo from "./ProductAllInfo";
import TypeSelect from "./TypeSelect";
import ProductBasicDetail from "./ProductBasicDetail";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDealerProductDetailsAsync,
  fetchProductDetailsAsync,
  selectProductDetails,
  setSelectedProd_ID,
  setSelectedVariant,
} from "../../features/productDesc/productDescSlice";
import { useParams } from "react-router";
import Advantages from "./Advantages";
import ProductPrice from "./ProductPrice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
function Detail() {
  const { prod_ID } = useParams();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const selectedVariant = useSelector(
    (state) => state.productDetail.selectedVariant
  );

  const dispatch = useDispatch();
  const productSelect = useSelector(selectProductDetails);
  useEffect(() => {
    const fetchData = async () => {
      handleOpen();
      await dispatch(setSelectedProd_ID(prod_ID));
      if (prod_ID?.startsWith("9")) {
        await dispatch(fetchDealerProductDetailsAsync(prod_ID));
        handleClose();
      } else {
        await dispatch(fetchProductDetailsAsync(prod_ID));
        handleClose();
      }
    };
    fetchData();
    return () => clearTimeout(2000);
  }, [dispatch, prod_ID]);

  useEffect(() => {
    if (
      productSelect &&
      productSelect?.products &&
      productSelect.products?.length > 0 &&
      productSelect.products[0]?.skuDetails &&
      productSelect.products[0]?.skuDetails?.length > 0
    ) {
      if (selectedVariant?.length === 0) {
        dispatch(setSelectedVariant(productSelect?.products[0]?.skuDetails[0]));
      }
    }
  }, [dispatch, productSelect]);


  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section
        className="product-details"
        style={{ position: "relative", zIndex: "1 !important" }}
      >
        <div className="product-slider">
          <ProductSlider selectProduct={productSelect?.products} />
        </div>
        <div className="product-page-details">
          <ProductBasicDetail selectProduct={productSelect?.products} />
          <Advantages selectProduct={productSelect?.products} />
          <ProductPrice selectProduct={productSelect?.products} />
          <TypeSelect
            selectProduct={productSelect?.products}
            qtys={productSelect?.quantity && productSelect?.quantity}
          />
        </div>
      </section>
      <ProductAllInfo productDesc={productSelect?.products} />
    </React.Fragment>
  );
}

export default Detail;
