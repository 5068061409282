import React from "react";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { fetchAllCategoryProductsAsync } from "../features/listingPage/listingPageSlice";
import { shopByCtgData } from "../data";
import { selectUser } from "../features/auth/authSlice";

export const CtgCard = ({ userDetail, category }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFetchCategoryProduct = (catID, catName) => {
    dispatch(fetchAllCategoryProductsAsync(catID)).then(() => {
      navigate(`/listing-page/${catID}/${catName}`);
    });
  };

  const handleBrandNavigate = (brand, e) => {
    e.preventDefault();
    navigate(`/listing-page/${brand}`);
    e.stopPropagation();
  };
  return (
    <div
      key={category.id}
      className=" w-full sm:w-[30vw] h-[90vw] sm:h-[30vw] relative cursor-pointer overflow-hidden group before:bg-black-600"
      onClick={() =>
        handleFetchCategoryProduct(category.categoryID, category?.category)
      }
    >
      <img loading="lazy"
        src={category?.background}
        alt={category?.category}
        className="w-full h-full object-cover transition-transform duration-300 ease-in group-hover:scale-105 before-overlay group-hover-befor-overlay"
      />
      <img loading="lazy"
        src={category?.brandLogo}
        alt={category?.brandName}
        className="absolute bottom-[20px] left-[20px] max-sm:h-[40px] h-[30px] md:h-[40px] max-sm:min-w-[100px] min-w-[80px] md:min-w-[100px] object-cover z-10 opacity-0 transform translate-y-full transition-opacity duration-300 ease-in-out group-hover:opacity-100 group-hover:translate-y-0 bg-white"
        onClick={(e) => handleBrandNavigate(category?.brandName, e)}
      />
      <span className="absolute bottom-[20px] text-center right-[20px] max-sm:text-[20px] text-[16px] uppercase tracking-wider font-extrabold text-white z-10 group-hover:top-[50%] group-hover:left-1/2 group-hover:transform group-hover:-translate-x-1/2 group-hover:-translate-y-1/2 group-hover:text-[40px] transition-all duration-300">
        {category?.category}
      </span>
      <span className="hidden group-hover:flex items-center absolute top-[55%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-20 font-bold text-white tracking-wide transition-opacity duration-300 whitespace-nowrap">
        {Object.keys(userDetail).length > 0 ? (
          category?.margin
        ) : (
          <VisibilityOffOutlinedIcon className="mx-1 text-[28px]" />
        )}
        % Dealer Margin
      </span>
    </div>
  );
};
const ShopByCtgCard = () => {
  const userDetail = useSelector(selectUser);
  return (
    <div className="w-full h-auto flex flex-col items-center justify-start py-1 bg-white-1">
      <div className="my-5">
        <span className="text-2xl text-blue font-extrabold tracking-wide capitalize">
          Shop By Category
        </span>
      </div>
      <div className=" w-full flex flex-col sm:flex-row items-center justify-between sm:px-[2.5vw]">
        {shopByCtgData?.map((category) => (
          <CtgCard userDetail={userDetail} category={category} />
        ))}
      </div>
    </div>
  );
};

export default ShopByCtgCard;
