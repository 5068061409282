import PropTypes from "prop-types";
import React, { useState } from "react";
import "./ReviewTypesStar.css";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";

function ReviewTypesStar({ onRatingChange }) {
  const [totalStars, setTotalStars] = useState(0);

  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <div
          key={i}
          className="reviewTypesStar-starIcon"
          onClick={() => toggleIndividualStar(i)}
        >
          {i < totalStars ? (
            <StarRoundedIcon />
          ) : (
            <StarOutlineRoundedIcon />
          )}
        </div>
      );
    }
    return stars;
  };

  const toggleIndividualStar = (index) => {
    setTotalStars(index + 1);
    onRatingChange(index + 1);
  };

  return (
    <div className="reviewTypesStar-ratings">
      <div className="reviewTypesStar-rating">{renderStars()}</div>
      <span className="reviewTypesStar-total-star-count">({totalStars})</span>
    </div>
  );
}

export default ReviewTypesStar;

ReviewTypesStar.propTypes = {
  onRatingChange: PropTypes.func.isRequired,
};
