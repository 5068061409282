import React from "react";
import "./Refund.css";
import styled from "styled-components";
import Header from "../HomePage/Header";
import Footer from "../HomePage/Footer";
import ScrollPromices from "../HomePage/ScrollPromices";
import { Link } from "react-router-dom";
import BottomPromises from "../HomePage/BottomPromises";
import PopularSearch from "../HomePage/PopularSearch";
import { useMediaQuery } from "react-responsive";
import MobileHeader from "../../mobileComponent/MobileEndpoints/MobileHeader";
import MobilePopularSearch from "../../mobileComponent/MobileEndpoints/MobilePopularSearch";
import MobileFooter from "../../mobileComponent/MobileEndpoints/MobileFooter";

export function Refund() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 501px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  return (
    <>
      {isDesktopOrLaptop && (
        <HeaderDiv>
          <ScrollPromices />
          <Header />
        </HeaderDiv>
      )}
      {isTabletOrMobile && (
        <MobileHeaderDiv>
          <MobileHeader />
        </MobileHeaderDiv>
      )}

      <div className="refund_container">
        <h2>REFUND &amp; RETURNS POLICY</h2>
        <p>
          Radkaat Ventures Private Limited, henceforth referred to as
          CycleCircle, is committed to providing a supportive and
          customer-friendly return policy for our partners. We are here to
          assist you in various scenarios.
        </p>
        <p>
          If a product is delivered to you with damaged packaging by our courier
          partner, kindly refuse to accept the package. This action ensures that
          the liability remains with the courier service, and we will manage the
          returns and exchanges for all items that reach you with their
          packaging intact.
        </p>
        <p>
          Please be aware that bicycles purchased from Cyclecircle.one must be
          assembled at an authorized brand store to qualify for warranty.
        </p>
        <h2>RETURNS &amp; EXCHANGES</h2>
        <p>In the following cases, please email us:</p>
        <ol>
          <li>
            <b>Functioning Defect:</b> If, for instance, a pump is not working,
            email us within 7 days for a refund or exchange.
          </li>
          <li>
            <b>Wrong Item:</b> If you receive an incorrect item, notify us
            within 48 hours for a refund or exchange.
          </li>
          <li>
            <b>Damaged Item:</b> For items with missing or broken parts, email
            us within 48 hours for a refund or exchange.
          </li>
          <li>
            <b>Size Issue for Apparels:</b> Email us within 48 hours for an
            exchange. If the desired size is unavailable, you will receive store
            credit. A size is considered incorrect if it does not match the
            description on our site. We advise reading the size chart carefully
            before ordering.
          </li>
          <li>
            <b>Cancellation:</b> If the item has not been shipped yet, you may
            cancel and receive store credit.
          </li>
        </ol>
        <p>
          Shipped items cannot be returned unless they meet the conditions
          outlined below. To check eligibility, visit our Returns &amp; Exchange
          Portal and provide photos/videos of the issue.
        </p>
        <h2>CONDITION FOR RETURN/EXCHANGE</h2>
        <p>Items send back must:</p>
        <ul>
          <li>Have all tags attached as originally provided.</li>
          <li>
            Be in the original, unripped packaging (excluding the courier bag).
          </li>
          <li>Be in a resellable condition: unused, unworn, and unwashed.</li>
        </ul>
        <h2>EXCLUSIONS FROM RETURNS/EXCHANGES</h2>
        <p>The following are not eligible for return or exchange:</p>
        <ul>
          <li>Products purchased during sales.</li>
          <li>Intimate-use items such as cycling shorts and socks.</li>
          <li>Shimano products, unless damaged or incorrect.</li>
          <li>Bicycles.</li>
          <li>Nutrition items.</li>
          <li>Tires.</li>
        </ul>
        <h2>HOW TO PROCESS A RETURN</h2>
        <p>
          To initiate a return, visit our Returns &amp; Exchange Portal to check
          eligibility and submit your requests. All refunds, exchanges, or store
          credits will be processed after we receive and inspect the item. The
          resolution is contingent on the product being in a resellable
          condition and the issue being valid.
        </p>
        <h2>WARRENTY</h2>
        <p>
          Issues reported after 7 days of receiving an item are treated as
          warranty issues. Our team will assist in facilitating genuine warranty
          cases with our suppliers or connect you with their official
          representatives in India for direct resolution, if required. For any
          disputes regarding warranty resolution, we recommend contacting the
          brand directly. Our support team can provide contact details where
          possible.
        </p>
      </div>
      {isDesktopOrLaptop && (
        <>
          <BottomPromises />
          <PopularSearch />
          <Footer />
        </>
      )}
      {isTabletOrMobile && (
        <>
          <MobilePopularSearch />
          <MobileFooter />
        </>
      )}
    </>
  );
}

const HeaderDiv = styled.div`
  width: 100%;
  height: 80px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;

const MobileHeaderDiv = styled.div`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;