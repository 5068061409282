import React, { useState } from "react";
import "./MobilePhoneAndEmailChange.css";
import { useSelector } from "react-redux";

import { selectUser } from "../../features/auth/authSlice";
import MobileChangeEmail from "./MobileChangeEmail";
import MobileChangePhone from "./MobileChangePhone";

function MobilePhoneAndEmailChange() {
  const userDetail = useSelector(selectUser);
  const [openChangeVerify, setOpenChangeVerify] = useState(null);

  const formattPhoneNumber = (phoneNumber) => {
    if (phoneNumber) {
      // Remove any existing dashes and spaces from the phone number
      phoneNumber = phoneNumber.replace(/[-\s]/g, "");

      // Insert dashes at appropriate positions (e.g., XXX-XXX-XXXX)
      phoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
      return phoneNumber;
    }
  };
  const handleChangeVerify = (value) => {
    setOpenChangeVerify(value);
  };
  return (
    <>
      {!openChangeVerify ? (
        <div className="mobile-phone-and-email-change-container">
          <div className="mobile-phone-change-main-container">
            <img loading="lazy"
              src="https://cdn-icons-png.flaticon.com/128/4477/4477610.png"
              alt=""
              className="mobile-a-phone-change-img-address"
            />
            <span className="mobile-phone-change-name">Phone Number</span>
            <span className="mobile-phone-change-phone-number">
              {userDetail && formattPhoneNumber(userDetail?.PhoneNumber)}
            </span>
            <span
              className="mobile-phone-change-btn"
              onClick={() => handleChangeVerify("Phone")}
            >
              Change Phone Number
            </span>
          </div>
          <div className="mobile-email-change-main-container">
            <img loading="lazy"
              src="https://cdn-icons-png.flaticon.com/128/732/732200.png"
              alt=""
              className="mobile-a-email-change-img-address"
            />
            <span className="mobile-email-change-name">Email Address</span>
            <span className="mobile-email-change-email-address">
              {userDetail && userDetail?.Email}
            </span>
            <span
              className="mobile-email-change-btn"
              onClick={() => handleChangeVerify("Email")}
            >
              Change Email Address
            </span>
          </div>
        </div>
      ) : (
        <>
          {openChangeVerify === "Phone" && (
            <MobileChangePhone handleChangeVerify={handleChangeVerify} />
          )}
          {openChangeVerify === "Email" && (
            <MobileChangeEmail handleChangeVerify={handleChangeVerify} />
          )}
        </>
      )}
    </>
  );
}

export default MobilePhoneAndEmailChange;
