import React, { useState } from "react";
import "./ListingPagination.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

function ListingPagination({ totalItems, itemsPerPage, onPageChange,currentPage,setCurrentPage }) {
  // const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    if (page <= 1) {
      setCurrentPage(1);
      onPageChange(1);
    } else if (page > totalPages) {
      setCurrentPage(totalPages);
      onPageChange(totalPages);
    } else {
      setCurrentPage(page);
      onPageChange(page);
    }
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleToOne = () => {
    setCurrentPage(1);
    onPageChange(1);
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleToEnd = () => {
    setCurrentPage(totalPages);
    onPageChange(totalPages);
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="listing-pagination-container">
      <div className="listing-pagination-total">
        <span className="listing-pagination-total-span">
          Page {currentPage} of {totalPages}
        </span>
      </div>
      <div className="listing-pagination-main-container">
        {currentPage > 1 && (
          <div
            className="listing-pagination-prev-container"
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <NavigateBeforeIcon />
            <span>Previous</span>
          </div>
        )}
        <div className="listing-pagination-total-number-container">
          {Array.from({ length: totalPages }).map((_, i) => {
            if (
              i === 0 ||
              i === totalPages - 1 ||
              (i >= currentPage - 2 && i <= currentPage + 2)
            ) {
              return (
                <span
                  key={i}
                  onClick={() => handlePageChange(i + 1)}
                  style={{
                    background: currentPage === i + 1 ? "var(--color-blue)" : "#f3f3f3",
                    color: currentPage === i + 1 ? "white" : "black",
                  }}
                >
                  {i + 1}
                </span>
              );
            } else if (
              (i === currentPage - 3 && currentPage > 4) ||
              (i === currentPage + 3 && currentPage < totalPages - 3)
            ) {
              return <span key={i}>...</span>;
            }
            return null;
          })}
        </div>
        {currentPage < totalPages && (
          <div
            className="listing-pagination-next-container"
            onClick={() => handlePageChange(currentPage + 1)}
          >
            <span>Next</span>
            <NavigateNextIcon />
          </div>
        )}
      </div>
    </div>
  );
}

export default ListingPagination;
