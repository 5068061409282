import {
    addToCartsAsync,
  fetchAllAdminUsersCartsAsync,
  fetchAllCartsAsync,
  fetchAllPreorderCartsAsync,
  fetchAllUserCartsAsync,
  removeFromCartsAsync,
  removeFromPreorderCartsAsync,
} from "../features/cartPage/cartPageSlice";

export const AdminRemoveFromPreorders = (
  data,
  userID,
  dispatch,
  handleOpen,
  handleClose
) => {
  handleOpen();
  dispatch(removeFromPreorderCartsAsync(data?.id)).then(() => {
    dispatch(fetchAllPreorderCartsAsync(userID));
    handleClose();
  });
};
export const adminRemoveFromCarts = async (
  data,
  userID,
  dispatch,
  handleOpen,
  handleClose
) => {
  handleOpen();
  let cartData = {
    userID: userID,
    SKU: data?.skuDetails?.SKU_ID,
  };
  dispatch(removeFromCartsAsync(cartData)).then(() => {
    dispatch(fetchAllUserCartsAsync(userID))
    handleClose();
  });
};


export const adminChangeQtyOfProductCarts = (
    data,
    userID,
    newQty,
    dispatch,
    handleOpen,
    handleClose
  ) => {
    handleOpen();
    let cartData = {
      userID: userID,
      SKU: data?.skuDetails?.SKU_ID,
      buyQty: newQty,
    };
    if (newQty > 0 && newQty <= data?.skuDetails?.qty) {
      dispatch(addToCartsAsync(cartData)).then(() => {
        dispatch(fetchAllUserCartsAsync(userID))
        handleClose();
      });
    }
  };
