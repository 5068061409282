import React from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Button } from "@mui/material";
import ccLogo from "../../img/cc-blue.png";
import { Link } from "react-router-dom";
import { steps } from "../../utils/utils";

const CartsBreadcrum = ({ currentStep, setCurrentStep }) => {
  const handleStepClick = (stepIndex) => {
    if (stepIndex <= currentStep) {
      setCurrentStep(stepIndex);
    }
  };

  return (
    <div className="w-full h-[75px] py-3 px-4 md:px-5 flex items-center justify-between text-gray-700 bg-white-1 shadow-md">
      <Link
        to="/"
        className="absolute top-1/2 -translate-y-1/2 left-5 cursor-pointer"
      >
        <img loading="lazy" src={ccLogo} alt="cc-logo" className="h-[50px]" />
      </Link>
      <div className="flex items-center justify-center absolute left-1/2 transform -translate-x-1/2">
        {steps?.map((step, i) => (
          <div
            key={i}
            className={`step-item relative flex-center flex-col w-[100px] md:w-[150px] ${
              currentStep === i + 1 && "active"
            } ${i + 1 < currentStep && "complete"} `}
            onClick={() => handleStepClick(i + 1)}
          >
            <div
              className={`step w-[30px] h-[30px] p-1 flex-center z-1 relative rounded-full font-semibold ${
                currentStep > i + 1
                  ? "text-white-1 bg-green"
                  : "text-blue bg-gray-200"
              }`}
            >
              {step.icon}
            </div>
            <p
              className={`mt-1 text-[12px] md:text-[14px] ${
                currentStep > i + 1 ? "text-green" : "text-blue"
              }`}
            >
              {step.label}
            </p>
          </div>
        ))}
      </div>
      {currentStep > 1 && (
        <Button
          variant="Outlined"
          onClick={() => setCurrentStep(1)}
          sx={{
            height: "30px",
            border: "1.5px solid rgba(0,0,0,0.7)",
            borderRadius: "4px",
            padding: "5px",
            background: "transparent",
            fontSize: "14px",
            fontWeight: "500",
            color: "rgba(0,0,0,0.7)",
            textTransform: "none",
            paddingRight: "10px",
            display: "flex",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            right: "20px",
            justifyContent: "space-between",
            "&:hover": {
              background: "transparent",
            },
          }}
        >
          <KeyboardArrowLeftIcon
            className="text-black-1/70 mr-1"
            style={{ fontSize: "16px" }}
          />
          Back to Cart
        </Button>
      )}
    </div>
  );
};

export default CartsBreadcrum;
