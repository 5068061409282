import React from 'react';
import "./BikePro.css"

function BikePro() {
  return (
    <div className="bikepro-container">
      <div className="bikepro-brand-info">
        <p><strong>Bikepro</strong> is the brand name of Rider Bikes. Rider Bikes Pvt Ltd has perfected the craft of manufacturing bicycle components over the past five decades. Renowned for its reliable and affordable products, the company consistently delivers high-quality workmanship that meets international standards. They are the only manufacturer of Bicycle Sealed Cartridge Bottom bracket and alloy hubs in India.</p>
      </div>
      <div className="bikepro-product-line">
        <h2>Product Line:</h2>
        <ul>
          <li>BB Cartridges</li>
          <li>BB Semi Cartridge</li>
          <li>Headsets</li>
          <li>Alloy Hubs</li>
        </ul>
      </div>
      <div className="bikepro-highlights">
        <h2>Key Highlights:</h2>
        <ul>
          <li><strong>Exclusive Supplier:- </strong> Rider Bikes Pvt Ltd is the only supplier of BB Cartridges throughout India.</li>
          <li><strong>Global Reach:- </strong> Bikepro products are exported to countries such as Sri Lanka, Poland, Colombia, Germany, and Australia.</li>
          <li><strong>Superior Quality:- </strong> Bikepro’s quality surpasses most competitors, offering the best quality at the most competitive rates.</li>
          <li><strong>Certification:- </strong> Bikepro products are ISO 9001 certified and in the process of attaining IATF 16949:2016 certification.</li>
          <li><strong>Quality Assurance:- </strong> The company believes in rigorous self-inspection to maintain quality and production standards, adhering to CQI-9 and CQI-11 guidelines.</li>
        </ul>
      </div>
    </div>
  )
}

export default BikePro