import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import {
  getAllInventoryAsync,
  selectAllInventory,
} from "../../features/cartPage/cartPageSlice";
import { IMAGE_BASE } from "../../config";
import inventoryIcon from "../../assets/inventory.svg";
import NoCartItem from "../Carts/NoCartItem";
import { formatWithCommasWithDecimal } from "../../utils/utils";

const AdminInventory = () => {
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const inventory = useSelector(selectAllInventory);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredInventory, setFilteredInventory] = useState(inventory);
  const [sortCriteria, setSortCriteria] = useState("");
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail?.UserID) {
      handleOpen();
      dispatch(getAllInventoryAsync()).then(() => {
        handleClose();
      });
    }
  }, [dispatch, userDetail.UserID]);

  useEffect(() => {
    if (inventory) {
      setFilteredInventory(
        inventory.filter(
          (data) =>
            data?.SKU_ID?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
            data?.BarCode?.toLowerCase()?.includes(
              searchQuery?.toLowerCase()
            ) ||
            data?.prod_name?.toLowerCase()?.includes(searchQuery)
        )
      );
    }
  }, [searchQuery, inventory]);

  useEffect(() => {
    // Sort the users based on the selected criteria
    if (filteredInventory) {
      let sortedInventory = [...filteredInventory];
      if (sortCriteria === "WMS_Quantity") {
        sortedInventory.sort((a, b) => {
          return b?.WMS_Quantity - a?.WMS_Quantity;
        });
      } else if (sortCriteria === "sold_quantity") {
        sortedInventory.sort((a, b) => b?.sold_quantity - a?.sold_quantity);
      }
      setFilteredInventory(sortedInventory);
    }
  }, [sortCriteria]);

  return (
    <div className="w-full h-screen relative overflow-auto">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className="w-full h-14 bg-white-1 flex items-center justify-between sticky top-0 right-0 py-1 px-5
       shadow-md z-10 opacity-90 max-sm:hidden"
      >
        <span className="text-lg font-bold tracking-wider text-gray-800">
          All Inventory
        </span>
        <img
          loading="lazy"
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
            userDetail?.UserImage || ""
          }`}
          alt={userDetail?.UserImage || ""}
          className="w-10 h-10 rounded-full border border-gray-300"
        />
      </div>
      <div className="w-full">
        <div className="w-full flex max-sm:flex-col items-center justify-center sm:justify-between">
          <div className="w-full sm:w-[200px] h-[90px] flex flex-col justify-between sm:mt-10 sm:ml-5 bg-white-1 rounded-md shadow-sm shadow-black-1/20 px-3 py-4">
            <span className="text-[16px] text-[#c1bebe]">Total Inventory</span>
            <div className="w-full flex items-center justify-between">
              <span className="flex items-center text-[20px] text-green">
                {inventory && Array.isArray(inventory) ? inventory?.length : 0}
              </span>
              <img
                src={inventoryIcon}
                alt="inventory_icon"
                className="w-[24px] h-[24px]"
              />
            </div>
          </div>
          <div className="w-full sm:w-auto max-sm:mt-5 sm:mr-5 flex max-sm:flex-col items-center max-sm:items-start justify-center sm:justify-end">
            <div className="w-full sm:w-[250px] h-[45px] sm:h-[35px] flex items-center bg-white-1 rounded-full shadow-sm shadow-black-1/20 px-2 sm:mr-5">
              <SearchIcon
                className="text-black-1/70"
                style={{ fontSize: "22px" }}
              />
              <input
                type="text"
                placeholder="Search by Name & SKU"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full h-full border-none outline-none p-0 pl-1 rounded-full text-[14px] tracking-wide text-black-1/80"
              />
            </div>
            <div className="flex items-center max-sm:mt-5 max-sm:ml-2">
              <span className="text-black-1/80 text-[14px] mr-2 font-bold tracking-wide">
                Sort by:
              </span>
              <FormControl
                variant="outlined"
                size="small"
                sx={{
                  minWidth: 120,
                  color: "black",
                  background: "white",
                  borderRadius: "4px",
                  boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                }}
              >
                <Select
                  name="sort-by"
                  value={sortCriteria}
                  onChange={(e) => setSortCriteria(e.target.value)}
                  label="Sort by"
                  displayEmpty
                  sx={{
                    color: sortCriteria === "" ? "rgba(0, 0, 0, 0.6)" : "black", // Set color for empty value
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="WMS_Quantity">WMS Inventory</MenuItem>
                  <MenuItem value="sold_quantity">Sold Inventory</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        {filteredInventory && Array.isArray(filteredInventory) ? (
          <div className="w-full overflow-x-auto pt-[100px] sm:px-5">
            <div className="w-full flex items-center justify-between bg-white-1 rounded-t-md">
              <span className="text-[16px] text-black-1/80 tracking-wide p-3">
                All Inventory
              </span>
            </div>
            <TableContainer component={Paper} className="shadow-md">
              <Table>
                <TableHead className="bg-gray-100">
                  <TableRow>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      S.No
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      BarCode
                    </TableCell>

                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      SKU ID
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Product Name
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Manufacturer Name
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      NDP Price
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      MRP Price
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      WMS Quantity
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Sold Quantity
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredInventory &&
                    Array.isArray(filteredInventory) &&
                    filteredInventory?.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {data?.BarCode || "N/A"}
                        </TableCell>
                        <TableCell align="center">
                          {data?.SKU_ID || "N/A"}
                        </TableCell>
                        <TableCell align="center">
                          {data?.prod_name || "N/A"}
                        </TableCell>
                        <TableCell align="center">
                          {data?.manufacture_name || "N/A"}
                        </TableCell>
                        <TableCell align="center">
                          {(data?.NDP_price && formatWithCommasWithDecimal(data?.NDP_price)) || "N/A"}
                        </TableCell>
                        <TableCell align="center">
                          {(data?.MRP_price && formatWithCommasWithDecimal(data?.MRP_price)) || "N/A"}
                        </TableCell>
                        <TableCell align="center">
                          {data?.WMS_Quantity || "N/A"}
                        </TableCell>
                        <TableCell align="center">
                          {data?.sold_quantity || "N/A"}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <NoCartItem text={"Inventory"} />
        )}
      </div>
    </div>
  );
};

export default AdminInventory;
