
import {
  fetchAllAddressAsync,
  modifyAddressAsync,
  removeAddressAsync,
} from "../features/cartPage/addressSlice";
import {
  addToCartsAsync,
  fetchAllCartsAsync,
  fetchAllPreorderCartsAsync,
  removeFromCartsAsync,
  removeFromPreorderCartsAsync,
} from "../features/cartPage/cartPageSlice";
import {
  addToWishlistsAsync,
  fetchAllWishlistsAsync,
  removeFromWishlistAsync,
} from "../features/wishlist/wishlistSlice";
import toast from "react-hot-toast";

export const handleAddToWishlist = async (
  data,
  userDetail,
  dispatch,
  handleOpen,
  handleClose,
  e
) => {
  handleOpen();
  let wishlistData = {
    UserID: userDetail?.UserID,
    prodID: data?.prod_ID,
  };
  dispatch(addToWishlistsAsync(wishlistData)).then(() =>
    dispatch(fetchAllWishlistsAsync(userDetail.UserID)).then(() => {
      handleClose();
    })
  ); // Fetch updated wishlist
  e.preventDefault();
};

export const handleRemoveFromWishlist = async (
  data,
  userDetail,
  dispatch,
  handleOpen,
  handleClose,
  e
) => {
  handleOpen();
  let wishlistData = {
    UserID: userDetail?.UserID,
    prodID: data?.prod_ID,
  };
  dispatch(removeFromWishlistAsync(wishlistData)).then(() => {
    dispatch(fetchAllWishlistsAsync(userDetail.UserID)).then(() => {
      handleClose();
    });
  });
  e.preventDefault();
};

export const isInWishlist = (productId, wishlist) => {
  return (
    Array.isArray(wishlist && wishlist?.product) &&
    wishlist?.product?.some((item) => item.prod_ID === productId)
  );
};

export const handleOpenMobileVarient = (id, setOpenMobileVarient, e) => {
  setOpenMobileVarient(id);
  e.preventDefault();
};

export const handleRemoveFromCarts = async (
  data,
  userDetail,
  dispatch,
  handleOpen,
  handleClose
) => {
  handleOpen();
  let cartData = {
    userID: userDetail?.UserID,
    SKU: data?.skuDetails?.SKU_ID,
  };
  dispatch(removeFromCartsAsync(cartData)).then(() => {
    dispatch(fetchAllCartsAsync(userDetail?.UserID));
    handleClose();
  });
};

export const handleChangeQtyOfProductCarts = (
  data,
  userDetail,
  newQty,
  dispatch,
  handleOpen,
  handleClose
) => {
  handleOpen();
  let cartData = {
    userID: userDetail?.UserID,
    SKU: data?.skuDetails?.SKU_ID,
    buyQty: newQty,
  };
  if (newQty > 0 && newQty <= data?.skuDetails?.qty) {
    dispatch(addToCartsAsync(cartData)).then(() => {
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
      handleClose();
    });
  }
};

export const handleModifyAddress = (
  address,
  userDetail,
  isDefault,
  dispatch,
  handleOpen,
  handleClose
) => {
  handleOpen();
  if (address.is_default === 1) {
    const addressData = {
      data: {
        is_default: "no",
      },
      shippingID: address.shippingID,
    };

    dispatch(modifyAddressAsync(addressData))
      .then(() => {
        // Address added successfully, you can handle the success here
        dispatch(fetchAllAddressAsync(userDetail?.UserID));
        handleClose();
      })
      .catch((error) => {
        handleClose();
        toast.error('Default not working')
        // Handle the error if the address addition fails
        console.error("Error adding address:", error);
      });
  } else {
    const addressData = {
      data: {
        is_default: "yes",
      },
      shippingID: address.shippingID,
    };

    dispatch(modifyAddressAsync(addressData))
      .then(() => {
        // Address added successfully, you can handle the success here
        dispatch(fetchAllAddressAsync(userDetail?.UserID));
        handleClose();
      })
      .catch((error) => {
        handleClose();
        toast.error("Default not working")
        // Handle the error if the address addition fails
        console.error("Error adding address:", error);
      });
  }
};

export const handleRemoveAddress = (shippingID,userDetail,dispatch,handleOpen,handleClose) => {
  handleOpen();
  dispatch(removeAddressAsync(shippingID)).then(() => {
    dispatch(fetchAllAddressAsync(userDetail?.UserID)).then(()=>{
      handleClose();
    });
  });
};

export const handleRemoveFromPreorders = (data,userDetail,dispatch,handleOpen,handleClose) => {
  handleOpen();
  dispatch(removeFromPreorderCartsAsync(data?.id)).then(() => {
    dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID));
    handleClose();
  });
};