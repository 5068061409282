import React, { useState, useEffect, useRef } from "react";
import upload_logo from "../../../img/upload_layer.svg";
import CropEasy from "../../../components/Authentication/SignUp/CropEasy";
import deleteUploadedImage from "../../../img/mdi-light_delete.svg";
import inspectUploadedImage from "../../../img/gg_search.svg";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { randomFemaleLogo } from "../../../data";
import { randomMaleLogo } from "../../../data";
import { API_BASE_URL } from "../../../config";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import toast from "react-hot-toast";

function MobileBasicInfo({ updateFinalData, finalData }) {
  const [originalSelectedImage, setOriginalSelectedImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [data, setData] = useState(0);
  const [inspect, setInspect] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedAddressType, setSelectedAddressType] = useState(
    (finalData && finalData?.save_address) || "no"
  );
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (
      finalData &&
      finalData?.USER_IMAGE &&
      finalData.USER_IMAGE.type.startsWith("image/")
    ) {
      const imageUrl = URL.createObjectURL(finalData.USER_IMAGE);
      setSelectedImage(imageUrl);
      setOriginalSelectedImage(imageUrl);
    }
  }, [finalData]);

  const inputRef = useRef(null);

  const importImageAsFile = async (importedImageUrl) => {
    try {
      const blob = await importedImageToBlob(importedImageUrl);
      const file = new File([blob], "image.png", { type: "image/png" });
      updateFinalData({ USER_IMAGE: file });
      updateFinalData({ Document_IMAGE: file });
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setOriginalSelectedImage(imageUrl);
    } catch (error) {
      console.error("Error importing image:", error);
    }
  };

  const importedImageToBlob = (importedImage) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", importedImage, true);
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(new Error("Failed to convert image to Blob."));
        }
      };
      xhr.onerror = () => {
        reject(new Error("Failed to convert image to Blob."));
      };
      xhr.send();
    });
  };

  const getStateId = (stateName) => {
    switch (stateName) {
      case "Andhra Pradesh":
        return "1";
      case "Arunachal Pradesh":
        return "2";
      case "Assam":
        return "3";
      case "Bihar":
        return "4";
      case "Chhattisgarh":
        return "5";
      case "Goa":
        return "6";
      case "Gujarat":
        return "7";
      case "Haryana":
        return "8";
      case "Himachal Pradesh":
        return "9";
      case "Jharkhand":
        return "10";
      case "Karnataka":
        return "11";
      case "Kerala":
        return "12";
      case "Madhya Pradesh":
        return "13";
      case "Maharashtra":
        return "14";
      case "Manipur":
        return "15";
      case "Meghalaya":
        return "16";
      case "Mizoram":
        return "17";
      case "Nagaland":
        return "18";
      case "Odisha":
        return "19";
      case "Punjab":
        return "20";
      case "Rajasthan":
        return "21";
      case "Sikkim":
        return "22";
      case "Tamil Nadu":
        return "23";
      case "Telangana":
        return "24";
      case "Tripura":
        return "25";
      case "Uttar Pradesh":
        return "26";
      case "Uttarakhand":
        return "27";
      case "West Bengal":
        return "28";
      case "Chandigarh":
        return "29";
      case "Delhi":
        return "30";
      case "Ladakh":
        return "31";
      case "Jammu and Kashmir":
        return "32";
      default:
        return "";
    }
  };
  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    // Check if the input is for 'Gender' and value is either 'Male' or 'Female'
    if (name === "Gender" && (value === "Male" || value === "Female")) {
      // Randomly select an image from userMaleLogo or userFemaleLogo based on the selected gender
      const randomIndex = Math.floor(Math.random() * 15); // Generates a random index from 0 to 54
      const randomImage =
        value === "Male"
          ? randomMaleLogo[randomIndex]
          : randomFemaleLogo[randomIndex];

      if (finalData.USER_IMAGE === null) {
        importImageAsFile(randomImage);
      }
    }
    if (name === "pin_code" && value.length === 6) {
      // Fetch data based on pin code
      try {
        handleOpen();
        const response = await fetch(
          `https://api.postalpincode.in/pincode/${value}`
        );
        const data = await response.json();
        if (response.ok && data.length > 0) {
          // Update state, district, location based on API response
          const { State, District, Division } = data[0].PostOffice[0];
          updateFinalData({
            state_id: getStateId(State),
            district: District,
            location: Division,
          });
          handleClose();
        } else {
          // Show error message if pin code is invalid
          handleClose();
          toast.error('Invalid Pin Code');
        }
      } catch (error) {
        handleClose();
        console.error("Error fetching data:", error);
        // Show error message if API call fails
        toast.error('Please try again later');
      }
    }

    updateFinalData({ [name]: value });
  };

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleInspect = () => {
    setInspect(!inspect);
  };

  const handleImageUpload = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);
      updateFinalData({ [e.target.name]: selectedFile });
      setSelectedImage(imageUrl);
      setOriginalSelectedImage(imageUrl);
    }
  };

  const handleRemoveImage = () => {
    updateFinalData({ USER_IMAGE: null });
    setSelectedImage(null);
    setOriginalSelectedImage(null);
    inputRef.current.value = null;
  };

  const handleZoomChange = (value) => {
    setData(value);
  };

  const handleEnterKey = (e, nextInputId) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const nextInput = document.getElementById(nextInputId);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  useEffect(() => {
    if (finalData && finalData?.pin_code && finalData?.pin_code.length < 6) {
      setPincodeAvailable(true);
      setPincodeError(null);
      setSelectedAddressType("no");
      updateFinalData({ ["save_address"]: "no" });
    }
  }, [finalData?.pin_code]);

  const [pincodeAvailable, setPincodeAvailable] = useState(true);
  const [pincodeError, setPincodeError] = useState(null);

  const handleSaveAddressType = async (addressType) => {
    if (addressType === "Office") {
      if (
        finalData &&
        finalData?.pin_code &&
        finalData?.pin_code.length === 6
      ) {
        try {
          handleOpen();
          const responseDelivery = await fetch(
            `${API_BASE_URL}pincode/${finalData?.pin_code}`
          );
          const dataDelivery = await responseDelivery.json();
          if (
            dataDelivery &&
            dataDelivery[0] &&
            dataDelivery[0]?.Pincode === parseInt(finalData?.pin_code)
          ) {
            setPincodeAvailable(true);
            setPincodeError(null);
            setSelectedAddressType(addressType);
            updateFinalData({ ["save_address"]: addressType });
            handleClose();
          } else {
            setPincodeAvailable(false);
            setPincodeError(
              "Please change pincode, the shipment is not available at this pincode"
            );
            handleClose();
          }
        } catch (error) {
          setPincodeAvailable(false);
          setPincodeError(
            "Please change pincode, the shipment is not available at this pincode"
          );
          handleClose();
        }
      } else {
        setPincodeAvailable(false);
        setPincodeError("Please fill the pincode first!");
      }
    } else {
      setSelectedAddressType(addressType);
      updateFinalData({ ["save_address"]: addressType });
    }
  };
  return (
    <div className="w-full min-h-[72vh] flex flex-col items-start justify-start py-4 px-3 bg-white-1">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        {inspect && (
          <div className="fixed top-0 left-0 w-full h-full bg-black-1/60 flex-center z-1000">
            <div className="w-[25%] bg-white-1 p-5 mt-[60px] rounded-md shadow-md shadow-black-1/20 z-999">
              <CropEasy
                photoURL={originalSelectedImage}
                setPhotoURL={setSelectedImage}
                zoom={data}
                onZoomChange={handleZoomChange}
                setOpenCrop={setInspect}
              />
            </div>
          </div>
        )}
        <div className="w-full relative">
          <span className="text-[13px] text-black-1/70 font-semibold my-2">
            We require this information to have smooth shopping experience
          </span>
          <div className="w-full flex flex-col items-center justify-between flex-wrap">
            <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
              <TextField
                label="First Name"
                variant="outlined"
                id="firstNameInput"
                placeholder="Enter your First Name"
                fullWidth
                name="FirstName"
                value={finalData?.FirstName || ""}
                onChange={handleInputChange}
                onKeyDown={(e) => handleEnterKey(e, "secondNameInput")}
                required
              />
            </div>
            <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
              <TextField
                label="Last Name"
                variant="outlined"
                id="secondNameInput"
                placeholder="Enter your Last Name"
                fullWidth
                name="LastName"
                value={finalData?.LastName || ""}
                onChange={handleInputChange}
                onKeyDown={(e) => handleEnterKey(e, "dobInput")}
                required
              />
            </div>
            <div className="w-full flex flex-row items-center justify-start mb-[5px] pl-3">
              <FormControl className="w-full" required>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  className="text-18"
                  sx={{
                    fontSize: "18px",
                  }}
                >
                  Gender
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    name="Gender"
                    id="genderInput"
                    value="Male"
                    checked={finalData?.Gender === "Male"}
                    onChange={handleInputChange}
                    control={<Radio />}
                    label="Male"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: "rgba(0, 0, 0, 0.8)",
                        fontSize: "16px",
                      },
                    }}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Female"
                    name="Gender"
                    id="genderInput"
                    value="Female"
                    checked={finalData?.Gender === "Female"}
                    onChange={handleInputChange}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: "rgba(0, 0, 0, 0.8)",
                        fontSize: "16px",
                      },
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
              <TextField
                label="DOB"
                variant="outlined"
                id="dobInput"
                type="date"
                placeholder="Select your DOB"
                fullWidth
                value={finalData?.DOB || ""}
                name="DOB"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  max: new Date().toISOString().split("T")[0],
                }}
                onKeyDown={(e) => handleEnterKey(e, "pincode")}
                onChange={handleInputChange}
                helperText="Select your date of birth"
                required
              />
            </div>
          </div>
          <div className="w-full flex flex-col">
            <span className="text-14 font-semibold mb-2 text-black-1/80 tracking-wide">
              Please add your business address as per your GST address.
            </span>
            <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
              <TextField
                label="Pincode"
                variant="outlined"
                id="pincode"
                fullWidth
                name="pin_code"
                placeholder="Enter your pincode"
                pattern="\d{6}"
                maxLength="6"
                type="number"
                value={finalData?.pin_code || ""}
                onKeyDown={(e) => handleEnterKey(e, "address1Input")}
                onChange={handleInputChange}
                onInput={(e) => {
                  // Limit input to 10 digits
                  if (e.target.value.length > 6) {
                    e.target.value = e.target.value.slice(0, 6);
                  }
                }}
                required
              />
            </div>
          </div>
          <div className="w-full flex flex-col items-center justify-between flex-wrap">
            <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
              <TextField
                label="Address Line 1"
                variant="outlined"
                id="address1Input"
                placeholder="Address line 1"
                fullWidth
                name="address_line_1"
                value={finalData?.address_line_1 || ""}
                onKeyDown={(e) => handleEnterKey(e, "address2Input")}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
              <TextField
                label="Address Line 2"
                variant="outlined"
                id="address2Input"
                placeholder="Address line 2"
                fullWidth
                name="address_line_2"
                value={finalData?.address_line_2 || ""}
                onKeyDown={(e) => handleEnterKey(e, "cityInput")}
                onChange={handleInputChange}
              />
            </div>
            <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
              <TextField
                label="City"
                variant="outlined"
                id="cityInput"
                placeholder="Enter your city name"
                fullWidth
                name="location"
                value={finalData?.location || ""}
                onKeyDown={(e) => handleEnterKey(e, "districtInput")}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
              <TextField
                label="District"
                variant="outlined"
                id="districtInput"
                placeholder="Enter your district name"
                fullWidth
                name="district"
                value={finalData.district || ""}
                onKeyDown={(e) => handleEnterKey(e, "state_id")}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
              <FormControl fullWidth required>
                <Select
                  id="state_id"
                  name="state_id"
                  value={finalData?.state_id || 0}
                  onChange={handleInputChange}
                  sx={{
                    height: "55px",
                  }}
                >
                  <MenuItem value="" disabled>
                    Select State
                  </MenuItem>
                  <MenuItem value="1">Andhra Pradesh</MenuItem>
                  <MenuItem value="2">Arunachal Pradesh</MenuItem>
                  <MenuItem value="3">Assam</MenuItem>
                  <MenuItem value="4">Bihar</MenuItem>
                  <MenuItem value="5">Chhattisgarh</MenuItem>
                  <MenuItem value="29">Chandigarh</MenuItem>
                  <MenuItem value="30">Delhi</MenuItem>
                  <MenuItem value="6">Goa</MenuItem>
                  <MenuItem value="7">Gujarat</MenuItem>
                  <MenuItem value="8">Haryana</MenuItem>
                  <MenuItem value="9">Himachal Pradesh</MenuItem>
                  <MenuItem value="32">Jammu and Kashmir</MenuItem>
                  <MenuItem value="10">Jharkhand</MenuItem>
                  <MenuItem value="11">Karnataka</MenuItem>
                  <MenuItem value="12">Kerala</MenuItem>
                  <MenuItem value="31">Ladakh</MenuItem>
                  <MenuItem value="13">Madhya Pradesh</MenuItem>
                  <MenuItem value="14">Maharashtra</MenuItem>
                  <MenuItem value="15">Manipur</MenuItem>
                  <MenuItem value="16">Meghalaya</MenuItem>
                  <MenuItem value="17">Mizoram</MenuItem>
                  <MenuItem value="18">Nagaland</MenuItem>
                  <MenuItem value="19">Odisha</MenuItem>
                  <MenuItem value="20">Punjab</MenuItem>
                  <MenuItem value="21">Rajasthan</MenuItem>
                  <MenuItem value="22">Sikkim</MenuItem>
                  <MenuItem value="23">Tamil Nadu</MenuItem>
                  <MenuItem value="24">Telangana</MenuItem>
                  <MenuItem value="25">Tripura</MenuItem>
                  <MenuItem value="26">Uttar Pradesh</MenuItem>
                  <MenuItem value="27">Uttarakhand</MenuItem>
                  <MenuItem value="28">West Bengal</MenuItem>
                </Select>
                <FormHelperText>Select your state</FormHelperText>
              </FormControl>
            </div>
          </div>
          <div className="w-full flex flex-col pb-[50px] pl-1">
            <h3 className="text-14 font-bold text-black-1/80">
              My shipping address is same as my billing address:
            </h3>
            <span className="text-12 font-medium text-black-1/50">
              This address will be saved and displayed at time of user profile
              creation.
            </span>
            <div className="w-full flex flex-row items-center justify-start mb-[5px]">
              <FormControl className="w-full" required>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Office"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    name="default_address"
                    value="Office"
                    checked={selectedAddressType === "Office"}
                    onChange={() => handleSaveAddressType("Office")}
                    control={<Radio />}
                    label="Yes"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: "rgba(0, 0, 0, 0.8)",
                        fontSize: "16px",
                      },
                    }}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    name="default_address"
                    value="no"
                    defaultChecked
                    checked={selectedAddressType === "no"}
                    onChange={() => handleSaveAddressType("no")}
                    label="No"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: "rgba(0, 0, 0, 0.8)",
                        fontSize: "16px",
                      },
                    }}
                  />
                </RadioGroup>
                {!pincodeAvailable && (
                  <span className="text-[10px] text-peach">
                    <b className="text-peach text-12 mr-[2px]">!</b>
                    {pincodeError}
                  </span>
                )}
              </FormControl>
            </div>
          </div>
          <div className="hidden">
            {finalData &&
            finalData?.USER_IMAGE &&
            finalData.USER_IMAGE.type.startsWith("image/") ? (
              <>
                <img loading="lazy"
                  src={
                    finalData &&
                    finalData?.USER_IMAGE &&
                    finalData.USER_IMAGE.type.startsWith("image/") &&
                    URL.createObjectURL(finalData?.USER_IMAGE)
                  }
                  alt="selected"
                  className="mobile-uploaded-image-fix"
                />
                <div className="mobile-uploaded-image-inspect-remove-container">
                  <div className="mobile-uploaded-image-inspect-div-container">
                    <img loading="lazy"
                      src={inspectUploadedImage}
                      alt=""
                      onClick={handleInspect}
                    />
                    <span onClick={handleInspect}>Inspect</span>
                  </div>
                  <div className="mobile-uploaded-image-remove-div-container">
                    <img loading="lazy"
                      src={deleteUploadedImage}
                      alt=""
                      onClick={handleRemoveImage}
                    />
                    <span
                      onClick={() => {
                        handleRemoveImage();
                        setInspect(false);
                      }}
                    >
                      Remove
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <div
                className="mobile-basic-detail-photo-upload-bordered-container"
                style={{ cursor: "pointer" }}
                onClick={handleImageClick}
              >
                <img loading="lazy" src={upload_logo} alt="upload_logo" />
                <label>
                  <p>Upload profile photo</p>
                </label>
                <span>Drag & Drop</span>
                <span className="mobile-upload-browse">Browse</span>
              </div>
            )}
            <input
              type="file"
              id="userImage"
              name="USER_IMAGE"
              ref={inputRef}
              style={{ display: "none" }}
              onChange={(e) => {
                handleImageUpload(e);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileBasicInfo;
