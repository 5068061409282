import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  activateCredit,
  toggleCredit,
  fetchAvailableCredit,
  fetchCreditPageDetails,
  payOrderUsingCredit,
  fetchCreditPayment,
  fetchCreditPaymentStatus,
  fetchSpendAmountPay,
  fetchCreditUser,
} from "./creditAPI";

const initialState = {
  creditData: [],
  creditUser: [],
  status: "idle",
  selectedCredit: null,
};

export const activateCreditAsync = createAsyncThunk(
  "credit/activateCredit",
  async (creditData) => {
    const response = await activateCredit(creditData);
    return response?.data;
  }
);

export const toggleCreditAsync = createAsyncThunk(
  "credit/toggleCredit",
  async (UserID) => {
    const response = await toggleCredit(UserID);
    return response?.data;
  }
);

export const fetchCreditUserAsync = createAsyncThunk(
  "credit/fetchCreditUser",
  async () => {
    const response = await fetchCreditUser();
    return response?.data;
  }
);

export const fetchAvailableCreditAsync = createAsyncThunk(
  "credit/fetchAvailableCredit",
  async (UserID) => {
    const response = await fetchAvailableCredit(UserID);
    return response?.data;
  }
);

export const fetchCreditPageDetailsAsync = createAsyncThunk(
  "credit/fetchCreditPageDetails",
  async (UserID) => {
    const response = await fetchCreditPageDetails(UserID);
    return response?.data;
  }
);

export const payOrderUsingCreditAsync = createAsyncThunk(
  "credit/payOrderUsingCredit",
  async (creditData) => {
    const response = await payOrderUsingCredit(creditData);
    return response?.data;
  }
);

export const fetchCreditPaymentAsync = createAsyncThunk(
  "credit/fetchCreditPayment",
  async ({ userID, orderNumber }) => {
    const response = await fetchCreditPayment(userID, orderNumber);
    return response?.data;
  }
);

export const fetchCreditPaymentStatusAsync = createAsyncThunk(
  "credit/fetchCreditPaymentStatus",
  async (link_ID) => {
    const response = await fetchCreditPaymentStatus(link_ID);
    return response?.data;
  }
);

export const fetchSpendAmountPayAsync = createAsyncThunk(
  "credit/fetchSpendAmountPay",
  async ({ userID, orderNo }) => {
    const response = await fetchSpendAmountPay(userID, orderNo);
    return response?.data;
  }
);

// Slice for handling state
export const creditSlice = createSlice({
  name: "credit",
  initialState,
  reducers: {
    setSelectedCredit: (state, action) => {
      state.selectedCredit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(activateCreditAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(activateCreditAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.creditData = action.payload;
      })
      .addCase(toggleCreditAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(toggleCreditAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(fetchCreditUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCreditUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.creditUser = action.payload;
      })
      .addCase(fetchAvailableCreditAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAvailableCreditAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.creditData = action.payload;
      })
      .addCase(fetchCreditPageDetailsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCreditPageDetailsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.creditData = action.payload;
      })
      .addCase(payOrderUsingCreditAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(payOrderUsingCreditAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.creditData = action.payload;
      })
      .addCase(fetchCreditPaymentAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCreditPaymentAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.creditData = action.payload;
      })
      .addCase(fetchCreditPaymentStatusAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCreditPaymentStatusAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.creditData = action.payload;
      })
      .addCase(fetchSpendAmountPayAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSpendAmountPayAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.creditData = action.payload;
      });
  },
});

// Exporting state selectors and actions
export const selectAllCreditData = (state) => state.credit.creditData;
export const selectAllCreditUser = (state) => state.credit.creditUser;
export const selectSelectedCredit = (state) => state.credit.selectedCredit;
export const { setSelectedCredit } = creditSlice.actions;
export default creditSlice.reducer;
