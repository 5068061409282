import React, { useState, useRef } from "react";
import CropEasy from "../../components/Authentication/SignUp/CropEasy";
import { fetchUserAsync, selectUser } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import inspectUploadedImage from "../../img/gg_search.svg";
import { IMAGE_BASE } from "../../config";
import deleteUploadedImage from "../../img/mdi-light_delete.svg";
import upload_logo from "../../img/upload_layer.svg";
import {
  updateProfileDetailAsync,
  updateProfileImageAsync,
} from "../../features/auth/authSlice";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import toast from "react-hot-toast";

const EditBasicProfile = ({ openEditProfile, setOpenEditProfile }) => {
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const [data, setData] = useState(0);
  const [inspect, setInspect] = useState(false);
  const inputRef = useRef(null);
  const [finalData, setFinalData] = useState({
    FirstName: (userDetail && userDetail?.FirstName) || null,
    LastName: (userDetail && userDetail?.LastName) || null,
    Email: (userDetail && userDetail?.Email) || null,
    PhoneNumber: (userDetail && userDetail?.PhoneNumber) || null,
    DOB: (userDetail && userDetail?.DOB) || null,
    Gender: (userDetail && userDetail?.Gender) || null,
    Password: (userDetail && userDetail?.Password) || null,
    USER_IMAGE: (userDetail && userDetail?.UserImage) || null,
    dealership_name: (userDetail && userDetail?.dealership_name) || null,
    address_line_1: (userDetail && userDetail?.address_line_1) || null,
    address_line_2: (userDetail && userDetail?.address_line_2) || null,
    pin_code: (userDetail && userDetail?.pin_code) || null,
    location: (userDetail && userDetail?.location) || null,
    district: (userDetail && userDetail?.district) || null,
    state_id: (userDetail && userDetail?.state_id) || null,
    GST: (userDetail && userDetail?.GST) || null,
    aadhar: (userDetail && userDetail?.aadhar) || null,
    pan_card: (userDetail && userDetail?.pan_card) || null,
    PAN_IMAGE: (userDetail && userDetail?.pan_card_img) || null,
    AADHAR_FRONT: (userDetail && userDetail?.aadhar_front) || null,
    AADHAR_BACK: (userDetail && userDetail?.aadhar_back) || null,
  });
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [updateFData, setUpdateFData] = useState({
    FirstName: (userDetail && userDetail?.FirstName) || null,
    LastName: (userDetail && userDetail?.LastName) || null,
    DOB: (userDetail && userDetail?.DOB) || null,
    Gender: (userDetail && userDetail?.Gender) || null,
  });
  const [updateImg, setUpdateImg] = useState(null);
  const [originalSelectedImage, setOriginalSelectedImage] = useState(
    userDetail &&
      `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
        userDetail && userDetail?.UserImage
      }`
  );
  const [selectedImage, setSelectedImage] = useState(
    userDetail &&
      `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
        userDetail && userDetail?.UserImage
      }`
  );
  const handleZoomChange = (value) => {
    setData(value);
  };
  const handleInspect = () => {
    setInspect(!inspect);
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setOriginalSelectedImage(null);
  };

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageUpload = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);
      updateFinalData({ [e.target.name]: selectedFile });
      setUpdateImg(selectedFile);
      setSelectedImage(imageUrl);
      setOriginalSelectedImage(imageUrl);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update final data with the input value
    updateFinalData({ [name]: value });
  };
  const handleEnterKey = (e, nextInputId) => {
    if (e.key === "Enter") {
      const nextInput = document.getElementById(nextInputId);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };
  const updateFinalData = (dataToUpdate) => {
    setFinalData((prevData) => ({
      ...prevData,
      ...dataToUpdate,
    }));
  };

  const handleUpdateDetail = () => {
    handleOpen();
    const changedFields = {};
    for (const key in updateFData) {
      if (updateFData[key] !== finalData[key]) {
        changedFields[key] = finalData[key];
      }
    }

    if (Object.keys(changedFields).length === 0 && updateImg === null) {
      setOpenEditProfile(false);
      return; // Exit early
    }

    // Dispatch the modifyAddressAsync action with the changedFields
    const updateData = {
      data: {
        ...changedFields,
      },
      userID: userDetail.UserID,
    };
    if (updateImg !== null) {
      let imageData = {
        userID: userDetail.UserID,
        files: updateImg,
      };
      if (Object.keys(updateFData).length > 0) {
        dispatch(updateProfileImageAsync(imageData))
          .then(() => {
            dispatch(updateProfileDetailAsync(updateData)).then(() => {
              dispatch(fetchUserAsync(userDetail?.UserID));
              setOpenEditProfile(false);
              handleClose();
              toast.success('Profile modified successfully')
            });
          })
          .catch((error) => {
            handleClose();
            // Handle the error if the address modification fails
            console.error("Error modifying address:", error);
            let errorMessage =
              "Failed to modify address. Please try again later.";

            if (error.response && error.response.status === 404) {
              errorMessage =
                "Address not found. Please refresh the page and try again.";
            }

            toast.error('error')
          });
      } else {
        dispatch(updateProfileImageAsync(imageData))
          .then(() => {
            dispatch(fetchUserAsync(userDetail?.UserID));
            setOpenEditProfile(false);
            handleClose();
            toast.success('Profile modified successfully')
          })
          .catch((error) => {
            handleClose();
            // Handle the error if the address modification fails
            console.error("Error modifying address:", error);
            let errorMessage =
              "Failed to modify address. Please try again later.";

            if (error.response && error.response.status === 404) {
              errorMessage =
                "Address not found. Please refresh the page and try again.";
            }

            toast.error('error')
          });
      }
    } else {
      dispatch(updateProfileDetailAsync(updateData))
        .then(() => {
          // Address modified successfully, show success message
          dispatch(fetchUserAsync(userDetail?.UserID));
          setOpenEditProfile(false);
          handleClose();
          toast.success('Profile modified successfully')
        })
        .catch((error) => {
          // Handle the error if the address modification fails
          handleClose();
          console.error("Error modifying address:", error);
          let errorMessage =
            "Failed to modify address. Please try again later.";

          if (error.response && error.response.status === 404) {
            errorMessage =
              "Address not found. Please refresh the page and try again.";
          }

          toast.success('error')
        });
    }
  };

  return (
    <Dialog
      open={openEditProfile}
      onClose={() => setOpenEditProfile(false)}
      maxWidth="xs"
      fullWidth
    >
      <div className="w-full h-full flex flex-col p-3 bg-white-1 rounded-md">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <h3 className="text-[14px] text-black-1/90 font-bold pb-2">
          Basic Details
        </h3>
        <div className="w-full flex flex-col sm:flex-row items-center sm:items-start justify-between">
          <div className="w-[150px] sm:w-[150px] h-[150px] sm:h-[150px] mt-2 sm:mt-0">
            {inspect && (
              <Dialog
                open={inspect}
                onClose={() => {
                  setOpenEditProfile(false);
                  setInspect(false);
                }}
                maxWidth="xs"
                fullWidth
              >
                <CropEasy
                  photoURL={originalSelectedImage}
                  setPhotoURL={setSelectedImage}
                  zoom={data}
                  onZoomChange={handleZoomChange}
                  setOpenCrop={setInspect}
                />
              </Dialog>
            )}
            <div className="w-full h-full p-1">
              {selectedImage ? (
                <div className="w-full h-full flex flex-col justify-between rounded-md border-[1px] border-black-1/10">
                  <img loading="lazy"
                    src={selectedImage}
                    alt="selected"
                    className="h-[80%] object-contain"
                  />
                  <div className="w-full h-[15%] flex items-center justify-between px-1">
                    <div className="w-[50%] h-full flex items-center cursor-pointer">
                      <img loading="lazy"
                        src={inspectUploadedImage}
                        alt="inspect-logo"
                        onClick={handleInspect}
                        className="text-green mr-1 w-[15px] object-contain"
                      />
                      <span
                        onClick={handleInspect}
                        className="text-green text-[12px] font-semibold"
                      >
                        Inspect
                      </span>
                    </div>
                    <div className="w-[50%] h-full flex items-center justify-end cursor-pointer">
                      <img loading="lazy"
                        src={deleteUploadedImage}
                        alt="delete-logo"
                        onClick={handleRemoveImage}
                        className="text-peach mr-1 w-[15px] object-contain"
                      />
                      <span
                        onClick={() => {
                          handleRemoveImage();
                          setInspect(false);
                        }}
                        className="text-peach text-[12px] font-semibold"
                      >
                        Remove
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="w-full h-full flex-center flex-col p-1 border-[2px] border-dashed border-blue rounded-md cursor-pointer"
                  onClick={handleImageClick}
                >
                  <img loading="lazy"
                    src={upload_logo}
                    alt="upload_logo"
                    className=" h-[35px] object-contain my-1"
                  />
                  <p className="text-[14px] text-blue font-semibold my-1">
                    Upload your photo
                  </p>
                  <span className="text-[12px] text-blue font-semibold my-1">
                    Drag & Drop
                  </span>
                  <span className="text-[12px] text-white-1 bg-blue rounded-[30px] py-1 px-3 font-bold my-1">
                    Browse
                  </span>
                </div>
              )}
              <input
                type="file"
                id="userImage"
                name="USER_IMAGE"
                ref={inputRef}
                style={{ display: "none" }}
                onChange={(e) => {
                  handleImageUpload(e);
                }}
                required
              />
            </div>
          </div>
          <div className="w-full sm:w-[250px] flex flex-col sm:ml-3">
            <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[5px]">
              <TextField
                label="First Name"
                variant="outlined"
                id="firstNameInput"
                placeholder="Enter your First Name"
                fullWidth
                name="FirstName"
                value={finalData?.FirstName || ""}
                onChange={handleInputChange}
                onKeyDown={(e) => handleEnterKey(e, "secondNameInput")}
                required
                InputProps={{
                  sx: {
                    height: "42px",
                    padding: "0",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: "14px",
                    lineHeight: "1",
                  },
                }}
              />
            </div>
            <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[5px]">
              <TextField
                label="Last Name"
                variant="outlined"
                id="secondNameInput"
                placeholder="Enter your Last Name"
                fullWidth
                name="LastName"
                value={finalData?.LastName || ""}
                onChange={handleInputChange}
                onKeyDown={(e) => handleEnterKey(e, "phoneInput")}
                required
                InputProps={{
                  sx: {
                    height: "42px",
                    padding: "0",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: "14px",
                    lineHeight: "1",
                  },
                }}
              />
            </div>
            <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[5px]">
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                name="PhoneNumber"
                id="phoneInput"
                value={finalData.PhoneNumber || ""}
                InputProps={{
                  readOnly: true,
                  sx: {
                    height: "42px",
                    padding: "0",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: "14px",
                    lineHeight: "1",
                  },
                }}
              />
            </div>
            <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[5px]">
              <TextField
                label="Email"
                variant="outlined"
                id="emailInput"
                fullWidth
                name="Email"
                value={finalData?.Email || ""}
                InputProps={{
                  readOnly: true,
                  sx: {
                    height: "42px",
                    padding: "0",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: "14px",
                    lineHeight: "1",
                  },
                }}
              />
            </div>
            <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[5px]">
              <TextField
                label="DOB"
                variant="outlined"
                id="dobInput"
                type="date"
                placeholder="Select your DOB"
                fullWidth
                value={finalData?.DOB || ""}
                name="DOB"
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    fontSize: "14px",
                    lineHeight: "1",
                  },
                }}
                inputProps={{
                  max: new Date().toISOString().split("T")[0],
                  sx: {
                    height: "42px",
                    padding: "0 10px",
                  },
                }}
                onKeyDown={(e) => handleEnterKey(e, "pincode")}
                onChange={handleInputChange}
                helperText="Select your date of birth"
                required
              />
            </div>
            <div className="w-full flex flex-row items-center justify-start mb-[5px] pl-3">
              <FormControl className="w-full" required>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  sx={{
                    fontSize: "16px",
                  }}
                >
                  Gender
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    name="Gender"
                    id="genderInput"
                    value="Male"
                    checked={finalData?.Gender === "Male"}
                    onChange={handleInputChange}
                    control={<Radio />}
                    label="Male"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: "rgba(0, 0, 0, 0.8)",
                        fontSize: "14px",
                      },
                    }}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Female"
                    name="Gender"
                    id="genderInput"
                    value="Female"
                    checked={finalData?.Gender === "Female"}
                    onChange={handleInputChange}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: "rgba(0, 0, 0, 0.8)",
                        fontSize: "14px",
                      },
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="w-full flex items-center justify-between">
              <Button
                variant="Outlined"
                sx={{
                  height: "30px",
                  fontSize: "14px",
                  border: "2px solid #283248",
                  outline: "none",
                  textTransform: "none",
                  "&:hover": {
                    outline: "none",
                  },
                }}
                className="my-profile-basic-detail-cancel-btn"
                onClick={() => setOpenEditProfile(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  height: "30px",
                  background: "#28a447",
                  fontSize: "14px",
                  outline: "none",
                  textTransform: "none",
                  "&:hover": {
                    background: "#28a447",
                    outline: "none",
                  },
                }}
                className="my-profile-basic-detail-save-changes-btn"
                onClick={handleUpdateDetail}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default EditBasicProfile;
