import React, { useRef, useCallback } from "react";
import CheckIcon from "@mui/icons-material/Check";
import successLogo from "../icons/Success-Image.png";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog } from "@mui/material";
import { useNavigate } from "react-router";

export const OTPVerified = () => {
  return (
    <div className="w-full mt-[5px] flex items-center">
      <CheckIcon
        className="p-[2px] text-white-1 bg-green rounded-full mr-[5px]"
        style={{ fontSize: "18px" }}
      />
      <span className="text-12 font-semibold text-green">Verified</span>
    </div>
  );
};

export const VerifyOTP = ({ handleVerify }) => {
  return (
    <div className="w-full flex items-center justify-between mt-[5px]">
      <span
        className="text-12 py-1 px-2 rounded-md bg-blue text-white-1 tracking-wide"
        onClick={handleVerify}
      >
        Verify
      </span>
      <div className="flex items-center">
        <span className="w-[18px] h-[18px] flex-center text-[14px] text-white-1 bg-[goldenrod] rounded-full p-1 mr-[5px]">
          !
        </span>
        <span className="text-12 text-[goldenrod]">
          Please verify to proceed
        </span>
      </div>
    </div>
  );
};

export const OTPVerificationModal = ({
  text,
  otp,
  setOTP,
  setVerify,
  resendOTP,
  handleVerify,
  timer,
  setTimer,
  resendDisabled,
  open,
  onClose,
}) => {
  const inputRefs = useRef(
    Array.from({ length: 6 }).map(() => React.createRef())
  );

  const handleFocus = (index) => {
    // Move the cursor to the last digit if there's already a value entered
    if (index === 5 && otp[index] !== "") {
      inputRefs.current[index].current?.focus();
    }
  };

  const handleKeyDown = (index, value, e) => {
    if (e.keyCode === 8 && value === "" && index > 0) {
      inputRefs.current[index - 1].current.focus();
    } else if (e.keyCode === 8 && index > 0 && value !== "") {
      inputRefs.current[index].current.focus();
    }
  };

  const handleOTPInputChange = useCallback(
    (index, value, e) => {
      // Accept only numeric values
      const numericValue = value.replace(/\D/g, "");

      const newOTP = [...otp];
      newOTP[index] = numericValue.slice(-1); // Only take the last digit

      setOTP(newOTP);

      // Move to the previous input when deleting a digit
      if (numericValue !== "" && index < 5 && inputRefs?.current[index + 1]) {
        inputRefs?.current[index + 1]?.current?.focus();
      }
    },
    [otp]
  );

  // HANDLE OTP PASTE
  const handleOTPPaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData.getData("text/plain");
    const pastedDigits = clipboardData.replace(/\D/g, "").slice(0, 6); // Filter out non-numeric characters and take the first 6 digits
    const newOTP = [...otp];
    pastedDigits.split("").forEach((digit, index) => {
      newOTP[index] = digit;
    });
    setOTP(newOTP);
    inputRefs.current[5].current.focus();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <div className="w-full h-full flex flex-col items-center p-3 relative">
        <div className="w-full flex flex-col items-center">
          <CheckIcon
            className=" p-1 rounded-full bg-green text-white-1"
            style={{ fontSize: "25px" }}
          />
          <span className="text-16 text-black-1/80 font-semibold my-1">
            Verification OTP sent
          </span>
        </div>
        <span className="w-[90%] text-14 text-black-1/60 font-medium my-1 text-center">
          Enter the 6-digit OTP sent to your Email <b>{text}</b>
        </span>
        <div className="w-full flex-center flex-col">
          <span className="text-center text-12 text-blue font-medium ">
            Enter OTP
          </span>
          <div className="w-full flex-center">
            {otp.map((digit, index) => (
              <input
                type="text"
                inputMode="numeric"
                maxlength="1"
                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                key={index}
                value={digit}
                onChange={(e) => handleOTPInputChange(index, e.target.value, e)}
                onKeyDown={(e) => handleKeyDown(index, e.target.value, e)}
                ref={inputRefs.current[index]}
                onFocus={() => handleFocus(index)}
                className={`w-[35px] h-[40px] text-16 text-black-1/80 rounded-md outline-none my-2 mr-3 text-center ${
                  digit
                    ? "border-[2px] border-black-1/80"
                    : "border-[1px] border-black-1/50"
                }`}
                onPaste={handleOTPPaste}
                required
              />
            ))}
          </div>
          <span
            className="w-full text-end text-14 text-[purple] mr-4 my-1"
            onClick={resendOTP}
          >
            Resend OTP {resendDisabled ? `(${timer}s)` : ""}
          </span>
        </div>
        <span
          className="absolute right-[10px] top-[10px]"
          onClick={() => {
            setVerify(false);
            setTimer(0);
            onClose();
          }}
        >
          <CloseIcon style={{ fontSize: "20px" }} className="text-black-1/80" />
        </span>
        <span
          className="text-center bg-green text-white-1 text-14 tracking-wider py-1 px-3 rounded-md"
          onClick={() => {
            handleVerify();
            onClose();
          }}
        >
          Confirm
        </span>
      </div>
    </Dialog>
  );
};

export const OnboardingSuccess = ({open})=>{
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };
  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <div className="w-[80%] fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 bg-white-1 rounded-md py-4 px-3 flex-center flex-col z-[1001]">
          <img loading="lazy" src={successLogo} alt="" className="h-[100px]" />
          <span className="text-[18px] text-black-1 font-bold tracking-wider my-2">
            Congratulations!
          </span>
          <span className="text-[16px] text-black-1/70 font-medium text-center">
            You have successfully created your account
          </span>
          <Button
          variant="contained"
          sx={{
            height:"35px",
            fontSize:"16px",
            borderRadius:"4px",
            textTransform:"none",
            background:"#28a447",
            padding:"5px 8px",
            fontWeight:"700",
            color:"white",
            marginTop:"8px",
            "&:hover":{
              background:"#28a447"
            }
          }}
            onClick={handleNavigate}
          >
            Continue to shopping
          </Button>
        </div>
    </Dialog>
  )
}
