import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { IMAGE_BASE } from "../../config";
import {
  activateCreditAsync,
  fetchCreditUserAsync,
  selectAllCreditUser,
  toggleCreditAsync,
} from "../../features/credit/creditSlice";
import silverIcon from "../../assets/Badge-Silver.png";
import goldIcon from "../../assets/Badge-Gold.png";
import DiamondIcon from "../../assets/Badge-Platinum.png";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  MenuItem,
  Switch,
  InputAdornment,
  TextField,
  Autocomplete,
} from "@mui/material";
import toast from "react-hot-toast";
import { formatWithCommasWithDecimal } from "../../utils/utils";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const AdminLOC = () => {
  const dispatch = useDispatch();
  const creditUser = useSelector(selectAllCreditUser);
  const userDetail = useSelector(selectUser);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [creditLimit, setCreditLimit] = useState("");
  const [creditType, setCreditType] = useState("");
  const [creditLimitError, setCreditLimitError] = useState("");

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  useEffect(() => {
    handleOpen();
    if (userDetail?.UserID) {
      dispatch(fetchCreditUserAsync()).then(() => handleClose());
    }
  }, [dispatch, userDetail?.UserID]);

  const handleCreditActivation = async () => {
    if (selectedUser && creditLimit) {
      if (parseInt(creditLimit, 10) < selectedUser?.spentAmount) {
        setCreditLimitError(
          "Credit limit cannot be assigned less than the credit spent by the user."
        );
        return;
      }

      setCreditLimitError("");
      handleOpen();

      const action =
        (await selectedUser?.creditStatus) === "Yes" ? "updated" : "activate";
      let creditData = {
        UserID: selectedUser.UserID,
          creditLimitCategory_Name: creditType,
          maxLimit: parseInt(creditLimit, 10),
      }
      await dispatch(
        activateCreditAsync(creditData)
      );
      await dispatch(fetchCreditUserAsync());
      await toast.success(
        `Line of Credit Feature successfully ${
          action === "activate" ? "activated" : "updated"
        }`
      );
      handleClose();
    } else {
      toast.error("Please select both a user and a valid credit limit.");
    }
  };

  const toggleCreditStatus = async (user) => {
    handleOpen();
    const newStatus = (await user.creditStatus) === "Yes" ? "No" : "Yes";
    await dispatch(toggleCreditAsync(user?.UserID));
    await dispatch(fetchCreditUserAsync());
    await toast.success(
      `Credit status for ${user.business_name || user.FirstName} ${
        newStatus === "Yes" ? "activated" : "deactivated"
      } successfully`
    );
    handleClose();
  };

  const getCreditIcon = (creditType) => {
    switch (creditType) {
      case "Silver":
        return silverIcon;
      case "Gold":
        return goldIcon;
      case "Diamond":
        return DiamondIcon;
      default:
        return null;
    }
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setTimeout(() => {
      document.getElementById("creditLimitField")?.focus(); // Focus on the credit limit field
    }, 100); // Small delay to ensure focus after rendering
  };

  useEffect(() => {
    const limit = parseInt(creditLimit, 10);

    if (limit > selectedUser?.spentAmount) {
      if (limit >= 0 && limit < 40000) {
        setCreditType("Silver");
      } else if (limit >= 40000 && limit < 80000) {
        setCreditType("Gold");
      } else if (limit >= 80000 && limit <= 100000) {
        setCreditType("Diamond");
      }
    }
  }, [creditLimit, selectedUser?.spentAmount]);

  const handleLimitChange = (e) => {
    setCreditLimit(e.target.value);
  };

  return (
    <div className="w-full h-screen relative overflow-auto">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className="w-full h-14 flex items-center justify-between sticky top-0 right-0 py-1 px-5
       shadow-md z-10 bg-white-1 opacity-90 max-sm:hidden"
      >
        <span className="text-lg font-bold tracking-wider text-gray-800">
          Line of Credit
        </span>
        <img
          loading="lazy"
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
            userDetail?.UserImage || ""
          }`}
          alt={userDetail?.UserImage || ""}
          className="w-10 h-10 rounded-full border border-gray-300"
        />
      </div>
      <div className="w-full sm:px-5">
        <div className="bg-white p-6 mt-6 rounded-lg shadow-md bg-white-1">
          <h3 className="text-lg text-gray-800 font-semibold mb-4">
            Activate Credit for User
          </h3>
          <div className="flex flex-col md:flex-row items-start max-md:items-center gap-4">
            <div className="flex-1 max-md:w-full">
              <Autocomplete
                value={selectedUser}
                onChange={(event, newValue) => setSelectedUser(newValue)}
                options={creditUser || []}
                getOptionLabel={(option) =>
                  option.business_name || option.FirstName || "N/A"
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select User"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        height: 50,
                      },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem
                    {...props}
                    key={option.UserID}
                    value={option.UserID}
                  >
                    {option.business_name || option.FirstName || "N/A"}
                  </MenuItem>
                )}
              />
            </div>
            <div className="flex-1 max-md:w-full">
              <TextField
                id="creditLimitField"
                label="Credit Limit"
                value={creditLimit}
                onChange={(e) => handleLimitChange(e)}
                type="number"
                fullWidth
                error={!!creditLimitError}
                helperText={
                  creditLimitError &&
                  "Credit limit cannot be assigned less than the credit spent by the user."
                }
                InputProps={{
                  sx: {
                    height: 50,
                  },
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            </div>
            <Button
              variant="contained"
              sx={{
                height: "50px",
                background: "#ba3737",
                textTransform: "none",
                letterSpacing: "1px",
                fontSize: "16px",
                "&:hover": {
                  background: "#ba3737",
                },
              }}
              color="primary"
              onClick={handleCreditActivation}
              className="bg-[#ba3737] text-white max-md:w-full"
            >
              {selectedUser?.creditStatus === "Yes"
                ? "Update Limit"
                : "Activate Credit"}
            </Button>
          </div>
        </div>
      </div>

      <div className="w-full h-auto bg-white mt-6 flex flex-col">
        {creditUser && Array.isArray(creditUser) && (
          <div className="w-full sm:p-5">
            <h2 className="text-[16px] tracking-wide p-3 text-black-1/80 bg-white-1 rounded-t-md">
              All Users
            </h2>
            <TableContainer component={Paper} className="shadow-md">
              <Table sx={{ minWidth: 650 }} aria-label="user credit table">
                <TableHead>
                  <TableRow className="bg-gray-100">
                    <TableCell
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      S.No
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Business Name
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Phone Number
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Credit Type
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Credit Status
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Active/Inactive
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Total Credit
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Available Credit
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Spent Credit
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {creditUser
                    ?.filter((user) => user.creditLimitCategory_Name !== null)
                    ?.sort((a, b) => {
                      const nameA = a?.business_name || a?.FirstName;
                      const nameB = b?.business_name || b?.FirstName;
                      return nameA?.localeCompare(nameB);
                    })
                    ?.map((user, index) => (
                      <TableRow key={index} className="hover:bg-gray-50">
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {user?.business_name?.slice(0, 45) || "N/A"}
                        </TableCell>
                        <TableCell align="center">
                          {user?.PhoneNumber || "N/A"}
                        </TableCell>
                        <TableCell align="center">
                          {getCreditIcon(user?.creditLimitCategory_Name) ? (
                            <div className="flex items-center justify-center">
                              <img
                                loading="lazy"
                                src={getCreditIcon(
                                  user?.creditLimitCategory_Name
                                )}
                                alt={`${user?.creditLimitCategory_Name} icon`}
                                width={25}
                                height={25}
                              />
                              <span className="text-sm text-gray-700 font-medium ml-2">
                                {user?.creditLimitCategory_Name}
                              </span>
                            </div>
                          ) : (
                            "N/A"
                          )}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {user.creditStatus === "Yes" ? "Active" : "Inactive"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <Switch
                            checked={user.creditStatus === "Yes"}
                            onChange={() => toggleCreditStatus(user)}
                            color="primary"
                            sx={{
                              "& .MuiSwitch-switchBase.Mui-checked": {
                                color: "#283248",
                              },
                              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                {
                                  backgroundColor: "#283248",
                                },
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {formatWithCommasWithDecimal(
                            parseInt(user?.Transaction_Limit)
                          ) || "N/A"}
                        </TableCell>
                        <TableCell align="center">
                          {formatWithCommasWithDecimal(user?.availableCredit) ||
                            "N/A"}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: "0" }}>
                          {user?.availableCredit && user?.spentAmount ? (
                            <div className="relative group w-[50px] font-extrabold h-[50px] mx-auto">
                              <CircularProgressbar
                                value={(
                                  (user.spentAmount /
                                    parseInt(user?.Transaction_Limit)) *
                                  100
                                ).toFixed(1)}
                                text={`${(
                                  (user.spentAmount /
                                    parseInt(user?.Transaction_Limit)) *
                                  100
                                ).toFixed(1)}%`}
                                styles={buildStyles({
                                  strokeLinecap: "round",
                                  textSize: "18px",
                                  pathTransitionDuration: 0.5,
                                  pathColor: "#283248",
                                  textColor: "#283248",
                                  trailColor: "#d6d6d6",
                                  fontWeight: "bold",
                                })}
                              />

                              <div className="flex flex-col font-medium whitespace-nowrap absolute bottom-0 right-full border bg-white-1 border-gray-300 shadow-md p-2 rounded-md opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto transition-opacity duration-300 z-100">
                                <p className="text-[11px] text-start text-black-1/60 my-[2px]">
                                  Total Credit:&nbsp;
                                  <b className="text-black-1/80">
                                    ₹
                                    {formatWithCommasWithDecimal(
                                      parseInt(user?.Transaction_Limit)
                                    )}
                                  </b>
                                </p>
                                <p className="text-[11px] text-start text-black-1/60 my-[2px]">
                                  Available Credit:&nbsp;
                                  <b className="text-black-1/80">
                                    ₹
                                    {formatWithCommasWithDecimal(
                                      user?.availableCredit
                                    )}
                                  </b>
                                </p>
                                <p className="text-[11px] text-start text-black-1/60 my-[2px]">
                                  Spent Amount:&nbsp;
                                  <b className="text-black-1/80">
                                    ₹
                                    {formatWithCommasWithDecimal(
                                      user?.spentAmount
                                    )}
                                  </b>
                                </p>
                                <Button
                                  onClick={() => handleEditUser(user)}
                                  sx={{
                                    mt: "2px",
                                    height: "30px",
                                    backgroundColor: "#283248",
                                    "&:hover": {
                                      backgroundColor: "#283240",
                                    },
                                    color: "white",
                                    fontSize: "0.875rem",
                                    py: 1,
                                    px: 2,
                                    borderRadius: "0.375rem",
                                    textTransform: "none",
                                    letterSpacing: "1px",
                                  }}
                                >
                                  Edit
                                </Button>
                              </div>
                            </div>
                          ) : (
                            "N/A"
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminLOC;
