import React, { useEffect, useState } from "react";
import "./SideNav.css";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import LogoutOverlay from "../EndPages/LogoutOverlay";
import logoutLogo from "../../assets/Icon-logout.svg";
import orderLogo from "../../assets/Icon-orders.svg";
import userLogo from "../../assets/Icon-user.svg";
import walletLogo from "../../assets/Icon-wallet.svg";
import lineOfCreditLogo from "../../assets/Icon-LOC1.svg";
import preorderLogo from "../../assets/Icon-preorder.svg";
import securityLogo from "../../assets/Icon-security.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import {
  fetchCreditPageDetailsAsync,
  selectAllCreditData,
} from "../../features/credit/creditSlice";

function SideNav() {
  const [openLogout, setOpenLogout] = useState(false);
  const creditData = useSelector(selectAllCreditData);
  const userDetail = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = useParams();

  const handleOpenLogout = () => {
    setOpenLogout(!openLogout);
  };

  useEffect(() => {
    if (userDetail && userDetail?.UserID) {
      dispatch(fetchCreditPageDetailsAsync(userDetail?.UserID));
    }
  }, [dispatch, userDetail]);

  return (
    <div className="sideNav-container">
      <div
        className="sidenav-item-div"
        onClick={() => navigate("/user/profile")}
        style={{ color: url.profileUrl === "profile" && "purple" }}
      >
        <img loading="lazy"
          src={userLogo}
          alt="user-logo"
          width={25}
          height={25}
          className="mr-2"
          style={{
            filter:
              url.profileUrl === "profile"
                ? "invert(20%) sepia(100%) saturate(1000%) hue-rotate(270deg) brightness(90%) contrast(110%)"
                : "none",
          }}
        />
        <span>My Profile</span>
      </div>
      <div
        className="sidenav-item-div"
        onClick={() => navigate("/user/preorders")}
        style={{ color: url.profileUrl === "preorders" && "purple" }}
      >
        <img loading="lazy"
          src={preorderLogo}
          alt="user-logo"
          width={25}
          height={25}
          className="mr-2"
          style={{
            filter:
              url.profileUrl === "preorders"
                ? "invert(20%) sepia(100%) saturate(1000%) hue-rotate(270deg) brightness(90%) contrast(110%)"
                : "none",
          }}
        />
        <span>My Pre-Orders</span>
      </div>
      <div
        className="sidenav-item-div"
        onClick={() => navigate("/user/orders")}
        style={{ color: url.profileUrl === "orders" && "purple" }}
      >
        <img loading="lazy"
          src={orderLogo}
          alt="order-logo"
          width={25}
          height={25}
          className="mr-2"
          style={{
            filter:
              url.profileUrl === "orders"
                ? "invert(20%) sepia(100%) saturate(1000%) hue-rotate(270deg) brightness(90%) contrast(110%)"
                : "none",
          }}
        />
        <span>My Orders</span>
      </div>
      {creditData?.creditDetails && (
        <div
          className="sidenav-item-div"
          onClick={() => navigate("/user/credit")}
          style={{ color: url.profileUrl === "credit" && "purple" }}
        >
          <img loading="lazy"
            src={lineOfCreditLogo}
            alt="LOC-logo"
            width={25}
            height={25}
            className="mr-2"
            style={{
              filter:
                url.profileUrl === "credit"
                  ? "invert(20%) sepia(100%) saturate(1000%) hue-rotate(270deg) brightness(90%) contrast(110%)"
                  : "none",
            }}
          />
          <span>Line Of Credit</span>
        </div>
      )}
      <div
        className="sidenav-item-div"
        onClick={() => navigate("/user/wallet")}
        style={{ color: url.profileUrl === "wallet" && "purple" }}
      >
        <img loading="lazy"
          src={walletLogo}
          alt="wallet-logo"
          width={25}
          height={25}
          className="mr-2"
          style={{
            filter:
              url.profileUrl === "wallet"
                ? "invert(20%) sepia(100%) saturate(1000%) hue-rotate(270deg) brightness(90%) contrast(110%)"
                : "none",
          }}
        />
        <span>Wallet</span>
      </div>
      <div
        className="sidenav-item-div"
        onClick={() => navigate("/user/security")}
        style={{ color: url.profileUrl === "security" && "purple" }}
      >
        <img loading="lazy"
          src={securityLogo}
          alt="user-logo"
          width={25}
          height={25}
          className="mr-2"
          style={{
            filter:
              url.profileUrl === "security"
                ? "invert(20%) sepia(100%) saturate(1000%) hue-rotate(270deg) brightness(90%) contrast(110%)"
                : "none",
          }}
        />
        <span>Security</span>
      </div>
      <div
        className="sidenav-item-div"
        onClick={handleOpenLogout}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onMouseEnter={(e) => {
          e.currentTarget.querySelector("img").style.filter =
            "brightness(0) invert(1)";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.querySelector("img").style.filter = "none";
        }}
      >
        <img loading="lazy"
          src={logoutLogo}
          alt="logout-logo"
          width={25}
          height={25}
          className="mr-2"
        />
        <span>Log Out</span>
        {openLogout && <LogoutOverlay openLogout={openLogout}  handleOpenLogout={handleOpenLogout} />}
      </div>
    </div>
  );
}

export default SideNav;
