import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllWishlistsAsync,
  removeAllFromWishlistAsync,
  selectAllWishlists,
} from "../../features/wishlist/wishlistSlice";
import { selectUser } from "../../features/auth/authSlice";
import MobileProductCard from "./MobileProductCard";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import NoProductFound from "../../mComponent/NoProductFound";

function MobileWishlist() {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const dispatch = useDispatch();
  const wishlist = useSelector(selectAllWishlists);
  const user = useSelector(selectUser);

  const handleRemoveAll = async () => {
    handleOpen();
    await dispatch(removeAllFromWishlistAsync(user?.UserID))
      .then(() => {
        dispatch(fetchAllWishlistsAsync(user?.UserID));
      })
      .then(() => {
        handleClose();
      });
  };

  useEffect(() => {
    dispatch(fetchAllWishlistsAsync(user?.UserID));
  }, [dispatch, user]);

  return (
    <div className="w-full flex flex-col items-start justify-start pb-2 bg-transparent">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open} 
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="w-full flex items-center justify-start relative px-3">
        <h3 className="text-16 capitalize mt-3 mb-2 text-black-1/90 font-bold">
          My Wishlist
        </h3>
        <span className="ml-2 text-16 text-gray-600">
          ({wishlist && wishlist?.product && wishlist?.product?.length} Items)
        </span>
        {wishlist && wishlist?.product && wishlist?.product?.length > 0 && (
          <span
            className="text-12 bg-peach text-white-1 py-[2px] px-2 rounded-[25px] tracking-wider absolute right-3 hover:bg-peach/80"
            onClick={handleRemoveAll}
          >
            Clear all
          </span>
        )}
      </div>
      <div className="w-full h-full min-h-[70vh] flex items-start justify-center m-auto">
        {wishlist && wishlist?.product && wishlist?.product?.length > 0 ? (
          <MobileProductCard />
        ) : (
          <NoProductFound />
        )}
      </div>
    </div>
  );
}

export default MobileWishlist;
