import React, { useState, useEffect } from "react";
import "./MobileMyOrder.css";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { useDispatch, useSelector } from "react-redux";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  getAllOrderAsync,
  selectAllOrders,
} from "../../features/cartPage/OrderSlice";
import { selectUser } from "../../features/auth/authSlice";
import { useNavigate } from "react-router";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { IMAGE_BASE } from "../../config";
import ReceiptIcon from "@mui/icons-material/Receipt";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { Slide } from "react-reveal";
import { OverlayCloseCause } from "rsuite/esm/internals/Overlay/OverlayTrigger";
import { formatWithCommasWithDecimal } from "../../utils/utils";
import ProfileSideBar from "../../mComponent/ProfileSideBar";

function MobileMyOrder() {
  const [openSidebar, setOpenSidebar] = useState(false);
  const handleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };
  const dispatch = useDispatch();
  const orders = useSelector(selectAllOrders);
  const userDetail = useSelector(selectUser);
  const navigate = useNavigate();
  const [showAllProducts, setShowAllProducts] = useState(null);

  // Define state variables to manage the visibility of different order statuses
  const [showAllProcessing, setShowAllProcessing] = useState(true);
  const [showAllDelivered, setShowAllDelivered] = useState(true);
  const [showAllCancelled, setShowAllCancelled] = useState(true);
  const [showAllPacked, setShowAllPacked] = useState(true);
  const [showAllPartConfirm, setShowAllPartConfirm] = useState(true);
  const [showAllConfirm, setShowAllConfirm] = useState(true);
  const [changeColorBtn, setChangeColorBtn] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  // Function to toggle visibility of processing orders
  const toggleProcessingOrders = () => {
    if (showAllProcessing === true && changeColorBtn !== "processing") {
      setChangeColorBtn("processing");
      setShowAllDelivered(false);
      setShowAllCancelled(false);
    } else if (changeColorBtn === "processing") {
      setChangeColorBtn("");
      setShowAllDelivered(true);
      setShowAllCancelled(true);
    }
  };

  // Function to toggle visibility of delivered orders
  const toggleDeliveredOrders = () => {
    if (showAllDelivered === true && changeColorBtn !== "delivered") {
      setChangeColorBtn("delivered");
      setShowAllProcessing(false);
      setShowAllCancelled(false);
    } else if (changeColorBtn === "delivered") {
      setChangeColorBtn("");
      setShowAllProcessing(true);
      setShowAllCancelled(true);
    }
  };

  // Function to toggle visibility of cancelled orders
  const toggleCancelledOrders = () => {
    if (showAllCancelled === true && changeColorBtn !== "cancelled") {
      setChangeColorBtn("cancelled");
      setShowAllDelivered(false);
      setShowAllProcessing(false);
    } else if (changeColorBtn === "cancelled") {
      setChangeColorBtn("");
      setShowAllProcessing(true);
      setShowAllDelivered(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (Object.keys(userDetail).length) {
        handleOpen();
        await dispatch(getAllOrderAsync(userDetail?.UserID));
        handleClose();
      }
    };
    fetchData();
    return () => clearTimeout(2000);
  }, [dispatch, userDetail]);

  // Filter orders based on the entered order code
  useEffect(() => {
    const capitalizedSearchTerm = searchTerm.toUpperCase();

    setFilteredOrders(
      orders.orderResults?.filter((order) =>
        order.order_no.includes(capitalizedSearchTerm)
      ) || []
    );
  }, [orders.orderResults, searchTerm]);

  useEffect(() => {
    if (selectedMonth !== "") {
      const currentDate = new Date();
      const startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - selectedMonth,
        1
      );

      setFilteredOrders(
        orders.orderResults?.filter((order) => {
          const orderDate = new Date(order.order_date);
          return orderDate >= startDate;
        }) || []
      );
    }
  }, [orders.orderResults, selectedMonth]);

  const handleTimeframeChange = (e) => {
    const selectedTimeframe = e.target.value;

    // Convert the selected timeframe to the corresponding number of months
    let months;
    switch (selectedTimeframe) {
      case "all":
        months = 0; // Assuming 0 represents all time
        break;
      case "3":
        months = 3;
        break;
      case "6":
        months = 6;
        break;
      default:
        months = 0; // Default to all time if none matches
        break;
    }

    // Update the selected month state
    setSelectedMonth(months);
    setOpenFilter(false);
  };

  const handleViewOrder = (order_ID) => {
    navigate(`/user/orders/view_order/${order_ID}`);
  };

  const handleFilterOrders = (status) => {
    if (
      status === "processing" ||
      status === "delivered" ||
      status === "cancelled"
    ) {
      if (searchTerm.trim() !== "") {
        // If search term is entered, filter based on search term and status
        setFilteredOrders(
          orders.orderResults?.filter(
            (order) =>
              order.order_no.includes(searchTerm) &&
              order.orderStatus === status
          ) || []
        );
      } else {
        // If no search term, filter based on status only
        setFilteredOrders(
          orders.orderResults?.filter(
            (order) => order.orderStatus === status
          ) || []
        );
      }
    } else {
      // If "All" button is clicked, reset filtered orders to all orders
      setSearchTerm("");
      setFilteredOrders(orders.orderResults || []);
    }
  };

  const toggleShowProducts = (value) => {
    if (showAllProducts === null) {
      setShowAllProducts(value);
    } else {
      setShowAllProducts(null);
    }
    if (!showAllProducts) {
      // Scroll to the "Show more" button when showing all products
      const showMoreButton = document.getElementById(
        `mobile-show-more-${value}-button`
      );
      showMoreButton.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  // Sort the filteredOrders array by date and time in descending order
  const sortedOrders = [...filteredOrders].sort((a, b) => {
    const dateA = new Date(a.order_date);
    const dateB = new Date(b.order_date);
    return dateB - dateA;
  });

  // Function to filter orders based on the selected filter
  const filterOrders = (order) => {
    // Filter out orders with null status
    if (!order?.orderStatus?.status) {
      return false;
    }

    if (!changeColorBtn) {
      // If no filter is selected, show all orders
      return true;
    }

    if (changeColorBtn === "processing") {
      // If "processing" button is active, include all statuses except "cancelled"
      return (
        order?.orderStatus?.status !== "cancelled" &&
        order?.orderStatus?.status !== "delivered"
      );
    }

    // Filter orders based on the selected status
    return order?.orderStatus?.status === changeColorBtn;
  };

  // REVIEW FUNCTIONALITY

  const [showReviewPopup, setShowReviewPopup] = useState(false);
  const closeReviewPopup = () => {
    setShowReviewPopup(false);
  };

  const toggleReviewPopup = () => {
    setShowReviewPopup(!showReviewPopup);
  };
  const handleDownloadInvoice = async (order) => {
    try {
      // Construct the URL for downloading the PDF invoice
      const invoiceUrl = `https://cyclecircle.blr1.digitaloceanspaces.com/${IMAGE_BASE}_INVOICE/${order?.order_no}.pdf`;

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = invoiceUrl;
      link.setAttribute("download", "invoice.pdf");

      // Simulate a click event to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up resources
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading invoice:", error);
    }
  };
  return (
    <div className="mobile-my-order-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open} // Show backdrop when either open or filtering state is true
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mobile-my-order-header-container p-3">
        <div className="mobile-my-order-header-left-container">
          <ChevronLeftOutlinedIcon onClick={() => navigate("/")} />
          <span className="mobile-my-order-header-left-span">
            My Orders <b>{sortedOrders && sortedOrders.length}</b>
          </span>
        </div>
        <div className="mobile-my-order-header-right-container">
          <MoreHorizOutlinedIcon onClick={handleSidebar} />
        </div>
        {openSidebar && (
          <ProfileSideBar
            handleSidebar={handleSidebar}
            openSidebar={openSidebar}
          />
        )}
      </div>
      <div className="mobile-my-order-search-container">
        <SearchOutlinedIcon />
        <input
          type="text"
          value={searchTerm}
          placeholder="Enter Order ID"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {openFilter && <div className="mobile-overlay"></div>}
      <div className="mobile-my-order-filter-sorting-container">
        <div className="mobile-my-order-filter-container">
          <span
            className={`mobile-my-order-each-filter ${
              changeColorBtn === "delivered" ? "active-order-sorting-btn" : ""
            }`}
            onClick={toggleDeliveredOrders}
          >
            Delivered
          </span>
          <span
            className={`mobile-my-order-each-filter ${
              changeColorBtn === "processing" ? "active-order-sorting-btn" : ""
            }`}
            onClick={toggleProcessingOrders}
          >
            Confirmed
          </span>
          <span
            className={`mobile-my-order-each-filter ${
              changeColorBtn === "cancelled" ? "active-order-sorting-btn" : ""
            }`}
            onClick={toggleCancelledOrders}
          >
            Cancelled
          </span>
        </div>
        <div className="mobile-my-order-sorting-container">
          <FilterListOutlinedIcon onClick={() => setOpenFilter(!openFilter)} />
        </div>
        {openFilter && (
          <Slide bottom>
            <div className="mobile-my-order-bottom-filter-container">
              <CloseOutlinedIcon
                className="mobile-my-order-bottom-filter-close-icon"
                onClick={() => setOpenFilter(!openFilter)}
              />
              <div className="mobile-my-order-bottom-filter-heading">
                <FilterListOutlinedIcon />
                <span className="mobile-my-order-bottom-filter-heading-name">
                  Filters
                </span>
              </div>
              <div className="mobile-my-order-bottom-filter-main-container">
                <div className="mobile-my-order-eachs-filter">
                  <input
                    type="radio"
                    name="timeframe"
                    id="allTime"
                    value="all"
                    onChange={handleTimeframeChange}
                    checked={selectedMonth === 0}
                  />
                  <span className="mobile-my-order-each-filter-name">
                    All time
                  </span>
                </div>
                <div className="mobile-my-order-eachs-filter">
                  <input
                    type="radio"
                    name="timeframe"
                    id="past3"
                    value="3"
                    onChange={handleTimeframeChange}
                    checked={selectedMonth === 3}
                  />
                  <span className="mobile-my-order-each-filter-name">
                    Past 3 Months
                  </span>
                </div>
                <div className="mobile-my-order-eachs-filter">
                  <input
                    type="radio"
                    name="timeframe"
                    id="past6"
                    value="6"
                    onChange={handleTimeframeChange}
                    checked={selectedMonth === 6}
                  />
                  <span className="mobile-my-order-each-filter-name">
                    Past 6 Months
                  </span>
                </div>
              </div>
            </div>
          </Slide>
        )}
      </div>
      <div className="mobile-my-order-main-order-container">
        {sortedOrders &&
          sortedOrders.map(
            (order, index) =>
              filterOrders(order) && (
                <div className="mobile-my-order-each-order-container">
                  <div className="mobile-my-order-each-order-header-container">
                    <div className="mobile-my-order-each-order-header-left-container">
                      <div className="mobile-my-order-each-order-header-left-order-id-container">
                        <span className="mobile-my-order-each-order-header-left-order-id-key">
                          Order ID:
                        </span>
                        <span className="mobile-my-order-each-order-header-left-order-id-value">
                          {order.order_no}
                        </span>
                      </div>
                      <div className="mobile-my-order-each-order-header-left-status-container">
                        <p className="mobile-my-order-each-order-header-left-status-key"></p>
                        <span className="mobile-my-order-each-order-header-left-status-value">
                          {" "}
                          {order?.orderStatus?.status === "pending"
                            ? "Confirmed"
                            : order?.orderStatus?.status}
                        </span>
                      </div>
                    </div>
                    <div className="mobile-my-order-each-order-header-right-container">
                      <div className="mobile-my-order-each-order-header-right-eta-container">
                        <span className="mobile-my-order-each-order-header-right-eta-key">
                          ETA:
                        </span>
                        <span className="mobile-my-order-each-order-header-right-eta-value">
                          {new Date(
                            new Date(order?.order_date).getTime() +
                              5 * 24 * 60 * 60 * 1000
                          ).toLocaleDateString("en-US", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </span>
                      </div>
                      <div
                        className="mobile-my-order-each-order-header-right-view-order-container"
                        onClick={() => handleViewOrder(order.order_no)}
                      >
                        <span className="mobile-my-order-each-order-header-right-view-order-name">
                          View Order
                        </span>
                        <KeyboardArrowRightOutlinedIcon />
                      </div>
                    </div>
                  </div>
                  {order.orderItems &&
                    order.orderItems?.map((product, index) => (
                      <div
                        className={`my-order-each-order-product-details-container ${
                          index > 2 && !showAllProducts ? "mobile-hidden" : ""
                        }`}
                        key={product.item_no_SKU}
                      >
                        <div className="my-order-each-order-product-top-container">
                          <div className="my-order-each-order-product-left-container">
                            <img loading="lazy"
                              src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${product.prodimage}`}
                              alt=""
                              className="my-order-each-order-product-left-img"
                            />
                          </div>
                          <div className="my-order-each-order-product-right-container">
                            <img loading="lazy"
                              src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                                product && product?.manufacture_images
                              }`}
                              alt=""
                              className="my-order-each-order-product-manufacture-img"
                            />
                            <span className="my-order-each-order-product-name">
                              {product.prod_name}
                            </span>
                            <span className="my-order-each-order-product-tech">
                              {product.prodTech &&
                                product.prodTech
                                  .slice(0, 1)
                                  .map((tech, index) => (
                                    <span key={index}>{tech} |</span>
                                  ))}
                            </span>
                            <div className="my-order-each-order-product-qty-varient-details-container">
                              <span className="my-order-each-order-product-qty">
                                Qty:&nbsp;<b>{product?.quantity}</b>
                              </span>
                              {product?.size_name && (
                                <span className="my-order-each-order-product-size">
                                  Size: &nbsp;
                                  <b>
                                    {product?.size_name && product?.size_name}
                                  </b>
                                </span>
                              )}
                              {product?.colour_name && (
                                <span className="my-order-each-order-product-size">
                                  Color: &nbsp;
                                  <b>
                                    {product?.colour_name &&
                                      product?.colour_name}
                                  </b>
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="my-order-each-order-product-review-container">
                          <span className="my-order-each-order-prduct-review">
                            Write a review
                          </span>
                          <span className="my-order-each-order-product-return">
                            Return this Item
                          </span>
                        </div>
                      </div>
                    ))}
                  {!showAllProducts && order.orderItems.length > 3 && (
                    <span
                      id={`mobile-show-more-${order.orderStatus}-button`}
                      className="mobile-show-more-button"
                      onClick={() => toggleShowProducts(order.orderStatus)}
                    >
                      {order.orderItems.length - 3} more items{" "}
                      <KeyboardArrowDownIcon />
                    </span>
                  )}
                  {/* Show less button */}
                  {showAllProducts === order.orderStatus &&
                    order.orderItems.length > 3 && (
                      <span
                        id={`mobile-show-less-${order.orderStatus}-button`}
                        className="mobile-show-less-button"
                        onClick={() => toggleShowProducts(order.orderStatus)}
                      >
                        Show less <KeyboardArrowUpIcon />
                      </span>
                    )}
                  <div className="my-order-each-order-total-price-invoice-container">
                    <div className="my-order-each-order-total-price-container">
                      <span className="my-order-each-order-total-price-key">
                        Order Total:
                      </span>
                      <span className="my-order-each-order-total-price-value">
                        ₹
                        {formatWithCommasWithDecimal(
                          order?.TotalAmount && parseInt(order?.TotalAmount)
                        )}&nbsp;
                        <b className="text-[13px] font-sans text-gray-800 tracking-tight font-medium">
                          {order?.Credit &&
                            `(+₹ ${formatWithCommasWithDecimal(
                              order?.TotalAmount &&
                                parseInt(order?.Credit && order?.Credit)
                            )} credit taken)`}
                        </b>
                      </span>
                    </div>
                    {order &&
                      order?.orderStatus &&
                      order?.orderStatus?.tracking_no && (
                        <div
                          className="my-order-each-order-product-invoice"
                          onClick={() => handleDownloadInvoice(order && order)}
                        >
                          <ReceiptIcon />
                          <span>Invoice</span>
                        </div>
                      )}
                  </div>
                </div>
              )
          )}
      </div>
    </div>
  );
}

export default MobileMyOrder;
