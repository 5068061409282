import React from "react";
import "./CopyRight.css";
import Header from "../HomePage/Header";
import Footer from "../HomePage/Footer";
import ScrollPromices from "../HomePage/ScrollPromices";
import { Link } from "react-router-dom";
import styled from "styled-components";
import BottomPromises from "../HomePage/BottomPromises";
import PopularSearch from "../HomePage/PopularSearch";
import { useMediaQuery } from "react-responsive";
import MobileHeader from "../../mobileComponent/MobileEndpoints/MobileHeader";
import MobilePopularSearch from "../../mobileComponent/MobileEndpoints/MobilePopularSearch";
import MobileFooter from "../../mobileComponent/MobileEndpoints/MobileFooter";
export function CopyRight() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 501px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  return (
    <>
      {isDesktopOrLaptop && (
        <HeaderDiv>
          <ScrollPromices />
          <Header />
        </HeaderDiv>
      )}
      {isTabletOrMobile && (
        <MobileHeaderDiv>
          <MobileHeader />
        </MobileHeaderDiv>
      )}

      <div className="copyright-container">
        <h2 className="copy-h2" style={{ fontWeight: "600" }}>
          COPYRIGHT
        </h2>
        <p>
          RADKAAT VENTURES PRIVATE LIMITED (“CycleCircle”) is the sole owner of
          all the rights to www.cyclecircle.one (“Website”) and its content.
          CYCLECIRCLE also uses contents of their vendors and third parties as
          well who might not be the original owners of copyright therein.
          Website content means its design, layout, text, images, graphics,
          sound, video etc. All the Titles, Ownership and Intellectual Property
          Rights in the Website and its contents shall remain with CYCLECIRCLE.
          All the rights not otherwise claimed under this agreement or by
          CYCLECIRCLE are hereby reserved. The information contained in this
          Website is intended, solely to provide general information for the
          personal/business use by the user, who accepts full responsibility of
          its use. The users should assume that standard copyright protection
          applies to all materials and contents displayed on the Website.
          CYCLECIRCLE does not represent or endorse the accuracy or reliability
          of any information, or advertisements (collectively, the “content”)
          contained on, distributed through, or linked, downloaded or accessed
          from any of the services contained in this Website, or the quality of
          any products, information or other materials displayed, or obtained by
          USER as a result of an advertisement or any other information or offer
          in or in connection with the service. We accept no responsibility for
          any errors or omissions, or for the results obtained from the use of
          the information contained in this Website. All information in this
          Website is provided “as is” with no guarantee of completeness,
          accuracy, timeliness or of the results obtained from the use of the
          information contained in this Website, and without warranty of any
          kind, express or implied, including, but not limited to warranties of
          performance, merchantability and fitness for a particular purpose. You
          may display, print, or download the contents to a local hard disk
          extracts for your personal, non-commercial use, but only if you
          acknowledge the Website as the source of the material. In no event
          shall CYCLECIRCLE be liable for any direct, indirect, incidental,
          punitive, or consequential damages of any kind whatsoever with respect
          to the service, the materials and the products available on the
          Website. User(s) of this site must hereby acknowledge that any
          reliance upon any content shall be at their sole risk. The information
          presented here has been compiled from publicly aired and published
          sources. CYCLECIRCLE respects these sources and is in no way trying to
          infringe on the respective copyrights or businesses of these entities.
          CYCLECIRCLE reserves the right, in its sole discretion and without any
          obligation, to make improvements to, or correct any error or omissions
          in any portion of the service or the materials.
        </p>
        <h2>Trademark</h2>
        <p>
          CycleCircle and related icons and logos are registered service marks
          of CycleCircle in various jurisdictions and are protected under
          applicable Trademark and other proprietary rights laws.
        </p>
        <h2>Intellectual Property Rights Policy</h2>
        <p>
          You are not permitted to use the Marks without the prior consent of
          CYCLECIRCLE, the vendor or the third party that may own the Marks. If
          you do so, CYCLECIRCLE may, in its sole discretion, terminate Your
          Account. All Marks, domain name, trade dress including the "look and
          feel" of the Website, its text, graphics, button icons, interfaces,
          etc., and the arrangements thereof, is the property of CYCLECIRCLE or
          its vendors or the third party, as the case may be, unless otherwise
          indicated. All the contents are protected under copyright, trademark
          and other applicable intellectual property laws and may not be used by
          You, except as permitted by CYCLECIRCLE, its vendors or the concerned
          third party.
        </p>
        <p>
          If you see something on the Website, which you believe is violating
          the intellectual property rights of any other entity, please send the
          infringement notice to:
        </p>
        <ul>
          <li>
            Legal Department (<Link to="#">spokes@CycleCircle.com</Link>)
          </li>
          <li>Radkaat Ventures Private Limited</li>
        </ul>
        <p>
          The Legal Department should be contacted only if you believe that your
          work has been used or copied in a way that constitutes copyright
          infringement and that such infringement is occurring on the Website or
          on websites linked to or from the Website, or in connection with the
          services provided on the Website. All other inquiries directed to the
          Legal Department will not be responded to. Such inquiries should be
          made through the feedback procedure referenced in the Website.
        </p>
      </div>
      {isDesktopOrLaptop && (
        <>
          <BottomPromises />
          <PopularSearch />
          <Footer />
        </>
      )}
      {isTabletOrMobile && (
        <>
          <MobilePopularSearch />
          <MobileFooter />
        </>
      )}
    </>
  );
}

const HeaderDiv = styled.div`
  width: 100%;
  height: 80px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;

const MobileHeaderDiv = styled.div`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;
