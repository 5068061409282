import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import {
  setBrands,
  setDealerMargin,
  setMaterials,
  setMaxPrice,
  setMinPrice,
  setRating,
  setShipping,
  setShopByOffer,
} from "../../store/slices/allFilterSlice";
import {
  clearAllFilters,
  setDealerMargins,
  setPriceRange,
  setRatings,
  setShippingMode,
  setShopByOffers,
} from "../../store/slices/listingPageFilterSlice";
import FilterComponent from "../../mComponent/FilterComponent";
import SortSharpIcon from "@mui/icons-material/SortSharp";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";
import { useParams } from "react-router";
import {
  fetchAllCategoryProductsAsync,
  fetchAllSubCategoriesAsync,
  fetchAllSubCategoryProductsAsync,
  fetchThroughBrandAsync,
  selectAllSubCategories,
  setSubCategories,
} from "../../features/listingPage/listingPageSlice";
import {
  selectedSelectedSubcategory,
  setSelectedSubcategory,
} from "../../store/slices/subCategorySlice";
import { setBreadcrumb } from "../../store/slices/breadCrumbsSlice";
import SubCategories from "../../mComponent/SubCategories";
import SortBy from "../../mComponent/SortBy";

function MobileSortByFilter({
  shippingMode,
  priceRange,
  ratings,
  dealerMargins,
  shopByOffers,
  setShippingMode,
  setPriceRange,
  setRatings,
  setDealerMargins,
  setShopByOffers,
  clearAllFilters,
  categoryName,
  CategoryID,
  setCurrentPage,
}) {
  const dispatch = useDispatch();
  const brands = useSelector((state) => state.allFilter?.brands);
  const materials = useSelector((state) => state.allFilter?.materials);
  const sortPrice = useSelector((state) => state.allFilter?.sortPrice);
  const sortRating = useSelector((state) => state.allFilter?.sortRating);
  const [openFilter, setOpenFilter] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [openCtg, setOpenCtg] = useState(false);

  const handleFilterOpen = () => {
    setFilterOpen(!filterOpen);
  };
  const toggleFilter = (filter) => {
    setOpenFilter((prevFilter) => (prevFilter === filter ? null : filter));
  };
  const handleSelection = (selectedOption) => {
    setOpenFilter(null);

    if (selectedOption?.brands) {
      dispatch(setBrands(selectedOption?.brands));
    }

    if (selectedOption?.materials) {
      dispatch(setMaterials(selectedOption?.materials));
    }
  };
  const handleClearAll = () => {
    clearAllFilters();
    setRatings("");
    setDealerMargins("");
    setShopByOffers("");
    dispatch(setRating(0));
    dispatch(setDealerMargin(0));
    dispatch(setShopByOffer(""));
    setPriceRange("");
    dispatch(setMinPrice(0));
    dispatch(setMaxPrice(0));
    setShippingMode("");
    dispatch(setShipping(""));
    dispatch(setBrands([]));
    dispatch(setMaterials([]));
  };
  const getTotalFiltersCount = () => {
    let totalFilters = 0;
    if (shippingMode) totalFilters++;
    if (priceRange) totalFilters++;
    if (ratings) totalFilters++;
    if (dealerMargins) totalFilters++;
    if (shopByOffers) totalFilters++;
    totalFilters += brands?.length;
    totalFilters += materials?.length;
    return totalFilters;
  };

  // SubCategory

  const handleOpenCtg = () => {
    setOpenCtg(!openCtg);
  };

  const brandname = useParams();
  const subCategories = useSelector(selectAllSubCategories);
  const selectedSubcategory = useSelector(selectedSelectedSubcategory);

  useEffect(() => {
    if (CategoryID && brandname?.brand === undefined) {
      dispatch(fetchAllSubCategoriesAsync(CategoryID));
    }
  }, [dispatch, CategoryID, brandname?.brand]);

  // Set selected subCategory
  const handleApplyClick = (subCategoryID, subCategoryName) => {
    dispatch(
      setSelectedSubcategory(
        selectedSubcategory === subCategoryID ? null : subCategoryID
      )
    );
    // Set subcategory in breadcrumb
    dispatch(
      setBreadcrumb({
        categoryID: CategoryID,
        categoryName: categoryName,
        subCategoryID: subCategoryID,
        subCategoryName: subCategoryID ? subCategoryName : "",
        productName: "",
        ProductID: null,
      })
    );
    setCurrentPage(1);
  };

  // Run when coming to another page and sub-category is already selected previously
  const fetchDataBasedOnSelection = () => {
    if (brandname?.brand) {
      dispatch(fetchThroughBrandAsync(brandname?.brand));
      dispatch(setSubCategories([]));
      dispatch(setSelectedSubcategory(null));
      dispatch(
        setBreadcrumb({
          categoryID: null,
          categoryName: brandname?.brand,
          subCategoryID: null,
          subCategoryName: "",
          productName: "",
          ProductID: null,
        })
      );
    } else if (
      selectedSubcategory ||
      (subCategories && subCategories?.length > 0)
    ) {
      const isSubCategorySelected = subCategories?.some(
        (item) => item.sc_id === selectedSubcategory
      );

      if (isSubCategorySelected) {
        dispatch(fetchAllSubCategoryProductsAsync(selectedSubcategory));
      }
      if (!selectedSubcategory) {
        // dispatch(fetchAllSubCategoryProductsAsync(selectedSubcategory));
        dispatch(fetchAllCategoryProductsAsync(CategoryID));
      }
    }
  };

  useEffect(() => {
    fetchDataBasedOnSelection();
  }, [selectedSubcategory, brandname?.brand]);

  return (
    <>
      <div className="w-full flex items-center bg-white-1 py-3 px-3 shadow-sm shadow-black-1/30 order-[0] relative z-10">
        <div
          className="flex items-center"
          onClick={() => toggleFilter("sortBy")}
        >
          <SortSharpIcon
            className="text-blue/90"
            style={{ fontSize: "24px" }}
          />
          <span className="ml-1 text-blue/90 text-[15px] font-bold tracking-wide">
            Sort By
          </span>
        </div>
        {openFilter === "sortBy" && (
          <SortBy
            onSelect={handleSelection}
            setOpenFilter={setOpenFilter}
            previouslySortPrice={sortPrice}
            previouslySortRating={sortRating}
            openFilter={openFilter}
          />
        )}
        <span className="mx-[10px] text-gray-500">|</span>
        <div className="flex items-center" onClick={handleFilterOpen}>
          <FilterListSharpIcon
            className="text-blue/90"
            style={{ fontSize: "24px" }}
          />
          <span className="ml-1 text-blue/90 text-[15px] font-bold tracking-wide">
            Filter
          </span>
          {getTotalFiltersCount() > 0 && (
            <span className="bg-blue rounded-sm text-white-1 px-1 font-sans ml-[2px]">
              {getTotalFiltersCount()}
            </span>
          )}
        </div>
        <span
          className="absolute right-3 text-14 font-semibold text-[purple] border-[1.5px] border-[purple] rounded-md py-1 px-2 tracking-wide"
          onClick={handleOpenCtg}
        >
          Subcategory
        </span>
      </div>
      <div className="w-full h-full">
        {filterOpen && (
          <FilterComponent
            setFilterOpen={setFilterOpen}
            onSelect={handleSelection}
            closeBrandSelect={() => setOpenFilter(null)}
            previouslySelectedBrands={brands}
            closeBikesSelect={() => setOpenFilter(null)}
            closeMaterialSelect={() => setOpenFilter(null)}
            previouslySelectedMaterials={materials}
            handleClearAll={handleClearAll}
            filterOpen={filterOpen}
          />
        )}
      </div>
      {getTotalFiltersCount() > 0 && (
        <div className="w-full flex items-center overflow-x-scroll py-4 px-5 bg-white-1 order-1 inset-shadow-black">
          <div className="flex items-center relative overflow-x-scroll">
            {shippingMode && (
              <div className="flex items-center px-[6px] py-[2px] font-sans rounded-sm mr-1 text-14 bg-blue text-white-1 whitespace-nowrap text-ellipsis">
                <span className="text-14 font-bold">{shippingMode} </span>
                <CloseSharpIcon
                  onClick={() => {
                    setShippingMode("");
                    dispatch(setShipping(""));
                  }}
                  style={{ fontSize: "16px" }}
                />
              </div>
            )}
            {priceRange && (
              <div className="flex items-center px-[6px] py-[2px] font-sans rounded-sm mr-1 text-14 bg-blue text-white-1 whitespace-nowrap text-ellipsis">
                <span className="text-14 font-bold">{priceRange} </span>
                <CloseSharpIcon
                  onClick={() => {
                    setPriceRange("");
                    dispatch(setMinPrice(0));
                    dispatch(setMaxPrice(0));
                  }}
                  style={{ fontSize: "16px" }}
                />
              </div>
            )}
            {ratings && (
              <div className="flex items-center px-[6px] py-[2px] font-sans rounded-sm mr-1 text-14 bg-blue text-white-1 whitespace-nowrap text-ellipsis">
                <span className="text-14 font-bold">{ratings}+</span>
                <StarRoundedIcon
                  className="text-[goldenrod] ml-0"
                  style={{ fontSize: "16px" }}
                />
                <CloseSharpIcon
                  onClick={() => {
                    setRatings("");
                    dispatch(setRating(0));
                  }}
                  style={{ fontSize: "16px" }}
                />
              </div>
            )}
            <div className="flex items-center whitespace-nowrap text-ellipsis">
              {brands &&
                brands?.map((brand, index) => (
                  <div
                    className="flex items-center px-[6px] py-[2px] font-sans rounded-sm mr-1 text-14 bg-blue text-white-1 whitespace-nowrap text-ellipsis"
                    key={index}
                  >
                    <span className="text-14 font-bold">{brand}</span>
                    <CloseSharpIcon
                      onClick={() => {
                        dispatch(
                          setBrands(brands.filter((b, i) => i !== index))
                        );
                      }}
                      style={{ fontSize: "16px" }}
                    />
                  </div>
                ))}
            </div>
            {dealerMargins && (
              <div className="flex items-center px-[6px] py-[2px] font-sans rounded-sm mr-1 text-14 bg-blue text-white-1 whitespace-nowrap text-ellipsis">
                <>
                  <span className="text-14 font-bold">
                    {dealerMargins}%+ Dealer Margin
                  </span>
                  <CloseSharpIcon
                    onClick={() => {
                      setDealerMargins("");
                      dispatch(setDealerMargin(0));
                    }}
                    style={{ fontSize: "16px" }}
                  />
                </>
              </div>
            )}
            {shopByOffers && (
              <div className="flex items-center px-[6px] py-[2px] font-sans rounded-sm mr-1 text-14 bg-blue text-white-1 whitespace-nowrap text-ellipsis">
                <>
                  <span className="text-14 font-bold">{shopByOffers}</span>
                  <CloseSharpIcon
                    onClick={() => {
                      setShopByOffers("");
                      dispatch(setShopByOffer(""));
                    }}
                    style={{ fontSize: "16px" }}
                  />
                </>
              </div>
            )}
            <div className="flex items-center whitespace-nowrap text-ellipsis">
              {materials &&
                materials?.map((material, index) => (
                  <div
                    className="flex items-center px-[6px] py-[2px] font-sans rounded-sm mr-1 text-14 bg-blue text-white-1 whitespace-nowrap text-ellipsis"
                    key={index}
                  >
                    <span className="text-14 font-bold">{material}</span>
                    <CloseSharpIcon
                      onClick={() => {
                        dispatch(
                          setMaterials(materials.filter((m, i) => i !== index))
                        );
                      }}
                      style={{ fontSize: "16px" }}
                    />
                  </div>
                ))}
            </div>
            {shippingMode ||
            priceRange ||
            ratings ||
            brands?.length > 0 ||
            materials?.length > 0 ? (
              <div className="ml-0 whitespace-nowrap text-ellipsis">
                <span
                  className="block text-peach/80 text-12"
                  onClick={handleClearAll}
                >
                  Clear all
                </span>
              </div>
            ) : null}
          </div>
        </div>
      )}
      {openCtg && (
        <SubCategories
          categoryName={categoryName}
          subCategories={subCategories}
          selectedSubcategory={selectedSubcategory}
          handleOpenCtg={handleOpenCtg}
          handleApplyClick={handleApplyClick}
          openCtg={openCtg}
          setOpenCtg={setOpenCtg}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  shippingMode: state.listingPageFilter?.shippingMode,
  priceRange: state.listingPageFilter?.priceRange,
  ratings: state.listingPageFilter?.ratings,
  dealerMargins: state.listingPageFilter?.dealerMargins,
  shopByOffers: state.listingPageFilter?.shopByOffers,
});

const mapDispatchToProps = {
  setShippingMode,
  setPriceRange,
  setRatings,
  setDealerMargins,
  setShopByOffers,
  clearAllFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileSortByFilter);
