import React from 'react';
import "./Granite.css";
import { graniteData } from '../../../data';

function Granite() {
    const { brand, slogan, description, productLineup, attributes, partnership } = graniteData;
  return (
    <div className="granite-container">
    <h2>{`Welcome to ${brand}`}</h2>
    <h3>{slogan}</h3>
    <p>{description}</p>

    <div className="product-lineup">
      <h3>Product Lineup</h3>
      {productLineup.categories.map((category, index) => (
        <div key={index}>
          <h4>{category.name}</h4>
          <p>{category.description}</p>
        </div>
      ))}
    </div>

    <div className="attributes">
      <h3>Attributes</h3>
      <ul>
        {Object.entries(attributes).map(([key, value]) => (
          <li key={key}>{key}: {value.toString()}</li>
        ))}
      </ul>
    </div>

    <div className="partnership">
      <h3>Partnership with {partnership.partner}</h3>
      <p>{partnership.description}</p>
    </div>
  </div>
  )
}

export default Granite