import React from "react";
import "./AboutUs.css";
import Header from "../HomePage/Header";
import Footer from "../HomePage/Footer";
import ScrollPromices from "../HomePage/ScrollPromices";
import styled from "styled-components";
import BottomPromises from "../HomePage/BottomPromises";
import PopularSearch from "../HomePage/PopularSearch";
import { useMediaQuery } from "react-responsive";
import MobileHeader from "../../mobileComponent/MobileEndpoints/MobileHeader";
import MobilePopularSearch from "../../mobileComponent/MobileEndpoints/MobilePopularSearch";
import MobileFooter from "../../mobileComponent/MobileEndpoints/MobileFooter";
export function AboutUs() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 501px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  return (
    <>
      {isDesktopOrLaptop && (
        <HeaderDiv>
          <ScrollPromices />
          <Header />
        </HeaderDiv>
      )}
      {isTabletOrMobile && (
        <MobileHeaderDiv>
          <MobileHeader />
        </MobileHeaderDiv>
      )}

      <div className="aboutus-container">
        <h2 style={{ fontWeight: "600" }}>About Us</h2>
        <p>
          CycleCircle provides a b2b e-commerce platform aimed at helping
          bicycle retailers and OEMs efficiently procure spare parts,
          accessories, clothing, and bicycles. Through our platform, we aim to
          ensure every small &amp; medium bicycle retailer in India has equal
          access to genuine products from premier global and local bicycle parts
          manufacturers.
        </p>
        <p>
          Our team, which founded one of the world's top MTB races- Hero MTB
          Himalaya, is made up of leading supply chain professionals, mountain
          biking champions, bicycle product designers, adventure sports experts,
          and technologists with a rich 18-year track record in delivering
          outstanding products and services in Start-ups and large Corporates.
        </p>
        <p>
          Rooted in the world of cycling, we're leveraging our passion and
          expertise to make the business of bicycling more sustainable for
          retailers, and in the process make the sport more inviting for the
          next generation of young bicyclists.
        </p>
      </div>
      {isDesktopOrLaptop && (
        <>
          <BottomPromises />
          <PopularSearch />
          <Footer />
        </>
      )}
      {isTabletOrMobile && (
        <>
          <MobilePopularSearch />
          <MobileFooter />
        </>
      )}
    </>
  );
}

const HeaderDiv = styled.div`
  width: 100%;
  height: 80px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;

const MobileHeaderDiv = styled.div`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;
