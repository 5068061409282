import React from "react";
import "./ProductPrice.css";
import bracketLogo from "../../img/navigation/Bracket.svg";
import discountLogo from "../../img/navigation/Discount.svg";
import { connect, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {
  selectIsOverlayVisible,
  toggleOverlay,
} from "../../store/slices/headerSlice";
import { calculateDealerPrice, calculateDiscountPercentage, calculateOfferPrice, formatWithCommasWithDecimal, formatWithCommasWithoutDecimal } from "../../utils/utils";

function ProductPrice({ selectProduct, isOverlayVisible, toggleOverlay }) {
  const selectedVariant = useSelector(
    (state) => state.productDetail.selectedVariant
  );
  const userDetail = useSelector(selectUser);

  


  return (
    <div className="productPrice-container">
      <div className="productPrice-left-container">
        <div className="productPrice-mrp-price-container">
          <span className="productPrice-mrp-price-heading">Dealer Price</span>
          <div className="productPrice-mrp-price-main-container">
            <div className="productPrice-ndp-offer-price-container">
              {Object.keys(userDetail).length > 0 ? (
                <span
                  className="productPrice-mrp-price-span"
                  style={{
                    fontSize: selectedVariant?.Offer_price !== null && "14px",
                    textDecoration:
                      selectedVariant?.Offer_price !== null && "line-through",
                    fontWeight: selectedVariant?.Offer_price !== null && "500",
                  }}
                >
                  ₹
                  {selectedVariant && selectedVariant?.NDP_Price &&
                    formatWithCommasWithDecimal(
                      calculateDealerPrice(
                        parseInt(selectedVariant?.MRP_price),
                        parseInt(selectedVariant?.NDP_Price),
                        selectProduct && selectProduct[0]?.GST
                      )
                    )}
                </span>
              ) : (
                <span
                  className="product-price-login-to-see-price"
                  onClick={toggleOverlay}
                >
                  Login to see Price
                </span>
              )}

              {Object.keys(userDetail).length > 0 &&
                selectedVariant && selectedVariant?.Offer_price !== null && (
                  <span className="productPrice-offer-price-span">
                    ₹
                    {formatWithCommasWithDecimal(
                      calculateOfferPrice(
                        parseInt(selectedVariant?.MRP_price),
                        parseInt(selectedVariant?.Offer_price),
                        selectProduct && selectProduct[0]?.GST
                      )
                    )}
                  </span>
                )}
            </div>
            <span className="productPrice-mrp-price-inclusive">
              (Inclusive of all Taxes)
            </span>
          </div>
        </div>
        <div className="productPrice-ndp-price-container">
          <span className="productPrice-ndp-price-heading">MRP</span>
          <span className="productPrice-ndp-price-span">
            ₹{formatWithCommasWithDecimal(parseInt(selectedVariant && selectedVariant?.MRP_price))}{" "}
          </span>
        </div>
      </div>
      <div className="productPrice-divider-container">
        <img loading="lazy" src={bracketLogo} alt="" className="productPrice-divider-img" />
      </div>
      {calculateDiscountPercentage(
        selectedVariant && selectedVariant?.MRP_price,
        selectedVariant && selectedVariant?.NDP_Price
      ) > 0 && (
        <div className="productPrice-right-container">
          <div className="productPrice-right-margin-container">
            {Object.keys(userDetail).length > 0 ? (
              <>
                <img loading="lazy"
                  src={discountLogo}
                  alt=""
                  className="productPrice-right-margin-logo"
                />
                <span
                  className="productPrice-right-margin-span"
                  style={{
                    fontSize:
                      selectedVariant?.Offer_price !== null ? "10px" : "",
                    textDecoration:
                      selectedVariant?.Offer_price !== null
                        ? "line-through"
                        : "",
                    fontWeight:
                      selectedVariant?.Offer_price !== null ? "500" : "",
                  }}
                >
                  {calculateDiscountPercentage(
                    selectedVariant?.MRP_price,
                    calculateDealerPrice(
                      parseInt(selectedVariant?.MRP_price),
                      parseInt(selectedVariant?.NDP_Price),
                      selectProduct && selectProduct[0]?.GST
                    )
                  )}
                </span>
                {selectedVariant.Offer_price !== null && (
                  <span className="productPrice-right-offer-span">
                    {calculateDiscountPercentage(
                      selectedVariant?.MRP_price,
                      calculateOfferPrice(
                        parseInt(selectedVariant?.MRP_price),
                        parseInt(selectedVariant?.Offer_price),
                        selectProduct && selectProduct[0]?.GST
                      )
                    )}
                  </span>
                )}
              </>
            ) : (
              <div className="product-price-not-visible-container">
                <VisibilityOffOutlinedIcon onClick={toggleOverlay} />
              </div>
            )}
            % Dealer Margin
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isOverlayVisible: selectIsOverlayVisible(state),
});
const mapDispatchToProps = {
  toggleOverlay,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductPrice);
