import React from "react";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { IMAGE_BASE } from "../config";
import { SwipeableDrawer } from "@mui/material";

const SubCategories = ({
  categoryName,
  subCategories,
  selectedSubcategory,
  handleOpenCtg,
  handleApplyClick,
  openCtg,
  setOpenCtg,
}) => {
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenCtg(open);
  };
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={openCtg}
      onClose={()=>toggleDrawer(false)}
      onOpen={()=>toggleDrawer(true)}
      PaperProps={{
        sx: {
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
          overflow: 'visible',
        },
      }}
    >
      <div className="w-full flex flex-col bg-white-1 rounded-t-[15px]">
        <div className="w-full flex items-center justify-between py-2 px-3 border-b-2 border-black-1/10">
          <span className="text-16 font-bold tracking-wide text-blue">
            {categoryName} Subcategories
          </span>
          <CloseSharpIcon
            onClick={handleOpenCtg}
            style={{ fontSize: "18px" }}
            className="text-blue"
          />
        </div>
        <div className="w-full flex flex-row flex-wrap py-2">
          {subCategories?.map((item) => (
            <div
              className={`w-[20%] flex-center flex-col my-2 mx-[2.5%]`}
              key={item?.sc_id}
              onClick={() => {
                handleApplyClick(
                  selectedSubcategory === item?.sc_id ? null : item?.sc_id,
                  item?.SubcategorieName
                );
                handleOpenCtg();
              }}
            >
              <img loading="lazy"
                src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_SUBCATEGORY_IMAGE/${item?.image}`}
                alt="sub-cat-logo"
                className={`w-[50px] h-[50px] rounded-full py-2 ${
                  selectedSubcategory === item?.sc_id
                    ? "border-2 border-blue/80"
                    : "border-[1px] border-blue/20"
                }`}
              />
              <span className="text-blue text-14 tracking-wide font-semibold text-center my-1">
                {item?.SubcategorieName}
              </span>
            </div>
          ))}
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export default SubCategories;
