import React from "react";
import "./SovereignSaddles.css";

function SovereignSaddles() {
  return (
    <div className="sovereign-container">
      <div className="sovereign-brand-info">
        <p>
          Sovereign Saddles India, a division of Sovereign Industries, is a
          premier bicycle saddle manufacturer known for its exceptional
          craftsmanship. They produce high-quality, ergonomic saddles that focus
          on comfort and performance. With a long-standing history in the
          industry, Sovereign Saddles has become a trusted choice for cycling
          enthusiasts both locally and globally, thanks to their commitment to
          innovation and customer satisfaction. They provide saddles for many
          leading bicycle brands in India and around the world, catering to
          diverse cycling needs.
        </p>
      </div>
      <div className="sovereign-highlights">
        <h2>Key Highlights:</h2>
        <ul>
          <li>
            <strong>Leading Manufacturer:- </strong>Part of Sovereign
            Industries, a top bicycle saddle manufacturer in India.
          </li>
          <li>
            <strong>Exceptional Craftsmanship:- </strong>Known for high-quality,
            ergonomic designs focusing on comfort and performance.
          </li>
          <li>
            <strong>Long-Standing History:- </strong>Pioneers in producing
            budget-friendly, high-quality saddles.
          </li>
          <li>
            <strong>Innovation and Customer Satisfaction:- </strong>Strong
            commitment to continuous improvement and customer-centric design.
          </li>
          <li>
            <strong>Advanced Materials and Technologies:- </strong>Uses
            cutting-edge materials and technologies.
          </li>
          <li>
            <strong>Diverse Product Range:- </strong>Saddles for casual riding
            to competitive sports.
          </li>
          <li>
            <strong>Trusted by Leading Brands:- </strong>Supplies many top
            bicycle brands in India and globally.
          </li>
          <li>
            <strong>Global Presence:- </strong>Significant market presence and
            loyal customer base worldwide.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SovereignSaddles;
