import React, { useState } from "react";
import "./MobileSecurity.css";
import { API_BASE_URL } from "../../config";

import { Link, useNavigate } from "react-router-dom";
import { selectUser } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import MobileViaPhone from "./MobileViaPhone";
import MobileViaEmail from "./MobileViaEmail";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ProfileSideBar from "../../mComponent/ProfileSideBar";
import toast from "react-hot-toast";

function MobileSecurity() {
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);
  const [verificationMethod, setVerificationMethod] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [verify, setVerify] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleBackClick = () => {
    setVerificationMethod(null);
  };
  const handlePhoneVerificationClick = async () => {
    const phoneNumber = userDetail && userDetail?.PhoneNumber;
    if (!phoneNumber || phoneNumber.length < 10) {
      toast.error("Please fill number correctly")
    } else {
      handleOpen();

      try {
        const response = await fetch(
          `${API_BASE_URL}update_phone_number/${userDetail?.UserID}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {

          toast.success(`${data.message}`)
          setVerificationMethod("phone");
        } else {
          console.error("API request failed:", response.statusText);
          toast.error('OTP not Sent')
        }
      } catch (error) {
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        toast.error('Please try again later');
      }
      handleClose();
    }
  };

  const handleEmailVerificationClick = async () => {
    const email = userDetail && userDetail?.Email;
    if (!email || !email.includes("@")) {
      toast.error('Please fill email correctly')
    } else {
      handleOpen();
      try {
        const response = await fetch(
          `${API_BASE_URL}update_email/${userDetail?.UserID}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          toast.success(`${data.message}`)
          setVerificationMethod("email");
        } else {
          console.error("API request failed:", response.statusText);
          toast.error('OTP not Sent')
        }
      } catch (error) {
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        toast.error('Please try again later');
      }
      handleClose();
    }
  };
  const [openSidebar, setOpenSidebar] = useState(false);
  const handleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };
  return (
    <div className="my-profile-mobile-security-container">
      <div className="mobile-my-order-header-container p-3">
        <div className="mobile-my-order-header-left-container">
          <ChevronLeftOutlinedIcon onClick={() => navigate("/")}/>
          <span className="mobile-my-order-header-left-span">Security</span>
        </div>
        <div className="mobile-my-order-header-right-container">
          <MoreHorizOutlinedIcon onClick={handleSidebar} />
        </div>
        {openSidebar && <ProfileSideBar handleSidebar={handleSidebar} openSidebar={openSidebar}/>}
      </div>
      <div className="mobile-security-basic-detail-phone-email-verfication">
        {verificationMethod ? (
          verificationMethod === "phone" ? (
            <MobileViaPhone
              handleBackClick={handleBackClick}
              phoneNumber={userDetail && userDetail?.PhoneNumber}
              verify={verify}
              setVerify={setVerify}
            />
          ) : (
            <MobileViaEmail
              handleBackClick={handleBackClick}
              email={userDetail && userDetail?.Email}
              verify={verify}
              setVerify={setVerify}
            />
          )
        ) : (
          <div className="mobile-security-basic-detial-phone-em-verification-main-cont">
            <span className="mobile-security-basic-detail-heading">
              Verification
            </span>
            <span className="mobile-security-basic-detail-text">
              To Protect your Account,you have to verify either Phone number or
              Email
            </span>
            <div className="mobile-security-basic-detail-phone-email-verification-btn">
              <Link to="#" onClick={handlePhoneVerificationClick}>
                Phone number
              </Link>
              <span className="mobile-security-divider">- or -</span>
              <Link to="#" onClick={handleEmailVerificationClick}>
                Email id
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MobileSecurity;
