import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedSupport:1
}

const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    setSelectedSupport: (state, action) => {
        state.selectedSupport = action.payload;
      },
  },
});

export const { setSelectedSupport } = supportSlice.actions;
export const selectSelectedSupport = (state) => state.selectedSupport;

export default supportSlice.reducer;
