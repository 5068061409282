import React from "react";
import Header from "../components/HomePage/Header";
import Footer from "../components/HomePage/Footer";
import styled from "styled-components";
import ScrollPromices from "../components/HomePage/ScrollPromices";
import PopularSearch from "../components/HomePage/PopularSearch";
import BottomPromises from "../components/HomePage/BottomPromises";
import ShopByCategories from "../components/HomePage/ShopByCategories";
import ListingPages from "../components/ListingPage/ListingPages/ListingPages";
import ShopByBrand from "../components/ListingPage/ShopByBrand";
import { useMediaQuery } from "react-responsive";
import MobileHeader from "../mobileComponent/MobileEndpoints/MobileHeader";
import MobileFooter from "../mobileComponent/MobileEndpoints/MobileFooter";
import MobilePopularSearch from "../mobileComponent/MobileEndpoints/MobilePopularSearch";
import MobileListingPage from "../mobileComponent/MobileListingPage/MobileListingPage";
import MobileBottomNavigation from "../mobileComponent/MobileEndpoints/MobileBottomNavigation";
import ShopByCtgCard from "../mComponent/ShopByCtgCard";

function ListingPage() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 501px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" });
  return (
    <div>
      {isDesktopOrLaptop && (
        <>
          <HeaderDiv>
            <ScrollPromices />
            <Header />
          </HeaderDiv>
          <ListingPages />
          <ShopByBrand />
          <ShopByCategories />
          <BottomPromises />
          <PopularSearch />
          <Footer />
        </>
      )}
      {isTabletOrMobile && (
        <>
          <MobileHeaderDiv>
            <MobileHeader />
          </MobileHeaderDiv>
          <MobileBottomNavigation />
          <MobileListingPage />
          <ShopByCtgCard/>
          <MobilePopularSearch />
          <MobileFooter />
        </>
      )}
    </div>
  );
}

export default ListingPage;

const HeaderDiv = styled.div`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  @media (max-width: 500px) {
    z-index: 999;
  }
`;

const MobileHeaderDiv = styled.div`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;
