import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  formatDate,
  formatDateAfter30Days,
  formatWithCommasWithDecimal,
  getFormattedDueDate,
} from "../../utils/utils";
import discountLogo from "../../assets/Icon-Discount.png";
import { generatePartialPaymentUrl } from "../../utils/payment";
import PaymentMain from "../PartialPayment/PaymentMain";

const DueAmount = ({ creditDetail, recentOrder, userDetail }) => {
  const [amount, setAmount] = useState(0);
  const [paymentSessionId, setPaymentSessionId] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [openPaymentModel, setOpenPaymentModal] = useState(false);
  const [pendingOrder, setPendingOrder] = useState(null);
  const [dueDate, setDueDate] = useState(null);

  useEffect(() => {
    if (recentOrder?.length > 0) {
      const pending = recentOrder
        ?.filter((order) => order?.transactionType === "SPEND")
        ?.sort(
          (a, b) => new Date(a?.transactionDate) - new Date(b?.transactionDate)
        )[0];

      setPendingOrder(pending);

      if (pending) {
        const dueDate = getFormattedDueDate(pending?.transactionDate);
        setDueDate(dueDate);
      }
    }
  }, [recentOrder]);

  const handleAmountChange = (event) => {
    const inputValue = event.target.value;

    // Allow clearing the input
    if (inputValue === "") {
      setAmount(0);
      return;
    }

    const numericValue = parseInt(inputValue);

    // Prevent setting the value if it's NaN or less than 0, or greater than the available spentAmount
    if (
      !isNaN(numericValue) &&
      numericValue >= 0 &&
      numericValue <= (creditDetail?.spentAmount || 0)
    ) {
      setAmount(numericValue);
    }
  };

  const handlePresetAmountClick = (amount) => {
    if (parseInt(amount) > 0 && parseInt(amount) <= creditDetail?.spentAmount) {
      setAmount(parseInt(amount));
    } else if (
      parseInt(amount) > 0 &&
      parseInt(amount) > creditDetail?.spentAmount
    ) {
      setAmount(parseInt(creditDetail?.spentAmount));
    }
  };

  const TargetPartialPayment = async () => {
    if (parseInt(amount) > 0) {
      setOpenPaymentModal(true);
      await generatePartialPaymentUrl(
        amount,
        userDetail,
        setPaymentSessionId,
        setTransactionId
      );
    }
  };

  const generatePresetAmounts = (spentAmount) => {
    if (!spentAmount || spentAmount <= 0) return [];

    const portion1 = Math.floor((spentAmount * 0.25) / 1000) * 1000; // 25% rounded down to nearest thousand
    const portion2 = Math.floor((spentAmount * 0.5) / 1000) * 1000; // 50% rounded down to nearest thousand
    const portion3 = Math.floor((spentAmount * 0.75) / 1000) * 1000; // 75% rounded down to nearest thousand

    // Ensure all portions are less than the spentAmount
    const amounts = [portion1, portion2, portion3]
      .filter((amount) => amount > 0 && amount < spentAmount) // Only show amounts less than spentAmount and greater than 0
      .filter((amount, index, self) => self.indexOf(amount) === index); // Ensure uniqueness

    return amounts;
  };

  // Usage in component
  const presetAmounts = generatePresetAmounts(creditDetail?.spentAmount || 0);

  return (
    <>
      <div className="w-full h-auto flex flex-col bg-white-1 shadow-sm shadow-black-1/10 mb-3 rounded-md p-3">
        <span className="w-full h-[40px] text-[18px] pt-1  text-black-1 font-bold tracking-wide border-b-[1.5px] border-black-1/10">
          Pay Due Amount
        </span>
        <div className="w-full flex flex-col lg:flex-row justify-between">
          {/* Discount Section */}
          <div className="w-full lg:w-[50%] flex flex-col justify-between py-2">
            {pendingOrder ? (
              <div className="w-auto flex-col">
                <div className="w-full sm:w-fit flex items-center p-2 bg-[#28a447]/10 rounded-md mb-3">
                  <img
                    loading="lazy"
                    src={discountLogo}
                    alt="discount-logo"
                    className="h-[30px]"
                  />
                  <div className="flex flex-col p-2">
                    <div className="w-full flex items-center justify-between">
                      <span className="text-[11px] text-black-1 font-bold my-[2px]">
                        2% Cashback Available
                      </span>
                    </div>
                    <span className="text-[10px] text-black-1/60 mt-1">
                      Pay{" "}
                      <b className="text-black-1/80">
                        ₹{formatWithCommasWithDecimal(pendingOrder?.amount)}
                      </b>{" "} ore more 
                      before{" "}
                      <b className="text-black-1/80">
                        {formatDateAfter30Days(pendingOrder?.transactionDate)}
                      </b>{" "}
                      to avail cashback!
                    </span>
                  </div>
                </div>
                <span className="text-[11px] text-peach/80 font-bold">
                  Upcomming Due Date:{" "}
                  <b className="text-[12px] text-peach/80">{dueDate}</b>
                </span>
              </div>
            ) : (
              <div className="w-full sm:w-fit flex items-center p-2 bg-gray-200 rounded-md">
                <img
                  loading="lazy"
                  src={discountLogo}
                  alt="no-discount-logo"
                  className="h-[30px] opacity-50"
                />
                <div className="flex flex-col p-2">
                  <span className="text-[11px] text-gray-600 font-bold my-[2px]">
                    No Pending Payments
                  </span>
                  <span className="text-[10.5px] text-gray-500 font-semibold my-[2px]">
                    You have no pending orders at the moment.
                  </span>
                </div>
              </div>
            )}
            <span className="text-[10.5px] text-black-1/70 mt-2">
              <b>Note:</b> Post bill payments, the updated amount due will
              reflect shortly.
            </span>
          </div>

          {/* Payment Section */}
          <div className="w-full lg:w-[45%] flex flex-col items-end py-3 mt-4 lg:mt-0">
            <div className="w-full flex flex-col">
              <span className="text-[15px] text-gray-950 font-bold">
                Enter the amount you want to pay
              </span>
              <div className="w-full flex items-center my-2 mb-1">
                <div className="flex gap-2 flex-wrap">
                  {presetAmounts.map((amount, index) => (
                    <button
                      key={index}
                      onClick={() => handlePresetAmountClick(amount)}
                      className="text-[10px] text-black-1/80 font-sans border-[2px] font-bold border-black-1/80 rounded-[30px] py-[3px] px-2 tracking-wide cursor-pointer"
                    >
                      ₹{amount.toLocaleString("en-IN")}.00
                    </button>
                  ))}
                </div>
                <button
                  onClick={() =>
                    handlePresetAmountClick(parseInt(creditDetail?.spentAmount))
                  }
                  className="text-[10px] whitespace-nowrap text-black-1/80 font-sans font-bold border-[2px] border-black-1/80 rounded-[30px] py-[3px] tracking-wide px-2 cursor-pointer mx-2"
                >
                  Pay full Amount
                </button>
              </div>

              <TextField
                variant="outlined"
                value={amount}
                type="number"
                onChange={handleAmountChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    height: "40px",
                  },
                }}
              />

              <Button
                variant="contained"
                color="primary"
                sx={{
                  width: "100%",
                  height: "35px",
                  backgroundColor: "#28a447",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#28a447",
                  },
                }}
                onClick={TargetPartialPayment}
              >
                Pay
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Payment Modal */}
      {openPaymentModel && (
        <PaymentMain
          openPaymentModel={openPaymentModel}
          setOpenPaymentModal={setOpenPaymentModal}
          transactionId={transactionId}
          paymentSessionId={paymentSessionId}
          userDetail={userDetail}
          amount={amount}
        />
      )}
    </>
  );
};

export default DueAmount;
