import React from "react";
import "./Policy.css";
import Header from "../HomePage/Header";
import Footer from "../HomePage/Footer";
import ScrollPromices from "../HomePage/ScrollPromices";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import MobileHeader from "../../mobileComponent/MobileEndpoints/MobileHeader";
import BottomPromises from "../HomePage/BottomPromises";
import PopularSearch from "../HomePage/PopularSearch";
import MobilePopularSearch from "../../mobileComponent/MobileEndpoints/MobilePopularSearch";
import MobileFooter from "../../mobileComponent/MobileEndpoints/MobileFooter";

export function Policy() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 501px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  return (
    <>
     {isDesktopOrLaptop && (
        <HeaderDiv>
          <ScrollPromices />
          <Header />
        </HeaderDiv>
      )}
      {isTabletOrMobile && (
        <MobileHeaderDiv>
          <MobileHeader />
        </MobileHeaderDiv>
      )}

      <div className="policy-container">
        <h2>PRIVACY POLICY</h2>

        <p>
          Radkaat Ventures Private Limited (hereinafter “CycleCircle”)
          recognizes the importance of privacy of its users and also of
          maintaining confidentiality of the information provided by its users
          as a responsible data controller and data processer.
        </p>

        <p>
          This Privacy Policy provides for the practices for handling and
          securing user&#39;s Personal Information (defined hereunder) by
          CycleCircle.
        </p>

        <p>
          This Privacy Policy is applicable to any person (‘User’) who purchase,
          intend to purchase, or inquire about any product(s) or service(s) made
          available by CycleCircle through any of CycleCircle’s customer
          interface channels including its website &amp; offline channels
          including call centers and offices (collectively referred herein as
          &quot;Sales Channels&quot;).
        </p>

        <p>
          For the purpose of this Privacy Policy, wherever the context so
          requires &quot;you&quot; or &quot;your&quot; shall mean User and the
          term &quot;we&quot;, &quot;us&quot;, &quot;our&quot; shall mean
          CycleCircle. For the purpose of this Privacy Policy, Website means the
          website(s), mobile site(s) and mobile app(s).
        </p>

        <p>
          By using or accessing the Website or other Sales Channels, the User
          hereby agrees with the terms of this Privacy Policy and the contents
          herein. If you disagree with this Privacy Policy please do not use or
          access our Website or other Sales Channels.
        </p>

        <p>
          This Privacy Policy does not apply to any website(s), mobile sites and
          mobile apps of third parties, even if their websites/products are
          linked to our Website. User should take note that information and
          privacy practices of CycleCircle’s business partners, advertisers,
          sponsors or other sites to which CycleCircle provides hyperlink(s),
          may be materially different from this Privacy Policy. Accordingly, it
          is recommended that you review the privacy statements and policies of
          any such third parties with whom they interact.
        </p>

        <p>
          This Privacy Policy is an integral part of your User Agreement with
          CycleCircle and all capitalized terms used, but not otherwise defined
          herein, shall have the respective meanings as ascribed to them in the
          User Agreement.
        </p>
        <div className="policy-cookies-container">
          <h3>Cookies, Session Data And Personal Information</h3>
          <h4>Cookies:</h4>
          <p>
            A cookie is a small file which asks permission to be placed on your
            computer’s hard drive. Once you agree, the file is added and the
            cookie helps analyse web traffic or lets you know when you visit a
            particular site. Cookies allow web applications to respond to you as
            an individual. The web application can tailor its operations to your
            needs, likes and dislikes by gathering and remembering information
            about your preferences.
          </p>
          <p>
            CycleCircle uses cookies to personalize your experience on the
            Website and the advertisements that maybe displayed. CycleCircle’s
            use of cookies is similar to that of any other reputable online
            companies.
          </p>

          <p>
            Cookies allow us to serve you better and more efficiently. Cookies
            also allow ease of access, by logging you in without having to type
            your login name each time (only your password is needed); we may
            also use such cookies to display any advertisement(s) to you while
            you are on the Website or to send you offers (or similar emails –
            provided you have not opted out of receiving such emails) focusing
            on destinations which may be of your interest.
          </p>

          <p>
            A cookie may also be placed by our advertising servers, or third
            party advertising companies. Such cookies are used for purposes of
            tracking the effectiveness of advertising served by us on any
            website, and also to use aggregated statistics about your visits to
            the Website in order to provide advertisements in the Website or any
            other website about services that may be of potential interest to
            you. The third party advertising companies or advertisement
            providers may also employ technology that is used to measure the
            effectiveness of the advertisements. All such information is
            anonymous. This anonymous information is collected through the use
            of a pixel tag, which is an industry standard technology and is used
            by all major websites.
          </p>

          <p>
            They may use this anonymous information about your visits to the
            Website in order to provide advertisements about goods and services
            of potential interest to you. No Personal Information is collected
            during this process. The information so collected during this
            process, is anonymous, and does not link online actions to a User.
          </p>
          <p>
            Most web browsers automatically accept cookies. Of course, by
            changing the options on your web browser or using certain software
            programs, you can control how and whether cookies will be accepted
            by your browser.
          </p>
        </div>
        <div className="policy-automatic-logging">
          <h3>Automatic Logging of Session Data:</h3>
          <p>
            Each time you access the Website your session data gets logged.
            Session data may consist of various aspects like the IP address,
            operating system and type of browser software being used and the
            activities conducted by the User while on the Website. We collect
            session data because it helps us analyze User’s choices, browsing
            pattern including the frequency of visits and duration for which a
            User is logged on. It also helps us diagnose problems with our
            servers and lets us better administer our systems. The aforesaid
            information cannot identify any User personally. However, it may be
            possible to determine a User&#39;s Internet Service Provider (ISP),
            and the approximate geographic location of User&#39;s point of
            connectivity through the above session data.
          </p>
        </div>
        <div className="policy-personal-info">
          <h3>
            Personal Information we collect when you register with us online or
            create an online account:
          </h3>
          <p>
            You can browse the Website without registering with us or creating
            an online account. However, in order to access certain resources or
            services via the Website (e.g., to access technical alerts or to
            join technical forums), you will need to register with us. In order
            to register and create an online account, we collect certain
            personal information about you such as:
          </p>
          <ul type="circle">
            <li>Customer Name</li>
            <li>Designation</li>
            <li>Company Name</li>
            <li>E mail address</li>
            <li>Mobile Number</li>
            <li>Website</li>
            <li>Country</li>
            <li>City</li>
          </ul>
          <h3>
            Personal Information we collect when do business with CycleCircle:
          </h3>
          <p>
            We may collect and process Vendor or Business Partner Data when you
            conduct business with CycleCircle as, or on behalf of, a vendor,
            supplier, consultant, professional adviser or other third party.
            “Vendor or Business Partner Data” means information relating to an
            identified or identifiable natural person that CycleCircle receives
            from or on behalf of a vendor, supplier, consultant, professional
            adviser or any other third parties that do business with
            CycleCircle, whether or not such natural person is also a Website
            user.
          </p>
          <div className="policy-gray">
            <p>Examples of Vendor or Business Partner Data include:</p>
            <p>
              Contact details of a point of contact for its Vendor or Business
              Partners (such as name, business phone numbers, business address);
            </p>
            <p>
              Business contact information (such as name of organization and
              website details); If you also hold an online CycleCircle account,
              we add any purchase information we collect, including details of
              the purchase and your business address, to your online account
              information; Credit card/debit card/demand draft/wire transfer
              information required to be furnished by you while making payment
              for our services;
            </p>
            <p>
              KYC Compliance Information: For adherence to legal and regulatory
              requirements, we collect and maintain essential KYC information.
              This may include, but is not limited to, proof of identity (such
              as government-issued identification), proof of address, and other
              relevant documentation. This information is necessary to fulfill
              our compliance and regulatory obligations and to ensure the
              integrity and security of our transactions with Whom Your Personal
              Information Is Shared.
            </p>
          </div>
        </div>
        <div className="policy-service-providers">
          <h3>Service Providers and suppliers:</h3>
          <p>
            Your information shall be shared with the end service providers like
            any other suppliers who are responsible for fulfilling your orders.
            You may note that while making a booking with CycleCircle you
            authorize us to share your information with the said service
            providers and suppliers. It is pertinent to note that CycleCircle
            does not authorize the end service provider to use your information
            for any other purpose(s) except as may be for fulfilling their part
            of service. However, how the said service providers/suppliers use
            the information shared with them is beyond the purview and control
            of CycleCircle as they process Personal Information as independent
            data controllers, and hence we cannot be made accountable for the
            same. You are therefore advised to review the privacy policies of
            the respective service provider or supplier whose services you
            choose to avail.
          </p>
          <p>
            CycleCircle does not sell or rent individual customer names or other
            Personal Information of Users to third parties except sharing of
            such information with our business / alliance partners or vendors
            who are engaged by us for providing various referral services and
            for sharing promotional and other benefits to our customers from
            time to time basis their booking history with us.
          </p>
          <h3>Companies in the Same Group:</h3>
          <p>
            In the interests of improving personalization and service
            efficiency, we may, under controlled and secure circumstances, share
            your Personal Information with our affiliate or associate entities.
            This will enable us to provide you with information about various
            products and services which might interest you or help us address
            your questions and requests in relation to your bookings.
          </p>
          <h3>Business Partners and Third-Party Vendors:</h3>
          <p>
            We may also share certain filtered Personal Information to our
            corporate affiliates or business partners who may contact the
            customers to offer certain products or services, which may include
            free or paid products / services, which will enable the customer to
            have better experience or to avail certain benefits specially made
            for CycleCircle customers. Examples of such partners are entities
            offering co-branded credit card, banking cards or similar sensitive
            information etc. If you choose to avail any such services offered by
            our business partners, the services so availed will be governed by
            the privacy policy of the respective service provider.
          </p>

          <p>
            CycleCircle may share your Personal Information to third party that
            CycleCircle may engage to perform certain tasks on its behalf,
            including but not limited to payment processing, data hosting, and
            data processing platforms.
          </p>

          <p>
            We use non-identifiable Personal Information of Users in aggregate
            or anonymized form to build higher quality, more useful online
            services by performing statistical analysis of the collective
            characteristics and behavior of our customers and visitors, and by
            measuring demographics and interests regarding specific areas of the
            Website. We may provide anonymous statistical information based on
            this data to suppliers, advertisers, affiliates and other current
            and potential business partners. We may also use such aggregate data
            to inform these third parties as to the number of people who have
            seen and clicked on links to their websites. Any Personal
            Information which we collect and which we may use in an aggregated
            format is our property. We may use it, in our sole discretion and
            without any compensation to you, for any legitimate purpose
            including without limitation the commercial sale thereof to third
            parties.
          </p>
        </div>
        <div className="policy-disclosure">
          <h3>Disclosure of Information</h3>
          <p>
            In addition to the circumstances described above, CYCLECIRCLE may
            disclose User&#39;s Personal Information if required to do so:
          </p>
          <ul>
            <li>
              by law, required by any enforcement authority for investigation,
              by court order or in reference to any legal process;
            </li>
            <li>to conduct our business;</li>
            <li>for regulatory, internal compliance and audit exercise(s)</li>
            <li>to secure our systems; or</li>
            <li>
              to enforce or protect our rights or properties of CYCLECIRCLE or
              any or all of its affiliates, associates, employees, directors or
              officers or when we have reason to believe that disclosing
              Personal Information of User(s) is necessary to identify, contact
              or bring legal action against someone who may be causing
              interference with our rights or properties, whether intentionally
              or otherwise, or when anyone else could be harmed by such
              activities.
            </li>
          </ul>

          <p>
            Such disclosure and storage may take place without your knowledge.
            In that case, we shall not be liable to you or any third party for
            any damages howsoever arising from such disclosure and storage.
          </p>
        </div>
        <div className="policy-how-we-protect">
          <h3>How We Protect Your Personal Information?</h3>
          <p>
            CYCLECIRCLE takes necessary steps, within its limits of commercial
            viability and necessity, to ensure that the user&#39;s information
            is treated secure.
          </p>

          <p>
            We request our users to sign out of their CYCLECIRCLE account and
            close their browser window when they have finished their work. This
            is to ensure that others cannot access their personal or business
            information and correspondence, if the user shares the computer with
            someone else or is using a computer in a public place.
          </p>

          <p>
            Unfortunately, no data transmission over the Internet can be
            guaranteed to be 100% secure. As a result, while CYCLECIRCLE strives
            to protect the user&#39;s personal &amp; business information, it
            cannot ensure the security of any information transmitted to
            CYCLECIRCLE and you do so at your own risk. Once CYCLECIRCLE
            receives your transmission, it makes best efforts to ensure its
            security in its systems. Please keep in mind that whenever you post
            personal &amp; business information online, that is accessible to
            the public, you may receive unsolicited messages from other parties.
          </p>
          <p>
            CYCLECIRCLE is not responsible for any breach of security or for any
            actions of any third parties that receive Your Information. The
            Website also linked to many other sites and we are not/shall be not
            responsible for their privacy policies or practices as it is beyond
            our control.
          </p>

          <p>
            Notwithstanding anything contained in this Policy or elsewhere,
            CYCLECIRCLE shall not be held responsible for any loss, damage or
            misuse of Your Information, if such loss, damage or misuse is
            attributable to a Force Majeure Event (as defined in Terms of Use).
          </p>
        </div>
        <div className="policy-your-rights">
          <h3>Your Rights Qua Personal Information</h3>
          <p>
            You may access your Personal Information from your user account with
            CYCLECIRCLE. You may also correct your personal information or
            delete such information (except some mandatory fields) from your
            user account directly. If you don’t have such a user account, then
            you write to <Link to="#">spokes@CycleCircle.com</Link>
          </p>
          <h3>Changes to the Privacy Policy</h3>
          <p>
            We reserve the rights to revise the Privacy Policy from time to time
            to suit various legal, business and customer requirement. We will
            duly notify the users as may be necessary.
          </p>
          <h3>Copyright Protection</h3>
          <p>
            All content on the Website including graphics, text, icons,
            interfaces, audio clips, logos, images and software is the property
            of CycleCircle and/or its content suppliers and is protected by
            Indian Copyright Law. The website can be used only for the purposes
            as permitted by CycleCircle from time to time. Any other use,
            including the reproduction, modification, distribution,
            transmission, republication, display or performance, of the content
            on this website can only be made with the express permission of
            CycleCircle.
          </p>
          <h3>Consent/ Dispute</h3>
          <p>
            By using this Website, you consent to the terms of this Privacy
            Policy and to the use and management of personal information by
            CycleCircle for the purposes and in the manner herein provided.
            Should this Policy change, CycleCircle intends to take every
            reasonable step to ensure that these changes are brought to your
            attention by posting the updated Privacy Policy on the Website. Your
            visit and any dispute over privacy are subject to this Privacy
            Policy. The said Privacy Policy shall be governed by and construed
            in accordance with the laws of such jurisdictions as that of your
            country of residence.
          </p>
          <h3>Account Deletion Policy</h3>{" "}
          <p>
            If you are a CycleCircle customer and have decided to stop using
            CycleCircle application, please contact the customer care on
            spokes@CycleCircle.com to initiate deletion of your account.
            CycleCircle shall, to the fullest extent technically feasible,
            delete all data in possession for the customer.
          </p>
          <p>
            You may always submit concerns regarding this Privacy Policy via
            email to us at <Link to="#">spokes@CycleCircle.com</Link>.
            CYCLECIRCLE shall endeavor to respond to all reasonable concerns and
            inquires.
          </p>
        </div>
      </div>
      {isDesktopOrLaptop && (
        <>
          <BottomPromises />
          <PopularSearch />
          <Footer />
        </>
      )}
      {isTabletOrMobile && (
        <>
          <MobilePopularSearch />
          <MobileFooter />
        </>
      )}
    </>
  );
}


const HeaderDiv = styled.div`
  width: 100%;
  height: 80px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

`;

const MobileHeaderDiv = styled.div`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;