import React from "react";
import "./WantDeleteProduct.css";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import {
  fetchAllPreorderCartsAsync,
  removeFromPreorderCartsAsync,
} from "../../features/cartPage/cartPageSlice";

function WantDeletePreorder({ productData, setProductToRemove }) {
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const handleRemoveFromCarts = () => {
    dispatch(
      removeFromPreorderCartsAsync(productData?.id)
    ).then(() => {
      dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID));
    });
  };
  return (
    <div className="want-delete-product-container">
      <span className="want-delete-product-heading">
        Are you sure want to delete this item?
      </span>
      <span className="want-delete-product-text">
        This will delete the item permanently from your cart
      </span>
      <div className="want-delete-product-btn-container">
        <span
          className="want-delete-cancel-btn"
          onClick={() => setProductToRemove(null)}
        >
          Cancel
        </span>
        <span className="want-delete-it-btn" onClick={handleRemoveFromCarts}>
          Yes,Delete it
        </span>
      </div>
    </div>
  );
}

export default WantDeletePreorder;
