import { API_BASE_URL } from "../../config";
export function fetchAllNavigations(vertical) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}categories/${vertical}`);
    const data = await response.json();
    resolve({ data });
  });
}
export function fetchAllPromises() {
  return new Promise(async (resolve) => {
    const response = await fetch("https://localhost:8080/promises");
    const data = await response.json();
    resolve({ data });
  });
}
export function fetchAllBanners() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}banners/desktop`);
    const data = await response.json();
    resolve({ data });
  });
}

export function fetchAllBannersMobile() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}banners/mobile`);
    const data = await response.json();
    resolve({ data });
  });
}
export function fetchAllOurBrands() {
  return new Promise(async (resolve) => {
    const response = await fetch("https://localhost:8080/our_brands");
    const data = await response.json();
    resolve({ data });
  });
}
export function fetchAllInclusiveBrands() {
  return new Promise(async (resolve) => {
    const response = await fetch("https://localhost:8080/inclusive_brands");
    const data = await response.json();
    resolve({ data });
  });
}

export function fetchAllWhatsNew() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}whats_new`);
    const data = await response.json();
    resolve({ data });
  });
}

export function fetchAllRecommended() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}recommendation`);
    const data = await response.json();
    resolve({ data });
  });
}

export function fetchAllHotDeals() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}hot_deals`);
    const data = await response.json();
    resolve({ data });
  });
}

export function fetchAllWishlst(UserID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}wishlist/${UserID}`);
    const data = await response.json();
    resolve({ data });
  });
}
