import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import confirmOrderLogo from "../../img/order_confirm-logo.png";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchCreditPageDetailsAsync } from "../../features/credit/creditSlice";
import { formatWithCommasWithDecimal } from "../../utils/utils";

const FullPaymentConfirmation = ({
  amount,
  setOpenPaymentModal,
  userDetail,
}) => {
  const dispatch = useDispatch();
  const handleCloseConfirmation = () => {
    if (userDetail && userDetail?.UserID) {
      dispatch(fetchCreditPageDetailsAsync(userDetail?.UserID));
      setOpenPaymentModal(false);
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = today.getFullYear();

    return `${day}-${month}-${year}`;
  };
  return (
    <div className="w-[260px] h-[350px] flex flex-col items-center justify-between font-thin bg-white-1 rounded-md px-4 py-6 relative max-sm:mb-5">
      <div className="w-full flex flex-col items-center justify-between">
        <LazyLoadImage
          src={confirmOrderLogo}
          alt="confirm-logo"
          effect="blur"
          wrapperProps={{
            style: { transitionDelay: "1s" },
          }}
          className="w-[65px] object-contain"
        />
        <span className="text-[16px] text-black-1 my-1 font-medium tracking-wider">
          Payment Success!
        </span>
        <span className="text-[24px] text-black-1 font-bold my-1">
          ₹{formatWithCommasWithDecimal(parseInt(amount))}
        </span>
      </div>
      <div className="border-t-[1px] border-black-1/10 w-full flex flex-col">
        <div className="w-full flex items-center justify-between pt-4 pb-3">
          <span className="text-[13px] text-black-1/80 font-medium tracking-wide">
            OrderID
          </span>
          <span className="text-[13px] text-black-1/80 font-medium tracking-wide">
            -
          </span>
        </div>
        <div className="w-full flex items-center justify-between pb-[10px] border-b-[1px] border-dashed border-black-1/10">
          <span className="text-[13px] text-black-1/80 font-medium tracking-wide">
            Payment Date
          </span>
          <span className="text-[13px] text-black-1/80 font-medium tracking-wide">
            {getCurrentDate()}
          </span>
        </div>
        <div className="w-full flex items-center justify-between pt-[10px] pb-3">
          <span className="text-[13px] text-black-1/80 font-medium tracking-wide">
            Due Amount
          </span>
          <span className="text-[13px] text-black-1/80 font-medium tracking-wide">
            ₹{formatWithCommasWithDecimal(parseInt(amount))}
          </span>
        </div>
        <div className="w-full flex items-center justify-between py-[6px]">
          <span className="text-[13px] text-black-1/80 font-medium tracking-wide">
            Discount
          </span>
          <span className="text-[13px] text-black-1/80 font-medium tracking-wide">
            -
          </span>
        </div>
      </div>
      <Button
        variant="contained"
        sx={{
          height: "30px",
          background: "#283248",
          fontSize: "14px",
          fontWeight: "500",
          textTransform: "none",
          padding: "0 10px",
          borderRadius: "30px",
          color: "white",
          marginTop: "12px",
          "&:hover": {
            background: "#283248",
          },
        }}
        onClick={handleCloseConfirmation}
      >
        Done
      </Button>
    </div>
  );
};

export default FullPaymentConfirmation;
