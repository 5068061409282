import React, { useState, useRef, useCallback, useEffect } from "react";
import "./BusinessOwner.css";
import { Link } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API_BASE_URL } from "../../../../config";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";

function BusinessOwner({
  updateFinalData,
  finalData,
  aadharVerify,
  setAadharVerify,
  setHerebyDeclare,
  herebyDeclare,
}) {
  const [verify, setVerify] = useState(false);
  const [errors, setErrors] = useState({});
  const [aadharExists, setAadharExists] = useState(false);
  const [openAadharOTP, setOpenAadharOTP] = useState(false);
  const [timer, setTimer] = useState(60);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const inputRefs = useRef(
    Array.from({ length: 6 }).map(() => React.createRef())
  );
  const [refNo, setRefNo] = useState(null);
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const startTimer = () => {
    setResendDisabled(true);
    setTimer(60);
  };

  // Effect to decrement timer every second
  useEffect(() => {
    let intervalId;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setResendDisabled(false);
      clearInterval(intervalId);
    }
    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
  }, [timer]);

  // HANDLE OTP PASTE
  const handleOTPPaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData.getData("text/plain");
    const pastedDigits = clipboardData.replace(/\D/g, "").slice(0, 6); // Filter out non-numeric characters and take the first 6 digits
    const newOTP = [...otp];
    pastedDigits.split("").forEach((digit, index) => {
      newOTP[index] = digit;
    });
    setOTP(newOTP);
    inputRefs.current[5].current.focus();
  };

  useEffect(() => {
    if (finalData?.aadhar?.length < 12) {
      setAadharVerify(false);
      updateFinalData({ ["Aadhar_DATA"]: null });
    }
  }, [finalData?.aadhar?.length]);

  const handleVerifyAadhar = async () => {
    setOTP(["", "", "", "", "", ""]);
    handleOpen();
    if (!finalData.aadhar || finalData.aadhar.length < 10) {
      handleClose();
      toast.error("Enter Aadhar correctly")
    } else {
      // if (timer > 0) {
      //   // Timer is still running, do nothing
      //   return;
      // }
      // startTimer();
      try {
        const response = await fetch(
          `${API_BASE_URL}aadhar_send_otp/${finalData.aadhar}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (data.Aadhar.message === "OTP sent successfully") {
          setOpenAadharOTP(true);
          setRefNo(data.Aadhar.ref_id);
          handleClose();
          
        } else {
          setVerify(true);
          handleClose();
          toast.error("Aadhar Doesn't Exist")
        }
      } catch (error) {
        handleClose();
        toast.error("Aadhar Doesn't Exist")
        console.error(
          "An error occurred during the API request:",
          error.message
        );
      }
    }
  };
  const handleVerifyAadharAgain = async () => {
    handleOpen();
    if (!finalData.aadhar || finalData.aadhar.length < 12) {
      handleClose();
      toast.error('Enter Aadhar correctly')
    } else {
      if (timer > 0) {
        // Timer is still running, do nothing
        return;
      }
      startTimer();
      try {
        const response = await fetch(
          `${API_BASE_URL}aadhar_send_otp/${finalData.aadhar}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (data.Aadhar.message === "OTP sent successfully") {
         toast.success('OTP sent Again');
          setOpenAadharOTP(true);
          setRefNo(data.Aadhar.ref_id);
          handleClose();
          
        } else {
          setVerify(true);
          handleClose();
          toast.error("Aadhar Doesn't Exist")
        }
      } catch (error) {
        handleClose();
        toast.error('Enter Valid Aadhar Number')
        console.error(
          "An error occurred during the API request:",
          error.message
        );
      }
    }
  };

  function validateAadhaar(aadhar) {
    const regex = /^\d{12}$/;
    return regex.test(aadhar);
  }

  const handleFocus = (index) => {
    // Move the cursor to the last digit if there's already a value entered
    if (index === 5 && otp[index] !== "") {
      inputRefs.current[index].current?.focus();
    }
  };

  const handleVerifyOTP = async () => {
    handleOpen();
    const enteredOTP = otp.join("");
    // Perform OTP verification logic here using enteredOTP

    if (!enteredOTP || enteredOTP.length < 6) {
      handleClose();
      toast.error('Enter OTP correctly')
    } else {
      try {
        const response = await fetch(
          `${API_BASE_URL}aadhar_otp_verify/${enteredOTP}/${refNo}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (data.Aadhar.message === "Aadhaar Card Exists") {
           await updateFinalData({ Aadhar_DATA: data.Aadhar });
          await setAadharVerify(true);
          // await setVerify(false);
           await setOpenAadharOTP(false);
          // setVerify(true);
          handleClose();
          
        } else {
          handleClose();
          toast.error('Invalid OTP');
        }
      } catch (error) {
        handleClose();
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        toast.error('Please try again later');
      }
    }
  };

  const handleKeyDown = (index, value, e) => {
    if (e.keyCode === 8 && value === "" && index > 0) {
      inputRefs.current[index - 1].current.focus();
    }
    else if(e.keyCode === 8 && index > 0 && value !== ""){
      inputRefs.current[index].current.focus();
    }
  };


  const handleOTPInputChange = useCallback(
    (index, value,e) => {
      // Accept only numeric values
      const numericValue = value.replace(/\D/g, "");

      const newOTP = [...otp];
      newOTP[index] = numericValue.slice(-1); // Only take the last digit

      setOTP(newOTP);

      // Move to the previous input when deleting a digit
      if (numericValue !== "" && index < 5 && inputRefs?.current[index + 1]) {
        inputRefs?.current[index + 1]?.current?.focus();
      }
    },
    [otp]
  );
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updateValue = value.toUpperCase();
    updateFinalData({ [name]: updateValue });

    if (name === "aadhar" && value.length === 12) {
      handleAadharBlur(e);
    } else if (name === "aadhar" && value.length < 12) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        Aadhar: "",
      }));
    }
  };

  const handleAadharBlur = async (e) => {
    const aadhar = e.target.value;
    if (!validateAadhaar(aadhar)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        Aadhar: "Please enter a valid Aadhar number",
      }));
      return;
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}singup_aadhar_verify/${aadhar}`
      );
      const data = await response.json();

      if (data.status === "error") {
        setAadharExists(true);
        setErrors((prevErrors) => ({
          ...prevErrors,
          Aadhar: "Aadhar already registered with Cyclecircle",
        }));
      } else {
        setAadharExists(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          Aadhar: "",
        }));
      }
    } catch (error) {
      console.error("Error checking Aadhar existence:", error);
    }
  };

  const formatAadharNumber = (aadharNumber) => {
    // Check if aadharNumber is provided and has 12 characters
    if (aadharNumber && aadharNumber.length === 12) {
      // Replace first 8 characters with 'X' and split the Aadhar number into groups of 4 characters
      const formattedAadharNumber = "XXXX-XXXX-" + aadharNumber.slice(-4);
      return formattedAadharNumber;
    } else {
      return aadharNumber; // Return original number if not valid
    }
  };

  const handleCancel = () => {
    setVerify(false);
    setOpenAadharOTP(false);
  };
  const handleHerebyDeclareChange = () => {
    setHerebyDeclare(!herebyDeclare);
  };
  return (
    <div className="business-owner-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <span className="business-owner-heading">Business Owner Details</span>
      <span className="business-owner-desc">
        We need the ID proof of the business owner for verification purposes.
      </span>
      <fieldset
        className={`input-fieldset ${
          !errors["Aadhar"] &&
          !aadharExists &&
          finalData.aadhar &&
          validateAadhaar(finalData.aadhar)
            ? "valid"
            : (errors["Aadhar"] || aadharExists) &&
              validateAadhaar(finalData.aadhar)
            ? "error"
            : ""
        }`}
      >
        <legend className="input-legend">
          Aadhar Number&nbsp;<b>*</b>
        </legend>
        <input
          type="text"
          onKeyPress={(e) => {
            // Allow only numbers (0-9) and backspace (8)
            if (!/[0-9\b]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          name="aadhar"
          value={finalData.aadhar || ""}
          placeholder="Ex:234538653926"
          onChange={handleInputChange}
          onBlur={handleAadharBlur}
          required
          maxLength="12"
          pattern="\d{12}"
          className="input-type-write"
        />
      </fieldset>
      {errors["Aadhar"] && finalData?.aadhar && (
        <span className="error-message">{errors["Aadhar"]}</span>
      )}
      {!aadharVerify &&
        !errors["Aadhar"] &&
        finalData?.aadhar &&
        finalData?.aadhar?.length === 12 && (
          <div className="email-verification-container">
            <span
              className="email-verification-verify-btn"
              onClick={handleVerifyAadhar}
            >
              Verify
            </span>
            <div className="email-verification-text-div">
              <span className="verify-symbol">!</span>
              <span className="email-verification-text">
              Please verify to proceed
              </span>
            </div>
          </div>
        )}
      {aadharVerify && (
        <div className="phone-number-verified-check">
          <CheckIcon />
          <span className="phone-number-verified-span">Verified</span>
        </div>
      )}
      <div className="receive-update-via-whatsapp">
        <input
          type="checkbox"
          onChange={handleHerebyDeclareChange}
          checked={herebyDeclare}
        />
        <span className="receive-update-whatsapp-span">
          I/We hereby declare,represent and undertake the{" "}
          <Link to="/support" target="_blank">Terms & Conditions</Link>
        </span>
      </div>

      {openAadharOTP && <div className="signup-overlay"></div>}

      {openAadharOTP && (
        <div className="email-verification-overlay-container">
          <div className="email-verification-upper-heading-container">
            <CheckIcon />
            <span className="email-verification-upper-heading-span">
              Verification OTP sent
            </span>
          </div>
          <span className="email-verification-details-text">
            Enter the 6-digit OTP received on your mobile
          </span>
          <div className="email-verification-otp-enter-input-container">
            <span className="email-otp-input-legend">Enter OTP</span>
            <div className="email-verification-otp-input-boxes">
              {otp.map((digit, index) => (
                <input
                  type="text"
                  maxlength="1"
                  oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                  key={index}
                  // maxLength={index === 5 ? 1 : 0}
                  value={digit}
                  onChange={(e) => handleOTPInputChange(index, e.target.value,e)}
                  onKeyDown={(e) => handleKeyDown(index, e.target.value,e)}
                  ref={inputRefs.current[index]}
                  onFocus={() => handleFocus(index)}
                  className={digit ? "filled" : ""}
                  onPaste={handleOTPPaste}
                  required
                />
              ))}
            </div>
            <span
              className="email-verification-resend-otp"
              onClick={handleVerifyAadharAgain}
            >
              Resend OTP {resendDisabled ? `(${timer}s)` : ""}
            </span>
          </div>
          <span
            className="email-verification-cancel-btn"
            onClick={() => {
              handleCancel();
              setTimer(0);
            }}
          >
            <CloseIcon />
          </span>
          <span
            className="email-verification-confirm-btn"
            onClick={handleVerifyOTP}
          >
            Confirm
          </span>
        </div>
      )}

      {aadharVerify && (
        <div className="show-aadhar-details-after-verify">
          {/* <DoneIcon /> */}
          <h3 className="show-aadhar-details-after-verify-heading">
            Aadhar is Valid
          </h3>
          {/* <img loading="lazy"
            className="show-aadhar-details-img-verify"
            src={finalData?.Aadhar_DATA?.photo_link}
            alt=""
          /> */}
          <div className="show-aadhar-details-after-verify-main-container">
            <div className="show-aadhar-details-after-verify-each-attribute">
              <span className="show-aadhar-details-after-verify-each-attribute-key">
                Aadhar No.
              </span>
              <span className="show-aadhar-details-after-verify-each-attribute-value">
                {formatAadharNumber(finalData.aadhar)}
              </span>
            </div>
            <div className="show-aadhar-details-after-verify-each-attribute">
              <span className="show-aadhar-details-after-verify-each-attribute-key">
                DOB
              </span>
              <span className="show-aadhar-details-after-verify-each-attribute-value">
                {finalData?.Aadhar_DATA?.dob}
              </span>
            </div>
            <div className="show-aadhar-details-after-verify-each-attribute">
              <span className="show-aadhar-details-after-verify-each-attribute-key">
                Name
              </span>
              <span className="show-aadhar-details-after-verify-each-attribute-value">
                {finalData?.Aadhar_DATA?.name}
              </span>
            </div>
            <div className="show-aadhar-details-after-verify-each-attribute">
              <span className="show-aadhar-details-after-verify-each-attribute-key">
                Address
              </span>
              <span className="show-aadhar-details-after-verify-each-attribute-value">
                {finalData?.Aadhar_DATA?.address}
              </span>
            </div>
            <div className="show-aadhar-details-after-verify-each-attribute">
              <span className="show-aadhar-details-after-verify-each-attribute-key">
                Reference ID
              </span>
              <span className="show-aadhar-details-after-verify-each-attribute-value">
                {finalData?.Aadhar_DATA?.ref_id}
              </span>
            </div>
            <div className="show-aadhar-details-after-verify-each-attribute">
              <span className="show-aadhar-details-after-verify-each-attribute-key">
                Year of Birth
              </span>
              <span className="show-aadhar-details-after-verify-each-attribute-value">
                {finalData?.Aadhar_DATA?.year_of_birth}
              </span>
            </div>
          </div>
          {/* <span
            className="show-aadhar-details-after-verify-close-btn"
            onClick={handleVerifyAadharClose}
          >
            Close
          </span> */}
        </div>
      )}
    </div>
  );
}

export default BusinessOwner;
