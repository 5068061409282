import React from 'react';
import "./Cratoni.css";
import { cratoniData } from '../../../data';

function Cratoni() {
    const { brand, description, history, productDevelopment, distribution } = cratoniData;
  return (
    <div className="cratoni-container">
    <h2>{`Welcome to ${brand}`}</h2>
    <p>{description}</p>

    <div className="history">
      <h3>History</h3>
      <p>{history.focus}</p>
    </div>

    <div className="product-development">
      <h3>Product Development</h3>
      <p>{productDevelopment.collaboration}</p>
    </div>

    <div className="distribution">
      <h3>Distribution</h3>
      <p>{distribution.agreements}</p>
    </div>
  </div>
  )
}

export default Cratoni
