import React from "react";
import { useParams } from "react-router-dom";
// import pdfff from "../../img/1702473341866-JWT AND SESSION.pdf";
import { IMAGE_BASE } from "../../config";

function OpenPdf() {
  const { pdfFile } = useParams();
  const pdfUrl = `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUAL/${pdfFile}`;
  const digi =
    `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUAL/1702473341866-JWT%20AND%20SESSION.pdf`;

  return (
    <div className="pdf-opener" style={{ width: "100%", height: "1000px" }}>
      <embed src={pdfUrl} type="application/pdf" width="100%" height="1000px" />
    </div>
  );
}

export default OpenPdf;
