import React from 'react';
import "./MobileDescPromises.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { services } from "../../data";

function MobileDescPromises() {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 650,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    vertical: false,
    arrows: false,
  };
  return (
    <div className="mobile-servicePromices-container">
    <Slider {...settings}>
      {services.map((data, index) => (
        <div className="mobile-servicePromices" key={index}>
          <img loading="lazy" src={data.icon} alt="" />
          <div className="mobile-servicePromices-text">
            <span className="mobile-servicePromices-heading">{data.heading}</span>
            <div className="mobile-servicePromices-desc">{data.desc}</div>
          </div>
        </div>
      ))}
    </Slider>
  </div>
  )
}

export default MobileDescPromises