import React, { useState } from "react";
import "./Support.css";
import { connect } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  setSelectedSupport,
  selectSelectedSupport,
} from "../../store/slices/supportSlice";

function Support({ selectedSupport, setSelectedSupport }) {
  const [openAcc, setOpenAcc] = useState(null);
  let supportData = [
    {
      id: 1,
      title: "Track My Order",
      content: [
        {
          index: 11,
          question: "Who qualifies for free shipping",
          answer:
            "Orders totaling INR 2000 or more qualify for our fast free shipping offer across India.",
        },
        {
          index: 12,
          question:
            "Is there an additional charge for Cash on Delivery orders?",
          answer:
            "Yes, an INR 100 surcharge is applicable for Cash on Delivery orders.",
        },
        {
          index: 13,
          question: "How quickly are orders dispatched and delivered?",
          answer:
            'Most products are readily available for swift dispatch, with items marked "In Stock - Ships Within 24 Hours" dispatched on the same or next business day. Delivery generally takes 7-8 working days from the order date.',
        },
        {
          index: 14,
          question:
            "How can I track my shipment? Does expedited shipping affect lead time?",
          answer:
            'A shipping confirmation email with detailed tracking information is sent within 24 hours of dispatch. Expedited shipping does not affect the lead time for items marked "Available - expect 3-5 business days lead time prior to shipment."',
        },
      ],
    },
    {
      id: 2,
      title: "Shipping Policy",
      content: [
        {
          index: 21,
          question: "Who qualifies for free shipping",
          answer:
            "Orders totaling INR 2000 or more qualify for our fast free shipping offer across India.",
        },
        {
          index: 22,
          question:
            "Is there an additional charge for Cash on Delivery orders?",
          answer:
            "Yes, an INR 100 surcharge is applicable for Cash on Delivery orders.",
        },
        {
          index: 23,
          question: "How quickly are orders dispatched and delivered?",
          answer:
            'Most products are readily available for swift dispatch, with items marked "In Stock - Ships Within 24 Hours" dispatched on the same or next business day. Delivery generally takes 7-8 working days from the order date.',
        },
        {
          index: 24,
          question:
            "How can I track my shipment? Does expedited shipping affect lead time?",
          answer:
            'A shipping confirmation email with detailed tracking information is sent within 24 hours of dispatch. Expedited shipping does not affect the lead time for items marked "Available - expect 3-5 business days lead time prior to shipment."',
        },
      ],
    },
    {
      id: 3,
      title: "Data Privacy",
      content: [
        {
          index: 31,
          question: "What does CycleCircle's Privacy Policy cover?",
          answer:
            "CycleCircle's Privacy Policy outlines how we handle and secure users' Personal Information, applicable to anyone purchasing, intending to purchase, or inquiring about products or services through our sales channels, including our website and offline channels.",
        },
        {
          index: 32,
          question:
            "What is required to agree with CycleCircle's Privacy Policy?",
          answer:
            "By accessing our website or other sales channels, users agree to the terms of this Privacy Policy. If you disagree, please refrain from using or accessing our services.",
        },
        {
          index: 33,
          question:
            "How does CycleCircle collect and protect personal information?",
          answer:
            "We collect personal information when you register online or do business with us. This information includes contact details, business information, and KYC compliance data. We employ security measures to protect user information within commercial viability limits.",
        },
        {
          index: 34,
          question:
            "How does CycleCircle share personal information, and how are policy changes handled?",
          answer:
            "We may share information with service providers, business partners, and third-party vendors under controlled circumstances. Policy changes will be notified to users, and they can address concerns or initiate account deletions by contacting customer care.",
        },
        {
          index: 35,
          question:
            "What measures does CycleCircle take for copyright protection, and how are disputes handled?",
          answer:
            "CycleCircle's content is protected by Indian Copyright Law. Disputes over privacy are subject to this Privacy Policy and governed by applicable laws. Users can address concerns or initiate account deletions by contacting customer care.",
        },
      ],
    },
    {
      id: 4,
      title: "User Agreement",
      content: [
        {
          index: 41,
          question: "What does CycleCircle's User Agreement cover?",
          answer:
            "CycleCircle's User Agreement outlines the terms and conditions governing the use of our services by users, applicable to anyone interacting with our website or other sales channels.",
        },
        {
          index: 42,
          question:
            "What is required to agree with CycleCircle's User Agreement?",
          answer:
            "By using or accessing our website or other sales channels, users consent to and accept the terms of this User Agreement. If you disagree, please refrain from using or accessing our services.",
        },
        {
          index: 43,
          question:
            "How does CycleCircle collect and protect user information?",
          answer:
            "We collect personal information when you register online or do business with us, including contact details, business information, and KYC compliance data. We employ security measures to protect user information within commercial viability limits.",
        },
        {
          index: 44,
          question:
            "What rights do users have regarding their personal information, and how are disputes handled?",
          answer:
            "Users may access, correct, or delete personal information through their user accounts or by contacting customer care. Disputes are subject to resolution according to applicable laws and the terms of this User Agreement.",
        },
        {
          index: 45,
          question:
            "How are changes to the User Agreement communicated, and how can users address concerns?",
          answer:
            "Users will be notified of changes to the User Agreement, and updates will be posted on the website. Concerns can be addressed or account deletions initiated by contacting customer care.",
        },
      ],
    },
    {
      id: 5,
      title: "Return and Refund",
      content: [
        {
          index: 51,
          question: "What is CycleCircle's Refund & Returns Policy about?",
          answer:
            "CycleCircle's Refund & Returns Policy aims to provide a supportive and customer-friendly process for returning or exchanging items purchased from our partners. We outline conditions, exclusions, and procedures to facilitate smooth transactions for our customers.",
        },
        {
          index: 52,
          question: "In what cases can customers request returns or exchanges?",
          answer:
            "Customers can request returns or exchanges in cases of functioning defects, wrong items received, damaged items, or size issues for apparels. Cancellation is also possible if the item has not been shipped yet.",
        },
        {
          index: 53,
          question: "What conditions must returned items meet?",
          answer:
            "Returned items must have all original tags attached, be in the original, unripped packaging (excluding the courier bag), and be in a resellable condition: unused, unworn, and unwashed.",
        },
        {
          index: 54,
          question: "What items are not eligible for return or exchange?",
          answer:
            "Products purchased during sales, intimate-use items such as cycling shorts and socks, Shimano products (unless damaged or incorrect), bicycles, nutrition items, and tires are not eligible for return or exchange.",
        },
        {
          index: 55,
          question: "How can customers initiate a return or exchange?",
          answer:
            "Customers can initiate a return or exchange by visiting our Returns & Exchange Portal to check eligibility and submit requests. Refunds, exchanges, or store credits will be processed after receiving and inspecting the item.",
        },
        {
          index: 56,
          question: "What happens in cases of warranty issues?",
          answer:
            "Issues reported after 7 days of receiving an item are treated as warranty issues. Our team will assist in facilitating genuine warranty cases with our suppliers or connect you with their official representatives in India for direct resolution, if required. For any disputes regarding warranty resolution, we recommend contacting the brand directly. Our support team can provide contact details where possible.",
        },
      ],
    },
  ];

  return (
    <div className="support-container">
      <div className="support-main-container">
        <div className="support-header-container">
          <div className="support-header-main-container">
            {supportData?.map((data) => (
              <span
                key={data?.id}
                className={`support-each-header ${
                  selectedSupport === data?.id && "header-active"
                }`}
                onClick={() => {
                  setSelectedSupport(data?.id);
                }}
              >
                {data?.title}
              </span>
            ))}
          </div>
        </div>
        <div className="support-details-container">
          {supportData
            .filter((data) => data?.id === selectedSupport)
            .map((selected) =>
              selected?.content?.map((item) => (
                <div
                  key={item?.index}
                  className="support-each-details-container"
                >
                  <div
                    className="support-each-details-header-container"
                    onClick={() =>
                      openAcc === null
                        ? setOpenAcc(item?.index)
                        : setOpenAcc(null)
                    }
                  >
                    <span className="support-each-details-header-text">
                      {item?.question}
                    </span>
                    {openAcc === item?.index ? (
                      <RemoveIcon onClick={() => setOpenAcc(null)} />
                    ) : (
                      <AddIcon onClick={() => setOpenAcc(item?.index)} />
                    )}
                  </div>
                  {openAcc === item?.index && (
                    <div className="support-each-details-main-content">
                      {item?.answer}
                    </div>
                  )}
                </div>
              ))
            )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  selectedSupport: selectSelectedSupport(state.selectedSupport),
});
const mapDispatchToProps = {
  setSelectedSupport,
};
export default connect(mapStateToProps, mapDispatchToProps)(Support);
