import React, { useState } from "react";
import "./PhoneAndEmailChange.css";
import { selectUser } from "../../../features/auth/authSlice";
import {useSelector} from "react-redux"
import ChangePhone from "./ChangePhone";
import ChangeEmail from "./ChangeEmail";

function PhoneAndEmailChange() {
  const userDetail = useSelector(selectUser)
  const [openChangeVerify,setOpenChangeVerify] = useState(null);

  const formattPhoneNumber = (phoneNumber)=>{
    if (phoneNumber) {
      // Remove any existing dashes and spaces from the phone number
      phoneNumber = phoneNumber.replace(/[-\s]/g, '');
  
      // Insert dashes at appropriate positions (e.g., XXX-XXX-XXXX)
      phoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      return phoneNumber;
    }
  }
  const handleChangeVerify = (value) =>{
    setOpenChangeVerify(value);
  }
  return (
    <>
    <div className="phone-and-email-change-container">
      <div className="phone-change-main-container">
        <img loading="lazy"
          src="https://cdn-icons-png.flaticon.com/128/4477/4477610.png"
          alt=""
          className="phone-change-img-address"
        />
        <span className="phone-change-name">Phone Number</span>
        <span className="phone-change-phone-number">{userDetail && formattPhoneNumber(userDetail?.PhoneNumber)}</span>
        <span className="phone-change-btn" onClick={()=>handleChangeVerify("Phone")}>Change Phone Number</span>
      </div>
      <div className="email-change-main-container">
        <img loading="lazy"
          src="https://cdn-icons-png.flaticon.com/128/732/732200.png"
          alt=""
          className="email-change-img-address"
        />
        <span className="email-change-name">Email Address</span>
        <span className="email-change-email-address">{userDetail && userDetail?.Email}</span>
        <span className="email-change-btn" onClick={()=>handleChangeVerify("Email")}>Change Email Address</span>
      </div>
    </div>

    {openChangeVerify === "Phone" && <ChangePhone handleChangeVerify={handleChangeVerify}/>}
    {openChangeVerify === "Email" && <ChangeEmail handleChangeVerify={handleChangeVerify}/>}

    </>
  );
}

export default PhoneAndEmailChange;
