import React from 'react';
import "./MobilePrice.css";
import { connect, useSelector } from 'react-redux';
import bracketLogo from "../../img/navigation/Bracket.svg";
import discountLogo from "../../img/navigation/Discount.svg";
import { selectUser } from '../../features/auth/authSlice';
import { selectIsOverlayVisible, toggleOverlay } from '../../store/slices/headerSlice';
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { calculateDealerPrice, calculateDiscountPercentage, calculateOfferPrice, formatWithCommasWithDecimal } from '../../utils/utils';

function MobilePrice({ selectProduct, isOverlayVisible, toggleOverlay }) {
  const selectedVariant = useSelector(
    (state) => state.productDetail.selectedVariant
  );
  const userDetail = useSelector(selectUser);

 

 


  return (
    <div className="mobile-productPrice-container">
    <div className="mobile-productPrice-left-container">
      <div className="mobile-productPrice-mrp-price-container">
        <span className="mobile-productPrice-mrp-price-heading">Dealer Price</span>
        <div className="mobile-productPrice-mrp-price-main-container">
          <div className="mobile-productPrice-ndp-offer-price-container">
            {Object.keys(userDetail).length > 0 ? (
              <span
                className="mobile-productPrice-mrp-price-span"
                style={{
                  fontSize: selectedVariant?.Offer_price !== null && "3vw",
                  textDecoration:
                    selectedVariant?.Offer_price !== null && "line-through",
                  fontWeight: selectedVariant?.Offer_price !== null && "500",
                }}
              >
                ₹
                {selectedVariant && selectedVariant?.NDP_Price &&
                  formatWithCommasWithDecimal(
                    calculateDealerPrice(
                      parseInt(selectedVariant?.MRP_price),
                      parseInt(selectedVariant?.NDP_Price),
                      selectProduct && selectProduct[0]?.GST
                    )
                  )}
              </span>
            ) : (
              <span
                className="mobile-product-price-login-to-see-price"
                onClick={toggleOverlay}
              >
                Login to see Price
              </span>
            )}

            {Object.keys(userDetail).length > 0 &&
              selectedVariant && selectedVariant?.Offer_price !== null && (
                <span className="mobile-productPrice-offer-price-span">
                  ₹
                  {formatWithCommasWithDecimal(
                    calculateOfferPrice(
                      parseInt(selectedVariant?.MRP_price),
                      parseInt(selectedVariant?.Offer_price),
                      selectProduct && selectProduct[0]?.GST
                    )
                  )}
                </span>
              )}
          </div>
          <span className="mobile-productPrice-mrp-price-inclusive">
            (Incl. of all Taxes)
          </span>
        </div>
      </div>
      <div className="mobile-productPrice-ndp-price-container">
        <span className="mobile-productPrice-ndp-price-heading">MRP</span>
        <span className="mobile-productPrice-ndp-price-span">
          ₹{formatWithCommasWithDecimal(parseInt(selectedVariant && selectedVariant?.MRP_price))}{" "}
        </span>
      </div>
    </div>
    <div className="mobile-productPrice-divider-container">
      <img loading="lazy" src={bracketLogo} alt="" className="mobile-productPrice-divider-img" />
    </div>
    {calculateDiscountPercentage(
      selectedVariant && selectedVariant?.MRP_price,
      selectedVariant && selectedVariant?.NDP_Price
    ) > 0 && (
      <div className="mobile-productPrice-right-container">
        <div className="mobile-productPrice-right-margin-container">
          {Object.keys(userDetail).length > 0 ? (
            <>
              <img loading="lazy"
                src={discountLogo}
                alt=""
                className="mobile-productPrice-right-margin-logo"
              />
              <span
                className="mobile-productPrice-right-margin-span"
                style={{
                  fontSize:
                    selectedVariant?.Offer_price !== null ? "2.5vw" : "",
                  textDecoration:
                    selectedVariant?.Offer_price !== null
                      ? "line-through"
                      : "",
                  fontWeight:
                    selectedVariant?.Offer_price !== null ? "500" : "",
                }}
              >
                {calculateDiscountPercentage(
                  selectedVariant?.MRP_price,
                  calculateDealerPrice(
                    parseInt(selectedVariant?.MRP_price),
                    parseInt(selectedVariant?.NDP_Price),
                    selectProduct && selectProduct[0]?.GST
                  )
                )}
              </span>
              {selectedVariant.Offer_price !== null && (
                <span className="mobile-productPrice-right-offer-span">
                  {calculateDiscountPercentage(
                    selectedVariant?.MRP_price,
                    calculateOfferPrice(
                      parseInt(selectedVariant?.MRP_price),
                      parseInt(selectedVariant?.Offer_price),
                      selectProduct && selectProduct[0]?.GST
                    )
                  )}
                </span>
              )}
            </>
          ) : (
            <div className="mobile-product-price-not-visible-container">
              <VisibilityOffOutlinedIcon onClick={toggleOverlay} />
            </div>
          )}
          % Dealer Margin
        </div>
      </div>
    )}
  </div>
  )
}

const mapStateToProps = (state) => ({
  isOverlayVisible: selectIsOverlayVisible(state),
});
const mapDispatchToProps = {
  toggleOverlay,
};
export default connect(mapStateToProps, mapDispatchToProps)(MobilePrice)