import React, { useEffect, useState } from "react";
import CreditCard from "../../dComponent/LineOfCredit/CreditCard";
import DueAmount from "../../dComponent/LineOfCredit/DueAmount";
import RecentOrders from "../../dComponent/LineOfCredit/RecentOrders";
import PaymentHistory from "../../dComponent/LineOfCredit/PaymentHistory";
import Support from "../../dComponent/LineOfCredit/Support";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { useNavigate } from "react-router";
import ProfileSideBar from "../../mComponent/ProfileSideBar";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import {
  fetchCreditPageDetailsAsync,
  selectAllCreditData,
} from "../../features/credit/creditSlice";

const LineOfCredit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);
  const creditData = useSelector(selectAllCreditData);
  const [openSidebar, setOpenSidebar] = useState(false);
  const handleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  useEffect(() => {
    if (userDetail && userDetail?.UserID) {
      dispatch(fetchCreditPageDetailsAsync(userDetail?.UserID));
    }
  }, [dispatch, userDetail]);


  return (
    <div className="w-full h-auto flex flex-col py-0 px-0 pl-0 sm:py-2 sm:px-4 sm:pl-1 flex-1 max-sm:px-3 relative">
      <p className="text-18 text-black-1 font-bold tracking-wider pt-1 flex max-sm:hidden">
        Line Of Credit
      </p>
      <div className="w-full fixed top-[58px] left-0 right-0 z-999 bg-white-1 shadow-md shadow-black-1/20 py-3 px-3 flex items-center justify-between sm:hidden">
        <div className="w-auto flex items-center">
          <ChevronLeftOutlinedIcon
            onClick={() => navigate("/")}
            className="text-black-1/80"
            style={{ fontSize: "20px" }}
          />
          <span className="text-16 text-black-1/80 font-bold flex items-center">
            Line of Credit
          </span>
        </div>
        <div className="h-full">
          <MoreHorizOutlinedIcon
            onClick={handleSidebar}
            className="text-black-1/80"
            style={{ fontSize: "20px" }}
          />
        </div>
        {openSidebar && (
          <ProfileSideBar
            handleSidebar={handleSidebar}
            openSidebar={openSidebar}
          />
        )}
      </div>
      <div className="w-full h-auto flex flex-col lg:flex-row justify-between bg-white-1 rounded-md px-4 lg:px-8 py-6 my-4 shadow-sm shadow-black-1/10 mt-[70px] sm:mt-4">
        <CreditCard
          iconType={creditData?.creditDetails?.creditLimitCategory_Name}
          color="green"
          cardType="credit"
          cardText="Total Credit Amount"
          Amount={creditData?.creditDetails?.Credit_Limit}
          updateDate={creditData?.creditDetails?.createdAt}
          spendDays=""
          recentOrder={creditData?.transactionHistory}
          creditHistory=""
        />
        <div className=" lg:block border-[1.5px] border-black-1/10 mx-4"></div>
        <CreditCard
          iconType=""
          color="blue"
          cardType="balance"
          cardText="Credit Balance"
          Amount={creditData?.creditDetails?.availableCredit}
          updateDate={creditData?.creditDetails?.createdAt}
          spendDays=""
          creditDetail={creditData?.creditDetails}
          recentOrder={creditData?.transactionHistory}
          creditHistory={creditData?.paymentHistory}
        />
        <div className=" lg:block border-[1.5px] border-black-1/10 mx-4"></div>
        <CreditCard
          iconType=""
          color="peach"
          cardType="spent"
          cardText="Amount Spent"
          Amount={creditData?.creditDetails?.spentAmount}
          updateDate=""
          spendDays={
            creditData?.creditDetails?.nextDueDate
              ? creditData?.creditDetails?.nextDueDate
              : "45"
          }
          recentOrder={creditData?.transactionHistory}
          creditHistory=""
        />
      </div>
      <div className="w-full flex flex-col lg:flex-row justify-between">
        <div className="w-full lg:w-[75%] h-auto flex flex-col mb-4 lg:mb-0">
          <DueAmount
            creditDetail={creditData?.creditDetails}
            recentOrder={creditData?.transactionHistory}
            userDetail={userDetail}
          />
          <RecentOrders
            recentOrder={creditData?.transactionHistory}
            userDetail={userDetail}
          />
        </div>
        <div className="w-full lg:w-[23%] h-auto flex flex-col mb-[70px] sm:mb-0">
          <Support />
          <PaymentHistory creditHistory={creditData?.paymentHistory} />
        </div>
      </div>
    </div>
  );
};

export default LineOfCredit;
