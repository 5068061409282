import React, { useEffect, useState } from "react";
import "./MyWishlist.css";
import ProductCard from "./ProductCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllWishlistsAsync,
  removeAllFromWishlistAsync,
  selectAllWishlists,
} from "../../features/wishlist/wishlistSlice";
import { selectUser } from "../../features/auth/authSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import NoProductFound from "../ListingPage/ListingProduct/NoProductFound";

function MyWishlist() {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const dispatch = useDispatch();
  const wishlist = useSelector(selectAllWishlists);
  const user = useSelector(selectUser);

  const handleRemoveAll = async () => {
    handleOpen();
    await dispatch(removeAllFromWishlistAsync(user?.UserID))
      .then(() => {
        dispatch(fetchAllWishlistsAsync(user?.UserID));
      })
      .then(() => {
        handleClose();
      });
  };

  useEffect(() => {
    dispatch(fetchAllWishlistsAsync(user?.UserID));
  }, [dispatch, user]);
  return (
    <div className="mywishlist-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open} // Show backdrop when either open or filtering state is true
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="text-container">
        <h3>My Wishlist</h3>
        <span className="mywishlist-total-no">
          ({wishlist && wishlist?.product && wishlist?.product?.length} Items)
        </span>
        {wishlist && wishlist?.product && wishlist?.product?.length > 0 && (
          <span className="mywishlist-remove-all" onClick={handleRemoveAll}>
            (remove all)
          </span>
        )}
      </div>
      <div className="product-card-container">
        {wishlist && wishlist?.product && wishlist?.product?.length > 0 ? (
          <ProductCard />
        ) : (
          <NoProductFound />
        )}
      </div>
    </div>
  );
}

export default MyWishlist;
