import { createSlice } from '@reduxjs/toolkit';

const selectedSubcategorySlice = createSlice({
  name: 'selectedSubcategory',
  initialState: "",
  reducers: {
    setSelectedSubcategory: (state, action) => action.payload,
  },
});

export const { setSelectedSubcategory } = selectedSubcategorySlice.actions;
export const selectedSelectedSubcategory = (state) => state.selectedSubcategory;

export default selectedSubcategorySlice.reducer;