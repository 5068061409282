import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  fetchThroughSearchAsync,
  setSubCategories,
} from "../features/listingPage/listingPageSlice";
import { setSelectedSubcategory } from "../store/slices/subCategorySlice";
import { setBreadcrumb } from "../store/slices/breadCrumbsSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function RelatedSearch() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchedItem = useParams();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleSearchNavigate = async (value) => {
    handleOpen();
    dispatch(fetchThroughSearchAsync(value.trim())).then(()=>{
      window.scrollTo({ top: 0, behavior: "smooth" });
      handleClose();
    });
    dispatch(setSubCategories(null));
    dispatch(setSelectedSubcategory(null));
    dispatch(
      setBreadcrumb({
        categoryID: 0,
        categoryName: "",
        subCategoryID: 0,
        subCategoryName: "",
        ProductID: 0,
        productName: "",
      })
    );
    navigate(`/search/${value}`);
  };
  return (
    <div className="w-full flex flex-col justify-between bg-[#a7dcb4] p-2 shadow-sm shadow-black-1/50">
       <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <span className="text-16 tracking-wide font-extrabold text-black-1/90">
        Related Searches
      </span>
      <div className="flex flex-wrap mt-2">
        <span
          className={`text-center rounded-[25px] font-sans tracking-wide text-14 flex-center py-1 px-2 m-1 whitespace-nowrap shadow-md shadow-black-1/30 ${
            searchedItem?.text === "Tubeless Gravel Tires"
              ? "bg-green text-white-1"
              : "bg-white-1 text-green"
          }`}
          onClick={() => handleSearchNavigate("Tubeless Gravel Tires")}
        >
          Tubeless Gravel Tires
        </span>
        <span
          className={`text-center rounded-[25px] font-sans tracking-wide text-14 flex-center py-1 px-2 m-1 whitespace-nowrap shadow-md shadow-black-1/30 ${
            searchedItem?.text === "Cockpit"
              ? "bg-green text-white-1"
              : "bg-white-1 text-green"
          }`}
          onClick={() => handleSearchNavigate("Cockpit")}
        >
          Cockpit
        </span>
        <span
          className={`text-center rounded-[25px] font-sans tracking-wide text-14 flex-center py-1 px-2 m-1 whitespace-nowrap shadow-md shadow-black-1/30 ${
            searchedItem?.text === "Valve"
              ? "bg-green text-white-1"
              : "bg-white-1 text-green"
          }`}
          onClick={() => handleSearchNavigate("Valve")}
        >
          Valve
        </span>
        <span
          className={`text-center rounded-[25px] font-sans tracking-wide text-14 flex-center py-1 px-2 m-1 whitespace-nowrap shadow-md shadow-black-1/30 ${
            searchedItem?.text === "Pump"
              ? "bg-green text-white-1"
              : "bg-white-1 text-green"
          }`}
          onClick={() => handleSearchNavigate("Pump")}
        >
          Pump
        </span>
        <span
          className={`text-center rounded-[25px] font-sans tracking-wide text-14 flex-center py-1 px-2 m-1 whitespace-nowrap shadow-md shadow-black-1/30 ${
            searchedItem?.text === "Sealant & Tubeless Kit"
              ? "bg-green text-white-1"
              : "bg-white-1 text-green"
          }`}
          onClick={() => handleSearchNavigate("Sealant & Tubeless Kit")}
        >
          Sealant & Tubeless Kit
        </span>
      </div>
    </div>
  );
}

export default RelatedSearch;
