import React from "react";
import "./Katour.css";

const Katour = () => {
  return (
    <div className="katour-container">
      <h2>Enhance Your Cycling Performance</h2>
      <p>
        With the Trioblade Disc Brake Caliper, a mechanical disc brake system
        engineered for strong braking force and precise control. Designed to
        deliver durability, smooth operation, and excellent heat dissipation,
        this brake caliper ensures reliable performance in various riding
        conditions.
      </p>

      <div className="brake-details">
        <h3>Specifications</h3>
        <ul>
          <li>
            <strong>Length:</strong> Approximately 8.7cm (3.4 inches), ensuring
            high friction and effective braking.
          </li>
          <li>
            <strong>Weight:</strong> Lightweight at 204 grams, optimizing bike
            handling and performance.
          </li>
          <li>
            <strong>Front Caliper:</strong> Fits 180mm disc brakes, suitable for
            front wheel installation.
          </li>
          <li>
            <strong>Rear Caliper:</strong> Fits 160mm disc brakes, designed for
            rear wheel installation.
          </li>
          <li>
            <strong>Thickness:</strong> Approximately 1.90mm, providing optimal
            braking efficiency.
          </li>
          <li>
            <strong>Compatibility:</strong> Suitable for mounting on both
            mountain bikes and road bikes.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Katour;
