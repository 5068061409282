import React, { useEffect, useState } from "react";
import {
  fetchAllWishlistsAsync,
  selectAllWishlists,
} from "../../features/wishlist/wishlistSlice";
import { fetchAllCartsAsync } from "../../features/cartPage/cartPageSlice";
import { selectUser } from "../../features/auth/authSlice";
import {
  selectAllCategoryProducts,
  setBrands,
  setMaterials,
  setSelectedCategoryID,
} from "../../features/listingPage/listingPageSlice";
import { selectAllFilter } from "../../store/slices/allFilterSlice";
import { setBreadcrumb } from "../../store/slices/breadCrumbsSlice";
import NoProductFound from "../../mComponent/NoProductFound";
import {
  calculateDealerPrice,
  calculateDiscountPercentage,
  calculateOfferPrice,
} from "../../utils/utils";
import ProductCard from "../../mComponent/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import ShopByPriceRange from "../../mComponent/ShopBy/ShopByPriceRange";
import ShopByRating from "../../mComponent/ShopBy/ShopByRating";
import Paginations from "../../mComponent/Pagination";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function MobilePdts({ currentPage, setCurrentPage }) {
  const dispatch = useDispatch();
  const pdts = useSelector(selectAllCategoryProducts);
  const products = pdts?.Data;
  const qtys = pdts?.quantity;
  const wishlist = useSelector(selectAllWishlists);
  const userDetail = useSelector(selectUser);
  const [items, setItems] = useState(products?.product || []);
  const [currentItems, setCurrentItems] = useState([]);

  const itemsPerPage = 16;
  const allfilter = useSelector(selectAllFilter);
  const { CategoryID, categoryName } = useParams();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail.UserID) {
      dispatch(fetchAllWishlistsAsync(userDetail.UserID));
      dispatch(fetchAllCartsAsync(userDetail.UserID));
    }
  }, [dispatch, userDetail.UserID]);

  useEffect(() => {
    if (CategoryID) {
      dispatch(setSelectedCategoryID(CategoryID));
    }

    // Set all Brands of respective category,subCategory
    if (products?.manufacture_name && products?.manufacture_name) {
      dispatch(
        setBrands(products?.manufacture_name && products?.manufacture_name)
      );
    }
    if (products?.material && products?.material) {
      dispatch(setMaterials(products?.material && products?.material));
    }

    // Update breadcrumb
    dispatch(
      setBreadcrumb({
        categoryID: CategoryID,
        categoryName: categoryName,
        // subCategoryID: null,
        subCategoryName: null,
        ProductID: null,
        productName: "",
      })
    );
  }, [
    dispatch,
    CategoryID,
    categoryName,
    products?.manufacture_name,
    products?.material,
  ]);

  useEffect(() => {
    if (
      products &&
      products.product &&
      Array.isArray(allfilter.brands) &&
      Array.isArray(allfilter.materials)
    ) {
      const filteredItems = products.product.filter((product) => {
        const brandFilterPassed =
          allfilter.brands.length === 0 ||
          allfilter.brands.includes(product?.manufacture_name);
        const materialFilterPassed =
          allfilter.materials.length === 0 ||
          allfilter.materials.includes(product?.material_name);
        const shippingFilterPassed =
          !allfilter.shipping ||
          allfilter.shipping === "standard" ||
          allfilter.shipping.includes(product?.shipping);
        const ratingFilterPassed =
          allfilter.rating === 0 ||
          parseInt(product.net_rating) >= parseInt(allfilter.rating);
        const dealerMarginFilterPassed =
          allfilter.dealerMargin === 0 ||
          parseInt(
            calculateDiscountPercentage(
              product?.skuDetails[0]?.MRP_price,
              product?.skuDetails[0]?.Offer_price
                ? calculateOfferPrice(
                    parseInt(product?.skuDetails[0]?.MRP_price),
                    parseInt(product?.skuDetails[0]?.Offer_price),
                    product?.GST
                  )
                : calculateDealerPrice(
                    parseInt(product?.skuDetails[0]?.MRP_price),
                    parseInt(product?.skuDetails[0]?.NDP_Price),
                    product?.GST
                  )
            )
          ) >= parseInt(allfilter.dealerMargin);
        const minPriceFilterPassed =
          allfilter.minPrice === 0 ||
          calculateDealerPrice(
            parseInt(product?.skuDetails[0]?.MRP_price),
            parseInt(product?.skuDetails[0]?.NDP_Price),
            product?.GST
          ) >= parseInt(allfilter.minPrice);
        const maxPriceFilterPassed =
          allfilter.maxPrice === 0 ||
          calculateDealerPrice(
            parseInt(product?.skuDetails[0]?.MRP_price),
            parseInt(product?.skuDetails[0]?.NDP_Price),
            parseInt(product?.GST)
          ) <= parseInt(allfilter.maxPrice);

        // Check if the product has the specified badge (excluding ".png" extension)
        const badgeFilterPassed =
          allfilter.shopByOffer === "" ||
          (product?.Badges && product?.Badges.replace(".png", "")) ===
            allfilter.shopByOffer;

        return (
          brandFilterPassed &&
          materialFilterPassed &&
          shippingFilterPassed &&
          ratingFilterPassed &&
          dealerMarginFilterPassed &&
          minPriceFilterPassed &&
          maxPriceFilterPassed &&
          badgeFilterPassed
        );
      });

      let sortedItems = [...filteredItems];

      if (allfilter.sortPrice === "desc") {
        sortedItems.sort(
          (a, b) =>
            calculateDealerPrice(
              parseInt(b?.skuDetails[0]?.MRP_price),
              parseInt(b?.skuDetails[0]?.NDP_Price),
              parseInt(b?.GST)
            ) -
            calculateDealerPrice(
              parseInt(a?.skuDetails[0]?.MRP_price),
              parseInt(a?.skuDetails[0]?.NDP_Price),
              parseInt(a?.GST)
            )
        );
      } else if (allfilter.sortPrice === "asc") {
        sortedItems.sort(
          (a, b) =>
            calculateDealerPrice(
              parseInt(a?.skuDetails[0]?.MRP_price),
              parseInt(a?.skuDetails[0]?.NDP_Price),
              parseInt(a?.GST)
            ) -
            calculateDealerPrice(
              parseInt(b?.skuDetails[0]?.MRP_price),
              parseInt(b?.skuDetails[0]?.NDP_Price),
              parseInt(b?.GST)
            )
        );
      } else if (allfilter.sortRating === "desc") {
        sortedItems.sort((a, b) => b?.net_rating - a?.net_rating);
      } else if (allfilter.sortRating === "asc") {
        sortedItems.sort((a, b) => a?.net_rating - b?.net_rating);
      }

      setItems(sortedItems);
    } else {
      return;
    }
  }, [products, allfilter]);

  // PAGINATION
  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    setCurrentItems(
      Array.isArray(items) ? items.slice(indexOfFirstItem, indexOfLastItem) : []
    );
  }, [items, indexOfFirstItem, indexOfLastItem]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="w-full flex flex-col items-center py-2">
        {currentItems && currentItems.length <= 0 && <NoProductFound />}
        {((currentItems && currentItems.slice(0, 4)) || []).map((data) => (
          <ProductCard
            data={data}
            userDetail={userDetail}
            wishlist={wishlist}
            handleOpen={handleOpen}
            handleClose={handleClose}
            qtys={qtys}
          />
        ))}
        {currentItems && currentItems?.length > 4 && <ShopByPriceRange />}
        {((currentItems && currentItems.slice(4, 8)) || []).map((data) => (
          <ProductCard
            data={data}
            userDetail={userDetail}
            wishlist={wishlist}
            handleOpen={handleOpen}
            handleClose={handleClose}
            qtys={qtys}
          />
        ))}
        {currentItems && currentItems?.length > 8 && <ShopByRating />}
        {((currentItems && currentItems.slice(8, 12)) || []).map((data) => (
          <ProductCard
            data={data}
            userDetail={userDetail}
            wishlist={wishlist}
            handleOpen={handleOpen}
            handleClose={handleClose}
            qtys={qtys}
          />
        ))}
        {((currentItems && currentItems.slice(12)) || []).map((data) => (
          <ProductCard
            data={data}
            userDetail={userDetail}
            wishlist={wishlist}
            handleOpen={handleOpen}
            handleClose={handleClose}
            qtys={qtys}
          />
        ))}
        <div className="w-full">
          {currentItems && currentItems.length > 0 && (
            <Paginations
              totalItems={items && items.length}
              itemsPerPage={itemsPerPage}
              onPageChange={onPageChange}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default MobilePdts;
