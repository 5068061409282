import React from "react";
import "./BottomPromises.css";
import { services } from "../../data";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function BottomPromises() {
  return (
    <div className="bottom-promises-container">
      <div className="bottom-promises-main-container">
        {services &&
          services.map((data, index) => (
            <div className="bottom-promises-each-promise" key={index}>
              <LazyLoadImage
                src={data.icon}
                alt=""
                className="promises-each-promise-img"
                effect="blur"
                wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
                }}
              />
              <span className="promises-each-promise-heading">
                {data.heading}
              </span>
              <span className="promises-each-promises-text">{data.desc}</span>
            </div>
          ))}
      </div>
    </div>
  );
}

export default BottomPromises;
