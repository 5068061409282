import React, { useEffect, useState } from "react";
import NoCartItem from "../Carts/NoCartItem";
import { IMAGE_BASE } from "../../config";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";
import {
  calculateDealerPrice,
  calculateDiscountPercentage,
  calculateOfferPrice,
} from "../../utils/utils";
import {
  fetchAllAdminUsersCartsAsync,
  selectAllAdminUserCart,
  selectAllAdminUserCarts,
  fetchAllUserCartsAsync,
} from "../../features/cartPage/cartPageSlice";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectUser } from "../../features/auth/authSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import SearchIcon from "@mui/icons-material/Search";
import {
  adminChangeQtyOfProductCarts,
  adminRemoveFromCarts,
} from "../../utils/admin";
import { AnimatedQuantity, FormattedNumber } from "../../utils/numberAnimation";
import {
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import linkIcon from "../../assets/link-icon.svg";

function AdminCarts() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const users = useSelector(selectAllAdminUserCart);
  const userCarts = useSelector(selectAllAdminUserCarts);
  const [totals, setTotals] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [sortCriteria, setSortCriteria] = useState("");
  const [openOrder, setOpenOrder] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail?.UserID) {
      handleOpen();
      dispatch(fetchAllAdminUsersCartsAsync()).then(() => {
        handleClose();
      });
    }
  }, [dispatch, userDetail?.UserID]);

  useEffect(() => {
    // Filter users whenever the search query changes
    if (users) {
      setFilteredUsers(
        users?.filter(
          (user) =>
            user?.FirstName?.toLowerCase()?.includes(
              searchQuery?.toLowerCase()
            ) ||
            user?.LastName?.toLowerCase()?.includes(
              searchQuery?.toLowerCase()
            ) ||
            user?.PhoneNumber?.includes(searchQuery) ||
            user?.stateName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
        )
      );
    }
  }, [searchQuery, users]);

  useEffect(() => {
    // Sort the users based on the selected criteria
    if (filteredUsers) {
      let sortedUsers = [...filteredUsers];
      if (sortCriteria === "last_login") {
        sortedUsers.sort((a, b) => {
          const dateA = a?.LastLogin
            ? new Date(a.LastLogin)
            : new Date(8640000000000000);
          const dateB = b?.LastLogin
            ? new Date(b.LastLogin)
            : new Date(8640000000000000);
          return dateB - dateA;
        });
      } else if (sortCriteria === "cart_count") {
        sortedUsers.sort((a, b) => b?.CartCount - a?.CartCount);
      } else if (sortCriteria === "state_name") {
        sortedUsers.sort((a, b) => {
          const stateA = a?.stateName?.toLowerCase() || "";
          const stateB = b?.stateName?.toLowerCase() || "";
          return stateA.localeCompare(stateB);
        });
      }
      setFilteredUsers(sortedUsers);
    }
  }, [sortCriteria]);

  const findAllOrderUsingUserID = (UserID) => {
    handleOpen();
    dispatch(fetchAllUserCartsAsync(UserID)).then(() => {
      handleClose();
    });
  };
  useEffect(() => {
    const getTotals = () => {
      let total = 0;
      let id = 0;

      for (let data of userCarts) {
        id = data?.UserID;
        const price = data?.skuDetails?.Offer_price
          ? calculateOfferPrice(
              parseInt(data?.skuDetails?.MRP_price),
              parseInt(data?.skuDetails?.Offer_price),
              data?.skuDetails?.GST
            )
          : calculateDealerPrice(
              parseInt(data?.skuDetails?.MRP_price),
              parseInt(data?.skuDetails?.NDP_Price),
              data?.skuDetails?.GST
            );

        total += price * data?.buy_quantity;
      }

      setTotals((prevTotals) => ({
        ...prevTotals,
        [id]: total,
      }));
    };

    getTotals();
  }, [userCarts]);

  const calculateTotalCarts = () => {
    let totalCarts = 0;
    for (const cart of users) {
      totalCarts += cart?.CartCount;
    }
    return totalCarts;
  };
  return (
    <div className="w-full h-screen relative overflow-auto">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className="w-full h-14 bg-white-1 flex items-center justify-between sticky top-0 right-0 py-1 px-5
       shadow-md z-10 opacity-90 max-sm:hidden"
      >
        <span className="text-lg font-bold tracking-wider text-gray-800">
          Carts
        </span>
        <img
          loading="lazy"
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
            userDetail?.UserImage || ""
          }`}
          alt={userDetail?.UserImage || ""}
          className="w-10 h-10 rounded-full border border-gray-300"
        />
      </div>
      <div className="w-full">
        <div className="w-full flex max-sm:flex-col items-center justify-center sm:justify-between">
          <div className="w-full sm:w-[200px] h-[90px] flex flex-col justify-between sm:mt-10 sm:ml-5 bg-white-1 rounded-md shadow-sm shadow-black-1/20 px-3 py-4">
            <span className="text-[16px] text-[#c1bebe]">Total Carts</span>
            <div className="w-full flex items-center justify-between">
              <span className="flex items-center text-[20px] text-green">
                {users && Array.isArray(users) ? calculateTotalCarts() : 0}
              </span>
              <ShoppingCartOutlinedIcon
                className="text-gray-300"
                style={{ fontSize: "24px" }}
              />
            </div>
          </div>
          <div className="w-full sm:w-auto max-sm:mt-5 sm:mr-5 flex max-sm:flex-col items-center max-sm:items-start justify-center sm:justify-end">
            <div className="w-full sm:w-[250px] h-[45px] sm:h-[35px] flex items-center bg-white-1 rounded-full shadow-sm shadow-black-1/20 px-2 sm:mr-5">
              <SearchIcon
                className="text-black-1/70"
                style={{ fontSize: "22px" }}
              />
              <input
                type="text"
                placeholder="Search by name, phone or state"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full h-full border-none outline-none p-0 pl-1 rounded-full text-[14px] tracking-wide text-black-1/80"
              />
            </div>
            <div className="flex items-center max-sm:mt-5 max-sm:ml-2">
              <span className="text-black-1/80 text-[14px] mr-2 font-bold tracking-wide">
                Sort by:
              </span>
              <FormControl
                variant="outlined"
                size="small"
                sx={{
                  minWidth: 120,
                  color: "black",
                  background: "white",
                  borderRadius: "4px",
                  boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                }}
              >
                <Select
                  name="sort-by"
                  value={sortCriteria}
                  onChange={(e) => setSortCriteria(e.target.value)}
                  label="Sort by"
                  displayEmpty
                  sx={{
                    color: sortCriteria === "" ? "rgba(0, 0, 0, 0.6)" : "black", // Set color for empty value
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="last_login">Last Login</MenuItem>
                  <MenuItem value="cart_count">Cart Count</MenuItem>
                  <MenuItem value="state_name">State</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        {filteredUsers && Array.isArray(filteredUsers) ? (
          <div className="w-full overflow-x-auto pt-[100px] sm:px-5">
            <h2 className="text-[16px] tracking-wide text-black-1/80 bg-white-1 rounded-t-md p-3">
              Recent Carts
            </h2>
            <TableContainer component={Paper} className="shadow-md">
              <Table>
                <TableHead className="bg-gray-100">
                  <TableRow>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      S.No
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Last Login
                    </TableCell>

                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      User Name
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Phone Number
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Business Name
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      State Name
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Cart Count
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Total Amount
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      <img
                        src={linkIcon}
                        alt="link-icon"
                        width={25}
                        height={25}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers &&
                    Array.isArray(filteredUsers) &&
                    filteredUsers?.map((user, index) => (
                      <>
                        <TableRow
                          key={index}
                          style={{
                            boxShadow:
                              user?.UserID === openOrder &&
                              "0 3px 3px 0 rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {user?.LastLogin !== null ? (
                              new Date(user?.LastLogin).toLocaleDateString(
                                "en-US",
                                {
                                  month: "long",
                                  day: "numeric",
                                  year: "numeric",
                                }
                              )
                            ) : (
                              <b
                                style={{
                                  color: "var(--color-peach)",
                                  fontWeight: "600",
                                  fontSize: "14px",
                                }}
                              >
                                Not Login Yet
                              </b>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {user?.FirstName}&nbsp;{user?.LastName}
                          </TableCell>
                          <TableCell align="center">
                            {user?.PhoneNumber}
                          </TableCell>
                          <TableCell align="center">
                            {user?.business_name || "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {user?.stateName || "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {user?.CartCount}
                          </TableCell>
                          <TableCell align="center">
                            {totals && totals[user?.UserID] ? (
                              <FormattedNumber value={totals[user?.UserID]} />
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              background:
                                openOrder === user?.UserID
                                  ? "linear-gradient(45deg, #ba4747 30%, #ff6b6b 90%)"
                                  : "linear-gradient(45deg, #28a447 30%, #4caf50 90%)",
                              color: "white",
                              cursor: "pointer",
                              letterSpacing: "1px",
                              transition: "background 0.3s ease-in-out",
                            }}
                            onClick={() => {
                              if (openOrder === user?.UserID) {
                                setOpenOrder(null);
                              } else {
                                setOpenOrder(user?.UserID);
                                findAllOrderUsingUserID(user?.UserID);
                              }
                            }}
                          >
                            {openOrder === user?.UserID ? "Close" : "Open"}
                          </TableCell>
                        </TableRow>
                        {openOrder === user?.UserID && (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className="w-full">
                                {userCarts && userCarts?.length > 0 && (
                                  <div className="main-carts-products-contain-div">
                                    {userCarts?.map((data) => (
                                      <div
                                        key={data?.skuDetails?.prod_ID}
                                        className="w-full flex h-[200px] bg-white-1 border-b-[1px] border-black-1/10 py-4 pl-5 relative"
                                      >
                                        <p className="absolute bottom-4 right-10 text-gray-500 text-[12px] tracking-wide">
                                          Total Amount:&nbsp;
                                          <b className="text-[15px] text-black-1/90 font-bold ml-[2px]">
                                            <FormattedNumber
                                              value={
                                                (data?.skuDetails?.Offer_price
                                                  ? calculateOfferPrice(
                                                      parseInt(
                                                        data.skuDetails
                                                          ?.MRP_price
                                                      ),
                                                      parseInt(
                                                        data.skuDetails
                                                          ?.Offer_price
                                                      ),
                                                      data?.skuDetails?.GST
                                                    )
                                                  : calculateDealerPrice(
                                                      parseInt(
                                                        data.skuDetails
                                                          ?.MRP_price
                                                      ),
                                                      parseInt(
                                                        data.skuDetails
                                                          ?.NDP_Price
                                                      ),
                                                      data?.skuDetails?.GST
                                                    )) * data?.buy_quantity
                                              }
                                            />
                                          </b>
                                        </p>
                                        <div className="w-[100px] h-full flex flex-col items-center justify-between">
                                          <div
                                            className="w-full h-[80%]  flex-center cursor-pointer"
                                            onClick={() =>
                                              navigate(
                                                `/product/${
                                                  data?.skuDetails?.prod_ID &&
                                                  data?.skuDetails?.prod_ID
                                                }`
                                              )
                                            }
                                          >
                                            <LazyLoadImage
                                              src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                                                data?.images && data.images
                                              }`}
                                              alt="product-img"
                                              effect="blur"
                                              wrapperProps={{
                                                style: {
                                                  transitionDelay: "1s",
                                                },
                                              }}
                                              className="object-contain px-2"
                                            />
                                          </div>
                                          <div className="w-full h-[20%] pt-2 flex items-center justify-between  ">
                                            <RemoveSharpIcon
                                              onClick={() => {
                                                const bundleSize = parseInt(
                                                  data?.skuDetails?.Bundle
                                                );
                                                const currentQuantity =
                                                  parseInt(data?.buy_quantity);

                                                if (
                                                  currentQuantity === bundleSize
                                                ) {
                                                  return;
                                                } else {
                                                  const newQty = Math.max(
                                                    currentQuantity -
                                                      bundleSize,
                                                    0
                                                  ); // Ensure the quantity doesn't go below 0
                                                  adminChangeQtyOfProductCarts(
                                                    data,
                                                    user?.UserID,
                                                    newQty,
                                                    dispatch,
                                                    handleOpen,
                                                    handleClose
                                                  );
                                                }
                                              }}
                                              className={`text-white-1 bg-blue rounded-sm p-1 cursor-pointer ${
                                                data?.buy_quantity === 1 &&
                                                "pointer-events-none opacity-50"
                                              }`}
                                              style={{ fontSize: "20px" }}
                                            />
                                            <span className="text-black-1/80 text-[12px] border-[1px] font-sans border-l-black-1/10 w-[40px] h-[20px] text-center ">
                                              <AnimatedQuantity
                                                value={data?.buy_quantity}
                                              />
                                            </span>
                                            <AddSharpIcon
                                              onClick={() => {
                                                const bundleSize = parseInt(
                                                  data?.skuDetails?.Bundle
                                                );
                                                const currentQuantity =
                                                  parseInt(data?.buy_quantity);

                                                const newQty =
                                                  currentQuantity + bundleSize;

                                                adminChangeQtyOfProductCarts(
                                                  data,
                                                  user?.UserID,
                                                  newQty,
                                                  dispatch,
                                                  handleOpen,
                                                  handleClose
                                                );
                                              }}
                                              className={`text-white-1 bg-blue rounded-sm p-1 cursor-pointer ${
                                                data?.buy_quantity ===
                                                  data?.skuDetails?.qty &&
                                                "opacity-50 pointer-events-none"
                                              }`}
                                              style={{ fontSize: "20px" }}
                                            />
                                          </div>
                                        </div>
                                        <div className="w-[calc(100% - 100px)] h-full flex flex-col items-start ml-4">
                                          <div className="w-full h-[80%]  flex items-start flex-col ">
                                            <div className="w-full">
                                              {data &&
                                              data?.skuDetails
                                                ?.manufacture_images ? (
                                                <LazyLoadImage
                                                  className="h-[20px] object-contain"
                                                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                                                    data &&
                                                    data?.skuDetails
                                                      ?.manufacture_images
                                                  }`}
                                                  alt="brand-logo"
                                                  effect="blur"
                                                  wrapperProps={{
                                                    // If you need to, you can tweak the effect transition using the wrapper style.
                                                    style: {
                                                      transitionDelay: "1s",
                                                    },
                                                  }}
                                                />
                                              ) : (
                                                <h3 className="text-[11px] italic font-extrabold tracking-wider text-peach">
                                                  {data?.skuDetails
                                                    ?.manufacture_name &&
                                                    data?.skuDetails
                                                      ?.manufacture_name}
                                                </h3>
                                              )}
                                            </div>
                                            <p className="text-[13px] text-blue font-semibold tracking-wide mt-1 cursor-pointer">
                                              {(data?.skuDetails?.prod_name).slice(
                                                0,
                                                65
                                              )}
                                            </p>
                                            <p className=" mt-0 flex items-center">
                                              {data?.skuDetails?.NDP_Price && (
                                                <span className="text-[13px] flex items-center text-blue font-semibold tracking-wide">
                                                  Dealer Price-&nbsp;
                                                  <b
                                                    style={{
                                                      textDecoration:
                                                        data?.skuDetails
                                                          ?.Offer_price !==
                                                          null &&
                                                        "line-through",
                                                      fontSize:
                                                        data?.skuDetails
                                                          ?.Offer_price !== null
                                                          ? "12px"
                                                          : "14px",
                                                    }}
                                                  >
                                                    {" "}
                                                    <FormattedNumber
                                                      value={calculateDealerPrice(
                                                        parseInt(
                                                          data.skuDetails
                                                            ?.MRP_price
                                                        ),
                                                        parseInt(
                                                          data.skuDetails
                                                            ?.NDP_Price
                                                        ),
                                                        data?.skuDetails?.GST
                                                      )}
                                                    />
                                                  </b>
                                                </span>
                                              )}
                                              {data?.skuDetails?.Offer_price !==
                                                null && (
                                                <span className="text-[16px] text-green font-bold tracking-wide ml-1">
                                                  <FormattedNumber
                                                    value={calculateOfferPrice(
                                                      parseInt(
                                                        data.skuDetails
                                                          ?.MRP_price
                                                      ),
                                                      parseInt(
                                                        data.skuDetails
                                                          ?.Offer_price
                                                      ),
                                                      data?.skuDetails?.GST
                                                    )}
                                                  />
                                                </span>
                                              )}{" "}
                                            </p>
                                            <p className="text-[10px] flex items-center text-gray-500 font-semibold tracking-wide mt-0">
                                              Product MRP-&nbsp;
                                              <b className="text-[13px] text-gray-800">
                                                <FormattedNumber
                                                  value={parseInt(
                                                    data.skuDetails?.MRP_price
                                                  )}
                                                />
                                              </b>
                                            </p>
                                            {calculateDiscountPercentage(
                                              data?.skuDetails?.MRP_price,
                                              data?.skuDetails?.NDP_Price
                                            ) > 0 && (
                                              <div className="text-[9px] flex items-center text-white-1 bg-peach py-[3px] px-[6px] font-sans rounded-[30px] shadow-sm mt-[2px] tracking-wide">
                                                <LocalOfferOutlinedIcon
                                                  className="text-white-1 mt-[1px]"
                                                  style={{ fontSize: "10px" }}
                                                />
                                                <span
                                                  style={{
                                                    textDecoration:
                                                      data?.skuDetails
                                                        ?.Offer_price !==
                                                        null && "line-through",
                                                    fontSize:
                                                      data?.skuDetails
                                                        ?.Offer_price !==
                                                        null && "9px",
                                                  }}
                                                  className="text-white-1 px-1"
                                                >
                                                  {calculateDiscountPercentage(
                                                    data?.skuDetails?.MRP_price,
                                                    calculateDealerPrice(
                                                      parseInt(
                                                        data.skuDetails
                                                          ?.MRP_price
                                                      ),
                                                      parseInt(
                                                        data.skuDetails
                                                          ?.NDP_Price
                                                      ),
                                                      data?.skuDetails?.GST
                                                    )
                                                  )}
                                                  %
                                                </span>
                                                {data?.skuDetails
                                                  ?.Offer_price !== null && (
                                                  <span className="text-[10px] text-white-1 pr-1">
                                                    {calculateDiscountPercentage(
                                                      data?.skuDetails
                                                        ?.MRP_price,
                                                      calculateOfferPrice(
                                                        parseInt(
                                                          data.skuDetails
                                                            ?.MRP_price
                                                        ),
                                                        parseInt(
                                                          data.skuDetails
                                                            ?.Offer_price
                                                        ),
                                                        data?.skuDetails?.GST
                                                      )
                                                    )}
                                                    %
                                                  </span>
                                                )}{" "}
                                                Dealer Margin
                                              </div>
                                            )}
                                            <div className="w-full flex items-center mt-2">
                                              {data?.skuDetails?.size_name && (
                                                <div className="w-auto h-[18px] flex items-center px-2 border-[1px] border-blue rounded-[30px] mr-2 font-sans">
                                                  <span className="text-[9px] text-blue font-medium tracking-wide">
                                                    {data?.skuDetails
                                                      ?.size_name &&
                                                      (data?.skuDetails?.size_name).slice(
                                                        0,
                                                        10
                                                      )}
                                                  </span>
                                                </div>
                                              )}
                                              {data?.skuDetails
                                                ?.colour_name && (
                                                <div className="w-auto h-[18px] flex items-center px-2 border-[1px] border-blue rounded-[30px] mr-2 font-sans">
                                                  <span className="text-[9px] text-blue font-medium tracking-wide">
                                                    {data?.skuDetails
                                                      ?.colour_name &&
                                                      (data?.skuDetails?.colour_name).slice(
                                                        0,
                                                        15
                                                      )}
                                                  </span>
                                                </div>
                                              )}
                                              {data?.skuDetails
                                                ?.varient_name && (
                                                <div className="w-auto h-[18px] flex items-center px-2 border-[1px] border-blue rounded-[30px] mr-2 font-sans">
                                                  <span className="text-[9px] text-blue font-medium tracking-wide">
                                                    {data?.skuDetails
                                                      ?.varient_name &&
                                                      (data?.skuDetails?.varient_name).slice(
                                                        0,
                                                        15
                                                      )}
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div className="w-full h-[20%] flex items-center pt-2 ">
                                            <span
                                              className="text-[11px] text-blue font-bold ml-6 cursor-pointer"
                                              onClick={() =>
                                                adminRemoveFromCarts(
                                                  data,
                                                  user?.UserID,
                                                  dispatch,
                                                  handleOpen,
                                                  handleClose
                                                )
                                              }
                                            >
                                              REMOVE
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <NoCartItem text={"Pre-Book"} />
        )}
      </div>
    </div>
  );
}

export default AdminCarts;
