import React, { useState, useMemo, useEffect, useRef } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { ADMIN_USER, IMAGE_BASE } from "../config";
import {
  setProductID,
  setSalePrice,
  setQuantity,
  selectVarient,
} from "../store/slices/selectVarient";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../features/auth/authSlice";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import {
  addToCartsAsync,
  addToPreorderCartsAsync,
  fetchAllAdminUsersCartsAsync,
  fetchAllCartsAsync,
  fetchAllPreorderCartsAsync,
  fetchAllUserCartsAsync,
  removeFromCartsAsync,
  selectAllAdminUserCart,
  selectAllAdminUserCarts,
  selectAllCarts,
  selectAllPreorders,
} from "../features/cartPage/cartPageSlice";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { useNavigate } from "react-router";
import { findqty } from "../data";
import CircularProgress from "@mui/material/CircularProgress";
import commingSoonLogo from "../icons/badges/comming-soon.png";
import {
  calculateDealerPrice,
  calculateDiscountPercentage,
  calculateOfferPrice,
  colourNameToHex,
  formatWithCommasWithDecimal,
} from "../utils/utils";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  SwipeableDrawer,
  TextField,
} from "@mui/material";

const VariantSelection = ({
  setOpenMobileVarient,
  openMobileVarient,
  product,
  qtys,
}) => {
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [availableColors, setAvailableColors] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectColor, setSelectColor] = useState(null);
  const cart = useSelector(selectAllCarts);
  const preOrder = useSelector(selectAllPreorders);
  const [qty, setQty] = useState(0); // Initialize quantity to 1
  const [inputQty, setInputQty] = useState("0"); // Input field value
  const variations = useMemo(() => product?.skuDetails || [], [product]);
  const [selectedProduct, setSelectedProduct] = useState(
    [product?.skuDetails[0]] || []
  );
  const mainQty = useSelector(selectVarient).quantity;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState("");
  const [isCartValueZero, setIsCartValueZero] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // For ADMIN ONLY
  const allCartsUser = useSelector(selectAllAdminUserCart);
  const allUserCartsItems = useSelector(selectAllAdminUserCarts);
  const [selectedUser, setSelectedUser] = useState(userDetail?.UserID || null);

  const handleSelectChange = (event) => {
    event.stopPropagation();
    setSelectedUser(event.target.value);
  };

  useEffect(() => {
    if (userDetail?.UserID && ADMIN_USER?.includes(userDetail?.UserID)) {
      handleOpen();
      dispatch(fetchAllAdminUsersCartsAsync()).then(() => {
        handleClose();
      });
    }
  }, [dispatch, userDetail?.UserID]);

  // Use useEffect to trigger the function when selectedUser changes
  useEffect(() => {
    const fetchOrders = async () => {
      if (selectedUser) {
        await dispatch(fetchAllUserCartsAsync(selectedUser)).then(() => {
          handleClose();
        });
      }
    };
    fetchOrders();
  }, [dispatch, selectedUser]);

  useEffect(() => {
    if (userDetail) {
      dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID));
    }
  }, [dispatch, userDetail]);

  useEffect(() => {
    setSelectedProduct([product.skuDetails[0]] || []);
  }, [product, setSelectedProduct]);

  const handleIncreaseQty = () => {
    const bundleSize = parseInt(product && product?.Bundle);
    if (product && product?.manufacture_name === "ESI") {
      setQty(qty + bundleSize);
      setInputQty(String(qty + bundleSize));
    } else {
      const updateqty = parseInt(
        mainQty % parseInt(product && product?.Bundle)
      );
      const newQuantity = Math.min(qty + bundleSize, mainQty - updateqty || 0);
      setQty(newQuantity);
      setInputQty(String(newQuantity));
    }
  };

  const handleDecreaseQty = () => {
    const bundleSize = parseInt(product && product?.Bundle);
    const newQuantity = Math.max(qty - bundleSize, 0);
    setQty(newQuantity);
    setInputQty(String(newQuantity));
  };

  const handleInputChange = (event) => {
    const input = event.target.value;
    setInputQty(input);

    if (product && product?.manufacture_name === "ESI") {
      setQty(input);
    } else {
      // Validate and update quantity based on the product quantity
      const updateqty = parseInt(
        mainQty % parseInt(product && product?.Bundle)
      );
      const newQuantity = Math.min(
        parseInt(input) || 0,
        mainQty - updateqty || 0
      );
      setQty(newQuantity);
    }
  };

  const handleInputBlur = () => {
    if (product && product?.manufacture_name === "ESI") {
      const bundleSize = parseInt(product && product?.Bundle);
      let newQuantity = qty || 0;
      // Adjust new quantity to be a multiple of the bundle size
      newQuantity = Math.floor(newQuantity / bundleSize) * bundleSize;
      setQty(newQuantity);
      setInputQty(String(newQuantity));
    } else {
      // If the user enters a quantity beyond the product quantity, adjust it

      const bundleSize = parseInt(product && product?.Bundle);
      let newQuantity = Math.min(qty, mainQty || 0);
      // Adjust new quantity to be a multiple of the bundle size
      newQuantity = Math.floor(newQuantity / bundleSize) * bundleSize;
      setQty(newQuantity);
      setInputQty(String(newQuantity));
    }
  };

  // Initialize selectedSize and selectColor with the most available size and color
  useEffect(() => {
    const mostAvailableSize = variations.reduce((acc, variant) => {
      if (!acc || findqty(variant.SKU_ID, qtys) > findqty(acc.SKU_ID, qtys)) {
        return variant.size_name;
      }
      return acc;
    }, null);

    const mostAvailableColor = variations.reduce((acc, variant) => {
      if (!acc || findqty(variant.SKU_ID, qtys) > findqty(acc.SKU_ID, qtys)) {
        return variant.colour_name;
      }
      return acc;
    }, null);

    // setSelectedSize(mostAvailableSize);
    // setSelectColor(mostAvailableColor);
  }, [variations, qtys]);

  useEffect(() => {
    if (product && product.prod_ID !== null) {
      dispatch(setSalePrice(product.skuDetails[0].NDP_Price));
      dispatch(setQuantity(findqty(product.skuDetails[0].SKU_ID, qtys)));
      dispatch(setProductID(product.prod_ID));
    }
    if (product && product?.skuDetails?.length === 1) {
      setSelectedSize(product?.skuDetails[0]?.size_name);
      setSelectColor(product?.skuDetails[0]?.colour_name);
    }
  }, [dispatch, product]);

  useEffect(() => {
    if (qty > 0) {
      setIsCartValueZero(false);
    }
  }, [qty]);

  useEffect(() => {
    const sizes = [...new Set(variations.map((variant) => variant.size_name))];
    const colors = [
      ...new Set(variations.map((variant) => variant.colour_name)),
    ];
    setAvailableSizes(sizes);
    setAvailableColors(colors);
  }, [variations]);

  useEffect(() => {
    let filteredSizes = [];
    let filteredColors = [];

    if (!selectedSize && !selectColor) {
      filteredSizes = [
        ...new Set(variations.map((variant) => variant.size_name)),
      ];
      filteredColors = [
        ...new Set(variations.map((variant) => variant.colour_name)),
      ];
      setSelectedProduct([variations[0]]);
    } else {
      if (selectedSize && selectColor) {
        const selectedSku = variations.find(
          (variant) =>
            variant.size_name === selectedSize &&
            variant.colour_name === selectColor
        );

        if (selectedSku) {
          dispatch(setSalePrice(selectedSku?.Sale_Price));
          dispatch(setQuantity(findqty(selectedSku?.SKU_ID, qtys)));
          const selectProduct = variations.filter(
            (variant) =>
              variant.size_name === selectedSize &&
              variant.colour_name === selectColor
          );
          setSelectedProduct(selectProduct);
          if (qty > findqty(selectedSku?.SKU_ID, qtys)) {
            setQty(findqty(selectedSku?.SKU_ID, qtys));
          }
        }
      } else if (selectedSize && !selectColor) {
        const selectedSku = variations.find(
          (variant) => variant.size_name === selectedSize
        );

        if (selectedSku) {
          dispatch(setSalePrice(selectedSku?.Sale_Price));
          dispatch(setQuantity(findqty(selectedSku?.SKU_ID, qtys)));
          const selectProduct = variations.filter(
            (variant) => variant.size_name === selectedSize
          );
          setSelectedProduct(selectProduct);
          if (qty > findqty(selectedSku?.SKU_ID, qtys)) {
            setQty(findqty(selectedSku?.SKU_ID, qtys));
          }
        }
      } else if (!selectedSize && selectColor) {
        const selectedSku = variations.find(
          (variant) => variant.colour_name === selectColor
        );

        if (selectedSku) {
          dispatch(setSalePrice(selectedSku?.Sale_Price));
          dispatch(setQuantity(findqty(selectedSku?.SKU_ID, qtys)));
          const selectProduct = variations.filter(
            (variant) => variant.colour_name === selectColor
          );
          setSelectedProduct(selectProduct);
          if (qty > findqty(selectedSku?.SKU_ID, qtys)) {
            setQty(findqty(selectedSku?.SKU_ID, qtys));
          }
        }
      }

      filteredSizes = availableSizes;
      filteredColors = availableColors;

      if (selectedSize) {
        filteredColors = variations
          .filter((variant) => variant.size_name === selectedSize)
          .map((variant) => variant.colour_name);
      }

      if (selectColor) {
        filteredSizes = variations
          .filter((variant) => variant.colour_name === selectColor)
          .map((variant) => variant.size_name);
      }
    }

    setAvailableSizes([...new Set(filteredSizes)]);
    setAvailableColors([...new Set(filteredColors)]);
  }, [selectedSize, selectColor, variations]);

  const handleSelectSize = (size) => {
    setSelectedSize(size);
  };

  const handleSelectColor = (color) => {
    setSelectColor(color);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the container
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setOpenMobileVarient(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef, setOpenMobileVarient]);

  // ADD TO CART
  const addToCart = (data, selectedQty, e) => {
    e.preventDefault();
    if (
      availableSizes &&
      availableSizes[0] !== null &&
      availableColors &&
      availableColors[0] !== null
    ) {
      if (selectedSize && selectColor) {
        setLoading("cart");
        let cartData = {
          userID:
            userDetail?.UserID &&
            ADMIN_USER?.includes(userDetail?.UserID) &&
            selectedUser !== null
              ? selectedUser
              : userDetail?.UserID,
          SKU: selectedProduct[0].SKU_ID,
          buyQty: selectedQty === 0 ? product?.Bundle : selectedQty,
        };
        if (selectedQty >= 1) {
          dispatch(addToCartsAsync(cartData)).then(() => {
            dispatch(fetchAllCartsAsync(userDetail?.UserID)).then(() => {
              if (
                userDetail?.UserID &&
                ADMIN_USER?.includes(userDetail?.UserID) &&
                selectedUser !== null
              ) {
                dispatch(fetchAllUserCartsAsync(selectedUser));
              }
              setLoading("");
            });
          });
        } else if (selectedQty === 0) {
          setIsCartValueZero(true);
          setLoading("");
        } else {
          setLoading("");
        }
      } else {
        return;
      }
    } else if (
      availableSizes &&
      availableSizes[0] !== null &&
      availableColors[0] === null
    ) {
      if (selectedSize !== null) {
        setLoading("cart");
        let cartData = {
          userID:
            userDetail?.UserID &&
            ADMIN_USER?.includes(userDetail?.UserID) &&
            selectedUser !== null
              ? selectedUser
              : userDetail?.UserID,
          SKU: selectedProduct[0].SKU_ID,
          buyQty: selectedQty === 0 ? product?.Bundle : selectedQty,
        };
        if (selectedQty >= 1) {
          dispatch(addToCartsAsync(cartData)).then(() => {
            dispatch(fetchAllCartsAsync(userDetail?.UserID)).then(() => {
              if (
                userDetail?.UserID &&
                ADMIN_USER?.includes(userDetail?.UserID) &&
                selectedUser !== null
              ) {
                dispatch(fetchAllUserCartsAsync(selectedUser));
              }
              setLoading("");
            });
          });
        } else if (selectedQty === 0) {
          setIsCartValueZero(true);
          setLoading("");
        } else {
          setLoading("");
        }
      }
    } else if (
      availableSizes &&
      availableSizes[0] === null &&
      availableColors &&
      availableColors[0] !== null
    ) {
      if (selectColor) {
        setLoading("cart");
        let cartData = {
          userID:
            userDetail?.UserID &&
            ADMIN_USER?.includes(userDetail?.UserID) &&
            selectedUser !== null
              ? selectedUser
              : userDetail?.UserID,
          SKU: selectedProduct[0].SKU_ID,
          buyQty: selectedQty === 0 ? product?.Bundle : selectedQty,
        };
        if (selectedQty >= 1) {
          dispatch(addToCartsAsync(cartData)).then(() => {
            dispatch(fetchAllCartsAsync(userDetail?.UserID)).then(() => {
              if (
                userDetail?.UserID &&
                ADMIN_USER?.includes(userDetail?.UserID) &&
                selectedUser !== null
              ) {
                dispatch(fetchAllUserCartsAsync(selectedUser));
              }
              setLoading("");
            });
          });
        } else if (selectedQty === 0) {
          setIsCartValueZero(true);
          setLoading("");
        } else {
          setLoading("");
        }
      }
    } else {
      return;
    }
  };

  const handleBuyNow = (data, selectedQty, e) => {
    if (isInCart()) {
      navigate("/carts");
    } else {
      e.preventDefault();

      if (
        availableSizes &&
        availableSizes[0] !== null &&
        availableColors &&
        availableColors[0] !== null
      ) {
        if (selectedSize && selectColor) {
          setLoading("buy");
          let cartData = {
            userID: userDetail?.UserID,
            SKU: selectedProduct[0].SKU_ID,
            buyQty: selectedQty === 0 ? product?.Bundle : selectedQty,
          };
          if (selectedQty >= 1) {
            dispatch(addToCartsAsync(cartData)).then(() => {
              dispatch(fetchAllCartsAsync(userDetail?.UserID)).then(() => {
                setLoading("");
                navigate("/carts");
              });
            });
          } else if (selectedQty === 0) {
            setIsCartValueZero(true);
            setLoading("");
          } else {
            setLoading("");
          }
        } else {
          return;
        }
      } else if (
        availableSizes &&
        availableSizes[0] !== null &&
        availableColors[0] === null
      ) {
        if (selectedSize !== null) {
          setLoading("Buy");
          let cartData = {
            userID: userDetail?.UserID,
            SKU: selectedProduct[0].SKU_ID,
            buyQty: selectedQty === 0 ? product?.Bundle : selectedQty,
          };
          if (selectedQty >= 1) {
            dispatch(addToCartsAsync(cartData)).then(() => {
              dispatch(fetchAllCartsAsync(userDetail?.UserID)).then(() => {
                setLoading("");
                navigate("/carts");
              });
            });
          } else if (selectedQty === 0) {
            setIsCartValueZero(true);
            setLoading("");
          } else {
            setLoading("");
          }
        }
      } else if (
        availableSizes &&
        availableSizes[0] === null &&
        availableColors &&
        availableColors[0] !== null
      ) {
        if (selectColor) {
          setLoading("Buy");
          let cartData = {
            userID: userDetail?.UserID,
            SKU: selectedProduct[0].SKU_ID,
            buyQty: selectedQty === 0 ? product?.Bundle : selectedQty,
          };
          if (selectedQty >= 1) {
            dispatch(addToCartsAsync(cartData)).then(() => {
              dispatch(fetchAllCartsAsync(userDetail?.UserID)).then(() => {
                setLoading("");
                navigate("/carts");
              });
            });
          } else if (selectedQty === 0) {
            setIsCartValueZero(true);
            setLoading("");
          } else {
            setLoading("");
          }
        }
      } else {
        return;
      }
    }
  };

  // Function to check if a product is in the cart
  const isInCart = () => {
    if (userDetail?.UserID && ADMIN_USER?.includes(userDetail?.UserID)) {
      return (
        Array.isArray(allUserCartsItems) &&
        allUserCartsItems?.some(
          (item) => item?.SKU_ID === selectedProduct[0]?.SKU_ID
        )
      );
    } else {
      return (
        Array.isArray(cart) &&
        cart?.some(
          (item) => item?.skuDetails?.SKU_ID === selectedProduct[0]?.SKU_ID
        )
      );
    }
  };

  // REMOVE FROM CART
  const removeFromCart = () => {
    setLoading("Cart");
    let cartData = {
      userID:
        userDetail?.UserID &&
        ADMIN_USER?.includes(userDetail?.UserID) &&
        selectedUser !== null
          ? selectedUser
          : userDetail?.UserID,
      SKU: selectedProduct[0]?.SKU_ID,
    };
    dispatch(removeFromCartsAsync(cartData)).then(() => {
      dispatch(fetchAllCartsAsync(userDetail?.UserID)).then(() => {
        if (
          userDetail?.UserID &&
          ADMIN_USER?.includes(userDetail?.UserID) &&
          selectedUser !== null
        ) {
          dispatch(fetchAllUserCartsAsync(selectedUser));
        }
        setLoading("");
      });
    });
  };

  const handlenavigate = (prod_id) => {
    navigate(`/product/${prod_id}`);
  };

  const addToPreBook = (data, selectedQty, e) => {
    e.preventDefault();
    if (
      availableSizes &&
      availableSizes[0] !== null &&
      availableColors &&
      availableColors[0] !== null
    ) {
      if (selectedSize && selectColor) {
        setLoading("Prebook");
        let preOrderData = {
          UserID: userDetail?.UserID,
          SKU_ID: selectedProduct[0].SKU_ID,
          quantity: selectedQty === 0 ? product?.Bundle : selectedQty,
        };
        if (selectedQty >= 1) {
          dispatch(addToPreorderCartsAsync(preOrderData)).then(() => {
            dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID)).then(
              () => {
                setLoading("");
              }
            );
          });
        } else if (selectedQty === 0) {
          setIsCartValueZero(true);
          setLoading("");
        } else {
          setLoading("");
        }
      } else {
        return;
      }
    } else if (
      availableSizes &&
      availableSizes[0] !== null &&
      availableColors[0] === null
    ) {
      if (selectedSize !== null) {
        setLoading("Prebook");
        let preOrderData = {
          UserID: userDetail?.UserID,
          SKU_ID: selectedProduct[0].SKU_ID,
          quantity: selectedQty === 0 ? product?.Bundle : selectedQty,
        };
        if (selectedQty >= 1) {
          dispatch(addToPreorderCartsAsync(preOrderData)).then(() => {
            dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID)).then(
              () => {
                setLoading("");
              }
            );
          });
        } else if (selectedQty === 0) {
          setIsCartValueZero(true);
          setLoading("");
        } else {
          setLoading("");
        }
      }
    } else if (
      availableSizes &&
      availableSizes[0] === null &&
      availableColors &&
      availableColors[0] !== null
    ) {
      if (selectColor) {
        setLoading("Prebook");
        let preOrderData = {
          UserID: userDetail?.UserID,
          SKU_ID: selectedProduct[0].SKU_ID,
          quantity: selectedQty === 0 ? product?.Bundle : selectedQty,
        };
        if (selectedQty >= 1) {
          dispatch(addToPreorderCartsAsync(preOrderData)).then(() => {
            dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID)).then(
              () => {
                setLoading("");
              }
            );
          });
        } else if (selectedQty === 0) {
          setIsCartValueZero(true);
          setLoading("");
        } else {
          setLoading("");
        }
      }
    } else {
      return;
    }
  };

  // Function to check if a product is in the pre order
  const isInPreOrder = () => {
    return (
      Array.isArray(preOrder) &&
      preOrder?.some(
        (item) =>
          parseInt(item?.SKU_ID) === parseInt(selectedProduct[0]?.SKU_ID)
      )
    );
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenMobileVarient(open);
  };

  const handleDrawerClick = (event) => {
    event.stopPropagation();
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={openMobileVarient}
      onClose={()=>toggleDrawer(false)}
      onOpen={()=>toggleDrawer(true)}
      disableAutoFocus
      disableEnforceFocus
      disableEscapeKeyDown
      disableRestoreFocus
      PaperProps={{
        sx: {
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
          overflow: "visible",
        },
      }}
    >
      <div
        className="w-full min-h-[75vh] bg-white-1 rounded-t-[20px] flex flex-col items-start justify-start overflow-y-auto pb-4"
        ref={containerRef}
      >
        <div className="w-full h-[40px] border-b-2 border-black-1/15 flex items-center justify-between px-3">
          <span className="text-16 font-bold text-blue">Select Variant</span>
          <CancelIcon
            onClick={() => setOpenMobileVarient(null)}
            className="text-blue"
            style={{ fontSize: "20px" }}
          />
        </div>
        <div className="w-full h-[155px] flex items-start justify-start py-2 px-2 border-b-2 border-black-1/15 relative">
          <div
            className="w-[25%] h-full flex-center"
            onClick={() => handlenavigate(product.prod_ID)}
          >
            <img loading="lazy"
              src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                product?.images[0] && product?.images[0]
              }`}
              alt="product-img"
              className="w-full h-[80px] object-contain"
            />
          </div>
          <div className="w-[75%] h-full flex flex-col items-start justify-start pl-4 relative">
            {product && product?.manufacture_images ? (
              <img loading="lazy"
                className="h-[20px] my-1 object-contain"
                src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                  product && product?.manufacture_images
                }`}
                alt="brand-logo"
              />
            ) : (
              <span className="text-[16px] font-semibold italic text-black-1/70">
                By: {product?.manufacture_name && product?.manufacture_name}
              </span>
            )}
            {product?.manufacture_name === "ESI" && (
              <img loading="lazy"
                src={commingSoonLogo}
                alt="comming soon"
                className="absolute top-[5px] right-[10px] h-[15px]"
              />
            )}
            <span className="text-[18px] leading-[1] mb-[5px] h-[38px] pr-4 font-bold font-sans text-blue">
              {selectedProduct[0] &&
                selectedProduct[0]?.prod_name &&
                selectedProduct[0]?.prod_name?.slice(0, 50) + "..."}
            </span>
            <div className="w-full flex flex-col items-start justify-start">
              <div className="flex items-center justify-start">
                <span className="text-[16px] leading-[1.2] text-blue py-[1px] font-sans">
                  Dealer Price -{" "}
                  <b
                    className={`${
                      selectedProduct[0]?.Offer_price &&
                      selectedProduct[0]?.Offer_price !== null
                        ? "line-through text-13 font-semibold"
                        : "font-bold text-[18px]"
                    }`}
                  >
                    ₹
                    {selectedProduct[0] &&
                      selectedProduct[0]?.NDP_Price &&
                      formatWithCommasWithDecimal(
                        calculateDealerPrice(
                          parseInt(
                            selectedProduct[0] && selectedProduct[0]?.MRP_price
                          ),
                          parseInt(
                            selectedProduct[0] && selectedProduct[0]?.NDP_Price
                          ),
                          product && product?.GST
                        )
                      )}
                  </b>
                </span>
                {selectedProduct[0]?.Offer_price &&
                  selectedProduct[0].Offer_price !== null && (
                    <span className="text-[20px] text-green font-bold leading-[1] font-sans ml-[6px]">
                      ₹
                      {formatWithCommasWithDecimal(
                        calculateOfferPrice(
                          parseInt(
                            selectedProduct[0] && selectedProduct[0]?.MRP_price
                          ),
                          parseInt(
                            selectedProduct[0] &&
                              selectedProduct[0]?.Offer_price
                          ),
                          product && product?.GST
                        )
                      )}
                    </span>
                  )}
              </div>
              <span className="text-[15px] leading-[1] font-sans font-semibold text-gray-800 py-[1px]">
                Product MRP - ₹
                {selectedProduct[0] &&
                  selectedProduct[0]?.MRP_price &&
                  formatWithCommasWithDecimal(
                    selectedProduct[0] && selectedProduct[0]?.MRP_price
                  )}
              </span>
              <div className="w-full flex items-center justify-between py-1">
                {selectedProduct[0]?.Offer_price &&
                selectedProduct[0].Offer_price !== null ? (
                  <span className="text-[12px] font-sans leading-[1] font-medium bg-peach text-white-1 py-1 px-1 whitespace-nowrap rounded-md">
                    {calculateDiscountPercentage(
                      selectedProduct[0] && selectedProduct[0]?.MRP_price,
                      calculateOfferPrice(
                        parseInt(
                          selectedProduct[0] && selectedProduct[0]?.MRP_price
                        ),
                        parseInt(
                          selectedProduct[0] && selectedProduct[0]?.Offer_price
                        ),
                        product && product?.GST
                      )
                    )}
                    % Dealer Margin
                  </span>
                ) : (
                  <span className="text-[12px] font-sans leading-[1] font-medium bg-peach text-white-1 py-1 px-1 whitespace-nowrap rounded-md">
                    {calculateDiscountPercentage(
                      selectedProduct[0] && selectedProduct[0]?.MRP_price,
                      calculateOfferPrice(
                        parseInt(
                          selectedProduct[0] && selectedProduct[0]?.MRP_price
                        ),
                        parseInt(
                          selectedProduct[0] && selectedProduct[0]?.NDP_Price
                        ),
                        product && product?.GST
                      )
                    )}
                    % Dealer Margin
                  </span>
                )}
                <div className="flex items-center justify-center ">
                  <div
                    className="bg-blue text-white-1 mx-1 p-[2px] flex-center rounded-full"
                    onClick={handleDecreaseQty}
                  >
                    <RemoveIcon style={{ fontSize: "18px" }} />
                  </div>
                  <TextField
                    type="number"
                    value={inputQty}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    onKeyPress={(e) => {
                      if (!/[0-9\b]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    InputProps={{
                      inputProps: { min: 0 },
                      sx: {
                        width: "30px",
                        height: "25px",
                        textAlign: "center",
                        borderRadius: "4px",
                        padding: "0",
                        margin: "0 4px",
                        border: isCartValueZero
                          ? "1px solid red"
                          : "1px solid rgba(0,0,0,0.2)",
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                        color: "rgba(0, 0, 0, 0.9)",
                        fontSize: "14px",
                        fontWeight: "500",
                        padding: "0",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: isCartValueZero ? "red" : "transparent",
                        },
                      },
                      "&:hover fieldset": {
                        borderColor: isCartValueZero ? "red" : "transparent", // Prevent hover blue outline
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent", // Remove blue outline on focus
                      },
                    }}
                  />

                  <div
                    className=" bg-blue text-white-1 mx-1 p-[2px] flex-center rounded-full"
                    onClick={handleIncreaseQty}
                  >
                    <AddIcon style={{ fontSize: "18px" }} />
                  </div>
                  {findqty(product.skuDetails[0].SKU_ID, qtys) <=
                    product?.Bundle && (
                    <span className="text-peach text-[10px] ml-1 whitespace-nowrap">
                      Out of Stock
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {product && (
          <div
            className="w-full flex flex-col items-start justify-start py-1 pl-3 border-b-2 border-black-1/15"
            onClick={handleDrawerClick}
          >
            <div className="w-full flex items-center justify-between pr-[20px]">
              <span className="text-16 font-bold mb-2 text-black-1/90">
                Advantages
              </span>
              {userDetail?.UserID &&
                ADMIN_USER?.includes(userDetail?.UserID) && (
                 <select
                      className="p-[3px] bg-white-1 text-black-1/90 outline-none h-[35px]"
                      name="select-user"
                      id="select-user"
                      onChange={handleSelectChange}
                    >
                      {allCartsUser &&
                        Array?.isArray(allCartsUser) &&
                        allCartsUser?.map((user) => (
                          <option
                            value={user?.UserID}
                            key={user?.UserID}
                            selected={userDetail?.UserID === user?.UserID}
                          >
                            {user?.business_name
                              ? user?.business_name
                              : user?.FirstName}
                          </option>
                        ))}
                    </select>
                )}
            </div>
            <div className="w-full flex items-center justify-start flex-wrap mt-1">
              {product?.Advantages?.Advantage1 && (
                <span className="h-[30px] flex items-center justify-start mr-1 mb-1 mt-1 p-1 bg-gray-200 rounded-[25px]">
                  <img loading="lazy"
                    src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_ADVANTAGE/${product?.Advantages?.Image1}`}
                    alt="adv-logo-1"
                    className="w-[25px] h-[25px] rounded-full p-[2px] mr-1"
                  />
                  <span className="text-14 font-sans leading-[1] font-semibold mr-1 text-black-1/80">
                    {product?.Advantages?.Advantage1?.length > 20
                      ? `${product.Advantages.Advantage1.slice(0, 20)}...`
                      : product.Advantages.Advantage1}
                  </span>
                </span>
              )}
              {product?.Advantages?.Advantage2 && (
                <span className="h-[30px] flex items-center justify-start mr-1 mb-1 mt-1 p-1 bg-gray-200 rounded-[25px]">
                  <img loading="lazy"
                    src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_ADVANTAGE/${product?.Advantages?.Image2}`}
                    alt="adv-logo-2"
                    className="w-[25px] h-[25px] rounded-full p-[2px] mr-1"
                  />
                  <span className="text-14 font-sans leading-[1] font-semibold mr-1 text-black-1/80">
                    {product?.Advantages?.Advantage2?.length > 20
                      ? `${product.Advantages.Advantage2.slice(0, 20)}...`
                      : product.Advantages.Advantage2}
                  </span>
                </span>
              )}
              {product?.Advantages?.Advantage3 && (
                <span className="h-[30px] flex items-center justify-start mr-1 mb-1 mt-1 p-1 bg-gray-200 rounded-[25px]">
                  <img loading="lazy"
                    src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_ADVANTAGE/${product?.Advantages?.Image3}`}
                    alt="adv-logo-3"
                    className="w-[25px] h-[25px] rounded-full p-[2px] mr-1"
                  />
                  <span className="text-14 font-sans leading-[1] font-semibold mr-1 text-black-1/80">
                    {product?.Advantages?.Advantage3?.length > 20
                      ? `${product.Advantages.Advantage3.slice(0, 20)}...`
                      : product.Advantages.Advantage3}
                  </span>
                </span>
              )}
              {product?.Advantages?.Advantage4 && (
                <span className="h-[30px] flex items-center justify-start mr-1 mb-1 mt-1 p-1 bg-gray-200 rounded-[25px]">
                  <img loading="lazy"
                    src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_ADVANTAGE/${product?.Advantages?.Image4}`}
                    alt="adv-logo-4"
                    className="w-[25px] h-[25px] rounded-full p-[2px] mr-1"
                  />
                  <span className="text-14 font-sans leading-[1] font-semibold mr-1 text-black-1/80">
                    {product?.Advantages?.Advantage4?.length > 20
                      ? `${product.Advantages.Advantage4.slice(0, 20)}...`
                      : product.Advantages.Advantage4}
                  </span>
                </span>
              )}
            </div>
          </div>
        )}

        <div className="w-full flex flex-col items-start justify-start py-1 px-2">
          {availableSizes &&
            availableSizes[0] !== null &&
            availableSizes?.length > 0 && (
              <div className="w-full flex flex-col items-start justify-start">
                <span className="text-16 font-bold tracking-wider text-black-1/90 ml-1">
                  Choose Size
                </span>
                {availableSizes && availableSizes[0] !== undefined && (
                  <div className="w-full flex items-center justify-start my-2 mx-2">
                    {availableSizes &&
                      availableSizes?.map((size) => (
                        <span
                          key={size}
                          onClick={() => handleSelectSize(size)}
                          className={`text-[14px] font-sans leading-[1] font-semibold py-[6px] px-2 text-gray-700 border-2 border-black-1/15 rounded-[6px] mr-1 ${
                            selectedSize === size
                              ? "border-2 border-peach bg-gray-50"
                              : ""
                          }`}
                        >
                          {size}
                        </span>
                      ))}
                  </div>
                )}
              </div>
            )}
          {availableColors &&
            availableColors[0] !== null &&
            availableColors?.length > 0 && (
              <div className="w-full flex flex-col items-start justify-start">
                <span className="text-16 font-bold tracking-wider text-black-1/90 ml-1">
                  Choose Colour
                </span>
                {availableColors && availableColors[0] !== undefined && (
                  <div className="w-full flex items-center justify-start my-2 mx-2">
                    {availableColors.map((color) => (
                      <div
                        key={color}
                        className={`w-[30px] h-[30px] rounded-full flex-center mr-2 ${
                          color === selectColor
                            ? "border-2 border-peach p-[2px]"
                            : ""
                        }`}
                        onClick={() => {
                          handleSelectColor(color);
                        }}
                      >
                        <button
                          className={
                            color === selectColor
                              ? "w-[22px] h-[22px] rounded-full"
                              : "w-[22px] h-[22px] rounded-full"
                          }
                          style={{ background: colourNameToHex(color) }}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
        </div>

        <div className="w-full absolute left-0 bottom-[15px] flex items-center justify-between">
          {(inputQty <= 0 ||
            (availableSizes && availableSizes[0] !== null && !selectedSize) ||
            (availableColors &&
              availableColors[0] !== null &&
              !selectColor)) && (
            <div className="w-full absolute left-[15px] -top-[20px] flex items-center justify-start text-peach text-[12.5px]">
              <sub className="-mt-[6px]">★</sub>Please select{" "}
              {inputQty <= 0 && <span>&nbsp;quantity</span>}{" "}
              {availableSizes &&
                availableSizes[0] !== null &&
                !selectedSize && <span>, size</span>}{" "}
              {availableColors &&
                availableColors[0] !== null &&
                !selectColor && <span>, color</span>}
            </div>
          )}
          {product?.manufacture_name === "ESI" ? (
            <div className="w-full h-[40px] flex-center px-[15px]">
              {isInPreOrder() ? (
                <Button
                  variant="contained"
                  className="w-full h-full text-white-1 text-[17px] font-semibold tracking-widest"
                  startIcon={
                    <AddTaskOutlinedIcon
                      sx={{ fontSize: "22px", marginRight: "10px" }}
                    />
                  }
                  sx={{
                    borderRadius: "25px",
                    backgroundColor: "#28a447", // Green color similar to your div
                    "&:hover": {
                      backgroundColor: "#228b3c", // Darker green on hover
                    },
                  }}
                >
                  Pre-Booked
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disabled={loading === "Prebook"}
                  className="w-full h-full text-white-1 text-[17px] font-semibold tracking-widest"
                  onClick={(e) => addToPreBook(product, qty, e)}
                  sx={{
                    borderRadius: "25px",
                    backgroundColor: "goldenrod", // Golden color for the pre-book button
                    "&:hover": {
                      backgroundColor: "#DAA520", // Darker goldenrod on hover
                    },
                  }}
                >
                  {loading === "Prebook" ? (
                    <CircularProgress size={24} className="ml-1" />
                  ) : (
                    "Pre-Book - At no advance cost"
                  )}
                </Button>
              )}
            </div>
          ) : (
            <div className="w-full flex items-center justify-between px-3">
              <Button
                variant="contained"
                className="w-[46%] h-[48px] mx-auto text-white-1 bg-green p-1 text-16 font-extrabold tracking-wider relative"
                onClick={
                  isInCart()
                    ? removeFromCart
                    : (e) => {
                        addToCart(product, qty, e);
                      }
                }
                disabled={loading === "cart"}
                startIcon={
                  isInCart() ? null : (
                    <ShoppingCartOutlinedIcon
                      sx={{
                        backgroundColor: "white",
                        color: "black",
                        borderRadius: "50%",
                        padding: "7px",
                        width: "40px",
                        height: "40px",
                        marginRight: "10px",
                      }}
                    />
                  )
                }
                sx={{
                  borderRadius: "30px",
                  padding: "10px",
                  backgroundColor: isInCart() ? "#28a447" : "#28a447",
                  "&:hover": {
                    backgroundColor: isInCart()
                      ? "rgb(46, 149, 113)"
                      : "rgb(46, 149, 113)",
                  },
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: isInCart() ? "center" : "start",
                }}
              >
                {isInCart() ? (
                  <span>Added</span>
                ) : (
                  <span className="flex items-center">
                    {loading === "cart" ? (
                      <CircularProgress size={24} className="ml-1" />
                    ) : (
                      <span>Add to Cart</span>
                    )}
                  </span>
                )}
              </Button>

              <Button
                variant="contained"
                className="w-[46%] h-[48px] mx-auto text-white-1 bg-blue rounded-[30px] p-1 text-16 font-extrabold tracking-wider relative"
                onClick={(e) => handleBuyNow(product, qty, e)}
                disabled={loading === "buy"}
                startIcon={
                  <ShoppingBagOutlinedIcon
                    sx={{
                      backgroundColor: "white",
                      color: "black",
                      borderRadius: "50%",
                      padding: "7px",
                      width: "40px",
                      height: "40px",
                      marginRight: "10px",
                    }}
                  />
                }
                sx={{
                  borderRadius: "30px",
                  padding: "10px",
                  backgroundColor: "#283248",
                  "&:hover": {
                    backgroundColor: "#181f30",
                  },
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <span className="flex items-center">
                  {loading === "buy" ? (
                    <CircularProgress size={24} className="ml-1" />
                  ) : (
                    <span>Buy Now</span>
                  )}
                </span>
              </Button>
            </div>
          )}
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export default VariantSelection;
