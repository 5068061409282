import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllWishlistsAsync,
  selectAllWishlists,
} from "../../features/wishlist/wishlistSlice";
import { fetchAllCartsAsync } from "../../features/cartPage/cartPageSlice";
import { selectUser } from "../../features/auth/authSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import ProductCard from "../../mComponent/ProductCard";

function MobileProductCard() {
  const dispatch = useDispatch();
  const wishlist = useSelector(selectAllWishlists);
  const products = wishlist.product;
  const qtys = wishlist.quantity;
  const userDetail = useSelector(selectUser);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail.UserID) {
      dispatch(fetchAllWishlistsAsync(userDetail.UserID));
      dispatch(fetchAllCartsAsync(userDetail.UserID));
    }
  }, [dispatch, userDetail.UserID]);

  return (
    <div className="w-full flex flex-col items-start justify-start">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open} // Show backdrop when either open or filtering state is true
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="w-full flex flex-col items-start justify-start">
        {(products || []).map((data) => (
          <ProductCard
            data={data}
            userDetail={userDetail}
            wishlist={wishlist}
            handleOpen={handleOpen}
            handleClose={handleClose}
            qtys={qtys}
          />
        ))}
      </div>
    </div>
  );
}

export default MobileProductCard;
