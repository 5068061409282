import React from "react";
import CartsProduct from "../components/Carts/CartsProduct";
import Footer from "../components/HomePage/Footer";
import Header from "../components/HomePage/Header";
import styled from "styled-components";
import ScrollPromices from "../components/HomePage/ScrollPromices";
import { useMediaQuery } from "react-responsive";
import MobileCarts from "../mobileComponent/MobileCartPage/MobileCarts";

function Carts() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 501px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" });
  return (
    <>
      {isDesktopOrLaptop && (
        <>
          {/* <HeaderDiv>
            <ScrollPromices />
            <Header />
          </HeaderDiv> */}
          <CartsProduct />
          <Footer />
        </>
      )}
      {isTabletOrMobile && (
          <MobileCarts />
      )}
    </>
  );
}

export default Carts;

const HeaderDiv = styled.div`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;
