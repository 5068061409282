import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllPromotion } from "../../../features/HomePage/homeSlice";
import {
  fetchAllWishlistsAsync,
  selectAllWishlists,
} from "../../../features/wishlist/wishlistSlice";
import { selectUser } from "../../../features/auth/authSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ProductCard from "../../../mComponent/ProductCard";

function MobileProduct() {
  const dispatch = useDispatch();
  const pdts = useSelector(selectAllPromotion);
  const products = pdts?.Data;
  const qtys = pdts?.quantity;
  const wishlist = useSelector(selectAllWishlists);
  const userDetail = useSelector(selectUser);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail.UserID) {
      dispatch(fetchAllWishlistsAsync(userDetail.UserID));
    }
  }, [dispatch, userDetail.UserID]);

  return (
    <div className="w-full flex flex-col items-center justify-start px-0 py-1">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {(products?.product || []).map((data) => (
        <ProductCard
          data={data}
          userDetail={userDetail}
          wishlist={wishlist}
          handleOpen={handleOpen}
          handleClose={handleClose}
          qtys={qtys}
        />
      ))}
    </div>
  );
}

export default MobileProduct;
