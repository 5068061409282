import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../features/auth/authSlice";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useNavigate, useParams } from "react-router";
import LogoutOverlay from "../components/EndPages/LogoutOverlay";
import { Slide } from "react-reveal";
import { IMAGE_BASE } from "../config";
import logoutLogo from "../assets/Icon-logout.svg";
import orderLogo from "../assets/Icon-orders.svg";
import userLogo from "../assets/Icon-user.svg";
import walletLogo from "../assets/Icon-wallet.svg";
import lineOfCreditLogo from "../assets/Icon-LOC1.svg";
import preorderLogo from "../assets/Icon-preorder.svg";
import securityLogo from "../assets/Icon-security.svg";
import {
  fetchCreditPageDetailsAsync,
  selectAllCreditData,
} from "../features/credit/creditSlice";

function ProfileSideBar({ handleSidebar }) {
  const dispatch = useDispatch();
  const creditData = useSelector(selectAllCreditData);
  const userDetail = useSelector(selectUser);
  const [openLogout, setOpenLogout] = useState(false);
  const navigate = useNavigate();
  const url = useParams();

  const handleOpenLogout = () => {
    setOpenLogout(!openLogout);
  };

  useEffect(() => {
    if (userDetail && userDetail?.UserID) {
      dispatch(fetchCreditPageDetailsAsync(userDetail?.UserID));
    }
  }, [dispatch, userDetail]);
  return (
    <Slide right>
      <div
        className="w-[75%] h-[calc(100vh-58px)] flex flex-col self-end fixed top-[58px] right-0 z-1 bg-white-1 py-3 px-3"
        style={{ boxShadow: "-5px 0 5px 0 rgba(0, 0, 0, 0.1)" }}
      >
        <div className="absolute top-4 right-4">
          <CloseOutlinedIcon
            onClick={handleSidebar}
            className="text-black-1/80"
            style={{ fontSize: "20px" }}
          />
        </div>
        <div className="w-full rounded-md flex items-center p-2 my-1">
          <img loading="lazy"
            src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
              userDetail?.UserImage || ""
            }`}
            alt="user-img"
            className="w-[50px] h-[50px] rounded-full p-[2px] mr-2 border-[1px] border-black-1/30"
          />
          <span className="text-20 text-black-1/90 font-bold tracking-wide">
            {userDetail?.FirstName} {userDetail?.LastName}
          </span>
        </div>
        <div
          className="w-full rounded-md flex items-center p-2 my-1"
          onClick={() => {
            navigate("/user/profile");
            handleSidebar();
          }}
          style={{
            color: url.profileUrl === "profile" && "white",
            background: url.profileUrl === "profile" && "rgba(0,0,0,0.6)",
          }}
        >
          <img loading="lazy"
            src={userLogo}
            alt="user-logo"
            width={25}
            height={25}
            className="mr-2"
            style={{
              filter:
                url.profileUrl === "profile"
                  ? "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)"
                  : "none",
            }}
          />
          <span
            className="text-[15px] text-black-1/90 font-semibold tracking-wide"
            style={{
              color: url.profileUrl === "profile" && "white",
            }}
          >
            My Profile
          </span>
        </div>
        <div
          className="w-full rounded-md flex items-center p-2 my-1"
          onClick={() => {
            navigate("/user/preorders");
            handleSidebar();
          }}
          style={{
            color: url.profileUrl === "preorders" && "white",
            background: url.profileUrl === "preorders" && "rgba(0,0,0,0.6)",
          }}
        >
          <img loading="lazy"
            src={preorderLogo}
            alt="user-logo"
            width={25}
            height={25}
            className="mr-2"
            style={{
              filter:
                url.profileUrl === "preorders"
                  ? "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)"
                  : "none",
            }}
          />
          <span
            className="text-[15px] text-black-1/90 font-semibold tracking-wide"
            style={{
              color: url.profileUrl === "preorders" && "white",
            }}
          >
            My Pre-Orders
          </span>
        </div>
        <div
          className="w-full rounded-md flex items-center p-2 my-1"
          onClick={() => {
            navigate("/user/orders");
            handleSidebar();
          }}
          style={{
            color: url.profileUrl === "orders" && "white",
            background: url.profileUrl === "orders" && "rgba(0,0,0,0.6)",
          }}
        >
          <img loading="lazy"
            src={orderLogo}
            alt="order-logo"
            width={25}
            height={25}
            className="mr-2"
            style={{
              filter:
                url.profileUrl === "orders"
                  ? "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)"
                  : "none",
            }}
          />
          <span
            className="text-[15px] text-black-1/90 font-semibold tracking-wide"
            style={{
              color: url.profileUrl === "orders" && "white",
            }}
          >
            My Orders
          </span>
        </div>
        {creditData?.creditDetails && (
          <div
            className="w-full rounded-md flex items-center p-2 my-1"
            onClick={() => {
              navigate("/user/credit");
              handleSidebar();
            }}
            style={{
              color: url.profileUrl === "credit" && "white",
              background: url.profileUrl === "credit" && "rgba(0,0,0,0.6)",
            }}
          >
            <img loading="lazy"
              src={lineOfCreditLogo}
              alt="LOC-logo"
              width={25}
              height={25}
              className="mr-2"
              style={{
                filter:
                  url.profileUrl === "credit"
                    ? "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)"
                    : "none",
              }}
            />
            <span
              className="text-[15px] text-black-1/90 font-semibold tracking-wide"
              style={{
                color: url.profileUrl === "credit" && "white",
              }}
            >
              Line of Credit
            </span>
          </div>
        )}
        <div
          className="w-full rounded-md flex items-center p-2 my-1"
          onClick={() => {
            navigate("/user/wallet");
            handleSidebar();
          }}
          style={{
            color: url.profileUrl === "wallet" && "white",
            background: url.profileUrl === "wallet" && "rgba(0,0,0,0.6)",
          }}
        >
          <img loading="lazy"
            src={walletLogo}
            alt="wallet-logo"
            width={25}
            height={25}
            className="mr-2"
            style={{
              filter:
                url.profileUrl === "wallet"
                  ? "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)"
                  : "none",
            }}
          />
          <span
            className="text-[15px] text-black-1/90 font-semibold tracking-wide"
            style={{
              color: url.profileUrl === "wallet" && "white",
            }}
          >
            My Wallet
          </span>
        </div>
        <div
          className="w-full rounded-md flex items-center p-2 my-1"
          onClick={() => {
            navigate("/user/security");
            handleSidebar();
          }}
          style={{
            color: url.profileUrl === "security" && "white",
            background: url.profileUrl === "security" && "rgba(0,0,0,0.6)",
          }}
        >
          <img loading="lazy"
            src={securityLogo}
            alt="user-logo"
            width={25}
            height={25}
            className="mr-2"
            style={{
              filter:
                url.profileUrl === "security"
                  ? "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)"
                  : "none",
            }}
          />
          <span
            className="text-[15px] text-black-1/90 font-semibold tracking-wide"
            style={{
              color: url.profileUrl === "security" && "white",
            }}
          >
            Security
          </span>
        </div>
        <div
          className="w-full rounded-md flex items-center p-2 my-1"
          onClick={handleOpenLogout}
        >
          <img loading="lazy"
            src={logoutLogo}
            alt="logout-logo"
            width={25}
            height={25}
            className="mr-2"
          />
          <span
            className="text-[15px] text-black-1/90 font-semibold tracking-wide"
            style={{ color: "var(--color-peach)" }}
          >
            Log Out
          </span>
          {openLogout && <LogoutOverlay openLogout={openLogout} handleOpenLogout={handleOpenLogout} />}
        </div>
      </div>
    </Slide>
  );
}

export default ProfileSideBar;
