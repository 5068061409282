import React from "react";
import NumberFlow from "@number-flow/react";

export const FormattedNumber = ({ value }) => {
  // Round value based on decimal part: if >= 0.5, round up; else round down
  const roundedValue =
    Math[value - Math.floor(value) >= 0.5 ? "ceil" : "floor"](value);

  // Force the rounded value to always display with two decimal places
  const formattedValue = roundedValue.toFixed(2);

  return (
    <NumberFlow
      value={formattedValue}
      format={{
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }}
      locales="en-IN"
    />
  );
};

export const AnimatedQuantity = ({ value }) => {
  return (
    <NumberFlow
      value={value}
      locales="en-US"
      format={{ notation: "standard" }} // Display as standard number
      transformTiming={{ duration: 400, easing: "linear" }}
      spinTiming={{ duration: 400, easing: "linear" }}
      opacityTiming={{ duration: 350, easing: "ease-out" }}
    />
  );
};
