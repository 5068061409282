import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPriceRange } from "../../store/slices/listingPageFilterSlice";
import { setMinPrice, setMaxPrice } from "../../store/slices/allFilterSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const ShopByPriceRange = () => {
  const priceRange = useSelector((state) => state.listingPageFilter.priceRange);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const priceRanges = [
    { label: "₹1 - ₹500" },
    { label: "₹501 - ₹1000" },
    { label: "₹1001 - ₹2500" },
    { label: "₹2501 - ₹5000" },
    { label: "₹5001 - ₹10000" },
  ];

  useEffect(() => {
    if (priceRange) {
      const prices = priceRange
        .replace(/₹/g, "") // Remove "₹" from the string
        .split(" - ");

      // Convert the values to numbers
      const minPrice = parseInt(prices[0], 10);
      const maxPrice = parseInt(prices[1], 10);
      dispatch(setMinPrice(minPrice));
      dispatch(setMaxPrice(maxPrice));
    }
  }, [dispatch, priceRange]);

  const handlePriceSelect = (ranges) => {
    handleOpen();

    window.scrollTo({ top: 0, behavior: "smooth" });

    setTimeout(async () => {
      if (ranges === priceRange) {
        await dispatch(setPriceRange(""));
        await dispatch(setMinPrice(0));
        await dispatch(setMaxPrice(0));
      } else if (ranges) {
        const prices = ranges.replace(/₹/g, "").split(" - ");
        const minPrice = parseInt(prices[0], 10);
        const maxPrice = parseInt(prices[1], 10);

        await dispatch(setMinPrice(minPrice));
        await dispatch(setMaxPrice(maxPrice));
        await dispatch(setPriceRange(ranges));
      }

      handleClose();
    }, 1000);
  };

  return (
    <div className="w-full flex flex-col justify-between bg-[#ba373766] p-2 shadow-sm shadow-black-1/50">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <span className="text-16 tracking-wide font-extrabold text-black-1/90">
        Shop by Price Range
      </span>
      <div className="flex flex-wrap mt-2">
        {priceRanges.map((range, index) => (
          <span
            key={index}
            className={`text-center rounded-[25px] font-sans tracking-wide text-14 flex-center py-1 px-2 m-1 whitespace-nowrap shadow-md shadow-black-1/30 ${
              priceRange === range.label
                ? "bg-blue text-white-1"
                : "bg-white-1 text-blue"
            }`}
            onClick={() => handlePriceSelect(range.label)}
          >
            {range.label}
          </span>
        ))}
      </div>
    </div>
  );
};

export default ShopByPriceRange;
