import React from "react";
import "./Shipping_Policy.css";
import styled from "styled-components";
import Header from "../HomePage/Header";
import Footer from "../HomePage/Footer";
import ScrollPromices from "../HomePage/ScrollPromices";
import { Link } from "react-router-dom";

export function Shipping_Policy() {
  return (
    <>
      <HeaderDiv>
        <Header />
      </HeaderDiv>
      <ScrollPromices />
      <div className="shipping_policy_container">
        <h2 className="shipping-pol">SHIPPING POLICY</h2>
        <h3>SHIPMENTS TO ALL OVER INDIA</h3>
        <p>
          Orders INR 2000 and over qualify for our fast free shipping offer.
        </p>
        <p
          style={{
            color: "black",
            textDecoration: "underline",
            fontWeight: "700",
          }}
        >
          Please note:INR 100 is applicable for cash on delivery orders.
        </p>
        <p>
          Radkaat Ventures Private Limited, henceforth referred to as
          CycleCircle, acknowledges the importance of prompt delivery following
          the placement of an order. Our commitment is to expedite the shipping
          process to the best of our capabilities. A majority of our products
          are readily available for swift dispatch, ensuring a rapid delivery to
          your doorstep. In collaboration with our suppliers, we endeavor to
          facilitate direct shipping from their warehouses to your location when
          it accelerates the delivery process.
        </p>
        <p>
          The availability of each item is indicated once a specific size or
          color is selected on the product page. This status is also reflected
          in the shopping cart and in the order confirmation email sent to you.
          Products labeled &quot;In Stock - Ships Within 24 Hours&quot; are
          dispatched on the same or the next business day. Orders placed earlier
          in the day have a higher likelihood of being shipped on the same day.
        </p>
        <p>
          While our aim is to ship from the distribution center nearest to you,
          it is important to note that not all items are continuously available
          in every location. Depending on the circumstances, we may either send
          items separately or consolidate them at one location before shipping.
          Our decision-making is guided by the objective of delivering your
          order in the most efficient manner possible.
        </p>
        <p>
          Items marked as &quot;Available - expect 3-5 business days lead time
          prior to shipment&quot; require a processing period before they can be
          dispatched. Please be aware that selecting an expedited shipping
          method will not affect this lead time.
        </p>
        <p>
          A shipping confirmation email, which includes detailed tracking
          information from the carrier, will generally be sent within 24 hours
          of the item&#39;s dispatch. For instance, if an order is placed on a
          Monday evening and dispatched on Tuesday morning, the tracking
          information would typically be provided by Tuesday evening or
          Wednesday morning.
        </p>
        <p>
          It is our constant endeavour to ensure that you receive the shipment
          in a maximum of 7-8 working days from the order date.
        </p>
      </div>
      <Footer />
    </>
  );
}

const HeaderDiv = styled.div`
  width: 100%;
  height: 80px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;
