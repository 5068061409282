import React, { useCallback, useEffect, useState } from "react";
import PriceDetail from "./PriceDetail";
import CartsAllProduct from "./CartsAllProduct";
import DefaultContact from "./DefaultContact";
import NeedHelp from "./NeedHelp";
import Promotions from "../HomePage/Promotions/Promotions";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import {
  fetchAllCartsAsync,
  selectAllCarts,
} from "../../features/cartPage/cartPageSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CartsBreadcrum from "./CartsBreadcrum";
import PaymentAccordian from "./PaymentAccordian";
import {
  handleChangeQtyOfProductCarts,
  handleRemoveFromCarts,
} from "../../utils/dispatch";
import {
  fetchCouponAsync,
  getWalletAsync,
  selectAllCoupon,
  selectWallet,
} from "../../features/cartPage/walletSlice";
import {
  fetchCreditPageDetailsAsync,
  selectAllCreditData,
} from "../../features/credit/creditSlice";
import {
  fetchAllAddressAsync,
  selectAllAddress,
  selectSelectedAddress,
} from "../../features/cartPage/addressSlice";
import { handleGeneratePaymentURL } from "../../utils/payment";
import { calculateAmountToPay, fetchUserShipping } from "../../utils/utils";
import OrderConfirmed from "./OrderConfirmed";
import { premiumUser } from "../../features/cartPage/walletAPI";
import NoCartItem from "./NoCartItem";

function CartsProduct() {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [generatePaymentUrl, setGeneratePaymentUrl] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const [paymentSessionId, setPaymentSessionId] = useState(null);
  const [openPaymentModel, setOpenPaymentModel] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [freeShipping, setFreeShipping] = useState(false);
  const [premUser, setPremUser] = useState(false);

  const userDetail = useSelector(selectUser);
  const cart = useSelector(selectAllCarts);
  const selectedAddress = useSelector(selectSelectedAddress);
  const addresses = useSelector(selectAllAddress);

  // Fetch Carts,Wallet,Coupon
  useEffect(() => {
    if (userDetail && userDetail?.UserID) {
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
      dispatch(fetchAllAddressAsync(userDetail?.UserID));
      dispatch(fetchCouponAsync());
      dispatch(getWalletAsync(userDetail?.UserID));
      dispatch(fetchCreditPageDetailsAsync(userDetail?.UserID));
    }
  }, [dispatch, userDetail]);

  // Managing Wallet
  const [useWalletAmount, setUseWalletAmount] = useState(0);
  const walletAmount = useSelector(selectWallet)?.balance;

  // Managing Coupon
  const [validCoupon, setValidCoupon] = useState({});
  const [selectedCoupon, setSelectedCoupon] = useState({
    couponCode: "",
    couponDiscountPercentage: 0,
    couponDiscountTaken: 0,
  });
  const couponList = useSelector(selectAllCoupon);

  // Managing Credit of line
  const creditData = useSelector(selectAllCreditData);
  const [useCreditAmount, setUseCreditAmount] = useState(0);

  // For deleting product automatically
  useEffect(() => {
    const fetchData = async () => {
      handleOpen();

      // Function to adjust quantities
      const adjustQuantityToAvailable = async () => {
        let quantityAdjustedFlag = false; // Local flag for adjustment

        for (const item of cart) {
          const availableQuantity = item?.skuDetails?.qty || 0;

          // Remove product if available quantity is 0
          if (availableQuantity < parseInt(item?.skuDetails?.Bundle)) {
            await handleRemoveFromCarts(
              item,
              userDetail,
              dispatch,
              handleOpen,
              handleClose
            );
            quantityAdjustedFlag = true; // Set flag to true
            continue; // Skip processing further for this item
          }

          const updatedQty = Math.min(item?.buy_quantity, availableQuantity);
          const newQty =
            (parseInt(availableQuantity) / parseInt(item?.skuDetails?.Bundle)) *
            parseInt(item?.skuDetails?.Bundle);

          // Update quantity only if buy_quantity exceeds available quantity
          if (item.buy_quantity !== updatedQty) {
            await handleChangeQtyOfProductCarts(
              item,
              userDetail,
              newQty,
              dispatch,
              handleOpen,
              handleClose
            );
            quantityAdjustedFlag = true; // Set flag to true
          }
        }

        if (quantityAdjustedFlag) {
          await dispatch(fetchAllCartsAsync(userDetail?.UserID));
        }

        handleClose(); // Close the loader here
      };

      // Call the function to adjust quantities when component mounts
      await adjustQuantityToAvailable();
    };

    fetchData();

    return () => clearTimeout(2000);
  }, [dispatch, cart, userDetail]);

  const checkPremiumUser = useCallback(async () => {
    try {
      const response = await premiumUser(userDetail?.UserID);
      if (response && response?.data && response?.data.message) {
        setPremUser(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [userDetail?.UserID]);

  // Use useEffect to call the function
  useEffect(() => {
    if (userDetail?.UserID) {
      checkPremiumUser();
    }
  }, [checkPremiumUser, userDetail]);

  const fetchAndSetShipping = useCallback(async () => {
    if (userDetail?.UserID) {
      try {
        const userShipping = await fetchUserShipping(userDetail?.UserID);

        if (userShipping === "false") {
          setFreeShipping(true);
        } else {
          setFreeShipping(false);
        }
      } catch (error) {
        console.error("Error fetching user shipping info:", error);
      }
    }
  }, [userDetail?.UserID]);

  useEffect(() => {
    if (userDetail?.UserID) {
      fetchAndSetShipping();
    }
  }, [fetchAndSetShipping, userDetail]);

  // Generate Payment URL Call
  useEffect(() => {
    const generateUrlAsync = async () => {
      if (generatePaymentUrl) {
        const amountToPay = parseInt(
          await calculateAmountToPay(
            cart,
            selectedCoupon?.couponDiscountTaken,
            useWalletAmount,
            useCreditAmount,
            setUseWalletAmount,
            setUseCreditAmount
          )
        );
        await handleGeneratePaymentURL(
          userDetail,
          setPaymentSessionId,
          setTransactionId,
          amountToPay
        );
      }
    };

    generateUrlAsync();
  }, [generatePaymentUrl]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="w-full sticky top-0 z-[800]">
        <CartsBreadcrum
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      </div>
      {cart && cart?.length > 0 ? (
        <div className="w-full min-h-[500px] my-6 px-4 md:px-[50px]  lg:px-[100px] flex flex-col md:flex-row">
          <div className="w-full md:w-2/3 px-4">
            <div className="w-full h-full ">
              {currentStep === 1 && (
                <CartsAllProduct
                  cart={cart}
                  userDetail={userDetail}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  setUseCreditAmount={setUseCreditAmount}
                  setUseWalletAmount={setUseWalletAmount}
                  setSelectedCoupon={setSelectedCoupon}
                />
              )}
              {currentStep === 2 && (
                <DefaultContact
                  addresses={addresses}
                  userDetail={userDetail}
                  selectedAddress={selectedAddress}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                />
              )}
              {currentStep === 3 && (
                <PaymentAccordian
                  userDetail={userDetail}
                  walletAmount={walletAmount}
                  useWalletAmount={useWalletAmount}
                  setUseWalletAmount={setUseWalletAmount}
                  errors={errors}
                  setErrors={setErrors}
                  couponList={couponList}
                  validCoupon={validCoupon}
                  setValidCoupon={setValidCoupon}
                  selectedCoupon={selectedCoupon}
                  setSelectedCoupon={setSelectedCoupon}
                  cart={cart}
                  creditData={creditData}
                  useCreditAmount={useCreditAmount}
                  setUseCreditAmount={setUseCreditAmount}
                  setGeneratePaymentUrl={setGeneratePaymentUrl}
                  setOpenPaymentModel={setOpenPaymentModel}
                />
              )}
            </div>
          </div>
          <div className="w-full md:w-1/3 px-4">
            <div className="sticky top-[90px] bg-white-1 shadow-md rounded-md mt-4 md:mt-0">
              <PriceDetail
                cart={cart}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                useWalletAmount={useWalletAmount}
                selectedCoupon={selectedCoupon}
                useCreditAmount={useCreditAmount}
                setUseWalletAmount={setUseWalletAmount}
                setUseCreditAmount={setUseCreditAmount}
                selectedAddress={selectedAddress}
                setGeneratePaymentUrl={setGeneratePaymentUrl}
                setOpenPaymentModel={setOpenPaymentModel}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full min-h-[500px] my-6 px-[100px] flex flex-col md:flex-row">
          <NoCartItem text={"Carts"} />
        </div>
      )}

      {generatePaymentUrl && openPaymentModel && (
        <OrderConfirmed
          cart={cart}
          userDetail={userDetail}
          paymentSessionId={paymentSessionId}
          transactionId={transactionId}
          selectedAddress={selectedAddress}
          selectedCoupon={selectedCoupon}
          useWalletAmount={useWalletAmount}
          setUseWalletAmount={setUseWalletAmount}
          useCreditAmount={useCreditAmount}
          setUseCreditAmount={setUseCreditAmount}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          generatePaymentUrl={generatePaymentUrl}
          openPaymentModel={openPaymentModel}
          setOpenPaymentModel={setOpenPaymentModel}
        />
      )}
      {currentStep === 1 && <Promotions />}
      <NeedHelp />
    </>
  );
}

export default CartsProduct;
