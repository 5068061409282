import React from "react";
import "./NoProductFound.css";
import noProductLogo from "../../../img/no-products-logo.png";

function NoProductFound() {
  return (
    <div className="no-product-item-container">
      <div className="no-product-item-div">
        <img loading="lazy" src={noProductLogo} alt="" />
        <h3>No products were found.</h3>
        <span className="sorry-mate-product">
          Sorry, Temporarily we couldn't find any products
        </span>
      </div>
    </div>
  );
}

export default NoProductFound;
