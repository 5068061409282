import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ViewStreamOutlinedIcon from "@mui/icons-material/ViewStreamOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { connect, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import verticalIcon from "../../img/mobileIcon/Button-Category.png";
import {
  selectIsOverlayVisible,
  toggleOverlay,
} from "../../store/slices/headerSlice";
import MobileSideBar from "./MobileSideBar";
import MobileBigNavigation from "./MobileBigNavigation";
import { useMediaQuery } from "react-responsive";
import AutoLoginPage from "../MobileAuthentication/AutoLoginPage/AutoLoginPage";

function MobileBottomNavigation({ isOverlayVisible, toggleOverlay }) {
  const [openBurger, setOpenBurger] = useState(false);
  const [showNavigation, setShowNavigation] = useState(null);
  const [showAutoLogin, setShowAutoLogin] = useState(false);
  const userDetail = useSelector(selectUser);
  const navigate = useNavigate();
  const location = useLocation();

  const isCurrentPath = (path) => {
    return location.pathname === path;
  };
  const handleHome = (path) => {
    navigate(`/`);
  };
  const handleCart = (path) => {
    navigate(`/${path}`);
  };
  const handleNavigate = (path) => {
    if (path === "search") {
      return;
    }
    navigate(`/user/${path}`);
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" });

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!Object.keys(userDetail).length) {
        // Check if user is not logged in
        setShowAutoLogin(true); // Show AutoLoginPage after 10 seconds
      }
    }, 5000); // 10 seconds delay

    return () => clearTimeout(timer); // Cleanup timer if component unmounts
  }, []);

  return (
    <div
      className="w-full flex items-center justify-between fixed left-0 right-0 bottom-0 bg-white-1 px-0   z-999"
      style={{ boxShadow: "0 -3px 5px 0 rgba(0,0,0,0.1)" }}
    >
      <div
        className="w-[20%] flex flex-col items-center justify-start"
        onClick={() => handleHome("/")}
      >
        <HomeOutlinedIcon
          style={{
            color: isCurrentPath("/")
              ? "var(--color-peach)"
              : "rgba(0,0,0,0.9)",
            fontSize: "30px",
          }}
        />
        <span
          className="text-12 font-bold text-black-2 mt-[2px]"
          style={{
            color: isCurrentPath("/")
              ? "var(--color-peach)"
              : "rgba(0,0,0,0.9)",
          }}
        >
          Home
        </span>
      </div>
      <div
        className="w-[20%] flex flex-col items-center justify-start"
        onClick={() =>
          Object.keys(userDetail).length
            ? handleNavigate("orders")
            : toggleOverlay()
        }
      >
        <ViewStreamOutlinedIcon
          style={{
            color: isCurrentPath("/user/orders")
              ? "var(--color-peach)"
              : "rgba(0,0,0,0.9)",
            fontSize: "30px",
          }}
        />
        <span
          className="text-12 font-bold text-black-2 mt-[2px]"
          style={{
            color: isCurrentPath("/user/orders")
              ? "var(--color-peach)"
              : "rgba(0,0,0,0.9)",
          }}
        >
          Orders
        </span>
      </div>
      {showNavigation && (
        <MobileBigNavigation setShowNavigation={setShowNavigation} />
      )}
      <img loading="lazy"
        src={verticalIcon}
        alt="vertical-icon"
        className="w-[60px] h-[60px]"
        onClick={() => setShowNavigation(true)}
      />
      <div
        className="w-[20%] flex flex-col items-center justify-start"
        onClick={() =>
          Object.keys(userDetail).length ? handleCart("carts") : toggleOverlay()
        }
      >
        <ShoppingCartOutlinedIcon
          style={{
            color: isCurrentPath("/carts")
              ? "var(--color-peach)"
              : "rgba(0,0,0,0.9)",
            fontSize: "30px",
          }}
        />
        <span
          className="text-12 font-bold text-black-2 mt-[2px]"
          style={{
            color: isCurrentPath("/carts")
              ? "var(--color-peach)"
              : "rgba(0,0,0,0.9)",
          }}
        >
          Cart
        </span>
      </div>
      <div
        className="w-[20%] flex flex-col items-center justify-start"
        onClick={() => setOpenBurger(true)}
      >
        <AccountCircleOutlinedIcon
          style={{
            color: openBurger ? "var(--color-peach)" : "rgba(0,0,0,0.9)",
            fontSize: "30px",
          }}
        />
        <span
          className="text-12 font-bold text-black-2 mt-[2px]"
          style={{
            color: openBurger ? "var(--color-peach)" : "rgba(0,0,0,0.9)",
          }}
        >
          Profile
        </span>
      </div>
      {openBurger && (
        <MobileSideBar setOpenBurger={setOpenBurger} openBurger={openBurger} />
      )}
      {isTabletOrMobile && showAutoLogin && (
        <AutoLoginPage setShowAutoLogin={setShowAutoLogin} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isOverlayVisible: selectIsOverlayVisible(state),
});
const mapDispatchToProps = {
  toggleOverlay,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileBottomNavigation);
