import React, { useEffect, useState } from "react";
import "./BusinessPAN.css";
import CheckIcon from "@mui/icons-material/Check";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API_BASE_URL } from "../../../../config";
import DoneIcon from "@mui/icons-material/Done";
import toast from "react-hot-toast";

function BusinessPAN({ updateFinalData, finalData, PANVerify, setPANVerify }) {
  const [verify, setVerify] = useState(false);
  const [panExists, setPanExists] = useState(false);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  function validatePAN(pan) {
    const regex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return regex.test(pan);
  }

  const handlePANBlur = async (e) => {
    const pan = e.target.value;
    if (!validatePAN(pan)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        PAN: "Please enter a valid 10-digit PAN number",
      }));
      return;
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}singup_pan_verify/${pan}`
      );
      const data = await response.json();

      if (data.status === "error") {
        setPanExists(true);
        setErrors((prevErrors) => ({
          ...prevErrors,
          PAN: "PAN number already registered with Cyclecircle",
        }));
      } else {
        setPanExists(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          PAN: "",
        }));
      }
    } catch (error) {
      console.error("Error checking PAN number existence:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updateValue = value.toUpperCase();
    updateFinalData({ [name]: updateValue });

    if (name === "pan_card" && value.length === 10) {
      handlePANBlur(e);
    } else if (name === "pan_card" && value.length < 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        PAN: "",
      }));
    }
  };

  const handleEnterKey = (e, nextInputId) => {
    if (e.key === "Enter") {
      const nextInput = document.getElementById(nextInputId);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleVerifyPANClose = () => {
    setVerify(false);
  };

  const handleVerifyPAN = async () => {
    handleOpen();
    if (!finalData.pan_card || finalData.pan_card.length < 10) {
      handleClose();
      toast.error('Enter PAN correctly')
    } else {
      try {
        const response = await fetch(
          `${API_BASE_URL}pan_verify/${finalData?.pan_card}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (data.PAN.message === "PAN verified successfully") {
          setVerify(true);
          setPANVerify(true);
          updateFinalData({ PAN_DATA: data.PAN });
          handleClose();
          
        } else {
          handleClose();
          toast.error("PAN Doesn't Exist")
        }
      } catch (error) {
        handleClose();
        toast.error("PAN Doesn't Exist")
        console.error(
          "An error occurred during the API request:",
          error.message
        );
      }
    }
  };
  useEffect(()=>{
    if(finalData?.pan_card?.length < 10){
      setPANVerify(false);
      updateFinalData({ ["PAN_DATA"]: null });
    }
  },[finalData?.pan_card?.length]);

  return (
    <div className="business-pan-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <span className="business-pan-heading">Business PAN</span>
      <span className="business-pan-desc">
        We need your PAN details associated with your business to verify your
        account.If you have an unregistered business or you are a sole
        proprietor,enter you personal PAN details.
      </span>
      <fieldset
        className={`input-fieldset ${
          !errors["PAN"] &&
          !panExists &&
          finalData?.pan_card &&
          validatePAN(finalData.pan_card)
            ? "valid"
            : (errors["PAN"] || panExists) && validatePAN(finalData.pan_card)
            ? "error"
            : ""
        }`}
      >
        <legend className="input-legend">Business PAN&nbsp;<b>*</b></legend>
        <input
          type="text"
          name="pan_card"
          value={finalData?.pan_card || ""}
          placeholder="Ex:APMPH5429D"
          id="PANInput"
          onChange={handleInputChange}
          onKeyDown={(e) => handleEnterKey(e, "GSTInput")}
          onBlur={handlePANBlur}
          required
          maxLength="10"
          className="input-type-write"
        />
      </fieldset>
      {errors["PAN"] && finalData?.pan_card && (
        <span className="error-message">{errors["PAN"]}</span>
      )}
      {!PANVerify && !errors["PAN"] &&
        finalData?.pan_card &&
        finalData?.pan_card?.length === 10 && (
          <div className="email-verification-container">
            
            <span
              className="email-verification-verify-btn"
              onClick={handleVerifyPAN}
            >
              Verify
            </span>
            <div className="email-verification-text-div">
              <span className="verify-symbol">!</span>
              <span className="email-verification-text">
              Please verify to proceed
              </span>
            </div>
          </div>
        )}
      {PANVerify && (
        <div className="phone-number-verified-check">
          <CheckIcon />
          <span className="phone-number-verified-span">Verified</span>
        </div>
      )}

      {/* <fieldset className="input-fieldset">
        <legend className="input-legend">Name as per PAN</legend>
        <input
          type="text"
          placeholder="John Doe"
          className="input-type-write"
        />
      </fieldset> */}
      {/* {verify && <div className="signup-overlay"></div>} */}
      {PANVerify && (
        <div className="show-aadhar-details-after-verify">
          {/* <DoneIcon /> */}
          <h3 className="show-aadhar-details-after-verify-heading">
            PAN is Valid
          </h3>
          <div className="show-aadhar-details-after-verify-main-container">
            <div className="show-aadhar-details-after-verify-each-attribute">
              <span className="show-aadhar-details-after-verify-each-attribute-key">
                PAN No.
              </span>
              <span className="show-aadhar-details-after-verify-each-attribute-value">
                {finalData?.PAN_DATA?.pan}
              </span>
            </div>
            <div className="show-aadhar-details-after-verify-each-attribute">
              <span className="show-aadhar-details-after-verify-each-attribute-key">
                Name
              </span>
              <span className="show-aadhar-details-after-verify-each-attribute-value">
                {finalData?.PAN_DATA?.registered_name}
              </span>
            </div>
            <div className="show-aadhar-details-after-verify-each-attribute">
              <span className="show-aadhar-details-after-verify-each-attribute-key">
                Reference ID
              </span>
              <span className="show-aadhar-details-after-verify-each-attribute-value">
                {finalData?.PAN_DATA?.reference_id}
              </span>
            </div>
            <div className="show-aadhar-details-after-verify-each-attribute">
              <span className="show-aadhar-details-after-verify-each-attribute-key">
                Valid
              </span>
              <span className="show-aadhar-details-after-verify-each-attribute-value">
                {"True"}
              </span>
            </div>
            <div className="show-aadhar-details-after-verify-each-attribute">
              <span className="show-aadhar-details-after-verify-each-attribute-key">
                Type
              </span>
              <span className="show-aadhar-details-after-verify-each-attribute-value">
                {finalData?.PAN_DATA?.type}
              </span>
            </div>
          </div>
          {/* <span
            className="show-aadhar-details-after-verify-close-btn"
            onClick={handleVerifyPANClose}
          >
            Close
          </span> */}
        </div>
      )}
    </div>
  );
}

export default BusinessPAN;
