import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { API_BASE_URL } from "../../../config";
import aadharLogo from "../../../img/banners/aadhar-logo.png";
import panLogo from "../../../img/banners/pan-card-logo.png";
import gstLogo from "../../../img/banners/gst-logo.png";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import {
  OTPVerified,
  VerifyOTP,
  OTPVerificationModal,
} from "../../../mComponent/OTPVerification";
import toast from "react-hot-toast";

function MobileContactInfo({
  updateFinalData,
  finalData,
  emailVerify,
  setEmailVerify,
  phoneVerify,
  setPhoneVerify,
  tempEmail,
  setTempEmail,
}) {
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const [verify, setVerify] = useState(false);
  const [errors, setErrors] = useState({});
  const [emailExists, setEmailExists] = useState(false);
  const [timer, setTimer] = useState(60);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const startTimer = () => {
    setResendDisabled(true);
    setTimer(60);
  };

  // Effect to decrement timer every second
  useEffect(() => {
    let intervalId;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setResendDisabled(false);
      clearInterval(intervalId);
    }
    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
  }, [timer]);

  // EMAIL VALIDATION
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  // INPUT CHANGE
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updateValue = value.toLowerCase();

    updateFinalData({ [name]: updateValue });

    // Reset emailExists state and clear Email error if name is Email and emailExists is true
    if (name === "Email" && emailExists) {
      setEmailExists(false);
      setErrors((prevErrors) => ({
        ...prevErrors,
        Email: "",
      }));
    }
    if (name === "Email" && value.includes("@")) {
      handleEmailBlur(e);
    }
    if (name === "Email" && value === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        Email: "",
      }));
    }
  };

  // SEND OTP TO VERIFY EMAIL
  const handleEmailVerificationClick = async () => {
    setOTP(["", "", "", "", "", ""]);
    const email = finalData.Email;
    if (!email || !email.includes("@")) {
      toast.error('Please fill email correctly')
    } else {
      handleOpen();
      try {
        const response = await fetch(`${API_BASE_URL}email_auth/${email}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        if (response.ok) {
          setVerify(true);
        } else {
          console.error("API request failed:", response.statusText);
          toast.error('OTP not Sent')
        }
      } catch (error) {
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        toast.error('Please try again later');
      }
      handleClose();
    }
  };

  // SEND OTP AGAIN TO VERIFY EMAIL
  const handleEmailVerificationAgain = async () => {
    const email = finalData.Email;
    if (!email || !email.includes("@")) {
      toast.error('Please fill email correctly')
    } else {
      if (timer > 0) {
        // Timer is still running, do nothing
        return;
      }
      startTimer();
      handleOpen();
      try {
        const response = await fetch(`${API_BASE_URL}email_auth/${email}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        if (response.ok) {
          setVerify(true);
        } else {
          console.error("API request failed:", response.statusText);
          toast.error('OTP not Sent')
        }
      } catch (error) {
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        toast.error('Please try again later');
      }
      handleClose();
    }
  };

  // CHECK EMAIL EXIST IN DATABASE OR NOT
  const handleEmailBlur = async (e) => {
    const email = e.target.value.toLowerCase();
    if (!isValidEmail(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        Email: "Please enter a valid email address",
      }));
      return;
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}singup_email_verify/${email}`
      );
      const data = await response.json();

      if (data.status === "error") {
        setEmailExists(true);
        setErrors((prevErrors) => ({
          ...prevErrors,
          Email: "Email already registered with Cyclecircle",
        }));
      } else {
        setEmailExists(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          Email: "",
        }));
      }
    } catch (error) {
      console.error("Error checking email existence:", error);
    }
  };

  useEffect(() => {
    if (finalData?.Email !== tempEmail) {
      setEmailVerify(false);
    }
  }, [finalData?.Email, tempEmail, setEmailVerify]);

  // VERIFY OTP
  const handleVerify = async () => {
    handleOpen();
    const enteredOTP = otp.join("");
    if (!enteredOTP || enteredOTP.length < 6) {
      toast.error('Enter OTP correctly')
    } else {
      try {
        const response = await fetch(
          `${API_BASE_URL}email_auth_otp/${finalData?.Email}/${enteredOTP}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          setVerify(false);
          setEmailVerify(true);
          setTempEmail(finalData?.Email);
        } else {
          console.error("API request failed:", response.statusText);
          toast.error('Invalid OTP');
        }
      } catch (error) {
        console.error(
          "An error occurred during the API request:",
          error.message
        );
      }
      handleClose();
    }
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    updateFinalData({ ReceiveWhatsApp: checked });
  };

  return (
    <div className="w-full min-h-[72vh] flex flex-col items-start justify-start py-4 px-3">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!emailVerify && (
        <div className="w-full border-2 border-green bg-green/20 rounded-md p-3 flex flex-col">
          <span className="w-full text-start text-16 font-bold text-blue/80">
            To sign up,you'll need these prerequisites.
          </span>
          <div className="w-full flex flex-col p-2">
            <div className="flex items-center justify-start my-1">
              <img loading="lazy"
                src={aadharLogo}
                alt="aadhar-logo"
                className="w-[20px] mr-3"
              />
              <span className="text-14 text-black-1/80 font-semibold">
                Aadhar Card
              </span>
            </div>
            <div className="flex items-center justify-start my-1">
              <img loading="lazy" src={panLogo} alt="pan-logo" className="w-[20px] mr-3" />
              <span className="text-14 text-black-1/80 font-semibold">
                PAN Card
              </span>
            </div>
            <div className="flex items-center justify-start my-1">
              <img loading="lazy" src={gstLogo} alt="gst-logo" className="w-[20px] mr-3" />
              <span className="text-14 text-black-1/80 font-semibold">GST</span>
            </div>
          </div>
        </div>
      )}
      <span className="text-[13px] text-black-1/70 font-semibold my-2">
        We will contact you on the below details for any account-related updates
      </span>
      <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[25px]">
        <TextField
          label="Email"
          variant="outlined"
          id="emailInput"
          placeholder="Enter your email id"
          fullWidth
          name="Email"
          value={finalData?.Email || ""}
          color={isValidEmail(finalData?.Email) && !emailExists && "success"}
          onChange={(e) => {
            const inputValue = e.target.value.replace(/\s+/g, "");
            handleInputChange({
              target: {
                name: e.target.name,
                value: inputValue,
              },
            });
          }}
          onBlur={handleEmailBlur}
          error={(finalData?.Email && errors["Email"])}
          helperText={errors["Email"] || ""}
          required
        />

        {!emailVerify && !emailExists && finalData?.Email && finalData.Email.includes("@") && (
          <VerifyOTP handleVerify={handleEmailVerificationClick} />
        )}
        {emailVerify && <OTPVerified />}
      </div>

      <TextField
        label="Mobile Number"
        id="phoneInput"
        name="PhoneNumber"
        className="mobile-input-type-write"
        variant="outlined"
        fullWidth
        value={finalData?.PhoneNumber || ""}
        InputProps={{
          readOnly: true,
        }}
        required
      />
      {phoneVerify && <OTPVerified />}
      <FormControlLabel
        control={
          <Checkbox
            checked={finalData.ReceiveWhatsApp}
            onChange={handleCheckboxChange}
          />
        }
        className=" text-black-1/70"
        label="Receive updates via WhatsApp"
      />
      {verify && (
        <OTPVerificationModal
          text={finalData?.Email}
          otp={otp}
          setOTP={setOTP}
          setVerify={setVerify}
          resendOTP={handleEmailVerificationAgain}
          handleVerify={handleVerify}
          timer={timer}
          setTimer={setTimer}
          resendDisabled={resendDisabled}
          open={verify}
          onClose={() => setVerify(false)}
        />
      )}
    </div>
  );
}

export default MobileContactInfo;
