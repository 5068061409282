import React, { useEffect, useState } from "react";
import "./Wallet.css";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import cashbackLogo from "../../img/banners/Cashback.png";
import purchaseLogo from "../../img/banners/Purchase.png";
import walletLogo from "../../img/banners/Wallet.png";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useDispatch, useSelector } from "react-redux";
import {
  getWalletDetailAsync,
  selectWalletDetail,
} from "../../features/cartPage/walletSlice";
import { selectUser } from "../../features/auth/authSlice";

function Wallet() {
  const [selectedFilter, setSelectedFilter] = useState("all");
  const dispatch = useDispatch();
  const walletDetail = useSelector(selectWalletDetail);
  const userDetail = useSelector(selectUser);

  useEffect(() => {
    if (userDetail?.UserID) {
      dispatch(getWalletDetailAsync(userDetail?.UserID));
    }
  }, [dispatch, userDetail?.UserID]);

  const [openAcc, setOpenAcc] = useState(null);
  let supportData = [
    {
      index: 1,
      question: "How can I add amount to wallet?",
      answer:
        "The Design Champs is all about accessing more of that amazing content you have grown to love from me in an exclusive member only way. Members also get to connect to a real group of other creative professionals as a support system and mastermind.",
    },
    {
      index: 2,
      question: "How the cashback works?",
      answer:
        "Cashback is a reward mechanism where you receive a percentage of the amount you spend on purchases back into your wallet. The cashback amount is usually credited to your wallet after the completion of a transaction or within a specified period. Terms and conditions may apply, so make sure to check the details of each cashback offer.",
    },
  ];

  const renderTransactions = () => {
    if (walletDetail && walletDetail?.history) {
      let filteredTransactions = walletDetail?.history;
      if (selectedFilter !== "all") {
        filteredTransactions = walletDetail?.history.filter(
          (transaction) => transaction?.transaction_type === selectedFilter
        );
      }
      if (filteredTransactions?.length === 0) {
        return (
          <div className="wallet-recent-transaction-not-available-container">
            <img loading="lazy"
              src={walletLogo}
              alt=""
              className="wallet-recent-transaction-not-available-img"
            />
            <span className="wallet-recent-transaction-head">
              No{" "}
              {selectedFilter === "all"
                ? ""
                : selectedFilter === "debit"
                ? "spent"
                : "received"}{" "}
              transactions yet
            </span>
            <span className="wallet-recent-transaction-detail">
              View your recent transactions here
            </span>
          </div>
        );
      }
      return filteredTransactions?.map((transaction, index) => (
        <div
          key={index}
          className="wallet-recent-transaction-each-transaction-container"
        >
          <span className="wallet-recent-transaction-each-transaction-date">
            {new Date(transaction?.transaction_date).toLocaleDateString(
              "en-US",
              {
                month: "long",
                day: "numeric",
                year: "numeric",
              }
            )}
          </span>
          <div className="wallet-recent-transaction-each-transaction-main-container">
            <span
              className="wallet-recent-transaction-each-transaction-amount"
              style={{
                color:
                  transaction?.transaction_type === "debit"
                    ? "var(--color-peach)"
                    : "var(--color-green)",
              }}
            >
              {transaction?.transaction_type === "debit" ? "-" : "+"}₹
              {transaction?.amount}
            </span>
            <img loading="lazy"
              src={
                transaction?.transaction_type === "debit"
                  ? purchaseLogo
                  : cashbackLogo
              }
              alt=""
              className="wallet-recent-transaction-each-transaction-img"
            />
            <div className="wallet-recent-transaction-each-transaction-details-div">
              <span className="wallet-recent-transaction-details-head">
                {transaction?.transaction_type === "debit"
                  ? "Spent Wallet"
                  : "Received Cashback"}
              </span>
              <span className="wallet-recent-transaction-details-p">
                {transaction?.transaction_type === "debit"
                  ? `Spent wallet on order no ${
                      transaction?.order_no
                    }  on ${new Date(
                      transaction?.transaction_date
                    ).toLocaleDateString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}`
                  : `Received cashback from order no ${
                      transaction?.order_no
                    }  on ${new Date(
                      transaction?.transaction_date
                    ).toLocaleDateString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}`}
              </span>
            </div>
          </div>
        </div>
      ));
    }
    return null;
  };

  return (
    <div className="wallet-container">
      <div className="wallet-heading-container">
        <span className="wallet-heading">My Wallet</span>
      </div>
      <div className="wallet-cashback-purchase-container">
        <div className="wallet-wallet-container">
          <span className="wallet-cashback-name">Wallet Balance</span>
          <span className="wallet-cashback-price">
            ₹
            {walletDetail &&
              walletDetail?.wallet &&
              walletDetail?.wallet[0] &&
              walletDetail?.wallet[0]?.balance}
          </span>
          <img loading="lazy" src={walletLogo} alt="" className="wallet-cashback-img" />
        </div>
      </div>
      <div className="wallet-recent-transaction-container">
        <span className="wallet-recent-transaction-heading">
          Recent Transaction
        </span>
        <div className="wallet-recent-transaction-main-container">
          <div className="wallet-recent-transaction-tabs-container">
            <button
              className={`wallet-recent-transaction-each-tabs ${
                selectedFilter === "all" ? "wallet-tab-active" : ""
              }`}
              onClick={() => setSelectedFilter("all")}
            >
              All
            </button>
            <button
              className={`wallet-recent-transaction-each-tabs ${
                selectedFilter === "credit" ? "wallet-tab-active" : ""
              }`}
              onClick={() => setSelectedFilter("credit")}
            >
              Received
            </button>
            <button
              className={`wallet-recent-transaction-each-tabs ${
                selectedFilter === "debit" ? "wallet-tab-active" : ""
              }`}
              onClick={() => setSelectedFilter("debit")}
            >
              Spent
            </button>
          </div>
          {renderTransactions()}
        </div>
      </div>
      <div className="wallet-information-detail-container">
        <span className="wallet-information-detail-heading">
          Get to know more about Your Wallet
        </span>
        <div className="support-details-container">
          {supportData?.map((item) => (
            <div
              key={item?.index}
              className="support-each-details-container"
              style={{
                boxShadow: "0 0 3px 0 rgba(0,0,0,0.1)",
                margin: "10px 0",
              }}
            >
              <div
                className="support-each-details-header-container"
                onClick={() =>
                  openAcc === null ? setOpenAcc(item?.index) : setOpenAcc(null)
                }
              >
                <span className="support-each-details-header-text">
                  {item?.question}
                </span>
                {openAcc === item?.index ? (
                  <RemoveIcon onClick={() => setOpenAcc(null)} />
                ) : (
                  <AddIcon onClick={() => setOpenAcc(item?.index)} />
                )}
              </div>
              {openAcc === item.index && (
                <div className="support-each-details-main-content">
                  {item?.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Wallet;
