import React from "react";
import "./NoCartItem.css";
import noCartLogo from "../../img/no-cart-item.png";
import { useNavigate } from "react-router";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Button } from "@mui/material";

function NoCartItem({ text }) {
  const navigate = useNavigate();
  const handleAddToCart = () => {
    navigate("/");
  };
  return (
    <div className="w-full min-h-[500px] h-full flex-center flex-col pb-5">
      <LazyLoadImage
        src={noCartLogo}
        alt="no-product-img"
        effect="blur"
        wrapperProps={{
          // If you need to, you can tweak the effect transition using the wrapper style.
          style: { transitionDelay: "1s" },
        }}
        className="w-[300px] object-contain my-3"
      />
      <h3 className="text-[16px] text-black-1/90 my-3">No Items Found!</h3>
      <span className="text-[14px] text-black-1/70 my-3">
        Sorry mate... no items found inside your {text}
      </span>
      <Button
        variant="contained"
        sx={{
          height: "30px",
          background: "purple",
          color: "white",
          borderRadius: "4px",
          textTransform: "none",
          fontSize: "14px",
          fontWeight: "500",
          letterSpacing: "0.5px",
          padding: "5px 15px",
          marginTop:"10px",
          "&:hover": {
            background: "purple",
          },
        }}
        onClick={handleAddToCart}
      >
        Add items To {text}
      </Button>
    </div>
  );
}

export default NoCartItem;
