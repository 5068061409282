import React, { useEffect, useState } from "react";
import MobileSortByFilter from "./MobileSortByFilter";
import MobilePdts from "./MobilePdts";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCategoryProductsAsync,
  fetchAllSubCategoryProductsAsync,
  fetchThroughBrandAsync,
  selectAllSubCategories,
  setSubCategories,
} from "../../features/listingPage/listingPageSlice";
import {
  selectedSelectedSubcategory,
  setSelectedSubcategory,
} from "../../store/slices/subCategorySlice";
import { setBreadcrumb } from "../../store/slices/breadCrumbsSlice";
import RelatedSearch from "../../mComponent/RelatedSearch";
import CtgProductCount from "../../mComponent/CtgProductCount";

function MobileListingPage() {
  const dispatch = useDispatch();
  const { CategoryID, categoryName } = useParams();
  const subCategories = useSelector(selectAllSubCategories);
  const selectedSubcategory = useSelector(selectedSelectedSubcategory);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const brandname = useParams(); // Fetch brand name from URL

  useEffect(() => {
    const fetchData = async () => {
      if (brandname?.brand) {
        handleOpen();
        await dispatch(fetchThroughBrandAsync(brandname.brand));
        await dispatch(setSubCategories([]));
        await dispatch(setSelectedSubcategory(null));
        await dispatch(
          setBreadcrumb({
            categoryID: null,
            categoryName: brandname.brand,
            subCategoryID: null,
            subCategoryName: "",
            productName: "",
            ProductID: null,
          })
        );
      } else if (
        selectedSubcategory ||
        (subCategories && subCategories?.length > 0)
      ) {
        handleOpen();
        const isSubCategorySelected =
          subCategories &&
          subCategories?.some((item) => item.sc_id === selectedSubcategory);

        if (isSubCategorySelected) {
          await dispatch(fetchAllSubCategoryProductsAsync(selectedSubcategory));
        } else {
          await dispatch(fetchAllCategoryProductsAsync(CategoryID));
        }
      } else {
        handleOpen();
        
        await dispatch(fetchAllCategoryProductsAsync(CategoryID));
      }
      handleClose();
      
    };
    fetchData();
    return () => clearTimeout(2000);
  }, [
    dispatch,
    CategoryID,
    categoryName,
    brandname.brand,
    selectedSubcategory,
  ]);

  const [currentPage, setCurrentPage] = useState(1); // SETUP FOR PAGINATION
  return (
    <div className="w-full flex flex-col relative">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CtgProductCount categoryName={categoryName} />
      <div className="w-full sticky top-[58px] z-990 bg-[#ffffff]">
        <MobileSortByFilter
          categoryName={categoryName}
          CategoryID={CategoryID}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <div className="w-full min-h-[50vh] relative">
        <MobilePdts currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </div>
      <RelatedSearch />
    </div>
  );
}

export default MobileListingPage;
