// mobileSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isMobile:false
}

const mobileSlice = createSlice({
  name: 'mobile',
  initialState,
  reducers: {
    setMobileView: (state) => {
      state.isMobile = !state.isMobile;
    },
  },
});

export const { setMobileView } = mobileSlice.actions;
export const selectMobileView = (state) => state.isMobile;

export default mobileSlice.reducer;
