import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { IMAGE_BASE } from "../../config";
import {
  fetchAllUsersRoleAsync,
  selectAllUsersRole,
  updateUserRoleAsync,
} from "../../features/cartPage/cartPageSlice";
import SearchIcon from "@mui/icons-material/Search";
import NoCartItem from "../Carts/NoCartItem";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
} from "@mui/material";

function AdminToggle() {
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const userRole = useSelector(selectAllUsersRole);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUserRole, setFilteredUserRole] = useState(userRole);
  const [countArray, setCountArray] = useState({
    count: 0,
    admin: 0,
  });

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    handleOpen();
    if (userDetail?.UserID)
      dispatch(fetchAllUsersRoleAsync()).then(() => {
        handleClose();
      });
  }, [dispatch, userDetail?.UserID]);

  useEffect(() => {
    if (userRole) {
      setFilteredUserRole(
        userRole?.filter(
          (user) =>
            user?.FirstName.toLowerCase()?.includes(
              searchQuery?.toLowerCase()
            ) ||
            user?.LastName.toLowerCase()?.includes(
              searchQuery?.toLowerCase()
            ) ||
            user?.PhoneNumber?.includes(searchQuery)
        )
      );
    }
  }, [searchQuery, userRole]);

  useEffect(() => {
    let cnt = 0;
    let adm = 0;
    for (const user of userRole) {
      cnt++;
      if (user?.UserType === "ADMIN") adm++;
    }
    setCountArray({
      count: cnt,
      admin: adm,
    });
  }, [userRole]);

  const handleToggleRole = (UserID) => {
    handleOpen();
    dispatch(updateUserRoleAsync(UserID))
      .then(() => {
        dispatch(fetchAllUsersRoleAsync());
      })
      .then(() => {
        handleClose();
      });
  };

  return (
    <div className="w-full h-screen relative overflow-auto">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className="w-full h-14 bg-white-1 flex items-center justify-between sticky top-0 right-0 py-1 px-5
       shadow-md z-10 opacity-90 max-sm:hidden"
      >
        <span className="text-lg font-bold tracking-wider text-gray-800">
          Switch to Admin
        </span>
        <img
          loading="lazy"
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
            userDetail?.UserImage || ""
          }`}
          alt={userDetail?.UserImage || ""}
          className="w-10 h-10 rounded-full border border-gray-300"
        />
      </div>
      <div className="w-full">
        <div className="w-full flex max-sm:flex-col items-center justify-center sm:justify-between">
          <div className="w-full sm:w-[200px] h-[90px] flex flex-col justify-between sm:mt-10 sm:ml-5 bg-white-1 rounded-md shadow-sm shadow-black-1/20 px-3 py-4">
            <span className="text-[16px] text-[#c1bebe]">Total Users</span>
            <span className="flex items-center text-[20px] text-green">
              {filteredUserRole && Array.isArray(filteredUserRole) && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      color: "rgba(0,0,0,0.6",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "rgba(0,0,0,0.5)",
                      }}
                    >
                      User:
                    </b>{" "}
                    {countArray?.count}
                  </span>
                  <b style={{ margin: "0 5px", color: "rgba(0,0,0,0.3)" }}>|</b>
                  <span
                    style={{
                      color: "rgba(0,0,0,0.6",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "rgba(0,0,0,0.5)",
                      }}
                    >
                      Admin:
                    </b>{" "}
                    {countArray?.admin}
                  </span>
                </div>
              )}
            </span>
          </div>
          <div className="w-full sm:w-[250px] h-[45px] sm:h-[35px] flex items-center bg-white-1 rounded-full shadow-sm shadow-black-1/20 px-2 max-sm:mt-5 sm:mr-5">
            <SearchIcon
              className="text-black-1/70"
              style={{ fontSize: "22px" }}
            />
            <input
              type="text"
              placeholder="Search by name or phone"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full h-full border-none outline-none p-0 pl-1 rounded-full text-[14px] tracking-wide text-black-1/80"
            />
          </div>
        </div>
        {filteredUserRole && Array.isArray(filteredUserRole) ? (
          <div className="w-full overflow-x-auto pt-[100px] sm:px-5">
            <h2 className="text-[16px] tracking-wide p-3 text-black-1/80 bg-white-1 rounded-t-md">
              All Users
            </h2>
            <TableContainer component={Paper} className="shadow-md">
              <Table sx={{ minWidth: 650 }} aria-label="user roles table">
                <TableHead>
                  <TableRow className="bg-gray-100">
                    <TableCell
                      align="center"
                      className="text-center font-bold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      S.No
                    </TableCell>
                    <TableCell
                      align="center"
                      className="text-center font-bold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      User Name
                    </TableCell>
                    <TableCell
                      align="center"
                      className="text-center font-bold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Phone Number
                    </TableCell>
                    <TableCell
                      align="center"
                      className="text-center font-bold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Business Name
                    </TableCell>
                    <TableCell
                      align="center"
                      className="text-center font-bold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Dealer Type
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUserRole?.map((user, index) => (
                    <TableRow key={index} className="hover:bg-gray-50">
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {user?.FirstName} {user?.LastName}
                      </TableCell>
                      <TableCell align="center">{user?.PhoneNumber}</TableCell>
                      <TableCell align="center">
                        {user?.business_name || "N/A"}
                      </TableCell>
                      <TableCell align="center">
                        <div className="flex items-center justify-center">
                          <b
                            className={`mr-2 ${
                              user?.UserType === "CUSTOMER"
                                ? "text-green"
                                : "text-gray-500"
                            }`}
                          >
                            Customer
                          </b>
                          <Switch
                            checked={user?.UserType === "ADMIN"}
                            onChange={() => handleToggleRole(user?.UserID)}
                            sx={{
                              "& .MuiSwitch-switchBase.Mui-checked": {
                                color: "#283248",
                              },
                              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                {
                                  backgroundColor: "#283248",
                                },
                            }}
                          />
                          <b
                            className={`ml-2 ${
                              user?.UserType === "ADMIN"
                                ? "text-blue"
                                : "text-gray-500"
                            }`}
                          >
                            Admin
                          </b>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <NoCartItem text="User" />
        )}
      </div>
    </div>
  );
}

export default AdminToggle;
