import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllWishlists,
  addToWishlists,
  removeFromWishlists,
  removeAllFromWishlists,
} from "./wishlistAPI";

const initialState = {
  wishlists: [],
  status: "idle",
};

export const fetchAllWishlistsAsync = createAsyncThunk(
  "wishlist/fetchAllWishlists",
  async (UserID) => {
    const response = await fetchAllWishlists(UserID);

    return response?.data;
  }
);

export const addToWishlistsAsync = createAsyncThunk(
  "wishlist/addToWishlist",
  async (wishlistData) => {
    const response = await addToWishlists(wishlistData);
    return response;
  }
);
export const removeFromWishlistAsync = createAsyncThunk(
  "wishlist/removeFromWishlists",
  async (wishlistData) => {
    const response = await removeFromWishlists(wishlistData);
    return response;
  }
);

export const removeAllFromWishlistAsync = createAsyncThunk(
  "wishlist/removeAllFromWishlists",
  async (UserID) => {
    const response = await removeAllFromWishlists(UserID);
    return response;
  }
);

export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllWishlistsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllWishlistsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.wishlists = action.payload;
      })
      .addCase(addToWishlistsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addToWishlistsAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(removeFromWishlistAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeFromWishlistAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(removeAllFromWishlistAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeAllFromWishlistAsync.fulfilled, (state, action) => {
        state.status = "idle";
      });
  },
});

export const selectAllWishlists = (state) => state.wishlist.wishlists;

export default wishlistSlice.reducer;
