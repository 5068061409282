import React from "react";
import "./UserAgreement.css";
import styled from "styled-components";
import Header from "../HomePage/Header";
import Footer from "../HomePage/Footer";
import ScrollPromices from "../HomePage/ScrollPromices";
import { Link } from "react-router-dom";

export function UserAgreement() {
  return (
    <>
      <HeaderDiv>
        <Header />
      </HeaderDiv>
      <ScrollPromices />
      <div className="user-agreement-container">
        <h2>USER AGREEMENT</h2>
        <p>
          Thank you for using CycleCircle! By accessing or signing up to the
          website and/or services, you agree to be bound by this User Agreement
          along with its services (collectively, the &quot;User
          Agreement&quot;), and to review and accept our Privacy Policy. Please
          read them carefully. You should review the Privacy Policy and User
          Agreement periodically for any modifications or changes.
        </p>
        <p>
          The terms ‘visitor(s)’, ‘User(s)’, ‘you’, ‘your’ hereunder refer to
          the person visiting, accessing, browsing through and/or using the
          Website at any point in time.
        </p>
        <p>
          If you do not agree to be bound by this User Agreement you must not
          access or use our website and/or services. The website by the domain
          name<Link to="/"> www.cyclecircle.one</Link> along with all services
          provided in the form and manner of mobile app, mobile site etc.
          (collectively termed as “Platform” or “Website”) are owned and managed
          by CycleCircle.
        </p>
        <p style={{ fontWeight: "600", color: "black" }}>
          This document is an electronic record in terms of Information
          Technology Act, 2000 and rules there under as applicable and amended
          from time to time. This electronic record is generated by a computer
          system and does not require any physical or digital signatures. This
          document is published in accordance with the provisions of Rule 3 of
          the Information Technology (Intermediary Guidelines and Digital Media
          Ethics Code) Rules, 2021. This User Agreement may be updated from time
          to time by CycleCircle without notice. It is therefore strongly
          recommended that you review the User Agreement, as available on the
          Website, each time you access and/or use the Website.
        </p>
        <h2>1. APPLICABILITY</h2>
        <p>
          The Website is operated and managed by Radkaat Ventures Private
          Limited, along with the products and/or services offered or made
          available on the Website (hereinafter “CycleCircle” or “We” ). Any
          person (&quot;User&quot;) who inquiries about or purchases any
          products or services of CycleCircle through its websites, sales
          persons, offices, call centers, agents etc. (all the aforesaid
          platforms collectively referred to as “Sales Channels”) agree to be
          governed by this User Agreement.
        </p>
        <p>
          Both User and CycleCircle are individually referred to as
          &#39;Party&#39; and collectively referred to as &#39;Parties&#39; to
          the User Agreement.
        </p>
        <h2>2. ELIGIBILITY TO USE</h2>
        <p>
          The User must possess the legal authority to enter into an agreement
          so as become a User and use the services of CycleCircle. Before using
          the Website, approaching any Sales Channels or procuring the services
          of CycleCircle, the Users shall compulsorily read and understand this
          User Agreement, and shall be deemed to have accepted this User
          Agreement as a binding document that governs User’s dealings and
          transactions with CycleCircle. 
          <b style={{ color: "black" }}>
            If the User does not agree with any part of this Agreement, then the
            User must not avail CycleCircle&#39;s services and must not access
            or approach the Sales Channels of CycleCircle.
          </b>
           Services on the Website are available and targeted for India,
          services in certain geographies in India are subject to restrictions
          based on business hours and days of third-party sellers. CycleCircle’s
          services can only be availed by those individuals or business
          entities, including sole proprietorship firms, companies and
          partnerships, which are authorised under applicable law to form
          legally binding agreements. As such, natural persons below 18 years of
          age and business entities or organisations that are not authorised by
          law to operate in India or other countries are not authorised to avail
          or use CycleCircle’s services. User(s) further agree and consent to be
          contacted by CycleCircle through phone calls, SMS notifications or any
          other means of communication, in respect to the services provided by
          CycleCircle even if contact number(s) provided to CycleCircle upon
          registration are on “Do Not Call Registry”.
        </p>
        <p>
          If you are registering as or on behalf of a business entity, you
          represent that you are duly authorized by the business entity to
          accept this User Agreement and you have the authority to bind that
          business entity to this User Agreement. For services been availed by
          business entity(ies) it is mandatory to provide GSTIN and/or business
          entity details, otherwise it will be presumed that it is a personal
          purchase and not a business purchase.
        </p>
        <p>
          User(s) agree to abide by the User Agreement and any other rules and
          regulations imposed on the User under the applicable law from time to
          time. CycleCircle shall have no liability to the User(s) or anyone
          else, for any content, information or any other material transmitted
          over the Website, including any fraudulent, untrue, misleading,
          inaccurate, defamatory, offensive or illicit material and that the
          risk of damage from such material rests entirely with each User(s).
          The User shall do its own due diligence before entering into any
          transaction with other users on the Website. CycleCircle at its sole
          discretion reserves the right to refuse CycleCircle services to anyone
          at any time without assigning reasons thereof. CycleCircle
          services/Website are not available and may not be availed or used by
          User(s) whose accounts have been temporarily or indefinitely suspended
          by CycleCircle.
        </p>
        <p>
          All rights and liabilities of the User and CycleCircle with respect to
          any services or product facilitated by CycleCircle shall be restricted
          to the scope of this User Agreement.
        </p>
        <h2>3. ACCEPTANCE</h2>
        <p>
          By signing up or by using Website or the Sales Channel in any way, you
          consent to, and agree to comply with all the terms in this User
          Agreement.
        </p>
        <h2>4. CONTENT</h2>
        <p>
          Except as otherwise expressly permitted in this User Agreement, you
          agree that you will not give access to, forward, display, post or
          distribute any part of the content to any individuals, persons or
          companies. You agree that you will not use the Website to forward,
          display, post or distribute any part of the content or the suggestions
          for improvement to any public website including but not limited to
          YouTube, Facebook, Twitter or similar websites.
        </p>
        <p>
          Some content displayed on the Website is provided or posted by third
          parties. User(s) can post their content on some of the
          sections/services of the Website using the self- submit and edit tools
          made at the respective User dashboard of the Website. User(s) may need
          to register and/or pay for using or availing some of these services.
          User(s) be solely responsible for compliance of all applicable laws
          including those in India for making payments.
        </p>
        <p>
          User(s) understand and agree that in such case CycleCircle is not the
          author of the content and that neither CycleCircle nor any of its
          affiliates, directors, officers or employees have entered into any
          arrangement including any agreement of sale or agency with such third
          parties by virtue of the display of such content on the Website.
          User(s) further understand and agree CycleCircle is not responsible
          for the accuracy, propriety, lawfulness or truthfulness of any
          third-party content made available on the Website and shall not be
          liable to any User(s) in connection with any damage suffered by the
          User(s) on account of the User(s)’s reliance on such content.
          CycleCircle shall not be liable for a User(s) activity on the Website,
          and shall not be liable to any person in connection with any damage
          suffered by any person as a result of any User&#39;s conduct.
        </p>
        <p>User(s) solely represent, warrant and agree to:</p>
        <p>
          (a) provide CycleCircle with true, accurate, current and complete
          information to be displayed on the Website;
        </p>
        <p>
          (b) maintain and promptly amend all information provided on the
          Website to keep it true, accurate, current and complete.
        </p>
        <p>
          You agree that if You provide any information that is untrue,
          inaccurate, not current or incomplete or CycleCircle has reasonable
          grounds to suspect that such information is untrue, inaccurate, not
          current or incomplete, or not in accordance with this User Agreement.
          CycleCircle shall have the right to indefinitely suspend or terminate
          or block access of your membership to the Website and refuse to
          provide You with access to the Website.
        </p>
        <p>
          User(s) hereby grant CycleCircle an irrevocable, perpetual, worldwide
          and royalty-free, sub- licensable (through multiple tiers) license to
          display and use all information provided by them in accordance with
          the purposes set forth in the User Agreement and to exercise the
          copyright, publicity and database rights User(s) have in such material
          or information, in any form of media, third party copyrights,
          trademarks, trade secret rights, patents and other personal or
          proprietary rights affecting or relating to material or information
          displayed on the Website, including but not limited to rights of
          personality and rights of privacy, or affecting or relating to
          products that are offered or displayed on the Website (hereafter
          referred to as &quot;
          <b style={{ color: "black" }}>Third Party Rights</b>&quot;).
        </p>
        <p>
          User(s) hereby represent, warrant and agree that User(s) shall be
          solely responsible for ensuring that any material or information
          posted or otherwise shared/authorised by the User(s) to CycleCircle to
          be made available or displayed on the Website, does not, and that the
          products represented thereby do not, violate any Third Party Rights,
          or is posted with the permission of the owner(s) of such Third Party
          Rights. User(s) hereby represent, warrant and agree that they have the
          right to manufacture, offer, sell, import, export and distribute the
          products offered and displayed on the Website, and that such
          manufacture, offer, sale, importation, exportation and/or distribution
          of those products violates no Third Party Rights.
        </p>
        <p>
          User(s) agree that they will not use Website and/or its services to
          send junk mail, chain letters, pyramid scheme or spamming. Further,
          User(s) of the Website agree that they will not use the email accounts
          to publish, distribute, transmit or circulate any unsolicited
          advertising or promotional information. User(s) further hereby
          represent, warrant and agree (i) to host, display, upload, modify,
          publish, transmit, store, update or share; or (ii) submit to
          CycleCircle for display on the Website or transmit or sought to be
          transmitted through the Website any content, material or information
          that does not and shall at no point:
        </p>
        <ul>
          <li>
            Contain fraudulent information or make fraudulent offers of items or
            involve the sale or attempted sale of counterfeit or stolen items or
            items whose sales and/or marketing is prohibited by applicable law,
            or otherwise promote other illegal activities;
          </li>
          <li>
            Belong to another person and to which User(s) do not have any right
            to;
          </li>
          <li>
            Be part of a scheme to defraud other User(s) of the Website or for
            any other unlawful purpose;
          </li>
          <li>
            Be intended to deceive or mislead the addressee about the origin of
            such messages or knowingly and intentionally is used to communicate
            any information which (i) is patently false or grossly offensive or
            menacing/misleading in nature but may reasonably be perceived as a
            fact; or (ii) harass a person, entity or agency for financial gain
            or to cause any injury to any person;
          </li>
          <li>
            Relate to sale of products or services that infringe or otherwise
            abet or encourage the infringement or violation of any third
            party&#39;s copyright, patent, trademarks, trade secret or other
            proprietary right or rights of publicity or privacy, or any other
            Third Party Rights;
          </li>
          <li>
            Violate any applicable law, statute, ordinance or regulation
            (including without limitation those governing export control,
            consumer protection, unfair competition, anti- discrimination or
            false advertising);
          </li>
          <li>
            Be defamatory, abusive libellous, unlawfully threatening, unlawfully
            harassing, grossly harmful, indecent, seditious, blasphemous,
            paedophilic, hateful, invasive of another’s privacy, including
            bodily privacy racially, ethnically objectionable, disparaging,
            relating or encouraging money laundering or gambling, leading to
            breach of confidence, or otherwise unlawful or objectionable in any
            manner whatever;
          </li>
          <li>
            Be vulgar, obscene or contain or infer any pornography or
            sex-related merchandising or any other content or otherwise promotes
            sexually explicit materials or is otherwise harmful to minors;
          </li>
          <li>
            Promote discrimination based on race, sex, religion, nationality,
            disability, sexual orientation or age;
          </li>
          <li>
            Contain any material that constitutes unauthorized advertising or
            harassment (including but not limited to spamming), invades
            anyone&#39;s privacy or encourages conduct that would constitute a
            criminal offense, give rise to civil liability, or otherwise violate
            any law or regulation;
          </li>
          <li>
            Solicit business from any User(s) in connection with a commercial
            activity that competes with CycleCircle;
          </li>
          <li>
            Threaten the unity, integrity, defence, security or sovereignty of
            India, friendly relations with foreign states, or public order or
            causes incitement to the commission of any cognisable offence or
            prevents investigation of any offence or is insulting any other
            nation;
          </li>
          <li>
            Contain any computer viruses or other destructive devices and codes
            that have the effect of damaging, interfering with, intercepting or
            expropriating any software or hardware system, data or personal
            information or that are designed to interrupt, destroy or limit the
            functionality of any computer resource;
          </li>
          <li>
            Impersonates any person or entity, including, but not limited to, a
            CycleCircle employee, or falsely states or otherwise misrepresents
            your affiliation with a person or entity; and
          </li>
          <li>
            Link directly or indirectly to or include descriptions of goods or
            services that are prohibited under the prevailing law; or otherwise
            create any liability for CycleCircle.
          </li>
        </ul>
        <h2>5. SECURITY AND ACCOUNT RELATED INFORMATION</h2>
        <p>
          While registering on the Website, the User will have to choose a
          password to access that User’s account and User shall be solely
          responsible for maintaining the confidentiality of both the password
          and the account as well as for all activities on the account. It is
          the duty of the User to notify CycleCircle immediately in writing of
          any unauthorized use of their password or account or any other breach
          of security. CycleCircle will not be liable for any loss that may be
          incurred by the User as a result of unauthorized use of the password
          or account, either with or without the User’s knowledge. The User
          shall not use anyone else&#39;s account at any time.
        </p>
        <p>
          Your mobile phone number and/or e-mail address is treated as your
          primary identifier on the Website, it is your responsibility to ensure
          that your mobile phone number and your email address is up to date on
          the Website at all times. You agree to notify CycleCircle promptly if
          your mobile phone number or e-mail address changes by updating the
          same on the Website.
        </p>
        <h2>6. INTELLECTUAL PROPERTY</h2>
        <p>
          CycleCircle is the sole owner and the lawful licensee of all the
          rights to the Website and its content (&quot;
          <b style={{ color: "black" }}>Website Content</b>&quot;). “Website
          Content” means the manner in which the design, layout, text, images,
          graphics, sound, video etc. are made available on the Website. The
          Website Content embodies trade secrets and other intellectual property
          rights protected under worldwide copyright and other applicable laws
          pertaining to intellectual property. All title, ownership and
          intellectual property rights on the Website and the Website Content
          shall remain in CycleCircle, or licensors of the Website Content, as
          the case may be.
        </p>
        <p>
          All rights, not otherwise claimed under this User Agreement by
          CycleCircle, are hereby reserved. Any information or advertisements
          contained on, distributed through, or linked, downloaded or accessed
          from any of the services contained on the Website or any offer
          displayed on or in connection with any service offered on the
          Website (&quot;<b style={{ color: "black" }}>Website Information</b>
          &quot;) is intended, solely to provide general information for the use
          of the User(s), who fully accept any and all responsibility and
          liabilities arising from and out of the use of such Website
          Information. CycleCircle does not represent, warrant or endorse in any
          manner the accuracy or reliability of Website Information, or the
          quality of any products obtained by the User(s) as a result of any
          Website Information.
        </p>
        <p>
          The Website Information is provided “as is” with no guarantee of
          completeness, accuracy, timeliness or of the results obtained from the
          use of the Website Information, and without warranty of any kind,
          express or implied, including, but not limited to warranties of
          performance, merchantability and fitness for a particular purpose.
          Nothing contained in the User Agreement shall to any extent substitute
          for the independent investigations and the sound technical and
          business judgment of the User(s). In no event shall CycleCircle be
          liable for any direct, indirect, incidental, punitive, or
          consequential damages of any kind whatsoever with respect to contents
          of the Website. User(s) hereby acknowledge that any reliance upon the
          Website Information shall be at their sole risk and further understand
          and acknowledge that the Website Information has been compiled from
          publicly aired and published sources. CycleCircle respects the rights
          of such entities and cannot be deemed to be infringing on the
          respective copyrights or businesses of such entities. CycleCircle
          reserves the right, in its sole discretion and without any obligation,
          to make improvements to, or correct any error or omissions in any
          portion of the Website
        </p>
        <h2>7. TRADEMARK</h2>
        <p>
          &quot;CycleCircle&quot; and related icons and logos are registered
          trademarks or trademarks or service marks of CycleCircle in various
          jurisdictions and are protected under applicable copyright, trademark
          and other proprietary and intellectual property rights laws. The
          unauthorized adoption copying, modification, use or publication of
          these marks is strictly prohibited.
        </p>
        <h2>8. COPYRIGHT</h2>
        <p>
          All Website Content including Website Information is copyrighted to
          CycleCircle excluding any third-party content and any links to any
          third-party websites being made available or contained on the Website.
          User(s) may not use any trademark, service mark or logo of any
          independent third parties without prior written approval from such
          parties.
        </p>
        <p>
          User(s) acknowledge and agree that CycleCircle is not an arbiter or
          judge of disputes concerning intellectual property rights and as such
          cannot verify that User(s) selling or supplying merchandise or
          providing services on the Website have the right to sell the
          merchandise or provide the services offered by such User(s).
          CycleCircle encourages User(s) to assist CycleCircle in identifying
          listings on the Website which in the User(s) knowledge or belief
          infringe their rights. User(s) further acknowledge and agree by taking
          down a listing, IIL does not and cannot be deemed to be endorsing a
          claim of infringement and further that in those instances in which
          CycleCircle declines to take down a listing, CycleCircle does not and
          cannot be deemed to be endorsing that the listing is not infringing of
          Third Party Rights or endorsing any sale or supply of merchandise or
          services pursuant to or on account of such listing.
        </p>

        <p>
          CycleCircle respects the intellectual property rights of others, and
          we expect our User(s) to do the same. User(s) agree to not copy,
          download or reproduce the Website Content, Website Information or any
          other material, text, images, video clips, directories, files,
          databases or listings available on or through the Website
          (&quot;CycleCircle Content&quot;) for the purpose of re-selling or re-
          distributing, mass mailing (via email, wireless text messages,
          physical mail or otherwise) operating a business competing with
          CycleCircle, or otherwise commercially exploiting the CycleCircle
          Content. Systematic retrieval of CycleCircle Content to create or
          compile, directly or indirectly, a collection, compilation, database
          or directory (whether through robots, spiders, automatic devices or
          manual processes) without written permission from CycleCircle is
          prohibited.
        </p>
        <p>
          In addition, use of the CycleCircle Content for any purpose not
          expressly permitted in this User Agreement is prohibited and entitles
          CycleCircle to initiate appropriate legal action. User(s) agree that
          as a condition of their access to and use of CycleCircle’s services,
          they will not use CycleCircle’s services to infringe the intellectual
          property rights of any third parties in any way. CycleCircle reserves
          the right to terminate the right of any User(s) to access or use
          CycleCircle’s services for any infringement of the rights of third
          parties in conjunction with use of the CycleCircle’s service, or in
          the event CycleCircle is of the view that User(s) conduct is
          prejudicial to the interests of CycleCircle, its affiliates, or other
          User(s), or for any other reason, at CycleCircle’s sole discretion,
          with or without cause.
        </p>
        <p>
          If you notice any act of infringement on the Website, you are
          requested to send us a written notice/ intimation in the following
          manner at <Link to="#">spokes@CycleCircle.com</Link>:
        </p>
        <div className="user-agrement-notice-infringement">
          <div>
            <h2>Notice of Infringement("Notice")</h2>
            <h3>Part I</h3>
          </div>
          <p>To</p> <p>CycleCircle</p>
          <p
            style={{
              color: "black",
              fontWeight: "600",
              textDecoration: "underline",
              fontSize: "1rem",
            }}
          >
            Subject: Notice of trademark/copyright infringement of [Brand Name]
          </p>
          <p>
            I, [name] ____________________________ of [address]
            _________________________ do solemnly and sincerely declare as
            follows:
          </p>
          <ol>
            <li>
              I am the owner/authorised representative of the intellectual
              property right, namely __________________ ("IP Owner");
            </li>
            <li>
              I have a good faith belief that the item listings or materials
              identified in Part II to the Notice hereto are not authorised by
              the above IP Owner, its agent, or the law and therefore infringe
              the IP Owner's rights;
            </li>
            <li>
              All the information provided by me in this Notice is accurate and
              correct;
            </li>
            <li>
              I will be liable for appropriate civil and criminal proceedings if
              any of the information found in this notice is false and
              frivolous; and
            </li>
            <li>
              I shall indemnify CycleCircle and its
              directors/officers/employees/agents against any
              loss/actions/claims including but not limited to cost of any legal
              disputes, in connection with or arising out of any false and
              frivolous documentation provided by me.
            </li>
          </ol>
          <p>I may be contacted at:</p>
          <ul>
            <li style={{ marginTop: "10px" }}>
              Name & Title of Authorised Person:
            </li>
            <li>Name of the Company:</li>
            <li>Address:</li>
            <li>Email (correspondence):</li>
            <li>Telephone/Fax:</li>
          </ul>
          <p>
            Request you to expeditiously remove or disable access to the
            material or products claimed to be infringing.
          </p>
          <p>For and on behalf of ______________________________</p>
          <p>______________________________</p>
          <p>[Name of authorised signatory]</p>
          <p>Date:</p>
          <p>Place:</p>
          <h3 style={{ width: "100%", textAlign: "center" }}>Part II</h3>
          <p>List of Allegedly Infringing Listings, Products, or Materials</p>
          <table>
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>Reason Code*</th>
                <th>URLs</th>
                <th>Material/ Product Name/ Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {/* Add more rows as needed */}
            </tbody>
          </table>
          <p>
            <b>*Reason Codes:</b> When identifying item numbers please use the
            reasons below. When removing products from the site, CycleCircle
            will inform Sellers of the specific reason for the removal of their
            products. Please associate each item you report with only one reason
            code.
          </p>
          <h4>Trademark-infringement</h4>
          <p>
            1.Trademark owner doesn't make this type of product or has
            discontinued the production of the product
          </p>
          <p>
            2.Item(s) is an unlawful replica of a product made by the trademark
            owner or is counterfeit
          </p>
          <h4>Trademark-listing description infringement</h4>
          <p>
            1.Listing(s) has unlawful comparison to trademark owner's brand or
            product
          </p>
          <p>2.Listing(s) contains unlawful use of trademark owner's logo</p>
          <h4>Copyright-item infringement</h4>
          <p>1.Item(s) is an unlawful copy (software, games, movies, etc.);</p>
          <p>2.Item(s) is unlawful duplication of printed material</p>
          <p>
            3.Item(s) is an unlawful copy of other copyrighted work (paintings,
            sculptures, etc.)
          </p>
          <h4>Copyright-listing content infringement</h4>
          <p>1.Listing(s) comprises unauthorized copy of copyrighted text</p>
          <p>2.Listing(s) comprises unauthorized copy of copyrighted image</p>
          <p>
            3.Listing(s) comprises unauthorized copy of copyrighted image and
            text
          </p>
          <h4 style={{ textDecoration: "underline" }}>Note</h4>
          <p>
            1.Please provide the Trademark Registration Certificate/ Copyright
            Registration Certificate (to be in the name of applicant);
          </p>
          <p>2.Please provide the evidence as to the ownership of copyright.</p>
          <p>
            In order to provide adequate redressal for each right holder we
            strongly suggest you share your intellectual property rights
            complaints only in the abovesaid manner.
          </p>
        </div>
        <h2>9. TRANSACTION AND COMMUNICATION</h2>
        <p>
          The Website is a hosting platform that User(s) utilize to meet and
          interact with one another for their transactions. CycleCircle is not
          and cannot be a party to or control in any manner any transaction
          between the Website's User(s).
        </p>
        <p>Henceforward:</p>
        <p>All commercial/contractual terms are offered by and agreed to between the Users i.e buyer and seller alone. The commercial/contractual terms include without limitation price, shipping costs, payment methods, payment terms, date, period and mode of delivery, warranties related to products and after sales services related to products. CycleCircle does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between the buyers and sellers. All discounts, offers (including exchange offers) are by the seller/respective brand and not by CycleCircle.</p>
        <p>CycleCircle does not make any representation or Warranty as to specifics (such as quality, value, saleability, etc) of the products or services proposed to be sold or offered to be sold or purchased on the Website. CycleCircle does not implicitly or explicitly support or endorse the sale or purchase of any products or services on the Website. CycleCircle accepts no liability for any errors or omissions, whether on behalf of itself or third parties.</p>
        <p>CycleCircle in no manner of whatsoever nature, is responsible for any non-performance or breach of any contract (directly or indirectly) entered into between buyers/Users’ and sellers. CycleCircle cannot and does not guarantee that the concerned buyers/Users’ and/or sellers will perform any transaction concluded on the Website. CycleCircle shall not and is not required to mediate or resolve any dispute or disagreement between Buyers and Sellers.</p>
        <p>CycleCircle does not make any representation or warranty as to the item-specifics (such as legal title, creditworthiness, identity, etc) of any of its Users. You are advised to independently verify the bona fides of any particular User that You choose to deal with on the Platform and use Your best judgment in that behalf.</p>
        <p>CycleCircle does not at any point of time during any transaction between buyer and seller on the Website come into or take possession of any of the products or services offered by seller nor does it at any point gain title to or have any rights or claims over the products or services offered by seller to buyer.</p>
        <p>At no time shall CycleCircle hold any right, title or interest over the products nor shall CycleCircle have any obligations or liabilities in respect of such contract entered into between buyers and sellers.</p>
        <h2>10. DELIVERY</h2>
        <p>CycleCircle is not responsible for (a) unsatisfactory or delayed performance of services; (b) damages or delays as a result of products which are out of stock; (c) unavailable or back ordered: and (d) non-delivery or loss related to non-delivery. Information on delivery periods is always non-binding. Significant, unforeseeable operational disruptions, delivery delays or delivery failures of the products for which CycleCircle is not responsible, as well as operational interruptions due to shortages (in particular shortages of raw materials, energy or labor), strikes, lockouts, traffic disruptions, official control measures, official requirements and cases of force majeure (in particular war, epidemics, pandemics, etc.) shall postpone the delivery date by the duration of the impediment to performance. CycleCircle may, under unforeseeable circumstances, be required to change the method of delivery or alter the time of delivery for reasons beyond its control. CycleCircle shall not be held liable for any direct or indirect damages arising from changes in the method or time of delivery caused by unenforceable reasons. User (s) understand and acknowledge that such circumstances are beyond the control of CycleCircle and may impact the timely delivery of the product.</p>
        <p>Transactions, price and all commercial terms such as delivery, dispatch of products and/or services are as per principal-to-principal bipartite contractual obligations between Buyer and Seller; the Website is only a hosting platform that can be utilized by Users to reach a larger base to buy and sell products or services. Use of the Website shall not render CycleCircle liable or responsible for the damage, breach of representations and warranties, non-provision of after sales or warranty services or fraud as regards the products and /or services listed on Website.</p>
        <h2>11. PRICING AND OFFERS</h2>
        <p>CycleCircle aims to ensure that prices of all products offered for sale are true and correct. However, from time to time, the prices of certain products may not be current or may be inaccurate on account of technical issues, typographical errors or incorrect product information provided to CycleCircle by third-party sellers. In each such case, notwithstanding anything to the contrary, CycleCircle reserves the right to cancel the order without any further liability.</p>
        <p>Subject to the foregoing, the price mentioned at the time of ordering a product shall be the price charged at the time of delivery, provided that no product offered for sale on the Website will be sold at a price higher than its MRP (maximum retail price).</p>
        <p>You release and indemnify CycleCircle and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions of the Users of the Website and specifically waive any claims that you may have in this behalf under any applicable law. Notwithstanding its reasonable efforts in that behalf, CycleCircle cannot take responsibility or control the information provided by other Users which is made available on the Website. You may find other User's information to be offensive, harmful, inconsistent, inaccurate, or deceptive. Please use caution and practice safe trading when using the Website.</p>
        <p>All product discounts and offers are by the sellers/brand and not by CycleCircle. From time to time, CycleCircle may conduct various types of marketing and promotional campaigns which may include offers, discounts and other promotional offers to be used on the Website. Such offers shall be subject to the terms and conditions which are solely determined by the CycleCircle, and the terms of such discounts and offers may vary for the customers based on factors relating to the customer such as usage of the Website, volume of transactions, time spent on the Website, city, place of residence, time, etc.</p>
        <p>CycleCircle reserves the right to void, discontinue, cancel or reject the use of any of the offers, discounts or promotional offers without any prior intimation. The offers, discounts and promotional offers may be changed or amended from time to time. It is your responsibility to review and comply with the terms and conditions governing the offers, discounts and other promotional offers provided on the Website. The offers, discounts and promotional offers cannot be exchanged for cash and can only be availed in accordance with the terms and conditions of the offers, unless otherwise communicated.</p>
        <p>Subject to CycleCircle’s cancellation and/or return, refund and replacement policy as amended from time to time, if the User: (i) cancels any product which is subject to any promotions, offer or discounts; or (ii) returns any product purchased which is subject to any promotions, offer or discounts, the User will be eligible to receive only the refund of the amount paid by the User on the purchase of such product. In the event, any product is cancelled or returned in accordance with CycleCircle’s Cancellation and/or Return, Refund and Replacement Policy (mentioned hereinbelow), then save and except as provided herein, any offer, promotion, discount applied to such product shall be forfeited.</p>
        <h2>12. RETURN, REFUND AND REPLACEMENT</h2>
        <p>User(s) may request returns for most items bought from sellers and suppliers listed on the Website that are within the return window. However, products that are explicitly identified as ‘not returnable’ on the product detail page cannot be returned. Products which are identified as ‘returnable’ are eligible for return with the conditions as specified in the product description page and this section of the User Agreement. If the return is approved, the seller shall provide a replacement of the product to the buyer within a reasonable timeframe as may be applicable to the respective product/service or such other time as maybe mutually agreed between the buyer and seller. The refund amount may be subject to deductions or adjustments based on applicable fees, shipping costs, or other agreed-upon terms. All returns are subject to the details provided in the product description page and this section of the User Agreement. Products that are not eligible for return on the Website, may be exchangeable for reasons mentioned on the product description page on the Website. However, if you receive a damaged/defective/wrong product from any seller listed on the Website, you will still be allowed to exchange the product for reasons as provided in the product description page. In case you have purchased a product with which a free product is delivered, and you raise a request for return the main product, you will have to return the free product as well. Further, a product which forms a part of a package of other products, you must return all products that form part of the package to process the refund.</p>
        <p>In circumstances where you return an extra or a different product, CycleCircle will not be accountable for misplacement or replacement of such product and will not be responsible for its delivery back to you.</p>
        <p>CycleCircle shall not be held responsible for any return or exchange arrangements between the buyer and the seller. CycleCircle only serves as a facilitator for transactions and does not assume any liability or responsibility for disputes arising from returns or refunds. If a User receives any product which fulfils the below mentioned criterion, the User is eligible for return / exchange provided the User provides CycleCircle with requisite job-sheet and/or proof as maybe required by the seller/brand listed on the Website:</p>
        <ul>
          <li>Product is used/altered/defective product.</li>
          <li>Physical / in-transit damage to the product.</li>
          <li>Branded product received seal opened.</li>
          <li>Empty packet/some items or accessories are missing.</li>
          <li>Wrong product sent.</li>
        </ul>

        <p>The seller retains the right to cancel any such order placed by the buyer, at its sole discretion and the buyer shall be intimated of the same by way of an email/SMS. Any transaction price paid by buyer in case of such cancellation by seller/brand, shall be refunded to the buyer, if the seller/brand is unable to provide the product at all. Further, the seller may cancel an order wherein the quantities exceed the typical individual consumption. This applies both to the number of products ordered within a single order and the placing of several orders for the same product where the individual orders comprise a quantity that exceeds the typical individual consumption. What comprises a typical individual's consumption quantity limit shall be based on various factors and at the sole discretion of the seller and may vary from individual to individual.</p>

        <p>Except as provided in this User Agreement, in the event of a refund request CycleCircle shall have the sole discretion to determine the eligibility for and the extent of the refund. CycleCircle reserves the right to consider factors such as the nature of the product, the reason for the refund request, and any applicable policies or guidelines.</p>

        <p>Refund shall be conditional and shall be with recourse available to CycleCircle in case of any misuse by buyer. CycleCircle may choose to grant a refund, either partially or in full, based on its assessment of the situation. This assessment may include reviewing the details provided by the User and any supporting evidence or documentation.</p>

        <p>The decision of CycleCircle regarding the refund shall be final and binding. User agrees to abide by CycleCircle's decision and acknowledges that CycleCircle is not obligated to provide a refund if the request does not meet the specified criteria or if CycleCircle determines it to be unreasonable or fraudulent.</p>

        <p>In the event that a refund is approved, CycleCircle will process the refund within a reasonable timeframe, using the same method of payment used for the original purchase, unless otherwise agreed upon.</p>
        <h2>13. FEES AND PAYMENT</h2>
        <p>The Users acknowledge and agree that CycleCircle may, at the request of the seller, act as the payment agent for the limited purpose of accepting payments on behalf of such seller. The Users understand, accept and agree that the payment facility(ies) [if any] provided by CycleCircle is neither a banking nor financial service but is merely a facilitator in providing a third-party payment processor for the transactions on the Website. Further, by providing payment facility, CycleCircle is neither acting as a trustee nor acting in a fiduciary capacity with respect to the transaction or the transaction price. CycleCircle will not be liable for any charges made by the Users bank in relation to payment of the total amount.</p>
        <p>In connection with any order, information such as name, billing address and credit card information may need to be provided either to CycleCircle or the third-party payment processor. If the Users are directed to the third-party payment processor, they may be subject to terms and conditions governing use of that third party’s service and that third party’s personal information collection practices. User(s) is requested to review such terms and conditions and privacy policy before using the Website. All business User(s) providing billing information, delivery address or other contact information of its end user/consumer to CycleCircle or other delivery service provider, they shall ensure that it has necessary consents and approvals from the respective end user/consumer as may be required under applicable law.</p>
        <p>CycleCircle merely collects the payment on behalf of the seller, all applicable taxes and levies, the rates thereof and the manner of applicability of such taxes are to be charged and determined by the seller. CycleCircle holds no responsibility for the legal correctness/validity of the levy of such taxes. The sole liability with respect to any legal issue arising on the taxes payable shall be with the supplier. The transaction is bilateral between seller & the buyer and/or reseller & end users/consumers ("User Transactions"), CycleCircle is not liable to charge or deposit any taxes applicable on such transaction.</p>
        <h2>14. ADVERTISERS ON CYCLECIRCLE, AND LINKED WEBSITES</h2>
        <p>The Website may contain links to third party websites. CycleCircle does not control such websites and is not responsible for its contents. If a User accesses any third-party website, the same shall be done entirely at the User’s risk and CycleCircle shall assume no liability for the same. CycleCircle is not responsible for any errors, omissions or representations on any of its pages, links or any linked website pages to the extent such information is updated or provided directly by the service providers or the advertisers. CycleCircle does not endorse any advertisers on the Website, or any linked sites in any manner. The User(s) is requested to verify the accuracy of all information provided on the third-party web pages. The linked sites are not under the control of CycleCircle and hence CycleCircle is not responsible for the contents of any linked site(s) or any further links on such site(s), or any changes or updates to such sites. CycleCircle is providing these links to the Users only as a convenience.</p>
        <h2>15. DISCLAIMER AND LIMITATION</h2>
        <p>The features and services on the Website are provided on an " as is " and " as available " basis, and CycleCircle hereby expressly disclaims any and all warranties, express or implied, including but not limited to any warranties of condition, quality, durability, performance, accuracy, reliability, merchantability or fitness for a particular purpose. All such warranties, representations, conditions, undertakings and terms are hereby excluded. CycleCircle makes no representations or warranties about the validity, accuracy, correctness, reliability, quality, stability or completeness of any information provided on or through the Website. CycleCircle does not represent or warrant that the manufacture, importation, distribution, offer, display, purchase, sale and/or use of products or services offered or displayed on the Website does not violate any Third-Party Rights; and CycleCircle makes no representations or warranties of any kind concerning any product or service offered or displayed on the Website. Any material downloaded or otherwise obtained through the Website is at the User(s) sole discretion and risk and the User(s) is solely responsible for any damage to its computer system or loss of data that may result from the download of any such material. No advice or information, whether oral or written, obtained by the User(s) from Website or through or from the Website shall create or be deemed to create any warranty not expressly stated herein.</p>
        <p>Under no circumstances shall CycleCircle be held liable for any delay or failure or disruption of the content or services delivered through the Website resulting directly or indirectly from acts of nature, forces or causes beyond its reasonable control, including without limitation, internet failures, computer, telecommunications or any other equipment failures, electrical power failures, strikes, labour disputes, riots, insurrections, civil disturbances, shortages of labour or materials, fires, flood, storms, explosions, Acts of God, natural calamities, war, governmental actions, orders of domestic or foreign courts or tribunals or non-performance of third parties. User(s) hereby agree to indemnify and save CycleCircle, its affiliates, directors, officers and employees harmless, from any and all losses, claims, liabilities (including legal costs on a full indemnity basis) which may arise from their use of the Website (including but not limited to the display of User(s) information on the Website) or from User(s)’s breach of any of the terms and conditions of this User Agreement. User(s) hereby further agree to indemnify and save CycleCircle, its affiliates, directors, officers and employees harmless, from any and all losses, claims, liabilities (including legal costs on a full indemnity basis) which may arise from User(s)’s breach of any representations and warranties made by the User(s) to CycleCircle.</p>
        <p>User(s) hereby further agree to indemnify and save CycleCircle, its affiliates, directors, officers and employees harmless, from any and all losses, claims, liabilities (including legal costs on a full indemnity basis) which may arise, directly or indirectly, as a result of any claims asserted by Third Party Rights claimants or other third parties relating to products offered or displayed on the Website. User(s) hereby further agree that CycleCircle is not responsible and shall have no liability for any material posted by other User(s) or any other person, including defamatory, offensive or illicit material and that the risk of damage from such material rests entirely with the User(s). CycleCircle reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by any User(s), in which event such User(s) shall cooperate with CycleCircle in asserting any available defences.</p>
        <p>CycleCircle shall not be liable for any special, direct, indirect, punitive, incidental or consequential damages or any damages whatsoever (including but not limited to damages for loss of profits or savings, business interruption, loss of information), whether in contract, negligence, tort, strict liability or otherwise or any other damages resulting from any of the following:</p>
        <ul>
          <li>The use or the inability to use the Website;</li>
          <li>Any defect in goods, samples, data, information or services purchased or obtained from a User(s) or a third-party service provider through the Website;</li>
          <li>Delay in delivery or inability of the seller to provide product and/or services or inability to deliver the said product and/or services or any cost incurred by the User due to the abovesaid.</li>
          <li>Violation of Third Party Rights or claims or demands that User(s) manufacture, importation, distribution, offer, display, purchase, sale and/or use of products or services offered or displayed on the Website may violate or may be asserted to violate Third Party Rights; or claims by any party that they are entitled to defense or indemnification in relation to assertions of rights, demands or claims by Third Party Rights claimants;</li>
          <li>Unauthorized access by third parties to data or private information of any User(s);or</li>
          <li>Statements or conduct of any User(s) of the Website;</li>
        </ul>
        <h2>16. GOVERNING LAW AND ARBITRATION</h2>
        <p>This User Agreement and the Privacy Policy shall be governed in all respects by the laws of Indian Territory. CycleCircle considers itself and intends itself to be subject to the jurisdiction of the Courts of Shimla, Himachal Pradesh, India only. The parties to this User Agreement hereby submit to the exclusive jurisdiction of the courts of Shimla, Himachal Pradesh, India.</p>
        <h2>17. MISCELLANEOUS</h2>
        <p>Headings for any section of the User Agreement are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such section.</p>
        <p>CycleCircle’s failure to enforce any right or failure to act with respect to any breach by a User(s) under the User Agreement and/or Privacy Policy will not be deemed to be a CycleCircle’s waiver of that right or CycleCircle’s waiver of the right to act with respect with subsequent or similar breaches. CycleCircle shall have the right to assign its obligations and duties in this User Agreement and in any other agreement relating CycleCircle services to any person or entity.</p>
        <p>If any provision of this User Agreement is held to be invalid or unenforceable, such provision shall be struck out and the remaining provisions of the User Agreement shall be enforced.</p>
        <p>All calls to CycleCircle are completely confidential. However, Your call may be recorded to ensure quality of service. Further, for training purpose and to ensure excellent customer service, calls from CycleCircle may be monitored and recorded.</p>
        <p>We reserve the right, at our sole discretion, to change, modify or otherwise alter the terms and conditions set forth in these User Agreement at any time and you agree to be bound by such changes, modifications or alterations. Such modifications shall become effective immediately upon the posting thereof on the Website. You must review these User Agreement and the policies mentioned herein or displayed on the Website, on a regular basis to keep yourself apprised of any changes. Your continued use of our Sales Channel or use of the Website or or interaction with User(s) signify and confirm your assent to the terms and conditions of the Website or any amendments made thereof.</p>
        <p>In accordance with Information Technology Act, 2000 and the Information Technology (Intermediaries Guidelines and Digital Ethics Code) Rules, 201 and the Consumer Protection Act,2019 and the Consumer Protection (E-Commerce) Rules, 2020 the name and contact details of the Grievance and Nodal Officer who can be contacted for any complaints or concerns pertaining to the Marketplace, including those pertaining to breach of the Terms of Use or any other policies are published as under:</p>
        <p style={{textDecoration:"underline"}}>Details of the Grievance and Nodal Officer is:</p>
        <p>Name: <b>Akshit Gaur</b></p>
        <p>Radkaat Ventures Private Limited,</p>
        <p>Address: TOP,FLR RAMESH SUD BUILD 1112,Shimla High Court,Shimla Urban(T),Shimla -171001,Himachal Pradesh</p>
        <p>Email id: <Link to="#">spokes@CycleCircle.com</Link></p>
        <h2>18. DATA PROTECTION</h2>
        <p>Personal information supplied by User(s) during the use of the Web Site is governed by CycleCircle’s privacy policy ("<b>Privacy Policy</b>"). Please click here to know about the Privacy Policy.</p>
      </div>
      <Footer />
    </>
  );
}

const HeaderDiv = styled.div`
  width: 100%;
  height: 80px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;
