import React, { useEffect, useRef, useState } from "react";
import "./MobilePriceBreakDown.css";
import selectVarient, { setQuantity } from "../../store/slices/selectVarient";
import {
  addToCartsAsync,
  addToPreorderCartsAsync,
  fetchAllCartsAsync,
  fetchAllPreorderCartsAsync,
  removeFromCartsAsync,
  selectAllCarts,
  selectAllPreorders,
} from "../../features/cartPage/cartPageSlice";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import { selectUser } from "../../features/auth/authSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import { findPDPqty } from "../../data";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import {
  selectIsOverlayVisible,
  toggleOverlay,
} from "../../store/slices/headerSlice";
import UserLoginPage from "../../components/ProductDescription/UserLoginPage";
import { useNavigate } from "react-router";
import {
  calculateDealerPrice,
  calculateDiscountPercentage,
  calculateOfferPrice,
  formatWithCommasWithDecimal,
} from "../../utils/utils";

function MobilePriceBreakDown({
  currentProduct,
  qtys,
  isOverlayVisible,
  toggleOverlay,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isCartValueZero, setIsCartValueZero] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const selectedVariant = useSelector(
    (state) => state.productDetail.selectedVariant
  );
  const [cartVal, setCartVal] = useState(0);
  const userDetail = useSelector(selectUser);
  const cart = useSelector(selectAllCarts);
  const preOrder = useSelector(selectAllPreorders);
  const quantity = findPDPqty(selectedVariant?.SKU_ID, qtys && qtys) || 0;

  useEffect(() => {
    if (findPDPqty(selectedVariant?.SKU_ID, qtys && qtys) || 0) {
      dispatch(
        setQuantity(findPDPqty((selectedVariant?.SKU_ID, qtys && qtys) || 0))
      );
    }
  }, [dispatch, currentProduct]);

  const [isAdded, setIsAdded] = useState(false);

  const [shippingOpen, setShippingOpen] = useState(false);
  const [requestSubmitted, setRequestSubmitted] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (userDetail?.UserID) {
      dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID));
    }
  }, [dispatch, userDetail?.UserID]);

  const handleInputChange = (e) => {
    // Ensure the entered value is a number
    let inputValue = parseInt(e.target.value);
    if (currentProduct && currentProduct[0].Manufacturer === "ESI") {
      setCartVal(parseInt(inputValue && inputValue) || 0);
    } else {
      const newQuantity = Math.min(parseInt(inputValue) || 0, quantity || 0);

      setCartVal(newQuantity);
    }
  };

  const handleInputBlur = () => {
    // If the user enters a quantity beyond the product quantity, adjust it
    if (currentProduct && currentProduct[0].Manufacturer === "ESI") {
      const newQuantity = cartVal || 0;
      const updateqty = parseInt(
        cartVal % parseInt(currentProduct && currentProduct[0]?.Bundle)
      );
      setCartVal(String(cartVal - updateqty));
    } else {
      const newQuantity = Math.min(cartVal, quantity || 0);
      if (cartVal < quantity) {
        const updateqty = parseInt(
          cartVal % parseInt(currentProduct && currentProduct[0]?.Bundle)
        );
        setCartVal(String(cartVal - updateqty));
      } else {
        const updateqty = parseInt(
          quantity % parseInt(currentProduct && currentProduct[0]?.Bundle)
        );
        setCartVal(String(quantity - updateqty));
      }
    }
  };

  // const currentPrice = () => {
  //   const originalPrice = parseInt(
  //     currentProduct && currentProduct[0]?.skuDetails[0]?.MRP_price
  //   );
  //   const discountedPrice = originalPrice - originalPrice * 0.2;
  //   return discountedPrice;
  // };

  const handleCurrentPrice = () => {
    const originalPrice =
      selectedVariant && selectedVariant?.Offer_price
        ? calculateOfferPrice(
            parseInt(selectedVariant?.MRP_price),
            parseInt(selectedVariant?.Offer_price),
            currentProduct && currentProduct[0] && currentProduct[0]?.GST
          )
        : calculateDealerPrice(
            parseInt(selectedVariant?.MRP_price),
            parseInt(selectedVariant?.NDP_Price),
            currentProduct && currentProduct[0] && currentProduct[0]?.GST
          );

    const discountedPrice = originalPrice;
    const finalPrice = discountedPrice * cartVal;
    // calculateGST(
    //   parseInt(
    //     selectedVariant && selectedVariant?.Offer_price
    //       ? selectedVariant?.Offer_price
    //       : selectedVariant?.NDP_Price
    //   ),
    //   parseInt(currentProduct[0]?.GST && currentProduct[0]?.GST)
    // ) +
    // handleShippingCost();
    return finalPrice;
  };
  const handleRequestMore = () => {
    setRequestSubmitted(true);

    setTimeout(() => {
      setRequestSubmitted(false);
    }, 2000); // Remove message after 3 seconds (3000 milliseconds)
  };

  const infoRef = useRef(null);
  useEffect(() => {
    if (Object.keys(userDetail).length > 0) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  }, [userDetail]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (infoRef.current && !infoRef.current.contains(event.target)) {
        // Clicked outside the component, you can close it here
        setShippingOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function increaseCarts(cartVal) {
    // should increase multiple of cartVal * currentProduct[0].Bundle
    const bundleSize = parseInt(currentProduct && currentProduct[0]?.Bundle);
    if (currentProduct && currentProduct[0].Manufacturer === "ESI") {
      setCartVal(parseInt(cartVal) + parseInt(bundleSize));
    } else {
      const maxQty = findPDPqty(selectedVariant?.SKU_ID, qtys && qtys);
      if (cartVal + bundleSize <= maxQty) {
        setCartVal(cartVal + bundleSize);
      }
    }
  }
  function decreaseCarts() {
    // should decrease multiple of cartVal * currentProduct[0].Bundle
    const bundleSize = parseInt(currentProduct && currentProduct[0]?.Bundle);
    if (cartVal > bundleSize) {
      setCartVal(cartVal - bundleSize);
    } else if (cartVal > 1) {
      // If current quantity is not a multiple of bundleSize but > 1, decrease to nearest multiple
      setCartVal(Math.max(1, Math.floor(cartVal / bundleSize) * bundleSize));
    }
  }

  useEffect(() => {
    if (cartVal > 0) {
      setIsCartValueZero(false);
    }
  }, [cartVal]);

  // TO ADD PRODUCT INTO CART
  const addToCart = () => {
    handleOpen();
    let cartData = {
      userID: userDetail?.UserID,
      SKU: selectedVariant?.SKU_ID,
      buyQty:
        cartVal === 0 ? currentProduct && currentProduct[0]?.Bundle : cartVal,
    };
    if (Object.keys(userDetail).length > 0 && cartVal >= 1) {
      dispatch(addToCartsAsync(cartData)).then(() => {
        dispatch(fetchAllCartsAsync(userDetail.UserID)).then(() => {
          handleClose();
        });
      });
    } else if (Object.keys(userDetail).length > 0 && cartVal === 0) {
      setIsCartValueZero(true);
      handleClose();
    } else {
      handleClose();
    }
  };

  const addToPreBook = () => {
    handleOpen();
    let preOrderData = {
      UserID: userDetail?.UserID,
      SKU_ID: selectedVariant?.SKU_ID,
      quantity:
        cartVal === 0 ? currentProduct && currentProduct[0]?.Bundle : cartVal,
    };
    if (Object.keys(userDetail).length > 0 && cartVal >= 1) {
      dispatch(addToPreorderCartsAsync(preOrderData)).then(() => {
        dispatch(fetchAllPreorderCartsAsync(userDetail.UserID)).then(() => {
          handleClose();
        });
      });
    } else if (Object.keys(userDetail).length > 0 && cartVal === 0) {
      setIsCartValueZero(true);
      handleClose();
    } else {
      handleClose();
    }
  };

  const buyNowHandle = () => {
    if (isInCart()) {
      navigate("/carts");
    } else {
      handleOpen();
      let cartData = {
        userID: userDetail?.UserID,
        SKU: selectedVariant?.SKU_ID,
        buyQty:
          cartVal === 0 ? currentProduct && currentProduct[0]?.Bundle : cartVal,
      };
      if (Object.keys(userDetail).length > 0 && cartVal >= 1) {
        dispatch(addToCartsAsync(cartData)).then(() => {
          dispatch(fetchAllCartsAsync(userDetail.UserID)).then(() => {
            handleClose();
            navigate("/carts");
          });
        });
      } else if (Object.keys(userDetail).length > 0 && cartVal === 0) {
        setIsCartValueZero(true);
        handleClose();
      } else {
        handleClose();
      }
    }
  };

  const removeFromCart = () => {
    handleOpen();
    let cartData = {
      userID: userDetail?.UserID,
      SKU: selectedVariant?.SKU_ID,
    };
    dispatch(removeFromCartsAsync(cartData)).then(() => {
      dispatch(fetchAllCartsAsync(userDetail.UserID)).then(() => {
        handleClose();
      });
    });
  };

  // Function to check if a product is in the cart
  const isInCart = () => {
    return (
      Array.isArray(cart) &&
      cart?.some((item) => item?.skuDetails?.SKU_ID === selectedVariant?.SKU_ID)
    );
  };

  // Function to check if a product is in the prebook
  const isInPreBook = () => {
    return (
      Array.isArray(preOrder) &&
      preOrder?.some(
        (item) => parseInt(item?.SKU_ID) === parseInt(selectedVariant?.SKU_ID)
      )
    );
  };


  const calculateGST = (price, gstPercentage) => {
    // Calculate GST amount
    const gstAmount = (price * gstPercentage) / 100;
    return gstAmount;
  };

  return (
    <>
      {!isLoggedIn ? (
        <div className="mobile-price-breakdown-container">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open} // Show backdrop when either open or filtering state is true
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="mobile-price-breakdown-top-container">
            <div className="mobile-product-pricing-div">
              <p className="mobile-product-pricing-p">MRP</p>
              <hr className="mobile-product-pricing-hr" />
              <span className="mobile-product-pricing-span">
                ₹
                {formatWithCommasWithDecimal(
                  selectedVariant && parseInt(selectedVariant?.MRP_price)
                )}
              </span>
            </div>
            <div
              className="mobile-product-pricing-div"
              style={{ display: "flex", alignItems: "center" }}
            >
              <p className="mobile-product-pricing-p">
                Dealer Price{" "}
                <b style={{ fontWeight: "500", fontSize: "2.2vw" }}>
                  (Incl. all Taxes)
                </b>
              </p>
              <hr className="mobile-product-pricing-hr" />
              <span className="mobile-product-pricing-span">
                ₹
                {formatWithCommasWithDecimal(
                  selectedVariant && selectedVariant?.Offer_price
                    ? calculateOfferPrice(
                        parseInt(selectedVariant?.MRP_price),
                        parseInt(selectedVariant?.Offer_price),
                        currentProduct && currentProduct[0]?.GST
                      )
                    : calculateDealerPrice(
                        parseInt(selectedVariant?.MRP_price),
                        parseInt(selectedVariant?.NDP_Price),
                        currentProduct && currentProduct[0]?.GST
                      )
                )}
              </span>
            </div>
            <div className="mobile-product-pricing-div">
              <p className="mobile-product-pricing-p">Dealer Margin</p>
              <hr className="mobile-product-pricing-hr" />
              <span className="mobile-product-pricing-span">
                {calculateDiscountPercentage(
                  selectedVariant && selectedVariant?.MRP_price,
                  selectedVariant && selectedVariant?.Offer_price
                    ? calculateOfferPrice(
                        parseInt(selectedVariant?.MRP_price),
                        parseInt(selectedVariant?.Offer_price),
                        currentProduct && currentProduct[0]?.GST
                      )
                    : calculateDealerPrice(
                        parseInt(selectedVariant?.MRP_price),
                        parseInt(selectedVariant?.NDP_Price),
                        currentProduct && currentProduct[0]?.GST
                      )
                )}
                %
              </span>
            </div>
            <div className="mobile-product-pricing-div">
              <p className="mobile-product-pricing-p">GST</p>
              <hr className="mobile-product-pricing-hr" />
              <span className="mobile-product-pricing-span">
                ₹
                {calculateGST(
                  parseInt(
                    selectedVariant?.NDP_Price && selectedVariant?.NDP_Price
                  ),
                  parseInt(
                    currentProduct &&
                      currentProduct[0]?.GST &&
                      currentProduct[0]?.GST
                  )
                )}{" "}
                <b style={{ fontSize: "3vw", fontWeight: "600" }}>
                  (
                  {parseInt(
                    currentProduct &&
                      currentProduct[0]?.GST &&
                      currentProduct[0]?.GST
                  )}
                  %)
                </b>
              </span>
            </div>
            <div className="mobile-product-pricing-div">
              <p className="mobile-product-pricing-p">Shipping To</p>
              <hr className="mobile-product-pricing-hr" />
              {Object.keys(userDetail).length > 0 ? (
                <span className="mobile-product-pricing-span">
                  {userDetail?.location}-{userDetail?.pin_code}
                </span>
              ) : (
                <span className="mobile-product-pricing-span">...</span>
              )}
            </div>
          </div>
          <div className="mobile-product-estimated-date-container">
            <AirplanemodeActiveIcon />
            <span className="mobile-estimated-data-span">
              Estimated delivery:{" "}
              {new Date(
                new Date().getTime() + 5 * 24 * 60 * 60 * 1000
              ).toLocaleDateString("en-US", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </span>
          </div>
          <div className="mobile-product-stock-price-counter-container">
            <div className="mobile-instock-total-price-container">
              <div className="mobile-products-carts-instock-container">
                {cartVal ===
                findPDPqty(
                  selectedVariant && selectedVariant?.SKU_ID,
                  qtys && qtys
                ) ? (
                  <div className="mobile-max-inventery-count-request">
                    <span className="mobile-max-inventery-count">
                      <sup>*</sup>only{" "}
                      {findPDPqty(
                        selectedVariant && selectedVariant?.SKU_ID,
                        qtys && qtys
                      )}{" "}
                      units in stocks!
                    </span>
                    <div
                      className={`request-submitted-success ${
                        requestSubmitted ? "open" : ""
                      }`}
                      style={{ top: "20vh" }}
                    >
                      <iframe
                        src="https://giphy.com/embed/QJ4Hm8oJgMJIqFAuVc"
                        width="50"
                        height="50"
                        title="done"
                        frameBorder="0"
                        class="giphy-embed"
                        allowFullScreen
                      ></iframe>
                      <span>Your request has been submitted successfully!</span>
                    </div>
                    <span
                      className="mobile-product-request-more"
                      onClick={handleRequestMore}
                    >
                      Request more
                    </span>
                  </div>
                ) : (
                  <div className="mobile-product-inStock">
                    <CheckBoxOutlinedIcon />
                    <span>In Stock</span>
                  </div>
                )}
              </div>
              <div className="mobile-products-totals-price">
                Total: ₹{formatWithCommasWithDecimal(handleCurrentPrice())}
              </div>
            </div>

            <div className="mobile-products-carts-quantity-container">
              <KeyboardArrowDownIcon
                style={{
                  background:
                    cartVal <= currentProduct && currentProduct[0]?.Bundle
                      ? "rgb(165, 157, 159)"
                      : "var(--color-blue)",
                }}
                onClick={() => decreaseCarts(cartVal)}
              />
              <input
                className="mobile-products-carts-qty-input"
                type="number"
                onKeyPress={(e) => {
                  if (!/[0-9\b]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                value={cartVal}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                style={{ border: isCartValueZero ? "1px solid red" : "" }}
              />
              <KeyboardArrowUpIcon
                style={{
                  background:
                    cartVal ===
                      findPDPqty(selectedVariant?.SKU_ID, qtys && qtys) &&
                    currentProduct &&
                    currentProduct[0]?.Manufacturer !== "ESI"
                      ? "rgb(165, 157, 159)"
                      : "var(--color-blue)",
                }}
                onClick={() => increaseCarts(cartVal)}
              />
            </div>
          </div>
          <div className="mobile-price-breakdown-add-cart-buy-container">
            {cartVal <= 0 && (
              <div className="mobile-price-breakdown-select-message">
                <sub>★</sub>Please select{" "}
                {cartVal <= 0 && <span>&nbsp;quantity</span>}
              </div>
            )}
            <div className="mobile-price-breakdown-cart-combination">
              {currentProduct && currentProduct[0]?.Manufacturer === "ESI" ? (
                <div
                  className="desktop-prebook-btn-container"
                  style={{ height: "40px" }}
                >
                  {isInPreBook() ? (
                    <span className="desktop-prebook-remove-btn">
                      <AddTaskOutlinedIcon />
                      Pre-Booked
                    </span>
                  ) : (
                    <span
                      className="desktop-prebook-book-btn"
                      onClick={() => addToPreBook()}
                    >
                      Pre-Book - At no advance cost
                    </span>
                  )}
                </div>
              ) : (
                <>
                  <div className="mobile-price-breakdown-add-to-cart-btn">
                    {isInCart() ? (
                      <span
                        className="mobile-price-breakdown-add-to-cart-span"
                        onClick={removeFromCart}
                      >
                        Added
                      </span>
                    ) : (
                      <div
                        className="mobile-price-breakdown-add-to-cart-main-btn"
                        onClick={() => {
                          userDetail && userDetail?.UserID
                            ? addToCart()
                            : toggleOverlay();
                        }}
                      >
                        <ShoppingCartOutlinedIcon />
                        <span className="mobile-price-breakdown-add-to-cart-span">
                          Add to cart
                        </span>
                      </div>
                    )}
                  </div>
                  <div
                    className="mobile-price-breakdown-buy-now-btn"
                    onClick={buyNowHandle}
                  >
                    <span className="mobile-price-breakdown-buy-now-span">
                      Buy Now
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <UserLoginPage />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  isOverlayVisible: selectIsOverlayVisible(state),
});
const mapDispatchToProps = {
  toggleOverlay,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobilePriceBreakDown);
