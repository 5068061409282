import React, { useState, useEffect } from "react";
import "./Promotions.css";
import ProductsSlider from "./ProductsSlider";
import { fetchAllCategoryProductsAsync } from "../../../features/listingPage/listingPageSlice";
import {
  fetchAllWhatsNewAsync,
  fetchAllRecommendedAsync,
  fetchAllHotDealsAsync,
  fetchAllWishlstAsync,
} from "../../../features/HomePage/homeSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";

function Promotions() {
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const [promotionContent, setPromotionContent] = useState(1);
  const [intervalId, setIntervalId] = useState(null);
  const location = window?.location?.pathname;

  useEffect(() => {
    dispatch(fetchAllWhatsNewAsync());
  }, [dispatch]);

  useEffect(() => {
    // Function to change promotion tab automatically after 10 seconds
    const id = setInterval(() => {
      setPromotionContent((prevContent) => {
        const nextContent =
          prevContent === 4 ? (location === "/carts" ? 5 : 1) : prevContent + 1 === 3 ? 4 : prevContent + 1;
        if (nextContent === 1) {
          dispatch(fetchAllWhatsNewAsync());
        } else if (nextContent === 2) {
          dispatch(fetchAllRecommendedAsync());
        } else if (nextContent === 4) {
          dispatch(fetchAllHotDealsAsync());
        } else if (nextContent === 5 && location === "/carts") {
          dispatch(fetchAllWishlstAsync(userDetail.UserID));
        }
        return nextContent;
      });
    }, 10000); // Change tab every 10 seconds

    setIntervalId(id);

    return () => clearInterval(id); // Cleanup interval on component unmount
  }, [dispatch, location, userDetail.UserID]);
  const handlePromotionContent = (value) => {
    clearInterval(intervalId);
    if (value === 1) {
      dispatch(fetchAllWhatsNewAsync());
    } else if (value === 2) {
      dispatch(fetchAllRecommendedAsync());
    } else if(value === 5 && location === "/carts"){
      dispatch(fetchAllWishlstAsync(userDetail?.UserID));
    } else {
      dispatch(fetchAllHotDealsAsync());
    }

    setPromotionContent(value);
  };

  const handleMouseEnter = () => {
    clearInterval(intervalId); // Pause the interval
  };

  const handleMouseLeave = () => {
    clearInterval(intervalId);
    const id = setInterval(() => {
      setPromotionContent((prevContent) => {
        const nextContent =
          prevContent === 4 ? 1 : prevContent + 1 === 3 ? 4 : prevContent + 1;
        if (nextContent === 1) {
          dispatch(fetchAllWhatsNewAsync());
        }
        if (nextContent === 2) {
          dispatch(fetchAllRecommendedAsync());
        }
        if (nextContent === 4) {
          dispatch(fetchAllHotDealsAsync());
        }
        if(nextContent === 5){
          dispatch(fetchAllWishlstAsync(userDetail.UserID))
        }
        return nextContent;
      });
    }, 10000); // Change tab every 10 seconds

    setIntervalId(id);
  };

  return (
    <div className="promotions-container">
      <div className="promotions-navigation-container">
        <div
          className={`promotions-navigation-what-navigate ${
            promotionContent === 1 && "what-navigation-active"
          }`}
        >
          <span
            className={`promotions-navigation-what-navigate-span ${
              promotionContent === 1 && "what-navigate-active"
            }`}
            onClick={() => handlePromotionContent(1)}
          >
            What's New
          </span>
        </div>
        <div
          className={`promotions-navigation-recommend-navigate ${
            promotionContent === 2 && "recommend-navigation-active"
          }`}
        >
          <span
            className={`promotions-navigation-recommend-navigate-span ${
              promotionContent === 2 && "recommend-navigate-active"
            }`}
            onClick={() => handlePromotionContent(2)}
          >
            Recommended
          </span>
        </div>
        <div
          className={`promotions-navigation-hot-navigate ${
            promotionContent === 4 && "hot-navigation-active"
          }`}
        >
          <span
            className={`promotions-navigation-hot-navigate-span ${
              promotionContent === 4 && "hot-navigate-active"
            }`}
            onClick={() => handlePromotionContent(4)}
          >
            Hot Deals
          </span>
        </div>
        {location === "/carts" && (
          <div
            className={`promotions-navigation-wishlist-navigate ${
              promotionContent === 5 && "wishlist-navigation-active"
            }`}
          >
            <span
              className={`promotions-navigation-wishlist-navigate-span ${
                promotionContent === 5 && "wishlist-navigate-active"
              }`}
              onClick={() => handlePromotionContent(5)}
            >
              Wishlist
            </span>
          </div>
        )}
      </div>
      <div className="promotions-product-sliders-container">
        <ProductsSlider
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
        />
      </div>
      <div className="promotion-bottom-white-space"></div>
    </div>
  );
}

export default Promotions;
