import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { setRating } from "../../store/slices/allFilterSlice";
import { setRatings } from "../../store/slices/listingPageFilterSlice";
import StarIcon from "@mui/icons-material/Star";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const ShopByRating = ({ ratings, setRatings }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    if (ratings) {
      dispatch(setRating(parseInt(ratings)));
    }
  }, [dispatch, ratings]);

  const handleRatingChange = (value) => {
    handleOpen();

    window.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(async () => {
      if (ratings === value) {
        setRatings("");
      } else {
        setRatings(value);
      }
      handleClose();
    }, 1000);
  };
  return (
    <div className="w-full flex flex-col justify-between bg-[#ba373766] p-2 shadow-sm shadow-black-1/50">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <span className="text-16 tracking-wide font-extrabold text-black-1/90">
        Shop by Ratings
      </span>
      <div className="flex flex-wrap mt-2">
        {[4, 3, 2, 1].map((value) => (
          <span
            key={value}
            className={`text-center rounded-[25px] tracking-wide font-sans text-14 flex-center py-1 px-2 m-1 whitespace-nowrap shadow-md shadow-black-1/30 ${
              ratings === value
                ? "bg-blue text-white-1"
                : "bg-white-1 text-blue"
            }`}
            onClick={() => handleRatingChange(value)}
          >
            {value}
            <StarIcon
              style={{ fontSize: "15px" }}
              className="text-[goldenrod]"
            />{" "}
            & Above
          </span>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ratings: state.listingPageFilter.ratings,
});

const mapDispatchToProps = {
  setRatings,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopByRating);
