import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import addressHome from "../../img/address-home.png";
import addressDefault from "../../img/address-default.png";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getStateName } from "../../utils/utils";
import { handleModifyAddress, handleRemoveAddress } from "../../utils/dispatch";
import { Checkbox, IconButton, Menu, MenuItem } from "@mui/material";
import EditAddressForm from "./EditAddressForm";
import { useMediaQuery } from "@mui/material";

const AddressCard = ({
  address,
  selectedAddress,
  setSelectedAddress,
  handleOpen,
  handleClose,
  userDetail,
}) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const openClickRef = useRef(null);
  const dispatch = useDispatch();
  const [isDefault, setIsDefault] = useState(false);
  const [openClick, setOpenClick] = useState(false);
  const [openedAddressIndex, setOpenedAddressIndex] = useState(null);
  const [sendAddress, setSendAddress] = useState(null);

  const handleSelectAddress = (shippingID) => {
    dispatch(setSelectedAddress(shippingID));
  };

  const handleEditAddress = (index, address) => {
    setSendAddress(address);
  };

  const handleOpenThreeDots = (index) => {
    if (openedAddressIndex === index) {
      setOpenedAddressIndex(null); // Close the opened container if clicked again
    } else {
      setOpenedAddressIndex(index); // Open the clicked container
    }
  };
  const handleOutsideClick = (event) => {
    if (openClickRef.current && !openClickRef.current.contains(event.target)) {
      setOpenClick(false);
      if (openedAddressIndex !== null) {
        handleOpenThreeDots(null);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className={`w-full sm:w-[380px] h-[180px] flex flex-col sm:flex-row sm:mr-4 sm:flex-wrap border-2  rounded-md mt-2 relative text-black-1  ${
        address.shippingID === 1 ? "border-[purple]" : "border-black-1/10"
      } ${
        selectedAddress === address.shippingID
          ? "border-green"
          : "border-black-1/10"
      }`}
      key={address?.shippingID}
    >
      <div className="w-full flex items-center justify-between">
        <div className="relative top-0 left-2">
          <Checkbox
            value={address.shippingID}
            onChange={() => handleSelectAddress(address.shippingID)}
            checked={selectedAddress === address.shippingID}
            className="input-radio"
            sx={{
              marginRight: "8px",
            }}
          />
        </div>
        <div className="relative top-0 right-0">
          <IconButton onClick={handleOpenMenu} className="text-black-1/90">
            <MoreHorizOutlinedIcon sx={{ fontSize: "20px" }} />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            PaperProps={{
              sx: {
                maxHeight: "150px",
                overflowY: "auto",
                backgroundColor: "white",
                borderRadius: "4px",
              },
            }}
            sx={{
              "& .MuiMenu-list": {
                padding: "0",
              },
            }}
          >
            {/* Edit Option */}
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                handleEditAddress(address.shippingID, address);
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                color: "#28a447",
                padding: isDesktop ? "20px" : "0 10px",
                fontSize: isDesktop ? "14px" : "18px",
                height: isDesktop ? "25px" : "30px",
                gap: "4px",
                minHeight: isDesktop ? "35px" : "40px",
              }}
            >
              <BorderColorOutlinedIcon
                sx={{ fontSize: isDesktop ? "20px" : "24px" }}
              />
              Edit
            </MenuItem>
            {/* Delete Option */}
            <MenuItem
              onClick={() => {
                handleRemoveAddress(
                  address.shippingID,
                  userDetail,
                  dispatch,
                  handleOpen,
                  handleClose
                );
                handleCloseMenu();
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                color: "#ba3737",
                padding: isDesktop ? "20px" : "0 10px",
                fontSize: isDesktop ? "14px" : "18px",
                height: isDesktop ? "25px" : "30px",
                gap: "4px",
                minHeight: isDesktop ? "35px" : "40px",
              }}
            >
              <DeleteIcon sx={{ fontSize: isDesktop ? "20px" : "24px" }} />
              Delete
            </MenuItem>

            {/* Default Option */}
            <MenuItem
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                padding: isDesktop ? "20px" : "0 10px",
                fontSize: isDesktop ? "14px" : "18px",
                height: isDesktop ? "25px" : "30px",
                gap: "4px",
                minHeight: isDesktop ? "35px" : "40px",
              }}
              className="flex items-center justify-between"
            >
              <span className="ml-1">Default</span>
              <FormControlLabel
                value="default"
                control={
                  <Switch
                    color="primary"
                    checked={address.is_default === 1}
                    onChange={() =>
                      {handleModifyAddress(
                        address,
                        userDetail,
                        address?.is_default,
                        dispatch,
                        handleOpen,
                        handleClose
                      );handleCloseMenu();}
                    }
                  />
                }
                labelPlacement="start"
              />
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className="w-full flex items-center px-[30px]">
        <img loading="lazy"
          className={`w-[30px] h-[30px] rounded-full p-1 border-2 ${
            address.is_default === 1 ? " border-[purple]" : " border-black-1/20"
          } ${selectedAddress === address.shippingID && " border-green"}`}
          src={addressHome}
          alt="home-logo"
        />
        <span className="text-[purple] text-16 ml-2">
          My {address.save_address}
        </span>
        {address.is_default === 1 && (
          <div className="bg-[purple] py-1 px-1 rounded-[30px] flex items-center justify-around ml-[20px]">
            <img loading="lazy"
              src={addressDefault}
              alt="default-logo"
              className="w-[15px] h-[15px] rounded-full"
            />
            <span className="text-white-1 text-[10px] ml-2 mr-2">Default</span>
          </div>
        )}
      </div>
      <div className="w-full pl-[40px] pr-[30px] py-2">
        <p className="text-[14px] leading-[1.25] text-black-1/90 font-semibold tracking-wide">
          {address.FirstName} {address.LastName}, {address.address_1}{" "}
          {address.address_2},{address.city} {address.district}{" "}
          {address.pin_code},{getStateName(address.state_id)}
        </p>
        <p className="text-[15px] text-black-1 font-bold tracking-wide">
          +91-{address.PhoneNumber}
        </p>
      </div>
      {sendAddress && (
        <EditAddressForm
          setSendAddress={setSendAddress}
          sendAddress={sendAddress}
          userDetail={userDetail}
        />
      )}
    </div>
  );
};

export default AddressCard;
