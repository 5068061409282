import React, { useEffect, useState } from "react";
import "./PriceRangeSlider.css";
import { connect } from "react-redux";
import {
  setMinValue,
  setMaxValue,
} from "../../../store/slices/priceRangeSlice";
import { setPriceRange } from "../../../store/slices/listingPageFilterSlice";
import ArrowForwardSharpIcon from "@mui/icons-material/ArrowForwardSharp";

const PriceRangeSlider = ({
  minValue,
  maxValue,
  setMinValue,
  setMaxValue,
  setPriceRange,
}) => {
  const [leftV, setLeftV] = useState(0);
  const [rightV, setRightV] = useState(40);
  useEffect(() => {
    const setArea = () => {

      const rangeWidth = 50000; // Updated range width
      const minPosition = (minValue / rangeWidth) * 100;
      const maxPosition = (maxValue / rangeWidth) * 100;

      setLeftV(minPosition);
      setRightV(100 - maxPosition);
    };
    setArea();
  }, [minValue, maxValue]);

  const setMinInput = (e) => {
    let minPrice = parseInt(e.target.value) || 0;

    // Clear existing value before updating state
    e.target.value = "";

    // Ensure that minPrice is not greater than maxValue
    if (minPrice > maxValue) {
      minPrice = maxValue;
    }

    if (minPrice < 0) {
      minPrice = 0;
    } else if (minPrice > 50000) {
      minPrice = 50000;
    }

    setMinValue(minPrice);
  };

  const setMaxInput = (e) => {
    let maxPrice = parseInt(e.target.value) || 0;

    // Clear existing value before updating state
    e.target.value = "";

    if (maxPrice < 0) {
      maxPrice = 0;
    } else if (maxPrice > 50000) {
      maxPrice = 50000;
    }

    // Ensure that maxPrice is not smaller than minValue
    if (maxPrice < minValue) {
      maxPrice = minValue;
    }

    setMaxValue(maxPrice);
  };

  const handleGoClick = () => {
    setPriceRange(`₹ ${minValue} - ₹ ${maxValue}`);
  };

  const handleMinInputKeyDown = (e) => {
    if (e.key === "Enter") {
      setMinInput(e);
    }
  };

  const handleMaxInputKeyDown = (e) => {
    if (e.key === "Enter") {
      setMaxInput(e);
    }
  };

  return (
    <div className="double-slider-box">
      <div className="range-slider">
        <div className="right-corner"></div>
        <div className="left-corner"></div>
        <span
          className="slider-track"
          style={{ left: `${leftV}%`, right: `${rightV}%` }}
        ></span>
        <input
          type="range"
          name="min_val"
          className="min-val"
          min="0"
          max="50000" // Updated max value
          value={minValue}
          onChange={(e) => setMinInput(e)}
        />
        <input
          type="range"
          name="max_val"
          className="max-val"
          min="0"
          max="50000" // Updated max value
          value={maxValue}
          onChange={(e) => setMaxInput(e)}
        />
        <div className="tooltip min-tooltip" style={{ left: `${leftV}%` }}>
          ₹ {minValue}
          <div className="tooltip-bottom-left-arrow"></div>
        </div>
        <div className="tooltip max-tooltip" style={{ right: `${rightV}%` }}>
          ₹ {maxValue}
          <div className="tooltip-bottom-left-arrow"></div>
        </div>
      </div>
      <div className="input-box">
        <div className="min-box">
          <div className="input-wrap">
            {/* <span className="input-addon">₹</span> */}
            <input
              type="text"
              name="min_input"
              className="input-field min-input"
              value={minValue}
              onChange={(e) => setMinInput(e)}
              onKeyDown={handleMinInputKeyDown}
            />
          </div>
        </div>
        <ArrowForwardSharpIcon
          className="input-range-arrow"
          style={{ fontSize: "16px" }}
        />
        <div className="max-box">
          <div className="input-wrap">
            {/* <span className="input-addon">₹</span> */}
            <input
              type="text"
              name="max_input"
              className="input-field max-input"
              value={maxValue}
              onChange={(e) => setMaxInput(e)}
              onKeyDown={handleMaxInputKeyDown}
            />
          </div>
        </div>
        <span className="filter-go-btn" onClick={handleGoClick}>
          GO
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  minValue: state.priceRange.minValue,
  maxValue: state.priceRange.maxValue,
});

const mapDispatchToProps = {
  setMinValue,
  setMaxValue,
  setPriceRange,
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceRangeSlider);
