import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setOTPVerified } from "../../store/slices/userSlice";

// import "bootstrap/dist/css/bootstrap.min.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API_BASE_URL } from "../../config";
import { toggleOverlay } from "../../store/slices/headerSlice";
import {
  fetchUserAsync,
  selectUser,
  setToken,
  setPhoneNumbers,
  setSaveData,
} from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { setLoginMode } from "../../store/slices/userSlice";
import toast from "react-hot-toast";

const OTPVerification = ({
  user,
  phoneNumber,
  setPhoneNumber,
  setLoginMode,
  setOTPVerified,
  toggleOverlay,
  setToken,
  setPhoneNumbers,
  setSaveData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const { loginMode } = user;
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef(
    Array.from({ length: 6 }).map(() => React.createRef())
  );
  const handleKeyDown = (index, value, e) => {
    // Check if backspace is pressed and the current input is empty
    if (e.keyCode === 8 && index > 0 && value === "") {
      inputRefs.current[index - 1].current.focus();
    } else if (e.keyCode === 8 && index > 0 && value !== "") {
      inputRefs.current[index].current.focus();
    }
  };

  const handleInputChange = useCallback(
    (index, value, e) => {
      // Accept only numeric values
      const numericValue = value?.replace(/\D/g, "");

      const newOTP = [...otp];
      newOTP[index] = numericValue?.slice(-1); // Only take the last digit

      setOTP(newOTP);
      // Move to the next input when entering a digit
      if (numericValue !== "" && index < 5 && inputRefs?.current[index + 1]) {
        inputRefs?.current[index + 1]?.current?.focus();
      }
    },
    [otp]
  );

  // HANDLE OTP PASTE
  const handleOTPPaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData.getData("text/plain");
    const pastedDigits = clipboardData.replace(/\D/g, "").slice(0, 6); // Filter out non-numeric characters and take the first 6 digits
    const newOTP = [...otp];
    pastedDigits.split("").forEach((digit, index) => {
      newOTP[index] = digit;
    });
    setOTP(newOTP);
    inputRefs.current[5]?.current.focus();
  };

  const handleVerifyOTP = async () => {
    const enteredOTP = otp.join("");

    if (!enteredOTP || enteredOTP.length < 6) {
      toast.error("Enter OTP correctly");
    } else {
      if (loginMode === "login") {
        try {
          const response = await fetch(
            `${API_BASE_URL}login_number_otp/${phoneNumber}/${enteredOTP}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const data = await response.json();
          if (response.ok) {
            document.cookie = `UserID=${data.UserID}`;
            document.cookie = `token=${data.token}`;

            await dispatch(setToken(data.token));
            dispatch(fetchUserAsync(data.UserID));
            setPhoneNumber(null);
            toggleOverlay();
          } else {
            console.error("API request failed:", response.statusText);
            toast.error("Invalid OTP");
          }
        } catch (error) {
          console.error(
            "An error occurred during the API request:",
            error.message
          );
          toast.error("Please try again later");
        }
      } else {
        try {
          const response = await fetch(
            `${API_BASE_URL}phone_auth_otp/${phoneNumber}/${enteredOTP}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const data = await response.json();

          if (response.ok) {
            // console.log(data.data);
            setSaveData(data?.data && data?.data);
            toggleOverlay();
            dispatch(setPhoneNumbers(phoneNumber));
            navigate(`/sign-up`);
            setPhoneNumber(null);
            setLoginMode("login");
          } else {
            console.error("API request failed:", response.statusText);
            toast.error("Invalid OTP");
          }
        } catch (error) {
          console.error(
            "An error occurred during the API request:",
            error.message
          );
        }
      }
      handleOpen();
      setOTPVerified(true);
      handleClose();
    }
  };

  useEffect(() => {
    const enteredOTP = otp.join("");
    if (enteredOTP.length === 6) {
      handleVerifyOTP();
    }
  }, [otp]);

  const handleSendOTPAgain = async () => {
    handleOpen();
    try {
      const response = await fetch(
        `${API_BASE_URL}login_number/${phoneNumber}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (response.ok) {
      } else {
        console.error("API request failed:", response.statusText);
        toast.error("OTP not Sent");
      }
    } catch (error) {
      console.error("An error occurred during the API request:", error.message);
      toast.error("Please try again later");
    }
    handleClose();
  };

  const handleFocus = (index) => {
    // Move the cursor to the last digit if there's already a value entered
    if (index === 5 && otp[index] !== "") {
      inputRefs?.current[index]?.current?.focus();
    }
  };

  return (
    <div className="otp-verification-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <p className="sent-code">We sent you a code</p>
      <p>Please enter it below to verify your phone number</p>
      <span>{phoneNumber}</span>
      {/* OTP input boxes */}
      <div className="otp-input-boxes">
        {otp.map((digit, index) => (
          <input
            type="text"
            maxlength="1"
            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
            key={index}
            // maxLength={index === 5 ? 1 : 0}
            value={digit}
            onChange={(e) => handleInputChange(index, e.target.value, e)}
            onKeyDown={(e) => handleKeyDown(index, e.target.value, e)}
            ref={inputRefs.current[index]}
            onFocus={() => handleFocus(index)}
            className={digit ? "filled" : ""}
            onPaste={handleOTPPaste}
            required
          />
        ))}
      </div>
      <div className="dont-receive-code">
        <p>Don't receive the code yet?</p>
        <Link to="#" onClick={handleSendOTPAgain}>
          send again
        </Link>
      </div>
      <button className="otp-verify-btn" onClick={handleVerifyOTP}>
        Verify
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  setLoginMode,
  setOTPVerified,
  toggleOverlay,
  setToken,
  setPhoneNumbers,
  setSaveData,
};

export default connect(mapStateToProps, mapDispatchToProps)(OTPVerification);
