import { Button } from "@mui/material";
import React from "react";
import { formatDate, formatWithCommasWithDecimal } from "../../utils/utils";

const PaymentHistory = ({ creditHistory }) => {
  return (
    <div className="w-full flex flex-col bg-white-1 shadow-sm shadow-black-1/10 mb-3 rounded-md">
      <span className="w-full h-[40px] text-[14px] text-black-1 flex items-center p-2 font-bold tracking-wide border-b-[1.5px] border-black-1/10">
        Payment History
      </span>
      <div className="w-full sm:h-[400px] sm:overflow-y-scroll flex flex-col">
        {creditHistory && creditHistory?.length > 0 ? (
          creditHistory?.map((data) => (
            <div
              key={data?.paymentID}
              className="w-full p-2 flex lg:flex-row items-start lg:items-center justify-between lg:h-[50px] space-y-2 lg:space-y-0"
            >
              <div className="flex flex-col justify-between lg:w-[70%]">
                <span className="text-[14px] text-black-1 font-bold tracking-wide">
                  ₹{formatWithCommasWithDecimal(data?.paymentAmount)}
                </span>
                <span className="text-[11px] text-black-1/70 font-semibold mt-1">
                  {formatDate(data?.paymentDate)}
                </span>
              </div>
              <div className="flex justify-end lg:w-[30%]">
                <Button
                  variant="contained"
                  sx={{
                    width: "80px",
                    height: "25px",
                    borderRadius: "30px",
                    padding: "5px",
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "600",
                    textTransform: "none",
                    background:
                      data.transactionType === "SPEND"
                        ? "rgba(215, 182, 130,0.3)"
                        : "rgba(40, 164, 71,0.3)",
                    boxShadow: "none",
                    color:
                      data.transactionType === "SPEND" ? "#d48506" : "#28a447",
                    "&:hover": {
                      boxShadow: "none",
                      background:
                        data.transactionType === "SPEND"
                          ? "rgba(215, 182, 130,0.3)"
                          : "rgba(40, 164, 71,0.3)",
                    },
                  }}
                >
                  {data?.transactionType === "SPEND" ? "Purchase" : "Credit"}
                </Button>
              </div>
            </div>
          ))
        ) : (
          <span className="w-full text-black-1 h-full flex-center p-2 py-5 text-[13px] tracking-wide text-center font-medium">
            No Payment History found!
          </span>
        )}
      </div>
    </div>
  );
};

export default PaymentHistory;
