import React from "react";
import noProductLogo from "../img/no-products-logo.png";

const NoProductFound = () => {
  return (
    <div className="w-full h-[50vh] flex-center flex-col px-5">
      <img loading="lazy" src={noProductLogo} alt="no-product-img" className="w-[35vw]" />
      <h3 className="text-20 text-blue font-bold tracking-wide">
        No products were found.
      </h3>
      <span className="text-14 text-blue/80 font-semibold tracking-wide text-center">
        Sorry, Temporarily we couldn't find any products
      </span>
    </div>
  );
};

export default NoProductFound;
