import React, { useState } from "react";
import {
  addAddressAsync,
  fetchAllAddressAsync,
} from "../../features/cartPage/addressSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API_BASE_URL } from "../../config";
import { getStateId } from "../../utils/utils";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SwipeableDrawer,
  TextField,
  Dialog,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import toast from "react-hot-toast";

const NewAddressForm = ({ setAddAddress, addAddress }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const [emailExists, setEmailExists] = useState(false);
  const [phoneExists, setPhoneExists] = useState(false);
  const [customAddressOpen, setCustomAddressOpen] = useState(false);
  const [customAddressType, setCustomAddressType] = useState(null);
  const [finalData, setFinalData] = useState({
    FirstName: (userDetail && userDetail?.FirstName) || null,
    LastName: (userDetail && userDetail?.LastName) || null,
    Email: (userDetail && userDetail?.Email) || null,
    PhoneNumber: (userDetail && userDetail?.PhoneNumber) || null,
    address_line_1: null,
    address_line_2: null,
    pin_code: null,
    city: null,
    district: null,
    state_id: null,
    addressAs: "no",
    makeDefault: "yes",
  });

  const updateFinalData = (dataToUpdate) => {
    setFinalData((prevData) => ({
      ...prevData,
      ...dataToUpdate,
    }));
    // If makeDefault is included in dataToUpdate, update the state
    if ("makeDefault" in dataToUpdate) {
      setMakeDefault(dataToUpdate.makeDefault);
    }
  };
  const [selectedAddressType, setSelectedAddressType] = useState("Home");
  const [makeDefault, setMakeDefault] = useState("no");
  const [errors, setErrors] = useState({});
  const [pincodeExists, setPincodeExists] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    updateFinalData({ [name]: value });
    if (name === "Email" && emailExists) {
      setEmailExists(false);
    }
    if (name === "PhoneNumber" && phoneExists) {
      setPhoneExists(false);
    }
    if (name === "pin_code" && value.length < 6) {
      setPincodeExists(false);
      setErrors((prevErrors) => ({
        ...prevErrors,
        Pincode: "",
      }));
    }
    if (name === "pin_code" && value.length === 6) {
      // Fetch data based on pin code
      try {
        handleOpen();
        const responseDelivery = await fetch(`${API_BASE_URL}pincode/${value}`);
        const dataDelivery = await responseDelivery.json();
        if (
          dataDelivery &&
          dataDelivery[0] &&
          dataDelivery[0]?.Pincode === parseInt(value)
        ) {
          setPincodeExists(false);
          setErrors((prevErrors) => ({
            ...prevErrors,
            Pincode: "",
          }));
          const response = await fetch(
            `https://api.postalpincode.in/pincode/${value}`
          );

          const data = await response.json();
          if (response.ok && data.length > 0) {
            // Update state, district, location based on API response
            const { State, District, Division } = data[0].PostOffice[0];
            updateFinalData({
              state_id: getStateId(State),
              district: District,
              city: Division,
            });
            handleClose();
          } else {
            // Show error message if pin code is invalid
            handleClose();
            toast.error("Invalid Pin Code")
          }
        } else {
          setPincodeExists(true);
          setErrors((prevErrors) => ({
            ...prevErrors,
            Pincode: "Delivery at this pincode is not available",
          }));
          handleClose();
          return;
        }
      } catch (error) {
        handleClose();
        console.error("Error fetching data:", error);
        // Show error message if API call fails
        toast.error('Please try again later');
      }
    }
  };

  const handleEnterKey = (e, nextInputId) => {
    if (e.key === "Enter") {
      const nextInput = document.getElementById(nextInputId);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };
  const handleCustomAddressTypeChange = (event) => {
    setCustomAddressType(event.target.value);
  };

  const handleSaveAddressType = (addressType) => {
    setSelectedAddressType(addressType);
    updateFinalData({ ["addressAs"]: addressType });
  };

  const handleSaveAddressTypeOk = (addressType) => {
    setCustomAddressOpen(false);
    if (addressType && addressType.length > 0) {
      setSelectedAddressType(addressType);
      updateFinalData({ ["save_address"]: addressType });
      setCustomAddressOpen(false);

      // Fetch the span element by ID
      const spanElement = document.getElementById("fetch-others");

      // Check if the element exists and set its value to customAddressType
      if (spanElement) {
        spanElement.textContent = customAddressType;
      }
    }
  };

  const handleMakeDefault = (value) => {
    setMakeDefault(value);
    updateFinalData({ ["makeDefault"]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Dispatch the addAddressAsync action with the finalData
    let addressData = {
      UserID: userDetail.UserID,
      FirstName: finalData.FirstName,
      LastName: finalData.LastName,
      Email: finalData.Email,
      PhoneNumber: finalData.PhoneNumber,
      address_1: finalData.address_line_1,
      address_2: finalData.address_line_2,
      city: finalData.city,
      district: finalData.district,
      pin_code: finalData.pin_code,
      state_id: finalData.state_id,
      save_address: finalData.addressAs,
      is_default: finalData.makeDefault,
    };
    if (!errors["Pincode"] && !pincodeExists) {
      dispatch(addAddressAsync(addressData))
        .then(() => {
          // Address added successfully, you can handle the success here
          dispatch(fetchAllAddressAsync(userDetail?.UserID));
          setAddAddress(false);
        })
        .catch((error) => {
          // Handle the error if the address addition fails
          console.error("Error adding address:", error);
        });
    } else {
      toast.error('Enter Available Pincode')
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setAddAddress(open);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {isMobile ? (
        <SwipeableDrawer
          anchor="left"
          open={addAddress}
          onClose={()=>toggleDrawer(false)}
          onOpen={()=>toggleDrawer(true)}
          PaperProps={{
            sx: {
              width: "100vw",
            },
          }}
        >
          <div className="w-full h-full flex flex-col p-3">
            <h3 className="text-14 text-blue font-bold">
              Enter details to add New Address
            </h3>
            <form className="w-full flex flex-col p-1" onSubmit={handleSubmit}>
              <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
                <TextField
                  label="First Name"
                  variant="outlined"
                  id="firstNameInput"
                  placeholder="Enter your First Name"
                  fullWidth
                  name="FirstName"
                  value={finalData?.FirstName || ""}
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleEnterKey(e, "secondNameInput")}
                  required
                  InputProps={{
                    sx: {
                      height: "45px",
                      padding: "0",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                      lineHeight: "1",
                    },
                  }}
                />
              </div>
              <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
                <TextField
                  label="Last Name"
                  variant="outlined"
                  id="secondNameInput"
                  placeholder="Enter your Last Name"
                  fullWidth
                  name="LastName"
                  value={finalData?.LastName || ""}
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleEnterKey(e, "emailInput")}
                  required
                  InputProps={{
                    sx: {
                      height: "45px",
                      padding: "0",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                      lineHeight: "1",
                    },
                  }}
                />
              </div>
              <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[25px]">
                <TextField
                  label="Email"
                  variant="outlined"
                  id="emailInput"
                  placeholder="Enter your email id"
                  fullWidth
                  name="Email"
                  value={finalData?.Email || ""}
                  onKeyDown={(e) => handleEnterKey(e, "phoneInput")}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/\s+/g, "");
                    handleInputChange({
                      target: {
                        name: e.target.name,
                        value: inputValue,
                      },
                    });
                  }}
                  required
                  InputProps={{
                    sx: {
                      height: "45px",
                      padding: "0",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                      lineHeight: "1",
                    },
                  }}
                />
              </div>
              <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  name="PhoneNumber"
                  placeholder="Enter your phone number"
                  id="phoneInput"
                  pattern="\d{10}"
                  maxLength="10"
                  type="number"
                  value={finalData.PhoneNumber || ""}
                  onKeyDown={(e) => handleEnterKey(e, "address1Input")}
                  onChange={handleInputChange}
                  onInput={(e) => {
                    // Limit input to 10 digits
                    if (e.target.value.length > 10) {
                      e.target.value = e.target.value.slice(0, 10);
                    }
                  }}
                  required
                  InputProps={{
                    sx: {
                      height: "45px",
                      padding: "0",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                      lineHeight: "1",
                    },
                  }}
                />
              </div>
              <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
                <TextField
                  label="Pincode"
                  variant="outlined"
                  id="pincode"
                  fullWidth
                  name="pin_code"
                  placeholder="Enter your pincode"
                  pattern="\d{6}"
                  maxLength="6"
                  type="number"
                  color={!errors["Pincode"] && "success"}
                  value={finalData?.pin_code || ""}
                  onKeyDown={(e) => handleEnterKey(e, "address1Input")}
                  onChange={handleInputChange}
                  onInput={(e) => {
                    // Limit input to 10 digits
                    if (e.target.value.length > 6) {
                      e.target.value = e.target.value.slice(0, 6);
                    }
                  }}
                  error={finalData?.pin_code && errors["Pincode"]}
                  helperText={errors["Pincode"] || ""}
                  required
                  InputProps={{
                    sx: {
                      height: "45px",
                      padding: "0",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                      lineHeight: "1",
                    },
                  }}
                />
              </div>
              <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
                <TextField
                  label="Address Line 1"
                  variant="outlined"
                  id="address1Input"
                  placeholder="Address line 1"
                  fullWidth
                  name="address_line_1"
                  value={finalData?.address_line_1 || ""}
                  onKeyDown={(e) => handleEnterKey(e, "address2Input")}
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    sx: {
                      height: "45px",
                      padding: "0",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                      lineHeight: "1",
                    },
                  }}
                />
              </div>
              <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
                <TextField
                  label="Address Line 2"
                  variant="outlined"
                  id="address2Input"
                  placeholder="Address line 2"
                  fullWidth
                  name="address_line_2"
                  value={finalData?.address_line_2 || ""}
                  onKeyDown={(e) => handleEnterKey(e, "cityInput")}
                  onChange={handleInputChange}
                  InputProps={{
                    sx: {
                      height: "45px",
                      padding: "0",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                      lineHeight: "1",
                    },
                  }}
                />
              </div>
              <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
                <TextField
                  label="City"
                  variant="outlined"
                  id="cityInput"
                  placeholder="Enter your city name"
                  fullWidth
                  name="location"
                  value={finalData?.location || ""}
                  onKeyDown={(e) => handleEnterKey(e, "districtInput")}
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    sx: {
                      height: "45px",
                      padding: "0",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                      lineHeight: "1",
                    },
                  }}
                />
              </div>
              <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
                <TextField
                  label="District"
                  variant="outlined"
                  id="districtInput"
                  placeholder="Enter your district name"
                  fullWidth
                  name="district"
                  value={finalData.district || ""}
                  onKeyDown={(e) => handleEnterKey(e, "state_id")}
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    sx: {
                      height: "45px",
                      padding: "0",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                      lineHeight: "1",
                    },
                  }}
                />
              </div>
              <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
                <FormControl fullWidth required>
                  <Select
                    id="state_id"
                    name="state_id"
                    value={finalData?.state_id || 0}
                    onChange={handleInputChange}
                    sx={{
                      height: "45px",
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select State
                    </MenuItem>
                    <MenuItem value="1">Andhra Pradesh</MenuItem>
                    <MenuItem value="2">Arunachal Pradesh</MenuItem>
                    <MenuItem value="3">Assam</MenuItem>
                    <MenuItem value="4">Bihar</MenuItem>
                    <MenuItem value="5">Chhattisgarh</MenuItem>
                    <MenuItem value="29">Chandigarh</MenuItem>
                    <MenuItem value="30">Delhi</MenuItem>
                    <MenuItem value="6">Goa</MenuItem>
                    <MenuItem value="7">Gujarat</MenuItem>
                    <MenuItem value="8">Haryana</MenuItem>
                    <MenuItem value="9">Himachal Pradesh</MenuItem>
                    <MenuItem value="32">Jammu and Kashmir</MenuItem>
                    <MenuItem value="10">Jharkhand</MenuItem>
                    <MenuItem value="11">Karnataka</MenuItem>
                    <MenuItem value="12">Kerala</MenuItem>
                    <MenuItem value="31">Ladakh</MenuItem>
                    <MenuItem value="13">Madhya Pradesh</MenuItem>
                    <MenuItem value="14">Maharashtra</MenuItem>
                    <MenuItem value="15">Manipur</MenuItem>
                    <MenuItem value="16">Meghalaya</MenuItem>
                    <MenuItem value="17">Mizoram</MenuItem>
                    <MenuItem value="18">Nagaland</MenuItem>
                    <MenuItem value="19">Odisha</MenuItem>
                    <MenuItem value="20">Punjab</MenuItem>
                    <MenuItem value="21">Rajasthan</MenuItem>
                    <MenuItem value="22">Sikkim</MenuItem>
                    <MenuItem value="23">Tamil Nadu</MenuItem>
                    <MenuItem value="24">Telangana</MenuItem>
                    <MenuItem value="25">Tripura</MenuItem>
                    <MenuItem value="26">Uttar Pradesh</MenuItem>
                    <MenuItem value="27">Uttarakhand</MenuItem>
                    <MenuItem value="28">West Bengal</MenuItem>
                  </Select>
                  <FormHelperText>Select your state</FormHelperText>
                </FormControl>
              </div>
              <div className="w-full flex flex-col justify-start mb-[15px]">
                <h3 className="text-16 text-blue font-bold">Save address as</h3>
                <div className="w-full flex items-center  flex-wrap mt-2 ml-1">
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleSaveAddressType("Dealership");
                      setCustomAddressOpen(false);
                    }}
                    sx={{
                      width: "100px",
                      height: "35px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      borderRadius: "4px",
                      padding: "4px 8px",
                      marginRight: "8px",
                      letterSpacing: "0.05em",
                      backgroundColor:
                        selectedAddressType === "Dealership"
                          ? "#283248"
                          : "#ffffff",
                      boxShadow: selectedAddressType !== "Dealership" && "none",
                      color:
                        selectedAddressType === "Dealership"
                          ? "#ffffff"
                          : "#283248",
                      "&:hover": {
                        backgroundColor: "#283248",
                        color: "white",
                      },
                    }}
                  >
                    Dealership
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleSaveAddressType("Office");
                      setCustomAddressOpen(false);
                    }}
                    sx={{
                      width: "100px",
                      height: "35px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      borderRadius: "4px",
                      padding: "4px 8px",
                      marginRight: "8px",
                      letterSpacing: "0.05em",
                      backgroundColor:
                        selectedAddressType === "Office"
                          ? "#283248"
                          : "#ffffff",
                      boxShadow: selectedAddressType !== "Office" && "none",
                      color:
                        selectedAddressType === "Office"
                          ? "#ffffff"
                          : "#283248",
                      "&:hover": {
                        backgroundColor: "#283248",
                        color: "white",
                      },
                    }}
                  >
                    Office
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setCustomAddressOpen(true);
                      setSelectedAddressType(null);
                    }}
                    id="fetch-others"
                    sx={{
                      width: "100px",
                      height: "35px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      borderRadius: "4px",
                      padding: "4px 8px",
                      marginRight: "8px",
                      letterSpacing: "0.05em",
                      backgroundColor:
                        selectedAddressType !== "Office" &&
                        selectedAddressType !== "Dealership"
                          ? "#283248"
                          : "#ffffff",
                      boxShadow:
                        (selectedAddressType === "Office" ||
                          selectedAddressType === "Dealership") &&
                        "none",
                      color:
                        selectedAddressType !== "Office" &&
                        selectedAddressType !== "Dealership"
                          ? "#ffffff"
                          : "#283248",
                      "&:hover": {
                        backgroundColor: "#283248",
                        color: "white",
                      },
                    }}
                  >
                    Others
                  </Button>
                  {customAddressOpen && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <TextField
                        placeholder="Please specify"
                        value={customAddressType}
                        onChange={handleCustomAddressTypeChange}
                        sx={{
                          width: "75%",
                          height: "35px",
                          fontSize: "14px",
                          mr: 2,
                          "& .MuiOutlinedInput-root": {
                            height: "35px",
                            color: "rgba(0, 0, 0, 0.8)",
                            borderRadius: "4px",
                            "& fieldset": {
                              borderColor: customAddressType
                                ? "#283248"
                                : "#ff0000",
                            },
                            "&:hover fieldset": {
                              borderColor: customAddressType
                                ? "#283248"
                                : "#ff0000",
                            },
                          },
                        }}
                        InputProps={{
                          style: { height: "35px" },
                        }}
                        variant="outlined"
                      />

                      <Button
                        variant="contained"
                        sx={{
                          width: "50px",
                          height: "35px",
                          backgroundColor: "#28a447",
                          borderRadius: "4px",
                          color: "#ffffff",
                          fontWeight: "bold",
                          fontSize: "16px",
                          textTransform: "none",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          letterSpacing: "0.05em",
                          "&:hover": {
                            backgroundColor: "#28a447",
                          },
                        }}
                        onClick={() =>
                          handleSaveAddressTypeOk(customAddressType)
                        }
                      >
                        Ok
                      </Button>
                    </Box>
                  )}
                </div>
              </div>

              <div className="w-full flex flex-row items-center justify-start mb-[5px]">
                <FormControl className="w-full " required>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    sx={{
                      fontSize: "18px",
                      color: "#283248",
                      fontWeight: "bold",
                    }}
                  >
                    Make address default
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="yes"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      name="default_address"
                      value="yes"
                      checked={makeDefault === "yes"}
                      onChange={() => handleMakeDefault("yes")}
                      control={<Radio />}
                      label="Yes"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          color: "rgba(0, 0, 0, 0.8)",
                          fontSize: "16px",
                        },
                      }}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      name="default_address"
                      value="no"
                      checked={makeDefault === "no"}
                      onChange={() => handleMakeDefault("no")}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          color: "rgba(0, 0, 0, 0.8)",
                          fontSize: "16px",
                        },
                      }}
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  height: "45px",
                  backgroundColor: "#28a447",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "16px",
                  padding: "8px 16px",
                  borderRadius: "4px",
                  margin: "5px 0",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#28a447",
                  },
                }}
                type="submit"
              >
                Save
              </Button>
              <Button
                variant="Outlined"
                sx={{
                  width: "100%",
                  height: "45px",
                  border: "2px solid #283248",
                  color: "#283248",
                  fontWeight: "bold",
                  fontSize: "16px",
                  padding: "8px 16px",
                  borderRadius: "4px",
                  margin: "5px 0",
                  marginBottom: "20px",
                  textTransform: "none",
                }}
                type="cancel"
                onClick={() => setAddAddress(false)}
              >
                Cancel
              </Button>
            </form>
          </div>
        </SwipeableDrawer>
      ) : (
        <Dialog
          open={addAddress}
          onClose={()=>toggleDrawer(false)}
          fullWidth={true}
          maxWidth="sm"
        >
          <div className="w-full h-full flex flex-col p-3">
            <h3 className="text-14 text-blue font-bold">
              Enter details to add New Address
            </h3>
            <form className="w-full flex flex-col p-1" onSubmit={handleSubmit}>
              <div className="w-full flex items-center justify-between">
                <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px] mr-4">
                  <TextField
                    label="First Name"
                    variant="outlined"
                    id="firstNameInput"
                    placeholder="Enter your First Name"
                    fullWidth
                    name="FirstName"
                    value={finalData?.FirstName || ""}
                    onChange={handleInputChange}
                    onKeyDown={(e) => handleEnterKey(e, "secondNameInput")}
                    required
                    InputProps={{
                      sx: {
                        height: "40px",
                        padding: "0",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: "14px",
                        lineHeight: "1",
                      },
                    }}
                  />
                </div>
                <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    id="secondNameInput"
                    placeholder="Enter your Last Name"
                    fullWidth
                    name="LastName"
                    value={finalData?.LastName || ""}
                    onChange={handleInputChange}
                    onKeyDown={(e) => handleEnterKey(e, "emailInput")}
                    required
                    InputProps={{
                      sx: {
                        height: "40px",
                        padding: "0",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: "14px",
                        lineHeight: "1",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="w-full flex items-center justify-between">
                <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px] mr-4">
                  <TextField
                    label="Email"
                    variant="outlined"
                    id="emailInput"
                    placeholder="Enter your email id"
                    fullWidth
                    name="Email"
                    value={finalData?.Email || ""}
                    onKeyDown={(e) => handleEnterKey(e, "phoneInput")}
                    onChange={(e) => {
                      const inputValue = e.target.value.replace(/\s+/g, "");
                      handleInputChange({
                        target: {
                          name: e.target.name,
                          value: inputValue,
                        },
                      });
                    }}
                    required
                    InputProps={{
                      sx: {
                        height: "40px",
                        padding: "0",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: "14px",
                        lineHeight: "1",
                      },
                    }}
                  />
                </div>
                <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    name="PhoneNumber"
                    placeholder="Enter your phone number"
                    id="phoneInput"
                    pattern="\d{10}"
                    maxLength="10"
                    type="number"
                    value={finalData.PhoneNumber || ""}
                    onKeyDown={(e) => handleEnterKey(e, "address1Input")}
                    onChange={handleInputChange}
                    onInput={(e) => {
                      // Limit input to 10 digits
                      if (e.target.value.length > 10) {
                        e.target.value = e.target.value.slice(0, 10);
                      }
                    }}
                    required
                    InputProps={{
                      sx: {
                        height: "40px",
                        padding: "0",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: "14px",
                        lineHeight: "1",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="w-full flex items-center justify-between">
                <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px] mr-4">
                  <TextField
                    label="Pincode"
                    variant="outlined"
                    id="pincode"
                    fullWidth
                    name="pin_code"
                    placeholder="Enter your pincode"
                    pattern="\d{6}"
                    maxLength="6"
                    type="number"
                    color={!errors["Pincode"] && "success"}
                    value={finalData?.pin_code || ""}
                    onKeyDown={(e) => handleEnterKey(e, "address1Input")}
                    onChange={handleInputChange}
                    onInput={(e) => {
                      // Limit input to 10 digits
                      if (e.target.value.length > 6) {
                        e.target.value = e.target.value.slice(0, 6);
                      }
                    }}
                    error={finalData?.pin_code && errors["Pincode"]}
                    helperText={errors["Pincode"] || ""}
                    required
                    InputProps={{
                      sx: {
                        height: "40px",
                        padding: "0",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: "14px",
                        lineHeight: "1",
                      },
                    }}
                  />
                </div>
                <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
                  <TextField
                    label="City"
                    variant="outlined"
                    id="cityInput"
                    placeholder="Enter your city name"
                    fullWidth
                    name="location"
                    value={finalData?.location || ""}
                    onKeyDown={(e) => handleEnterKey(e, "districtInput")}
                    onChange={handleInputChange}
                    required
                    InputProps={{
                      sx: {
                        height: "40px",
                        padding: "0",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: "14px",
                        lineHeight: "1",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="w-full flex items-center justify-between">
                <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px] mr-4">
                  <TextField
                    label="Address Line 1"
                    variant="outlined"
                    id="address1Input"
                    placeholder="Address line 1"
                    fullWidth
                    name="address_line_1"
                    value={finalData?.address_line_1 || ""}
                    onKeyDown={(e) => handleEnterKey(e, "address2Input")}
                    onChange={handleInputChange}
                    required
                    InputProps={{
                      sx: {
                        height: "40px",
                        padding: "0",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: "14px",
                        lineHeight: "1",
                      },
                    }}
                  />
                </div>
                <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
                  <TextField
                    label="Address Line 2"
                    variant="outlined"
                    id="address2Input"
                    placeholder="Address line 2"
                    fullWidth
                    name="address_line_2"
                    value={finalData?.address_line_2 || ""}
                    onKeyDown={(e) => handleEnterKey(e, "cityInput")}
                    onChange={handleInputChange}
                    InputProps={{
                      sx: {
                        height: "40px",
                        padding: "0",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: "14px",
                        lineHeight: "1",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="w-full flex items-start justify-between">
                <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px] mr-4">
                  <TextField
                    label="District"
                    variant="outlined"
                    id="districtInput"
                    placeholder="Enter your district name"
                    fullWidth
                    name="district"
                    value={finalData.district || ""}
                    onKeyDown={(e) => handleEnterKey(e, "state_id")}
                    onChange={handleInputChange}
                    required
                    InputProps={{
                      sx: {
                        height: "40px",
                        padding: "0",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: "14px",
                        lineHeight: "1",
                      },
                    }}
                  />
                </div>
                <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[15px]">
                  <FormControl fullWidth required>
                    <Select
                      id="state_id"
                      name="state_id"
                      value={finalData?.state_id || 0}
                      onChange={handleInputChange}
                      sx={{
                        height: "40px",
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select State
                      </MenuItem>
                      <MenuItem value="1">Andhra Pradesh</MenuItem>
                      <MenuItem value="2">Arunachal Pradesh</MenuItem>
                      <MenuItem value="3">Assam</MenuItem>
                      <MenuItem value="4">Bihar</MenuItem>
                      <MenuItem value="5">Chhattisgarh</MenuItem>
                      <MenuItem value="29">Chandigarh</MenuItem>
                      <MenuItem value="30">Delhi</MenuItem>
                      <MenuItem value="6">Goa</MenuItem>
                      <MenuItem value="7">Gujarat</MenuItem>
                      <MenuItem value="8">Haryana</MenuItem>
                      <MenuItem value="9">Himachal Pradesh</MenuItem>
                      <MenuItem value="32">Jammu and Kashmir</MenuItem>
                      <MenuItem value="10">Jharkhand</MenuItem>
                      <MenuItem value="11">Karnataka</MenuItem>
                      <MenuItem value="12">Kerala</MenuItem>
                      <MenuItem value="31">Ladakh</MenuItem>
                      <MenuItem value="13">Madhya Pradesh</MenuItem>
                      <MenuItem value="14">Maharashtra</MenuItem>
                      <MenuItem value="15">Manipur</MenuItem>
                      <MenuItem value="16">Meghalaya</MenuItem>
                      <MenuItem value="17">Mizoram</MenuItem>
                      <MenuItem value="18">Nagaland</MenuItem>
                      <MenuItem value="19">Odisha</MenuItem>
                      <MenuItem value="20">Punjab</MenuItem>
                      <MenuItem value="21">Rajasthan</MenuItem>
                      <MenuItem value="22">Sikkim</MenuItem>
                      <MenuItem value="23">Tamil Nadu</MenuItem>
                      <MenuItem value="24">Telangana</MenuItem>
                      <MenuItem value="25">Tripura</MenuItem>
                      <MenuItem value="26">Uttar Pradesh</MenuItem>
                      <MenuItem value="27">Uttarakhand</MenuItem>
                      <MenuItem value="28">West Bengal</MenuItem>
                    </Select>
                    <FormHelperText>Select your state</FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="w-full flex flex-col justify-start mt-[10px] mb-[15px]">
                <h3 className="text-16 text-blue font-bold">Save address as</h3>
                <div className="w-full flex items-center  flex-wrap mt-2 ml-1">
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleSaveAddressType("Dealership");
                      setCustomAddressOpen(false);
                    }}
                    sx={{
                      width: "100px",
                      height: "35px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      borderRadius: "4px",
                      padding: "4px 8px",
                      marginRight: "8px",
                      letterSpacing: "0.05em",
                      textTransform: "none",
                      backgroundColor:
                        selectedAddressType === "Dealership"
                          ? "#283248"
                          : "#ffffff",
                      boxShadow: selectedAddressType !== "Dealership" && "none",
                      color:
                        selectedAddressType === "Dealership"
                          ? "#ffffff"
                          : "#283248",
                      "&:hover": {
                        backgroundColor: "#283248",
                        color: "white",
                      },
                    }}
                  >
                    Dealership
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleSaveAddressType("Office");
                      setCustomAddressOpen(false);
                    }}
                    sx={{
                      width: "100px",
                      height: "35px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      borderRadius: "4px",
                      padding: "4px 8px",
                      marginRight: "8px",
                      letterSpacing: "0.05em",
                      textTransform: "none",
                      backgroundColor:
                        selectedAddressType === "Office"
                          ? "#283248"
                          : "#ffffff",
                      boxShadow: selectedAddressType !== "Office" && "none",
                      color:
                        selectedAddressType === "Office"
                          ? "#ffffff"
                          : "#283248",
                      "&:hover": {
                        backgroundColor: "#283248",
                        color: "white",
                      },
                    }}
                  >
                    Office
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setCustomAddressOpen(true);
                      setSelectedAddressType(null);
                    }}
                    id="fetch-others"
                    sx={{
                      width: "100px",
                      height: "35px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      borderRadius: "4px",
                      padding: "4px 8px",
                      marginRight: "8px",
                      letterSpacing: "0.05em",
                      textTransform: "none",
                      backgroundColor:
                        selectedAddressType !== "Office" &&
                        selectedAddressType !== "Dealership"
                          ? "#283248"
                          : "#ffffff",
                      boxShadow:
                        (selectedAddressType === "Office" ||
                          selectedAddressType === "Dealership") &&
                        "none",
                      color:
                        selectedAddressType !== "Office" &&
                        selectedAddressType !== "Dealership"
                          ? "#ffffff"
                          : "#283248",
                      "&:hover": {
                        backgroundColor: "#283248",
                        color: "white",
                      },
                    }}
                  >
                    Others
                  </Button>
                  {customAddressOpen && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginTop: "10px",
                      }}
                    >
                      <TextField
                        placeholder="Please specify"
                        value={customAddressType}
                        onChange={handleCustomAddressTypeChange}
                        sx={{
                          width: "75%",
                          height: "35px",
                          fontSize: "14px",
                          mr: 2,
                          "& .MuiOutlinedInput-root": {
                            height: "35px",
                            color: "rgba(0, 0, 0, 0.8)",
                            borderRadius: "4px",
                            "& fieldset": {
                              borderColor: customAddressType
                                ? "#283248"
                                : "#ff0000",
                            },
                            "&:hover fieldset": {
                              borderColor: customAddressType
                                ? "#283248"
                                : "#ff0000",
                            },
                          },
                        }}
                        InputProps={{
                          style: { height: "35px" },
                        }}
                        variant="outlined"
                      />

                      <Button
                        variant="contained"
                        sx={{
                          width: "50px",
                          height: "35px",
                          backgroundColor: "#28a447",
                          borderRadius: "4px",
                          color: "#ffffff",
                          fontWeight: "bold",
                          fontSize: "16px",
                          textTransform: "none",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          letterSpacing: "0.05em",
                          "&:hover": {
                            backgroundColor: "#28a447",
                          },
                        }}
                        onClick={() =>
                          handleSaveAddressTypeOk(customAddressType)
                        }
                      >
                        Ok
                      </Button>
                    </Box>
                  )}
                </div>
              </div>

              <div className="w-full flex flex-row items-center justify-start mb-[5px]">
                <FormControl className="w-full " required>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    sx={{
                      fontSize: "18px",
                      color: "#283248",
                      fontWeight: "bold",
                    }}
                  >
                    Make address default
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="yes"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      name="default_address"
                      value="yes"
                      checked={makeDefault === "yes"}
                      onChange={() => handleMakeDefault("yes")}
                      control={<Radio />}
                      label="Yes"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          color: "rgba(0, 0, 0, 0.8)",
                          fontSize: "16px",
                        },
                      }}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      name="default_address"
                      value="no"
                      checked={makeDefault === "no"}
                      onChange={() => handleMakeDefault("no")}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          color: "rgba(0, 0, 0, 0.8)",
                          fontSize: "16px",
                        },
                      }}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="w-full flex items-start justify-between">
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    height: "45px",
                    backgroundColor: "#28a447",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "16px",
                    padding: "8px 16px",
                    borderRadius: "4px",
                    margin: "5px 0",
                    marginRight: "16px",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#28a447",
                    },
                  }}
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  variant="Outlined"
                  sx={{
                    width: "100%",
                    height: "45px",
                    border: "2px solid #283248",
                    color: "#283248",
                    fontWeight: "bold",
                    fontSize: "16px",
                    padding: "8px 16px",
                    borderRadius: "4px",
                    margin: "5px 0",
                    marginBottom: "20px",
                    textTransform: "none",
                  }}
                  type="cancel"
                  onClick={() => setAddAddress(false)}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default NewAddressForm;
