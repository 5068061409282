// HoverSlider.js
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { IMAGE_BASE } from "../../../config";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

const settings = {
  dots: false,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false, // Autoplay is initially disabled
  autoplaySpeed: 1500,
  prevArrow: <></>,
  nextArrow: <></>,
};

const HoverSlider = ({ images }) => {
  const [isHovered, setIsHovered] = useState(false);
  const sliderRef = useRef(null);

  // Enable autoplay when hovering over the slider
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  // Disable autoplay when not hovering over the slider
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    // Start or stop autoplay based on hover state
    if (sliderRef.current) {
      if (isHovered) {
        sliderRef.current.slickPlay();
      } else {
        sliderRef.current.slickPause();
      }
    }
  }, [isHovered]);

  return (
    <Div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <SliderImg {...settings} ref={sliderRef}>
        {images &&
          images?.map((image, index) => (
            <ImgDiv key={index}>
              <LazyLoadImage
                src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${image}`}
                alt=""
                effect="blur"
                wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
                }}
              />
            </ImgDiv>
          ))}
      </SliderImg>
    </Div>
  );
};

export default HoverSlider;

const Div = styled.div`
  width: 100%;
  height: 100%;
  overflow-hidden;
`;
const SliderImg = styled(Slider)`
  width: 100%;
  height: 100%;
  div.slick-list {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  div.slick-track {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    div.slick-slide {
      width: 100%;
      height: 100%;
      div {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
const ImgDiv = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
  img {
    width: 100%;
    height: 100%;
    border-radius: 5px 5px 0 0;
    margin: 0;
    padding: 0;
    object-fit:contain;
  }
  @media (max-width: 500px) {
    border-radius: 5px;
    img{
      border-radius: 5px;
    }
`;
