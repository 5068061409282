import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchThroughBrandAsync } from "../../features/listingPage/listingPageSlice";
import { useNavigate } from "react-router";
import { setSubCategories } from "../../features/listingPage/listingPageSlice";
import { setSelectedSubcategory } from "../../store/slices/subCategorySlice";
import { selectUser } from "../../features/auth/authSlice";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import bikeProLogo from "../../icons/parts/bike-pro-temp-logo.png";
import sovereignLogo from "../../img/banners/sovereign-logo.png";
import masterLogo from "../../img/banners/proride-logo-1.png";
import prorideLogo from "../../img/banners/proride-logo-2.png";
import { setBreadcrumb } from "../../store/slices/breadCrumbsSlice";
import { Button } from "@mui/material";

function OurBrands() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);
  const [hoverBrand, setHoverBrand] = useState("cratoni");
  const [imgIdx, setImgIdx] = useState(0);

  const handleFetchThroughBrands = (brand) => {
    dispatch(fetchThroughBrandAsync(brand)).then(() => {
      dispatch(setSubCategories([]));
      dispatch(setSelectedSubcategory(null));
      dispatch(
        setBreadcrumb({
          categoryID: null,
          categoryName: brand,
          subCategoryID: null,
          subCategoryName: "",
          productName: "",
          ProductID: null,
        })
      );
      navigate(`/listing-page/${brand}`);
    });
  };

  const handleFetchThroughBrandsImg = (idx) => {
    switch (idx) {
      case 0:
        navigate(`/listing-page/${"cratoni"}`);
        break;
      case 1:
        navigate(`/listing-page/${"moon sports"}`);
        break;
      case 2:
        navigate(`/listing-page/${"funn"}`);
        break;
      case 3:
        navigate(`/listing-page/${"granite"}`);
        break;
      default:
        break;
    }
  };

  const img = [
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Cratoni.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Moon.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Funn.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Granite.png",
  ];
  const imgHidden = [
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Cratoni.png ",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Moon.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Funn.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Granite.png",
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      const nextIdx = (imgIdx + 1) % img.length;
      setImgIdx(nextIdx);
      switch (nextIdx) {
        case 0:
          setHoverBrand("cratoni");
          break;
        case 1:
          setHoverBrand("moon");
          break;
        case 2:
          setHoverBrand("funn");
          break;
        case 3:
          setHoverBrand("granite");
          break;
        default:
          break;
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [imgIdx, img.length]);

  const handleHoverBrand = (brand, idx) => {
    setHoverBrand(brand);
    setImgIdx(idx);
  };

  return (
    <div className="w-full flex items-center flex-col bg-white-1 border-black-1/10 border-2 py-5">
      <div className="w-full flex flex-col items-center justify-start bg-white border-b border-gray-200">
        <span className="pb-6 text-2xl font-bold tracking-wider text-blue capitalize">
          Exclusively on Cyclecircle
        </span>
        <div className="w-full overflow-hidden bg-white mb-4">
          <img
            loading="lazy"
            src={
              Object.keys(userDetail).length > 0
                ? img[imgIdx]
                : imgHidden[imgIdx]
            }
            alt="main-img"
            className="w-full h-auto cursor-pointer shadow-lg"
            onClick={() => handleFetchThroughBrandsImg(imgIdx)}
          />
        </div>
        <div className="w-full bg-white">
          <div className="flex items-center justify-between">
            <div
              className={`w-1/4 h-20 flex flex-col items-center justify-start relative cursor-pointer bg-white ${
                hoverBrand === "cratoni" && "z-1 shadow-md"
              }`}
              onMouseEnter={() => handleHoverBrand("cratoni", 0)}
              onClick={() => handleFetchThroughBrands("cratoni")}
            >
              <img
                loading="lazy"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQohbUwx79PkQDbs4YO695jU0OXIAmfqRB-0jAxz05cag&s"
                alt="cratoni-logo"
                className={`w-1/2 md:w-[40%] h-[40px] md:h-[30px]  object-cover transition-transform duration-500 pt-1 ${
                  hoverBrand === "cratoni" ? "scale-105" : ""
                }`}
              />
              <span className="absolute bottom-[30%] text-sm font-bold text-gray-900">
                Helmets
              </span>
              <span
                className={`absolute text-[10px] bottom-[8px] tracking-wide font-medium  flex items-center transition-colors duration-500 ${
                  hoverBrand === "cratoni" ? "text-peach" : "text-gray-900"
                }`}
              >
                Upto{" "}
                {Object.keys(userDetail).length > 0 ? (
                  "35"
                ) : (
                  <VisibilityOffOutlinedIcon
                    className="mx-1"
                    style={{ fontSize: "14px" }}
                  />
                )}
                % Dealer Margin
              </span>
              <hr
                className={`absolute bottom-0 left-0 w-0 border-2 transition-all ease-in duration-500 ${
                  hoverBrand === "cratoni"
                    ? "w-full border-peach"
                    : "w-0 border-white-1"
                }`}
              />
            </div>
            <div
              className={`w-1/4 h-20 flex flex-col items-center justify-start relative cursor-pointer bg-white ${
                hoverBrand === "moon" && "z-1 shadow-md"
              }`}
              onMouseEnter={() => handleHoverBrand("moon", 1)}
              onClick={() => handleFetchThroughBrands("moon sports")}
            >
              <img
                loading="lazy"
                src="https://www.handshake.fi/wp-content/uploads/2018/06/logo-moon.png"
                alt="moon-logo"
                className={`w-1/2 md:w-[40%] h-[40px] md:h-[30px]  object-cover transition-transform duration-500 pt-1 ${
                  hoverBrand === "moon" ? "scale-105" : ""
                }`}
              />
              <span className="absolute bottom-[30%] text-sm font-bold text-gray-900">
                Lights
              </span>
              <span
                className={`absolute text-[10px] bottom-[8px] tracking-wide font-medium  flex items-center transition-colors duration-500 ${
                  hoverBrand === "moon" ? "text-peach" : "text-gray-900"
                }`}
              >
                Upto{" "}
                {Object.keys(userDetail).length > 0 ? (
                  "35"
                ) : (
                  <VisibilityOffOutlinedIcon
                    className="mx-1"
                    style={{ fontSize: "14px" }}
                  />
                )}
                % Dealer Margin
              </span>
              <hr
                className={`absolute bottom-0 left-0 w-0 border-2 transition-all ease-in duration-500 ${
                  hoverBrand === "moon"
                    ? "w-full border-peach"
                    : "w-0 border-white-1"
                }`}
              />
            </div>
            <div
              className={`w-1/4 h-20 flex flex-col items-center justify-start relative cursor-pointer bg-white ${
                hoverBrand === "funn" && "z-1 shadow-md"
              }`}
              onMouseEnter={() => handleHoverBrand("funn", 2)}
              onClick={() => handleFetchThroughBrands("funn")}
            >
              <img
                loading="lazy"
                src="https://images.bike24.com/i/mb/0c/67/20/logofunn-3903.jpg"
                alt="funn-logo"
                className={`w-1/2 md:w-[40%] h-[40px] md:h-[30px]  object-cover transition-transform duration-500 pt-1 ${
                  hoverBrand === "funn" ? "scale-105" : ""
                }`}
              />
              <span className="absolute bottom-[30%] text-sm font-bold text-gray-900">
                Cockpit
              </span>
              <span
                className={`absolute text-[10px] bottom-[8px] tracking-wide font-medium  flex items-center transition-colors duration-500 ${
                  hoverBrand === "funn" ? "text-peach" : "text-gray-900"
                }`}
              >
                Upto{" "}
                {Object.keys(userDetail).length > 0 ? (
                  "35"
                ) : (
                  <VisibilityOffOutlinedIcon
                    className="mx-1"
                    style={{ fontSize: "14px" }}
                  />
                )}
                % Dealer Margin
              </span>
              <hr
                className={`absolute bottom-0 left-0 w-0 border-2 transition-all ease-in duration-500 ${
                  hoverBrand === "funn"
                    ? "w-full border-peach"
                    : "w-0 border-white-1"
                }`}
              />
            </div>
            <div
              className={`w-1/4 h-20 flex flex-col items-center justify-start relative cursor-pointer bg-white ${
                hoverBrand === "granite" && "z-1 shadow-md"
              }`}
              onMouseEnter={() => handleHoverBrand("granite", 3)}
              onClick={() => handleFetchThroughBrands("granite")}
            >
              <img
                loading="lazy"
                src="https://www.stifmtb.com/cdn/shop/collections/Granite_logo_WH_1200x1200_13e278a3-8114-419c-94c0-0f6c6a0a86cc_1200x1200.jpg?v=1646754378"
                alt="granite-logo"
                className={`w-1/2 md:w-[40%] h-[40px] md:h-[30px]  object-cover transition-transform duration-500 pt-1 ${
                  hoverBrand === "granite" ? "scale-105" : ""
                }`}
              />
              <span className="absolute bottom-[30%] text-sm font-bold text-gray-900">
                Tools
              </span>
              <span
                className={`absolute text-[10px] bottom-[8px] tracking-wide font-medium  flex items-center transition-colors duration-500 ${
                  hoverBrand === "granite" ? "text-peach" : "text-gray-900"
                }`}
              >
                Upto{" "}
                {Object.keys(userDetail).length > 0 ? (
                  "35"
                ) : (
                  <VisibilityOffOutlinedIcon
                    className="mx-1"
                    style={{ fontSize: "14px" }}
                  />
                )}
                % Dealer Margin
              </span>
              <hr
                className={`absolute bottom-0 left-0 w-0 border-2 transition-all ease-in duration-500 ${
                  hoverBrand === "granite"
                    ? "w-full border-peach"
                    : "w-0 border-white-1"
                }`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-start py-4 px-8">
        <div className="w-auto h-auto my-2">
          <span className="text-2xl text-blue font-bold tracking-wide capitalize">
            Vocal4Local : Discover New Indian Brands
          </span>
        </div>
        <div className="w-full h-auto flex items-center justify-between pt-8 overflow-x-auto brand-scroll-v1">
          <div
            className="w-[240px] h-[380px] rounded-md relative mx-5 cursor-pointer flex-shrink-0"
            onClick={() => handleFetchThroughBrands("proride")}
          >
            <img
              loading="lazy"
              src="https://cdn.mos.cms.futurecdn.net/pUyW3aVBo9VDsVP5ycsDSj.jpg"
              alt="brand-img"
              className="w-full h-full object-cover bg-center bg-cover rounded-md"
            />

            <div className="w-[94%] h-[45%] rounded-md absolute bottom-[2.2%] left-[3%] bg-white-1 flex-center flex-col opacity-90 hover:opacity-100">
              <div className="w-full h-[30px] mb-2 flex-center">
                <img
                  loading="lazy"
                  src={prorideLogo}
                  alt="brand-logo"
                  className="w-auto h-[25px] contain"
                />
              </div>
              <span className="text-[12px] font-bold text-center text-black-1/90 h-[25px]">
                Grips,Fenders & Bottles
              </span>
              <div className="w-full h-[25px] flex-center">
                <span className="text-[12px] text-black-1/90 font-bold capitalize tracking-wide flex items-center">
                  {Object.keys(userDetail).length > 0 ? (
                    "50-85"
                  ) : (
                    <VisibilityOffOutlinedIcon
                      style={{ margin: "0 4px", fontSize: "15px" }}
                    />
                  )}
                  % Dealer Margin
                </span>
              </div>
              <Button
                variant="contained"
                sx={{
                  height: "25px",
                  padding: "0 10px",
                  borderRadius: "30px",
                  background: "#283248",
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "500",
                  textTransform: "none",
                  letterSpacing: "0.5px",
                  marginTop: "8px",
                  "&:hover": {
                    background: "#ba4747",
                  },
                }}
                onClick={() => handleFetchThroughBrands("proride")}
              >
                Shop Now
              </Button>
            </div>
          </div>
          <div
            className="w-[240px] h-[380px] rounded-md relative mx-5 cursor-pointer flex-shrink-0"
            onClick={() => handleFetchThroughBrands("sovereign")}
          >
            <img
              loading="lazy"
              src="https://w0.peakpx.com/wallpaper/926/811/HD-wallpaper-bike-cafe-cycle-downhill-hornet-motor-motors-mountain-racer.jpg"
              alt=""
              className="w-full h-full object-cover bg-center bg-cover rounded-md"
            />

            <div className="w-[94%] h-[45%] rounded-md absolute bottom-[2.2%] left-[3%] bg-white-1 flex-center flex-col opacity-90 hover:opacity-100">
              <div className="w-full h-[30px] mb-2 flex-center">
                <img
                  loading="lazy"
                  src={sovereignLogo}
                  alt=""
                  className="w-auto h-[25px] contain"
                />
              </div>
              <span className="text-[12px] font-bold text-center text-black-1/90 h-[25px]">
                Premium Saddles
              </span>
              <div className="w-full h-[25px] flex-center">
                <span className="text-[12px] text-black-1/90 font-bold capitalize tracking-wide flex items-center">
                  {Object.keys(userDetail).length > 0 ? (
                    "50-85"
                  ) : (
                    <VisibilityOffOutlinedIcon
                      style={{ margin: "0 4px", fontSize: "15px" }}
                    />
                  )}
                  % Dealer Margin
                </span>
              </div>
              <Button
                variant="contained"
                sx={{
                  height: "25px",
                  padding: "0 10px",
                  borderRadius: "30px",
                  background: "#283248",
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "500",
                  textTransform: "none",
                  letterSpacing: "0.5px",
                  marginTop: "8px",
                  "&:hover": {
                    background: "#ba4747",
                  },
                }}
                onClick={() => handleFetchThroughBrands("sovereign")}
              >
                Shop Now
              </Button>
            </div>
          </div>
          <div
            className="w-[240px] h-[380px] rounded-md relative mx-5 cursor-pointer flex-shrink-0"
            onClick={() => handleFetchThroughBrands("bikepro")}
          >
            <img
              loading="lazy"
              src="https://c0.wallpaperflare.com/preview/500/768/1015/blue-bike-in-selective-focus-photography.jpg"
              alt=""
              className="w-full h-full object-cover bg-center bg-cover rounded-md"
            />

            <div className="w-[94%] h-[45%] rounded-md absolute bottom-[2.2%] left-[3%] bg-white-1 flex-center flex-col opacity-90 hover:opacity-100">
              <div className="w-full h-[30px] mb-2 flex-center">
                <img
                  loading="lazy"
                  src={bikeProLogo}
                  alt=""
                  className="w-auto h-[25px] contain"
                />
              </div>
              <span className="text-[12px] font-bold text-center text-black-1/90 h-[25px]">
                Bottom Brackets & Alloy Hubs
              </span>
              <div className="w-full h-[25px] flex-center">
                <span className="text-[12px] text-black-1/90 font-bold capitalize tracking-wide flex items-center">
                  {Object.keys(userDetail).length > 0 ? (
                    "50-85"
                  ) : (
                    <VisibilityOffOutlinedIcon
                      style={{ margin: "0 4px", fontSize: "15px" }}
                    />
                  )}
                  % Dealer Margin
                </span>
              </div>
              <Button
                variant="contained"
                sx={{
                  height: "25px",
                  padding: "0 10px",
                  borderRadius: "30px",
                  background: "#283248",
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "500",
                  textTransform: "none",
                  letterSpacing: "0.5px",
                  marginTop: "8px",
                  "&:hover": {
                    background: "#ba4747",
                  },
                }}
                onClick={() => handleFetchThroughBrands("bikepro")}
              >
                Shop Now
              </Button>
            </div>
          </div>
          <div
            className="w-[240px] h-[380px] rounded-md relative mx-5 cursor-pointer flex-shrink-0"
            onClick={() => handleFetchThroughBrands("master")}
          >
            <img
              loading="lazy"
              src="https://preview.redd.it/nbd-commencal-meta-tr-v0-9d13nkt74aqb1.jpg?width=1080&crop=smart&auto=webp&s=831be7a350083dfee92feedc470a43892382867d"
              alt=""
              className="w-full h-full object-cover bg-center bg-cover rounded-md"
            />

            <div className="w-[94%] h-[45%] rounded-md absolute bottom-[2.2%] left-[3%] bg-white-1 flex-center flex-col opacity-90 hover:opacity-100">
              <div className="w-full h-[30px] mb-2 flex-center">
                <img
                  loading="lazy"
                  src={masterLogo}
                  alt=""
                  className="w-auto h-[25px] contain"
                />
              </div>
              <span className="text-[12px] font-bold text-center text-black-1/90 h-[25px]">
                Bushless Chains
              </span>
              <div className="w-full h-[25px] flex-center">
                <span className="text-[12px] text-black-1/90 font-bold capitalize tracking-wide flex items-center">
                  {Object.keys(userDetail).length > 0 ? (
                    "50-85"
                  ) : (
                    <VisibilityOffOutlinedIcon
                      style={{ margin: "0 4px", fontSize: "15px" }}
                    />
                  )}
                  % Dealer Margin
                </span>
              </div>
              <Button
                variant="contained"
                sx={{
                  height: "25px",
                  padding: "0 10px",
                  borderRadius: "30px",
                  background: "#283248",
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "500",
                  textTransform: "none",
                  letterSpacing: "0.5px",
                  marginTop: "8px",
                  "&:hover": {
                    background: "#ba4747",
                  },
                }}
                onClick={() => handleFetchThroughBrands("master")}
              >
                Shop Now
              </Button>
            </div>
          </div>
          <div
            className="w-[240px] h-[380px] rounded-md relative mx-5 cursor-pointer flex-shrink-0"
            onClick={() => handleFetchThroughBrands("dass")}
          >
            <img
              loading="lazy"
              src="https://www.johnsonbecker.com/wp-content/uploads/Trek-Bicycle-Disc-Brakes-Recall-Lawsuit.jpg"
              alt=""
              className="w-full h-full object-cover bg-center bg-cover rounded-md"
            />

            <div className="w-[94%] h-[45%] rounded-md absolute bottom-[2.2%] left-[3%] bg-white-1 flex-center flex-col opacity-90 hover:opacity-100">
              <div className="w-full h-[30px] mb-2 flex-center">
                <img
                  loading="lazy"
                  src="https://dassbike.com/assets/img/logo/logo.png"
                  alt=""
                  className="w-auto h-[25px] contain"
                />
              </div>
              <span className="text-[12px] font-bold text-center text-black-1/90 h-[25px]">
                Brake Parts & Hubs
              </span>
              <div className="w-full h-[25px] flex-center">
                <span className="text-[12px] text-black-1/90 font-bold capitalize tracking-wide flex items-center">
                  {Object.keys(userDetail).length > 0 ? (
                    "50-85"
                  ) : (
                    <VisibilityOffOutlinedIcon
                      style={{ margin: "0 4px", fontSize: "15px" }}
                    />
                  )}
                  % Dealer Margin
                </span>
              </div>
              <Button
                variant="contained"
                sx={{
                  height: "25px",
                  padding: "0 10px",
                  borderRadius: "30px",
                  background: "#283248",
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "500",
                  textTransform: "none",
                  letterSpacing: "0.5px",
                  marginTop: "8px",
                  "&:hover": {
                    background: "#ba4747",
                  },
                }}
                onClick={() => handleFetchThroughBrands("dass")}
              >
                Shop Now
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurBrands;
