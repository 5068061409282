import React from "react";
import ccLogo from "../../img/banners/Coupon-CC-Logo.png";

const Support = () => {
  return (
    <div className="w-full h-auto flex justify-between bg-white-1 shadow-sm shadow-black-1/10 mb-3 rounded-md p-3">
      <div className="w-[18%] h-full">
        <img loading="lazy"
          src={ccLogo}
          alt="cc-logo"
          width={30}
          height={30}
          className="object-contain"
        />
      </div>

      <div className="w-[82%] h-full flex flex-col">
        <h3 className="text-[14px] text-black-1 font-bold">Extension of Credit Amount</h3>
        <span className="text-[10.5px] text-black-1/60 my-1">
          For further details contact us.
        </span>
        <span className="text-[13px] text-green font-bold my-2">+91-9429693000</span>
        <span className="text-[13px] text-green font-bold">
          support@cyclecircle.one
        </span>
      </div>
    </div>
  );
};

export default Support;
