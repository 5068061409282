import React, { useEffect, useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API_BASE_URL } from "../../../config";
import { OTPVerified, VerifyOTP } from "../../../mComponent/OTPVerification";
import { TextField } from "@mui/material";
import { VerifiedPAN } from "../../../mComponent/VerifiedDocument";
import toast from "react-hot-toast";

function MobileBusinessPAN({
  updateFinalData,
  finalData,
  PANVerify,
  setPANVerify,
}) {
  const [verify, setVerify] = useState(false);
  const [panExists, setPanExists] = useState(false);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  function validatePAN(pan) {
    const regex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return regex.test(pan);
  }

  const handlePANBlur = async (e) => {
    const pan = e?.target?.value?.toUpperCase();
    if (!validatePAN(pan)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        PAN: "Please enter a valid 10-digit PAN number",
      }));
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}singup_pan_verify/${pan}`);
      const data = await response.json();

      if (data.status === "error") {
        setPanExists(true);
        setErrors((prevErrors) => ({
          ...prevErrors,
          PAN: "PAN number already registered with Cyclecircle",
        }));
      } else {
        setPanExists(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          PAN: "",
        }));
      }
    } catch (error) {
      console.error("Error checking PAN number existence:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updateValue = value.toUpperCase();
    updateFinalData({ [name]: updateValue });

    if (name === "pan_card" && value.length === 10) {
      handlePANBlur(e);
    } else if (name === "pan_card" && value.length < 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        PAN: "",
      }));
    }
  };

  const handleEnterKey = (e, nextInputId) => {
    if (e.key === "Enter") {
      const nextInput = document.getElementById(nextInputId);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleVerifyPAN = async () => {
    handleOpen();
    if (!finalData.pan_card || finalData.pan_card.length < 10) {
      handleClose();
      toast.error('Enter PAN correctly')
    } else {
      try {
        const response = await fetch(
          `${API_BASE_URL}pan_verify/${finalData?.pan_card}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (data.PAN.message === "PAN verified successfully") {
          setVerify(true);
          setPANVerify(true);
          updateFinalData({ PAN_DATA: data.PAN });
          handleClose();
        } else {
          handleClose();
          toast.error("PAN Doesn't Exist")
        }
      } catch (error) {
        handleClose();
        toast.error("PAN Doesn't Exist")
        console.error(
          "An error occurred during the API request:",
          error.message
        );
      }
    }
  };
  useEffect(() => {
    if (finalData?.pan_card?.length < 10) {
      setPANVerify(false);
      updateFinalData({ ["PAN_DATA"]: null });
    }
  }, [finalData?.pan_card?.length]);

  return (
    <div className="w-full min-h-[72vh] flex flex-col items-start justify-start py-4 px-3">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <span className="text-[13px] text-black-1/70 font-semibold my-2">
        We need your PAN details associated with your business to verify your
        account.If you have an unregistered business or you are a sole
        proprietor,enter you personal PAN details.
      </span>
      <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[25px]">
        <TextField
          label="Business PAN"
          variant="outlined"
          id="PANInput"
          placeholder="Ex:APMPH5429D"
          fullWidth
          maxLength="10"
          name="pan_card"
          color={validatePAN(finalData?.pan_card) && "success"}
          value={finalData?.pan_card || ""}
          onChange={(e) => {
            const inputValue = e.target.value.replace(/\s+/g, "");
            if (inputValue.length <= 10) {
              handleInputChange({
                target: {
                  name: e.target.name,
                  value: inputValue,
                },
              });
            }
          }}
          onKeyDown={(e) => handleEnterKey(e, "GSTInput")}
          onBlur={handlePANBlur}
          error={
            (errors["PAN"] || panExists) && !validatePAN(finalData?.pan_card)
          }
          helperText={errors["PAN"] || ""}
          required
        />

        {!PANVerify &&
          !errors["PAN"] && !panExists &&
          finalData?.pan_card &&
          finalData?.pan_card?.length === 10 && (
            <VerifyOTP handleVerify={handleVerifyPAN} />
          )}
        {PANVerify && <OTPVerified />}
      </div>

      {PANVerify && <VerifiedPAN finalData={finalData} />}
    </div>
  );
}

export default MobileBusinessPAN;
