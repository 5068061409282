import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllAddress,
  addAddress,
  removeAddress,
  modifyAddress,
} from "./addressAPI";

const initialState = {
  address: [],
  selectedAddress: null,
  status: "idle", // Include the 'status' property in the initialState
};

export const fetchAllAddressAsync = createAsyncThunk(
  "address/fetchAllAddress",
  async (UserID) => {
    const response = await fetchAllAddress(UserID);

    return response?.data;
  }
);

export const addAddressAsync = createAsyncThunk(
  "address/addAddress",
  async (addressData) => {
    const response = await addAddress(addressData);
    return response?.data;
  }
);
export const removeAddressAsync = createAsyncThunk(
  "address/removeAddress",
  async (shippingID) => {
    const response = await removeAddress(shippingID);
    return response;
  }
);
export const modifyAddressAsync = createAsyncThunk(
  "address/modifyAddress",
  async (addressData) => {
    const response = await modifyAddress(addressData);
    return response;
  }
);

export const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    setSelectedAddress: (state, action) => {
      state.selectedAddress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAddressAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllAddressAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.address = action.payload;
      })
      .addCase(addAddressAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addAddressAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.address = action.payload;
      })
      .addCase(removeAddressAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeAddressAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.address = action.payload;
      })
      .addCase(modifyAddressAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(modifyAddressAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.address = action.payload;
      });
  },
});

export const selectAllAddress = (state) => state.address.address;
export const selectSelectedAddress = (state) => state.address.selectedAddress;
export const { setSelectedAddress } = addressSlice.actions;
export default addressSlice.reducer;
