import React from "react";
import "./Funn.css";
import { funnData } from "../../../data";

function Funn() {
  const {
    brand,
    slogan,
    description,
    legacy,
    innovation,
    performance,
    riders,
    style,
  } = funnData;
  return (
    <div className="funn-container">
      <h2>{`Welcome to ${brand}`}</h2>
      <p>{description}</p>

      <div className="legacy">
        <h3>Legacy</h3>
        <p>{legacy.history}</p>
      </div>

      <div className="innovation">
        <h3>Cutting-Edge Innovation</h3>
        <p>{innovation.commitment}</p>
      </div>

      <div className="performance">
        <h3>Trail-Tested Performance</h3>
        <p>{performance.testing}</p>
      </div>

      <div className="riders">
        <h3>Meet our 2023 team of extraordinary riders</h3>
        <p>{riders.description}</p>
        <ul>
          {riders["2023Team"].map((rider, index) => (
            <li key={index}>{rider}</li>
          ))}
        </ul>
      </div>

      <div className="style">
        <h3>Style Meets Function</h3>
        <p>{style.description}</p>
      </div>
    </div>
  );
}

export default Funn;
