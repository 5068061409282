import React from "react";
import { useNavigate } from "react-router";
import DoneIcon from "@mui/icons-material/Done";
import CyclecircleLogo from "../../../img/parts/Cycle-Circle-Logo-Orange-1.png";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

function MobileSignUpSideBar({ currentStep, steps }) {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/");
  };
  
  return (
    <div className="w-full py-2 px-3 z-0 flex flex-col items-start justify-start">
      <div className="fixed top-[15px] left-[15px]">
        <img loading="lazy"
          src={CyclecircleLogo}
          alt=""
          className="h-[40px]"
          onClick={handleNavigate}
        />
      </div>
      <CloseSharpIcon
        className="w-[30px] h-[30px] rounded-full shadow-sm shadow-black-1/50 fixed top-[15px] right-[15px] text-black-1/80"
        style={{ fontSize: "25px" }}
        onClick={(e) => handleCancel(e)}
      />
      <span className="w-full flex-center text-16 text-black-1/80 tracking-wide font-bold mt-[15px]">
        {steps[currentStep]?.name}
      </span>
      <div className="w-full mt-0 flex items-center justify-center px-3">
        {steps.map((step, index) => (
          <div key={index} className="flex items-center my-2 relative">
            <div
              className={`w-[30px] h-[30px] rounded-full relative ${
                index < currentStep
                  ? "border-[2px] border-green flex-center"
                  : ""
              } ${index === currentStep ? "border-[2px] border-black-1" : ""}`}
            >
              {index < currentStep ? (
                <DoneIcon className="text-white-1 bg-green rounded-full p-[4px]" />
              ) : (
                <span
                  className={`absolute w-full h-full left-0 right-0 top-0 bottom-0 rounded-full bg-black-1/20 ${
                    index < currentStep ? "bg-green inset-shadow" : ""
                  } ${
                    index === currentStep ? "bg-black-1/80 inset-shadow" : ""
                  }`}
                ></span>
              )}
            </div>

            {index !== steps?.length - 1 && (
              <div className="w-[30px] flex-center">
                <hr
                  className={`border-none h-[30px] w-[2px] bg-black-1/20 transition-colors duration-500 rotate-90 ${
                    index < currentStep ? "bg-green" : ""
                  }`}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MobileSignUpSideBar;
