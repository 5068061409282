import React from "react";
import "./Advantages.css";
import { IMAGE_BASE } from "../../config";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Advantages({ selectProduct }) {
  const advantages =
    selectProduct && selectProduct[0] && selectProduct[0]?.Advantages;
  return (
    <>
      {advantages && Object.keys(advantages)?.length > 0 && (
        <div className="product-description-adv-container">
          <h3>Advantages</h3>

          <div className="product-description-adv-main-container">
            {advantages?.Advantage1 && (
              <div className="product-description-each-adv-div">
                <LazyLoadImage
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_ADVANTAGE/${advantages?.Image1}`}
                  alt=""
                  effect="blur"
                  wrapperProps={{
                    // If you need to, you can tweak the effect transition using the wrapper style.
                    style: { transitionDelay: "1s" },
                  }}
                />
                <span>{advantages?.Advantage1}</span>
              </div>
            )}
            {advantages?.Advantage2 && (
              <div className="product-description-each-adv-div">
                <LazyLoadImage
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_ADVANTAGE/${advantages?.Image2}`}
                  alt=""
                  effect="blur"
                  wrapperProps={{
                    // If you need to, you can tweak the effect transition using the wrapper style.
                    style: { transitionDelay: "1s" },
                  }}
                />
                <span>{advantages?.Advantage2}</span>
              </div>
            )}
            {advantages?.Advantage3 && (
              <div className="product-description-each-adv-div">
                <LazyLoadImage
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_ADVANTAGE/${advantages?.Image3}`}
                  alt=""
                  effect="blur"
                  wrapperProps={{
                    // If you need to, you can tweak the effect transition using the wrapper style.
                    style: { transitionDelay: "1s" },
                  }}
                />
                <span>{advantages?.Advantage3}</span>
              </div>
            )}
            {advantages?.Advantage4 && (
              <div className="product-description-each-adv-div">
                <LazyLoadImage
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_ADVANTAGE/${advantages?.Image4}`}
                  alt=""
                  effect="blur"
                  wrapperProps={{
                    // If you need to, you can tweak the effect transition using the wrapper style.
                    style: { transitionDelay: "1s" },
                  }}
                />
                <span>{advantages?.Advantage4}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Advantages;
