import React from 'react';
import "./MobileProfileComponent.css";
import { useParams } from 'react-router';
import LineOfCredit from '../../components/MyProfile/LineOfCredit';
import Wallet from '../../components/MyProfile/Wallet';
import Security from '../../components/MyProfile/Security';
import MyOrder from '../../components/MyProfile/MyOrder';
import MyPreOrder from '../../components/MyProfile/MyPreOrder';
import MyProfile from '../../components/MyProfile/MyProfile';
import MobileMyOrder from "./MobileMyOrder";
import MobileMyWallet from "./MobileMyWallet";
import MobileSecurity from "./MobileSecurity";

function MobileProfileComponent() {
    const url = useParams();
  return (
    <div className='mobile-profile-component-container'>
        <div className="mobile-profile-content">
            {url.profileUrl === "profile" && <MyProfile/>}
            {url.profileUrl === "preorders" && <MyPreOrder/>}
            {url.profileUrl === "orders" && <MobileMyOrder/>}
            {url.profileUrl === "security" && <MobileSecurity/>}
            {url.profileUrl === "wallet" && <MobileMyWallet/>}
            {url.profileUrl === "credit" && <LineOfCredit/>}
        </div>
    </div>
  )
}

export default MobileProfileComponent