import React from 'react';
import './MasterChains.css';

function MasterChains() {
  return (
    <div className="master-container">
      <div className="master-brand-info">
        <p>Master by Lucky Engineers is recognized as the largest manufacturer of bicycle chains in India, a reputation earned through decades of dedication to quality and innovation. With a state-of-the-art manufacturing facility equipped with cutting-edge technology and a team of skilled engineers and technicians, the brand MASTER stands as a symbol of excellence in the bicycle component industry.</p>
      </div>
      <div className="master-key-highlights">
        <h2>Key Highlights:</h2>
        <ul>
          <li><strong>Established Legacy:- </strong> Nearly 30 years ago, Lucky Engineers began their journey with a vision to revolutionize the cycling experience in India.</li>
          <li><strong>Commitment to Quality:- </strong> MASTER produces high-performance bicycle components that cater to the diverse needs of cyclists nationwide.</li>
          <li><strong>Innovation and R&D:- </strong> Continuous investment in research and development has led to chains that offer superior durability, efficiency, and smooth operation.</li>
          <li><strong>Top-Quality Chains:- </strong> Specializes in manufacturing top-quality chains for the cycling industry, deeply understanding the needs of Indian cyclists.</li>
          <li><strong>Efficiency and Quality:- </strong> MASTER chains are more efficient and on par in quality with leading global brands.</li>
          <li><strong>Pioneering Bushless Chains:- </strong> First and only manufacturer of bushless chains in India, having fully transitioned to this advanced chain type.</li>
          <li><strong>Coining in Single Speed Chains:- </strong> Offers "Coining" even in their most basic single-speed chains, a feature typically found only in premium single-speed and multi-speed chains worldwide.</li>
        </ul>
      </div>
      <p style={{fontSize:"14px"}}>MASTER by Lucky Engineers has truly revolutionized the bicycle chain manufacturing industry in India, setting new standards of quality and performance.</p>
    </div>
  );
}

export default MasterChains;
