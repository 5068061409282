import React from 'react';
import './Dass.css';

function Dass() {
  return (
    <div className="dass-container">
      <div className="dass-brand-info">
        <p>Dass is a pioneering brand established in 1957, specializing in the production of bicycle parts for both the Indian and global markets. The brand has built a reputation as a leader in bicycle brakes and various other components.</p>
      </div>
      <div className="dass-key-products">
        <h2>Key Products:</h2>
        <ul>
          <li>Disc Brakes</li>
          <li>Caliper Brakes</li>
          <li>Cantilever Brakes</li>
          <li>V-Brakes</li>
          <li>Brake Levers</li>
          <li>Hubs</li>
          <li>Air Pumps</li>
        </ul>
      </div>
      <div className="dass-manufacturing-capabilities">
        <h2>Manufacturing Capabilities:</h2>
        <p><b>Advanced Machinery:- </b>Utilizes imported and domestically made machinery including cold forging, CNC turning, sheet cutting, plating (zinc & chrome), phosphating, heat treatment, paint shop, and testing lab.</p>
        <p><b>Tool Room:- </b>Equipped with modern die-making machines.</p>
        <p><b>Environmental Responsibility:- </b>Operates an effluent treatment plant to ensure sustainable production practices.</p>
      </div>
      <div className="dass-certifications-recognition">
        <h2>Certifications and Recognition:</h2>
        <ul>
          <li>Quality Standards: Achieved QS-9000 certification, highlighting commitment to high manufacturing standards.</li>
          <li>Supplier to OEMs: Supplies to almost all Original Equipment Manufacturers (OEMs) in the bicycle industry.</li>
          <li>Awards and Recognitions: Has received numerous awards and recognitions from both central and state governments for excellence in manufacturing and business practices.</li>
        </ul>
      </div>
      <p>Dass continues to innovate and provide high-quality bicycle parts, focusing on durability, performance, and meeting global standards of excellence.</p>
    </div>
  );
}

export default Dass;
