import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { initiateCashfreePayment } from "../../utils/payment";
import WaitingGateway from "./WaitingGateway";
import FullPaymentConfirmation from "./FullPaymentConfirmation";

const PaymentMain = ({
  openPaymentModel,
  setOpenPaymentModal,
  transactionId,
  paymentSessionId,
  userDetail,
  orderNumber,
  amount,
}) => {
  const [openWaitingGateway, setOpenWaitingGateway] = useState(true);
  const [openPaymentConfirmation, setOpenPaymentConfirmation] = useState(false);

  useEffect(() => {
    if (paymentSessionId && transactionId) {
      const timer = setTimeout(() => {
        initiateCashfreePayment(
          userDetail,
          transactionId,
          setOpenWaitingGateway,
          paymentSessionId,
          orderNumber,
          setOpenPaymentConfirmation
        );
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [paymentSessionId, transactionId, orderNumber, userDetail]);

  return (
    <Dialog
      open={openPaymentModel}
      fullWidth={!openPaymentConfirmation} // Use full width only for waiting gateway
      maxWidth={openPaymentConfirmation ? "xs" : "md"} // Limit size for confirmation
      sx={{
        "& .MuiDialog-paper": openPaymentConfirmation
          ? {
              width: "auto", // Set width based on content
              height: "auto", // Set height based on content
              padding: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "12px", // Add custom border radius
            }
          : {
              width: "100vw",
              height: "100vh",
              margin: 0,
              maxWidth: "100vw",
              maxHeight: "100vh",
            },
        "@media (min-width: 600px)": {
          "& .MuiDialog-paper": !openPaymentConfirmation
            ? {
                width: "auto",
                height: "auto",
              }
            : {},
        },
      }}
    >
      {openWaitingGateway && <WaitingGateway />}
      {!openPaymentConfirmation && !openWaitingGateway && (
        <div id="cashfree-payment" style={{ borderRadius: "10px" }}></div>
      )}
      {openPaymentConfirmation && !openWaitingGateway && (
        <FullPaymentConfirmation
          orderNumber={orderNumber}
          setOpenPaymentModal={setOpenPaymentModal}
          userDetail={userDetail}
          amount={amount}
        />
      )}
    </Dialog>
  );
};

export default PaymentMain;
