import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSelector } from "react-redux";
import { selectSelectedAddress } from "../features/cartPage/addressSlice";

import { selectAllCarts } from "../features/cartPage/cartPageSlice";
import toast from "react-hot-toast";
import { steps } from "../utils/utils";

const CartBreadcrum = ({ currentStep, setCurrentStep }) => {
  const selectedAddress = useSelector(selectSelectedAddress);
  const cart = useSelector(selectAllCarts);

  const handleStepClick = (stepIndex) => {
    if (stepIndex <= currentStep) {
      setCurrentStep(stepIndex);
    }
  };
  const handleStepClicksPrev = (stepIndex) => {
    setCurrentStep(stepIndex);
  };
  const handleStepClicks = (stepIndex) => {
    if (currentStep < 2) {
      if (cart && cart?.length <= 0) {
        toast.error("Please add items in cart");
      } else {
        setCurrentStep(stepIndex + 1);
      }
    } else if (currentStep === 2 && selectedAddress !== null) {
      setCurrentStep(3);
    } else if (currentStep === 2 && selectedAddress === null) {
      toast.error("Please add an address");
    }
  };

  return (
    <div className="w-full h-[65px] pt-2 pb-2 flex-center relative">
      {steps?.map((step, i) => (
        <div
          key={i}
          className={`mobile-step-item relative flex-center flex-col w-[85px] md:w-[150px] ${
            currentStep === i + 1 && "active"
          } ${i + 1 < currentStep && "complete"} `}
          onClick={() => handleStepClick(i + 1)}
        >
          <div
            className={`step w-[35px] h-[35px]  flex-center z-1 relative rounded-full font-semibold ${
              currentStep > i + 1
                ? "text-white-1 bg-green"
                : "text-blue bg-gray-200"
            }`}
          >
            {step.icon}
          </div>
          {/* <p
            className={`mt-1 text-[12px] md:text-[14px] ${
              currentStep > i + 1 ? "text-green" : "text-blue"
            }`}
          >
            {step.label}
          </p> */}
        </div>
      ))}
      {currentStep > 1 && (
        <ArrowBackIosIcon
          style={{ fontSize: "30px" }}
          className="absolute top-1/2 transform -translate-y-1/2 left-5 shadow-sm shadow-black-1/20 flex-center text-blue rounded-full p-1 pl-2"
          onClick={() =>
            currentStep > 1 && handleStepClicksPrev(currentStep - 1)
          }
        />
      )}
      {currentStep < 3 && (
        <ArrowForwardIosIcon
          style={{ fontSize: "30px" }}
          className="absolute top-1/2 transform -translate-y-1/2 right-5 shadow-sm shadow-black-1/20 flex-center text-blue rounded-full p-[6px]"
          onClick={() => currentStep < 3 && handleStepClicks(currentStep)}
        />
      )}
    </div>
  );
};

export default CartBreadcrum;
