import React, { useEffect, useRef, useState } from "react";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { gsap } from "gsap";

import { API_BASE_URL } from "../../../config";
import {
  setCodeEntered,
  setLoginMode,
  setOTPVerified,
  setPhoneNumber,
} from "../../../store/slices/userSlice";
import { toggleOverlay } from "../../../store/slices/headerSlice";
import { setToken } from "../../../features/auth/authSlice";
import AutoOTPVerification from "./AutoOTPVerification";
import toast from "react-hot-toast";

function AutoLoginPage({
  user,
  setLoginMode,
  setPhoneNumber,
  setCodeEntered,
  setOTPVerified,
  toggleOverlay,
  setToken,
  setShowAutoLogin,
}) {
  // GSAP CODE
  const containerRef = useRef(null);
  const phoneInputRef = useRef(null);
  useEffect(() => {
    // GSAP animation for loading the component
    gsap.fromTo(
      containerRef.current,
      { y: "100%", opacity: 0 },
      { y: "0%", opacity: 1, duration: 0.5, ease: "power3.out" }
    );

    // Focus on the phone number input when the component is opened
    if (phoneInputRef.current) {
      phoneInputRef.current.focus();
    }
  }, []);
  const handleCloseTab = () => {
    // GSAP animation for closing the component
    gsap.to(containerRef.current, {
      y: "100%",
      opacity: 0,
      duration: 0.5,
      ease: "power3.in",
      onComplete: () => setShowAutoLogin(false),
    });
  };

  // MAIN CODE
  const { loginMode, phoneNumber } = user;
  const [phoneExists, setPhoneExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setLoading(false);
  };
  const handleOpen = () => {
    setLoading(true);
  };

  const handletoggle = () => {
    if (loginMode === "login") {
      setLoginMode("signup");
      setPhoneNumber(null);
    } else {
      setLoginMode("login");
      setPhoneNumber(null);
    }
  };
  const handleSendOTP = async (event) => {
    handleOpen();
    event.preventDefault();
    const formData = new FormData(event.target);
    const phoneNumber = formData.get("phoneNumber");
    if (loginMode === "login") {
      try {
        const response = await fetch(
          `${API_BASE_URL}login_number/${phoneNumber}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          handleClose();
          setPhoneNumber(phoneNumber);
        } else {
          console.error("API request failed:", response.statusText);
          handleClose();
          toast.error('OTP not Sent')
        }
      } catch (error) {
        handleClose();
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        toast.error('Please try again later');
      }
    } else {
      try {
        const response = await fetch(
          `${API_BASE_URL}phone_auth/${phoneNumber}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          handleClose();

          setPhoneNumber(phoneNumber);
        } else {
          handleClose();
          console.error("API request failed:", response.statusText);
          toast.error('OTP not Sent')
        }
      } catch (error) {
        handleClose();
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        toast.error('Please try again later');
      }
    }
  };

  const handlePhoneNumberExist = async (phone) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}singup_phone_verify/${phone}`
      );
      const data = await response.json();

      if (data.status === "error") {
        setPhoneExists(true);
      } else {
        setPhoneExists(false);
      }
    } catch (error) {
      console.error("Error checking phone number existence:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (
      loginMode === "signup" &&
      name === "phoneNumber" &&
      value.length === 10
    ) {
      handlePhoneNumberExist(value);
    } else if (
      loginMode === "signup" &&
      name === "phoneNumber" &&
      value.length < 10
    ) {
      setPhoneExists(false);
    }
  };

  return (
    <>
      <div className="overlay" onClick={handleCloseTab}></div>
      <div
        className="w-full fixed left-0 right-0 bottom-0 z-1000 bg-white-1 flex flex-col items-start justify-start p-[20px] pb-[50px] rounded-t-[14px]"
        ref={containerRef}
      >
        <div
          className="absolute top-[14px] right-[14px] text-black-1 text-[20px]"
          onClick={handleCloseTab}
        >
          <CloseSharpIcon style={{ fontSize: "20px" }} />
        </div>
        <div className="w-auto mt-[10px] mb-[20px]">
          <span className="text-[19px] text-black-1/80 font-extrabold tracking-wider">
            {loginMode === "login" ? "Login" : "Sign Up"} to continue
          </span>
        </div>
        <div className="w-full">
          {phoneNumber ? (
            <AutoOTPVerification
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              handleCloseTab={handleCloseTab}
            />
          ) : (
            <form
              onSubmit={handleSendOTP}
              className="w-full flex flex-col items-start"
            >
             
              <label
                htmlFor="phoneNumber"
                className="text-[purple] tracking-wide font-bold m-0 text-[15px]"
              >
                Phone Number
              </label>
              <input
                type="number"
                id="auto-phoneNumber"
                className="w-full py-[5px] mb-[5px] border-x-0 border-t-0 border-b-[2.5px] border-[purple] text-[purple] text-[18px] outline-none tracking-wider caret-[purple]"
                maxLength="10"
                max="9999999999"
                name="phoneNumber"
                onChange={handleInputChange}
                pattern="\d{10}"
                ref={phoneInputRef}
                onKeyPress={(e) => {
                  if (!/[0-9\b]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                onInput={(e) => {
                  if (e.target.value.length > 10) {
                    e.target.value = e.target.value.slice(0, 10);
                  }
                }}
                required
              />
              {phoneExists && (
                <span className="text-[11px] text-peach text-right -m-t-1 mr-1 font-semibold tracking-wide">
                  Ph. Number already registered with Cyclecircle
                </span>
              )}
              <button
                id="auto-login-btn"
                className={`w-full h-[45px] p-[10px] rounded-md text-white-1 font-extrabold tracking-wider text-16 outline-none border-none mt-5 ${
                  loading ? "bg-[purple]/80" : "bg-[purple]"
                }`}
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <div className="auto-spinner"></div>
                  </>
                ) : (
                  "Send OTP"
                )}
              </button>
            </form>
          )}
        </div>
        <div className="flex-center my-2 mx-auto text-black-1/80 font-bold text-[15px]">
          <p className="text-[14px] m-0 mr-1">
            {loginMode === "login"
              ? "Don't have an account"
              : "Already have an account"}
            ?
          </p>
          <span className="text-[16px] text-[purple]" onClick={handletoggle}>
            {loginMode === "login" ? "Sign up" : "Login"}
          </span>
        </div>
        <div className="w-full text-center text-black-1/40 font-bold tracking-wide text-14 mt-[20px]">
          <span className="text-[14px] text-gray-700">
            By continuing, you agree to Cyclecircle's{" "}
            <Link
              to="/privacypolicy"
              className="text-[purple] font-extrabold hover:underline"
            >
              Privacy Policy
            </Link>{" "}
            and{" "}
            <Link
              to="/support"
              className="text-[purple] font-extrabold hover:underline"
            >
              User Agreement
            </Link>{" "}
          </span>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  setLoginMode,
  setPhoneNumber,
  setCodeEntered,
  setOTPVerified,
  toggleOverlay,
  setToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoLoginPage);
