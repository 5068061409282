import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API_BASE_URL } from "../../../config";
import { VerifiedAadhar } from "../../../mComponent/VerifiedDocument";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import {
  OTPVerificationModal,
  OTPVerified,
  VerifyOTP,
} from "../../../mComponent/OTPVerification";
import toast from "react-hot-toast";

function MobileBusinessOwner({
  updateFinalData,
  finalData,
  aadharVerify,
  setAadharVerify,
  setHerebyDeclare,
  herebyDeclare,
}) {
  const [verify, setVerify] = useState(false);
  const [errors, setErrors] = useState({});
  const [aadharExists, setAadharExists] = useState(false);
  const [openAadharOTP, setOpenAadharOTP] = useState(false);
  const [timer, setTimer] = useState(60);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [refNo, setRefNo] = useState(null);
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const startTimer = () => {
    setResendDisabled(true);
    setTimer(60);
  };

  // Effect to decrement timer every second
  useEffect(() => {
    let intervalId;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setResendDisabled(false);
      clearInterval(intervalId);
    }
    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
  }, [timer]);

  useEffect(() => {
    if (finalData?.aadhar?.length < 12) {
      setAadharVerify(false);
      updateFinalData({ ["Aadhar_DATA"]: null });
    }
  }, [finalData?.aadhar?.length]);

  const handleVerifyAadhar = async () => {
    setOTP(["", "", "", "", "", ""]);
    handleOpen();
    if (!finalData.aadhar || finalData.aadhar.length < 10) {
      handleClose();
      toast.error('Enter Aadhar correctly')
    } else {
      try {
        const response = await fetch(
          `${API_BASE_URL}aadhar_send_otp/${finalData.aadhar}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (data.Aadhar.message === "OTP sent successfully") {
          setOpenAadharOTP(true);
          setRefNo(data.Aadhar.ref_id);
          handleClose();
        } else {
          setVerify(true);
          handleClose();
          toast.error("Aadhar Doesn't Exist")
        }
      } catch (error) {
        handleClose();
        toast.error("Aadhar Doesn't Exist")
        console.error(
          "An error occurred during the API request:",
          error.message
        );
      }
    }
  };
  const handleVerifyAadharAgain = async () => {
    handleOpen();
    if (!finalData.aadhar || finalData.aadhar.length < 12) {
      handleClose();
      toast.error('Enter Aadhar correctly')
    } else {
      if (timer > 0) {
        // Timer is still running, do nothing
        return;
      }
      startTimer();
      try {
        const response = await fetch(
          `${API_BASE_URL}aadhar_send_otp/${finalData.aadhar}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (data.Aadhar.message === "OTP sent successfully") {
          setOpenAadharOTP(true);
          setRefNo(data.Aadhar.ref_id);
          handleClose();
        } else {
          setVerify(true);
          handleClose();
         toast.error("Aadhar Doesn't Exist")
        }
      } catch (error) {
        handleClose();
        toast.error('Enter Valid Aadhar Number')
        console.error(
          "An error occurred during the API request:",
          error.message
        );
      }
    }
  };

  function validateAadhaar(aadhar) {
    const regex = /^\d{12}$/;
    return regex.test(aadhar);
  }

  const handleVerifyOTP = async () => {
    handleOpen();
    const enteredOTP = otp.join("");
    // Perform OTP verification logic here using enteredOTP

    if (!enteredOTP || enteredOTP.length < 6) {
      handleClose();
      toast.error('Enter OTP correctly')
    } else {
      try {
        const response = await fetch(
          `${API_BASE_URL}aadhar_otp_verify/${enteredOTP}/${refNo}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (data.Aadhar.message === "Aadhaar Card Exists") {
          updateFinalData({ Aadhar_DATA: data.Aadhar });
          setAadharVerify(true);
          setOpenAadharOTP(false);
          handleClose();
        } else {
          handleClose();
          toast.error('Invalid OTP');
        }
      } catch (error) {
        handleClose();
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        toast.error('Please try again later');
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updateValue = value.toUpperCase();
    updateFinalData({ [name]: updateValue });

    if (name === "aadhar" && value.length === 12) {
      handleAadharBlur(e);
    } else if (name === "aadhar" && value.length < 12) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        Aadhar: "",
      }));
    }
  };

  const handleAadharBlur = async (e) => {
    const aadhar = e.target.value?.toUpperCase();
    if (!validateAadhaar(aadhar)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        Aadhar: "Please enter a valid Aadhar number",
      }));
      return;
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}singup_aadhar_verify/${aadhar}`
      );
      const data = await response.json();

      if (data.status === "error") {
        setAadharExists(true);
        setErrors((prevErrors) => ({
          ...prevErrors,
          Aadhar: "Aadhar already registered with Cyclecircle",
        }));
      } else {
        setAadharExists(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          Aadhar: "",
        }));
      }
    } catch (error) {
      console.error("Error checking Aadhar existence:", error);
    }
  };

  const handleHerebyDeclareChange = () => {
    setHerebyDeclare(!herebyDeclare);
  };

  return (
    <div className="w-full min-h-[72vh] flex flex-col items-start justify-start py-4 px-3 bg-white-1">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <span className="text-[13px] text-black-1/70 font-semibold my-2">
        We need the ID proof of the business owner for verification purposes.
      </span>
      <div className="w-full flex flex-col items-center justify-start mt-[10px] mb-[25px]">
        <TextField
          label="Aadhar Number"
          variant="outlined"
          inputmode="numeric"
          type="number"
          id="AadharInput"
          placeholder="Ex:234538653926"
          fullWidth
          maxLength="12"
          name="aadhar"
          color={validateAadhaar(finalData?.aadhar) && "success"}
          value={finalData.aadhar || ""}
          onChange={(e) => {
            const inputValue = e.target.value.replace(/\s+/g, "");
            if (inputValue.length <= 12) {
              handleInputChange({
                target: {
                  name: e.target.name,
                  value: inputValue,
                },
              });
            }
          }}
          onBlur={handleAadharBlur}
          error={
            (errors["Aadhar"] || aadharExists) &&
            !validateAadhaar(finalData?.aadhar)
          }
          helperText={errors["Aadhar"] || ""}
          required
        />
        {!aadharVerify &&
          !errors["Aadhar"] && !aadharExists &&
          finalData?.aadhar &&
          finalData?.aadhar?.length === 12 && (
            <VerifyOTP handleVerify={handleVerifyAadhar} />
          )}
        {aadharVerify && <OTPVerified />}
      </div>
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleHerebyDeclareChange}
            checked={herebyDeclare}
          />
        }
        className=" text-black-1/70"
        label={
          <span className="text-[14px]">
            I/We hereby declare, represent, and undertake.{" "}
            <Link to="#" className="text-[purple] underline">
              Terms & Conditions
            </Link>
          </span>
        }
      />

      {openAadharOTP && (
        <OTPVerificationModal
          text={finalData?.PhoneNumber}
          otp={otp}
          setOTP={setOTP}
          setVerify={setOpenAadharOTP}
          resendOTP={handleVerifyAadharAgain}
          handleVerify={handleVerifyOTP}
          timer={timer}
          setTimer={setTimer}
          resendDisabled={resendDisabled}
          open={openAadharOTP}
          onClose={() => setOpenAadharOTP(false)}
        />
      )}

      {aadharVerify && <VerifiedAadhar finalData={finalData } />}
    </div>
  );
}

export default MobileBusinessOwner;
