import React from "react";
import { useMediaQuery } from "react-responsive";
import AdminDashboard from "../components/AdminDashboard/AdminDashboard";
import MobileAdminDashboard from "../mobileComponent/MobileAdminDashboard/MobileAdminDashboard";

function AdminDashbd() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 501px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  return (
    <>
      {isDesktopOrLaptop && <AdminDashboard />}
      {isTabletOrMobile && <MobileAdminDashboard />}
    </>
  );
}

export default AdminDashbd;
