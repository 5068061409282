import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LockIcon from "@mui/icons-material/Lock";
import cyclecircleNameLogo from "../../img/parts/CyclecircleLogo.png";
import cyclecircleOrangeLogo from "../../img/parts/Cycle-Circle-Logo-Orange-1.png";
import googlePlus from "../../img/parts/GooglePlus.png";
import twitter from "../../img/parts/TwitterX.png";
import { setSelectedSupport } from "../../store/slices/supportSlice";

import {
  FaCcMastercard,
  FaCcPaypal,
  FaCcAmex,
  FaCcVisa,
  FaCcDiscover,
} from "react-icons/fa";
function Footer({ setSelectedSupport }) {
  return (
    <>
      <Container>
        <UpperContainer>
          <Information>
            <h3>About Us</h3>
            <span>
              <Link to="/aboutUs">Know About Us</Link>
            </span>
            <span>
              <Link to="/aboutUs">Become a member</Link>
            </span>

            <span>
              <Link to="/refund">Exclusively on CC</Link>
            </span>
            <span>
              <Link to="/our_belief_system">Our Belief System</Link>
            </span>
            <span>
              <Link to="/privacypolicy">Privacy Policy</Link>
            </span>
            <span>
              <Link to="/copyright">CopyRight</Link>
            </span>
          </Information>
          <Help>
            <h3 className="text-white-1">Cyclecircle Support</h3>
            <span>
              <Link to="/support" onClick={() => setSelectedSupport(1)}>
                Track My Order
              </Link>
            </span>
            <span>
              <Link to="/support" onClick={() => setSelectedSupport(5)}>
                Returns and Refunds
              </Link>
            </span>
            <span>
              <Link to="/support" onClick={() => setSelectedSupport(4)}>
                User Agreement
              </Link>
            </span>
            <span>
              <Link to="/support" onClick={() => setSelectedSupport(3)}>
                Data Privacy Policy
              </Link>
            </span>
            <span>
              <Link to="/support" onClick={() => setSelectedSupport(2)}>
                Shipping Policy
              </Link>
            </span>
          </Help>
          <Contact>
            <h3>Contact Us</h3>
            <LogoDiv>
              <img loading="lazy" src={cyclecircleOrangeLogo} alt="" />
            </LogoDiv>
            <Address>
              <Location style={fontS} />
              <p>
                <b>RADKAAT VENTURES PRIVATE LIMITED</b>
                <br /> 1st Floor, Unit no.124, Paras Trade Centre,
                <br /> Gurgaon Faridabad Road, Gwal Pahari, Gurugram,
                <br /> District, Gurugram, Haryana 122003
                <br />
                <br /> <b>GST: 02AAMCR8372H1Z4</b>
              </p>
            </Address>
            <hr />
            <Phone>
              <Ph style={fontS} />
              <p>+91-9429693000 </p>
            </Phone>
            <Email>
              <Mail style={fontS} />
              <p >support@cyclecircle.one</p>
            </Email>
          </Contact>
          {/* <TradeDiv>
            *cyclecircle.one is a brand name fully owned by RADKAAT VENTURES
            PRIVATE LIMITED
          </TradeDiv> */}
        </UpperContainer>
        <BottomFooter>
          <FollowUs>
            <h3 className="text-white-1">Stay Connected</h3>
            <Social>
              <Link
                to="https://www.instagram.com/cyclecircle.one"
                target="_blank"
              >
                <Instagram src="https://cdn-icons-png.flaticon.com/128/2111/2111463.png" />
              </Link>
              <Link
                to="https://www.facebook.com/profile.php?id=100083101911813&_rdr"
                target="_blank"
              >
                <Facebook
                  style={fontSS}
                  src="https://cdn-icons-png.flaticon.com/128/733/733547.png"
                />
                {/* <Link to="https://www.twitter.com/cyclecircle" target="_blank">
                  <Twitter src="https://cdn-icons-png.flaticon.com/128/2504/2504947.png" />
                </Link> */}
              </Link>
              <Link
                to="https://www.youtube.com/@cyclecircle5922"
                target="_blank"
              >
                <Youtube src="https://cdn-icons-png.flaticon.com/128/3938/3938026.png" />
              </Link>
              {/* <Link to="https://www.google.com/cyclecircle" target="_blank">
                <Google src="https://cdn-icons-png.flaticon.com/128/2111/2111450.png" />
              </Link> */}
            </Social>
          </FollowUs>
          <Reserverd>
            © Radkaat Ventures Private Limited @2024, All Rights Reserved
          </Reserverd>
          <PaymentGateway>
            {/* <Secure>
              <LockIcon />
              <p>Secure Online Payment</p>
            </Secure> */}
            <Payment>
              <FaCcMastercard />
              <FaCcPaypal />
              <FaCcAmex />
              <FaCcVisa />
              <FaCcDiscover />
            </Payment>
          </PaymentGateway>
        </BottomFooter>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  setSelectedSupport,
};
export default connect(mapStateToProps, mapDispatchToProps)(Footer);

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media screen and (min-width: 501px) and (max-width: 1000px) {
    
  }
`;
const UpperContainer = styled.div`
  width: 100%;
  min-height: 45vh;
  height: auto;
  background: var(--color-blue);
  display: flex;
  align-items: flex-start;
  border-radius: 0 0 20px 20px;
  justify-content: space-between;
  z-index: 1;
  padding: 20px 50px 50px 50px;
  position: relative;
  @media screen and (min-width: 501px) and (max-width: 1000px) {
    padding: 20px;
    min-height:auto;
  }
`;
const TradeDiv = styled.span`
  position: absolute;
  font-size: 0.9vw;
  font-weight: 600;
  color: white;
  letter-spacing: 0.5px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2vh;
`;
const BottomFooter = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: var(--color-blue-hover);
  padding: 7vh 2vw 1vh 2vw;
  margin-top: -2.8vh;
  @media screen and (min-width: 501px) and (max-width: 1000px) {
    padding:5vh 2vw 1vh 2vw;
    margin-top:-3vh;
  }
`;
const Reserverd = styled.span`
  font-size: 0.85rem;
  font-weight: 500;
  color: white;
  @media screen and (min-width: 501px) and (max-width: 1000px) {
    text-align:center;
  }
`;
const Information = styled.div`
  min-width: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  color: white;
  font-size: 0.9rem;
  h3 {
    padding-right: 1rem;
    font-size: 1.5rem;
    margin: 2vh 0;
  }
  p {
    font-size: 0.9rem;
    font-weight: 500;
  }
  span {
    padding: 5px 0;
    a {
      color: white;
      text-decoration: none;
      text-transform: capitalize;
      font-size: 0.85rem;
      font-weight: 500;
      &:hover {
        color: var(--color-gold);
      }
    }
    margin-bottom: 6px;
  }
  @media screen and (min-width: 501px) and (max-width: 1000px) {
    margin-right:10px;
    h3{
      font-size:1.2rem;
    }
  }
`;
const LogoDiv = styled.div`
  display: flex;
  align-items: center;
  // margin: 10px 0;
  // margin-bottom: 15px;
  position:absolute;
  top:0;
  right:0;
  img {
    width: auto;
    height: 7vh;
    margin-top: 1vh;
    // margin-right: 10px;
  }
  @media screen and (min-width: 501px) and (max-width: 1000px) {
    img{
      height:5vh;
      margin-top:0;
    }
  }
`;

const FollowUs = styled.div`
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  h3 {
    margin: 0 1vw 0.5vh 0;
    font-size: 0.9rem;
  }
  @media screen and (min-width: 501px) and (max-width: 1000px) {
    margin-right:10px;
  }
`;
const Payment = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;

  svg {
    font-size: 1.1rem;
    margin-right: 0.8rem;
    color: white;
  }
  @media screen and (min-width: 501px) and (max-width: 1000px) {
    margin-left:10px;
  }
`;


const Own = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h3 {
    padding: 0;
    margin: 0;
    margin: 20px 0;
    font-size: 1.5rem;
    text-transform: capitalize;
    margin: 2vh 0;
  }
  span {
    padding: 5px 0;
    a {
      color: white;
      text-decoration: none;
      font-size: 0.85rem;
      font-weight: 500;
      &:hover {
        color: var(--color-gold);
      }
    }
    margin-bottom: 6px;
  }
  @media screen and (min-width: 501px) and (max-width: 1000px) {
    margin-right:10px;
    h3{
      font-size:1.2rem;
    }
  }
`;
const Help = styled(Own)`
  h3 {
    text-transform: capitalize;
  }
`;

const Contact = styled.div`
width: auto;
color: white;
position:relative;
h3 {
  padding: 0 2.2rem;
  margin:2vh 0;
  margin-left: -2.2rem;
  font-size: 1.5rem;
}
hr {
  width: 89%;
  margin:0;
  margin-top:10px;
  margin-left: 2.5rem;
}
@media screen and (min-width: 501px) and (max-width: 1000px) {
  h3{
    font-size:1.2rem;
  }
}
`;

const Address = styled.div`
  display: flex;
  align-items: flex-start;
  text-transform: none;
  margin-top:4vh;
  p {
    margin: 0;
    margin-left: 0.8rem;
    line-height: 1.5;
    b {
      font-size: 0.9rem;
      color: white;
    }
  }
  // border-bottom: 2px solid white;
  font-size: 0.8rem;

  @media screen and (min-width: 501px) and (max-width: 1000px) {
    margin-top:2vh;
  }
`;
const Location = styled(LocationOnIcon)`
  color: white;
  // margin-top: 0.8rem;
  @media screen and (min-width: 501px) and (max-width: 1000px) {
    /* Your CSS rules here */
  }
`;
const Phone = styled.div`
  display: flex;
  align-items: center;
  text-transform: none;
  p {
    margin-left: 0.8rem;
  }
  font-size: 0.9rem;
  @media screen and (min-width: 501px) and (max-width: 1000px) {
    /* Your CSS rules here */
  }
`;
const Ph = styled(PhoneIcon)`
  color: white;
  @media screen and (min-width: 501px) and (max-width: 1000px) {
    /* Your CSS rules here */
  }
`;
const Email = styled.div`
  display: flex;
  align-items: center;
  text-transform: none;
  // margin-top: -10px;
  p {
    margin-left: 0.8rem;
  }
  font-size: 0.9rem;
  @media screen and (min-width: 501px) and (max-width: 1000px) {
    /* Your CSS rules here */
  }
`;
const Mail = styled(MailIcon)`
  color: white;
  @media screen and (min-width: 501px) and (max-width: 1000px) {
    /* Your CSS rules here */
  }
`;
const Social = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media screen and (min-width: 501px) and (max-width: 1000px) {
    /* Your CSS rules here */
  }
`;
const Facebook = styled.img`
  width: 2rem;
  height: auto;
  padding: 0px 8px;
  margin: 0 0.2rem;
  margin-left: 0;
  cursor: pointer;
  transition: all 0.3s ease; /* Add a transition effect */

  &:hover {
    transform: scale(1.2); /* Increase size on hover */
  }
  @media screen and (min-width: 501px) and (max-width: 1000px) {
    /* Your CSS rules here */
  }
`;
const Instagram = styled(Facebook)``;
const Youtube = styled(Facebook)``;

const PaymentGateway = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media screen and (min-width: 501px) and (max-width: 1000px) {
    /* Your CSS rules here */
  }
`;

const fontS = {
  fontSize: "22",
};
const fontSS = {
  fontSize: "20",
};
