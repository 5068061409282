import React from "react";
import { Pagination, PaginationItem, Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Paginations=({
  totalItems,
  itemsPerPage,
  onPageChange,
  currentPage,
  setCurrentPage,
})=> {

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (event, page) => {
    if (page <= 1) {
      setCurrentPage(1);
      onPageChange(1);
    } else if (page > totalPages) {
      setCurrentPage(totalPages);
      onPageChange(totalPages);
    } else {
      setCurrentPage(page);
      onPageChange(page);
    }
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <Stack
      spacing={2}
      className="w-full flex-center relative inset-shadow-black bg-white-1 py-3"
    >
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        renderItem={(item) => (
          <PaginationItem
            slots={{
              previous: ArrowBackIcon,
              next: ArrowForwardIcon,
            }}
            {...item}
          />
        )}
        // Optionally, you can customize the styles
        sx={{
          "& .MuiPaginationItem-root": {
            backgroundColor: "#f3f3f3",
            color: "#000",
            margin: "0 5px",
            "&.Mui-selected": {
              backgroundColor: "#283248",
              color: "#fff",
            },
            "&:hover": {
              backgroundColor: "#283248",
              color: "#fff",
            },
          },
        }}
      />
    </Stack>
  );
}

export default Paginations;
