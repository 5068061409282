import React from "react";
import Header from "../components/HomePage/Header";
import Footer from "../components/HomePage/Footer";
import styled from "styled-components";
import ScrollPromices from "../components/HomePage/ScrollPromices";
import ViewOrderDetail from "../components/MyProfile/ViewOrderDetail";
import { useMediaQuery } from "react-responsive";
import MobileHeader from "../mobileComponent/MobileEndpoints/MobileHeader";
import MobileViewOrder from "../mobileComponent/MobileMyProfile/MobileViewOrder";

function ViewOrderPage() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 501px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" });
  return (
    <div>
      {isDesktopOrLaptop && (
        <>
          <HeaderDiv>
            <ScrollPromices />
            <Header />
          </HeaderDiv>
          <ViewOrderDetail />
          <Footer />
        </>
      )}
      {isTabletOrMobile && (
        <>
          <MobileHeaderDiv>
            <MobileHeader />
          </MobileHeaderDiv>
          <MobileViewOrder />
        </>
      )}
    </div>
  );
}

export default ViewOrderPage;

const HeaderDiv = styled.div`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const MobileHeaderDiv = styled.div`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;
