// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    productID:0,
  salePrice:0,
  quantity:0,
};

const selectVarientSlice = createSlice({
  name: 'selectVarient',
  initialState,
  reducers: {
    setProductID: (state, action) => {
      state.productID = action.payload;
    },
    setSalePrice: (state, action) => {
      state.salePrice = action.payload;
    },
    setQuantity: (state, action) => {
      state.quantity = action.payload;
    },
  },
});

export const {setProductID, setSalePrice, setQuantity} = selectVarientSlice.actions;
export const selectVarient = (state) => state.selectVarient;

export default selectVarientSlice.reducer;
