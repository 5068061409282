import React, { useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { IMAGE_BASE } from "../../config";
import EditBasicProfile from "./EditBasicProfile";

const BasicProfile = ({ userDetail }) => {
  const [openEditProfile, setOpenEditProfile] = useState(false);
  const handleEditProfile = () => {
    setOpenEditProfile(!openEditProfile);
  };
  return (
    <div className="w-full flex items-center relative bg-white-1 shadow-md shadow-black-1/10 rounded-md px-4 py-5 my-4 mt-[70px] sm:mt-4">
      <div
        className="absolute top-3 right-3 flex items-center cursor-pointer"
        onClick={handleEditProfile}
      >
        <BorderColorIcon
          className="text-green mr-1"
          style={{ fontSize: "16px" }}
        />
        <span className="text-[14px] font-bold text-green">Edit</span>
      </div>
      <LazyLoadImage
        src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
          userDetail && userDetail?.UserImage
        }`}
        alt="user-logo"
        className="w-[120px] h-[120px] rounded-full border-[1px] border-black-1/30 p-[2px]"
        effect="blur"
        wrapperProps={{
          // If you need to, you can tweak the effect transition using the wrapper style.
          style: { transitionDelay: "1s" },
        }}
      />
      <div className="flex flex-col ml-3 sm:ml-5">
        <div className="text-[16px] font-extrabold tracking-wider text-black-1">
          {userDetail &&
            userDetail?.FirstName &&
            userDetail?.FirstName.charAt(0).toUpperCase() +
              userDetail?.FirstName.slice(1)}{" "}
          {userDetail &&
            userDetail?.LastName &&
            userDetail?.LastName.charAt(0).toUpperCase() +
              userDetail?.LastName.slice(1)}
        </div>

        <span className="flex items-center my-1">
          <CalendarMonthIcon
            className="text-black-1/70 mr-1"
            style={{ fontSize: "16px" }}
          />
          <span className="text-[14px] text-black-1/70 font-semibold">
            {userDetail && userDetail?.DOB}
          </span>
        </span>
        <div className="my-profile-basic-profile-details-gender">
          <AcUnitIcon
            className="text-black-1/70 mr-1"
            style={{ fontSize: "16px" }}
          />
          <span className="text-[14px] text-black-1/70 font-semibold">
            {userDetail && userDetail?.Gender}
          </span>
        </div>
        <div className="my-profile-basic-profile-details-phone-number">
          <LocalPhoneIcon
            className="text-black-1/70 mr-1"
            style={{ fontSize: "16px" }}
          />
          <span className="text-[14px] text-black-1/70 font-semibold">
            {userDetail && userDetail?.PhoneNumber}
          </span>
          <CheckCircleIcon
            className="text-green ml-1"
            style={{ fontSize: "16px" }}
          />
        </div>
        <div className="my-profile-basic-profile-details-email">
          <EmailIcon
            className="text-black-1/70 mr-1"
            style={{ fontSize: "16px" }}
          />
          <span className="text-[14px] text-black-1/70 font-semibold">
            {userDetail && userDetail?.Email}
          </span>
        </div>
      </div>
      {openEditProfile && (
        <EditBasicProfile
          openEditProfile={openEditProfile}
          setOpenEditProfile={setOpenEditProfile}
        />
      )}
    </div>
  );
};

export default BasicProfile;
