import React, { useState } from "react";
import "./ProductAllInfo.css";
import Description from "./Description";
import RatingsReviews from "./Ratings_Reviews";
import TechnicalData from "./TechnicalData";
import vector_black from "../../icons/Vector_black.svg";
import vector_white from "../../icons/Vector_white.svg";
import data_black from "../../icons/data_black.svg";
import data_white from "../../icons/data_white.svg";
import review_black from "../../icons/review_black.svg";
import review_white from "../../icons/review_white.svg";
import insight_white from "../../icons/Insights-outline.svg";
import insight_black from "../../icons/Insights-filled.svg";
import informationMaganta from "../../icons/information-maganta.png";
import informationBlack from "../../icons/information-black.png";
import AboutBrands from "./AboutBrands";

/**Mobile Import */
import Insights from "./Insights";

function ProductAllInfo({ productDesc }) {
  const [infoTitle, setInfoTitle] = useState("Compatibility");

  return (
    <>
      <div className="product-all-info">
        <ul className="product-info-menu">
          <li
            onClick={() => setInfoTitle("Compatibility")}
            className={`p-info-list ${
              "Compatibility" === infoTitle ? "active-tabs-compatility" : ""
            }`}
            style={{
              color: `${
                "Compatibility" === infoTitle ? "var(--color-green)" : ""
              }`,
              borderColor: "var(--color-blue)",
              // fontSize: "14px",
            }}
          >
            <img
              loading="lazy"
              className="info-img-compatibility"
              src={`${
                "Compatibility" === infoTitle ? vector_white : vector_black
              }`}
              alt=""
            />
            Product Description
          </li>
          {/* <li
            onClick={() => setInfoTitle("Insights")}
            className={`p-info-list ${
              "Insights" === infoTitle ? "active-tabs-insights" : ""
            }`}
            style={{
              color: `${"Insights" === infoTitle ? "var(--color-peach)" : ""}`,
              borderColor: "var(--color-blue)",
            
            }}
          >
           
            <img loading="lazy"
              className="info-img-insights"
              src={`${
                "Insights" === infoTitle ? insight_black : insight_white
              }`}
              alt=""
            />
            Insights
          </li> */}
          <li
            onClick={() => setInfoTitle("Technical Data")}
            className={`p-info-list ${
              "Technical Data" === infoTitle ? "active-tabs-technical" : ""
            }`}
            style={{
              color: `${
                "Technical Data" === infoTitle ? "var(--color-blue)" : ""
              }`,
              borderColor: "var(--color-blue)",
              // fontSize: "14px",
            }}
          >
            <img
              loading="lazy"
              className="info-img"
              src={`${
                "Technical Data" === infoTitle ? data_white : data_black
              }`}
              alt=""
            />
            Technical Data
          </li>
          {/* <li
            onClick={() => setInfoTitle("How To")}
            className={`p-info-list ${
              "How To" === infoTitle ? "active-tabs-how" : ""
            }`}
            style={{
              color: `${"How To" === infoTitle ? "var(--color-peach)" : ""}`,
              borderColor: "var(--color-blue)",
              fontSize: "14px",
            }}
          >
            <img loading="lazy" className="info-img"  src={`${
                "How To" === infoTitle ? play_white : play_black
              }`} alt="" />
            How To
          </li> */}
          <li
            onClick={() => setInfoTitle("Reviews")}
            className={`p-info-list ${
              "Reviews" === infoTitle ? "active-tabs-reviews" : ""
            }`}
            style={{
              color: `${"Reviews" === infoTitle ? "var(--color-purple)" : ""}`,
              borderColor: "var(--color-blue)",
              // fontSize: "14px",
            }}
          >
            <img
              loading="lazy"
              className="info-img"
              src={`${"Reviews" === infoTitle ? review_white : review_black}`}
              alt=""
            />
            Reviews
          </li>
          <li
            onClick={() => setInfoTitle("About Brands")}
            className={`p-info-list ${
              "About Brands" === infoTitle ? "active-tabs-About-Brands" : ""
            }`}
            style={{
              color: `${
                "About Brands" === infoTitle ? "rgba(20, 91, 104, 1)" : ""
              }`,
              borderColor: "var(--color-blue)",
              // fontSize: "14px",
            }}
          >
            <img
              loading="lazy"
              className="info-img"
              src={`${
                "About Brands" === infoTitle
                  ? informationMaganta
                  : informationBlack
              }`}
              alt=""
              style={{ borderRadius: "0", padding: "7px" }}
            />
            About Brands
          </li>
        </ul>
        {infoTitle === "Insights" && (
          <div
            className={`info-container ${
              infoTitle === "Insights" ? "active" : ""
            }`}
          >
            <Insights productDesc={productDesc} />
          </div>
        )}
        {infoTitle === "About Brands" && (
          <div
            className={`info-container ${
              infoTitle === "About Brands" ? "active" : ""
            }`}
          >
            <AboutBrands />
          </div>
        )}
        {infoTitle === "Reviews" && (
          <div
            className={`info-container ${
              infoTitle === "Reviews" ? "active" : ""
            }`}
          >
            <RatingsReviews />
          </div>
        )}
        {infoTitle === "Technical Data" && (
          <div
            className={`info-container ${
              infoTitle === "Technical Data" ? "active" : ""
            }`}
          >
            <TechnicalData productDesc={productDesc} />
          </div>
        )}
        {/* {infoTitle === "How To" && (
          <div
            className={`info-container ${
              infoTitle === "How To" ? "active" : ""
            }`}
          >
            <Discover productDesc={productDesc}/>
          </div>
        )} */}
        {infoTitle === "Compatibility" && (
          <div
            className={`info-container ${
              infoTitle === "Compatibility" ? "active" : ""
            }`}
          >
            <Description productDesc={productDesc} />
          </div>
        )}
      </div>
    </>
  );
}

export default ProductAllInfo;
