import React, { useEffect, useState } from "react";
import BadgeGold from "../../assets/Badge-Gold.png";
import BadgeSilver from "../../assets/Badge-Silver.png";
import BadgePlatinum from "../../assets/Badge-Platinum.png";
import LOCChartIconRed from "../../assets/LOC-Chart-Icon-red.png";
import {
  calculateDueDate,
  calculateSpentPercentage,
  formatDate,
  formatWithCommasWithDecimal,
} from "../../utils/utils";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CreditCard = ({
  iconType,
  color,
  cardType,
  cardText,
  Amount,
  updateText,
  updateDate,
  spendDays,
  creditDetail,
  recentOrder,
  creditHistory,
}) => {
  const [pendingOrder, setPendingOrder] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const getBadgeIcon = () => {
    if (cardType === "credit") {
      switch (iconType) {
        case "Gold":
          return (
            <img
              loading="lazy"
              src={BadgeGold}
              alt="badge"
              width={55}
              height={55}
            />
          );
        case "Silver":
          return (
            <img
              loading="lazy"
              src={BadgeSilver}
              alt="badge"
              width={55}
              height={55}
            />
          );
        case "Diamond":
          return (
            <img
              loading="lazy"
              src={BadgePlatinum}
              alt="badge"
              width={55}
              height={55}
            />
          );
        default:
          return "";
      }
    } else if (cardType === "balance") {
      const percentage = calculateSpentPercentage(
        creditDetail?.Credit_Limit,
        creditDetail?.spentAmount
      );
      return (
        <CircularProgressbar
          value={percentage}
          text={`${percentage}%`}
          styles={buildStyles({
            strokeLinecap: "round",
            textSize: "25px",
            pathTransitionDuration: 0.5,
            pathColor: "#283248",
            textColor: "#283248",
            trailColor: "#d6d6d6",
          })}
        />
      );
    }
    return (
      <img
        loading="lazy"
        src={LOCChartIconRed}
        alt="badge"
        width={55}
        height={55}
      />
    );
  };

  useEffect(() => {
    if (cardType === "spent" && recentOrder?.length > 0) {
      const pending = recentOrder
        ?.filter((order) => order?.transactionType === "SPEND")
        ?.sort(
          (a, b) => new Date(a?.transactionDate) - new Date(b?.transactionDate)
        )[0];

      setPendingOrder(pending);

      if (pending) {
        const dueDate = calculateDueDate(pending?.transactionDate);
        setDueDate(dueDate);
      }
    }
    if (cardType === "balance" && creditHistory?.length > 0) {
      const update = [...creditHistory]?.sort(
        (a, b) => new Date(b?.paymentDate) - new Date(a?.paymentDate)
      )[0];
      setLastUpdate(update?.paymentDate);
    }
  }, [recentOrder, cardType, creditHistory]);

  return (
    <div className="w-full lg:w-[300px] h-full flex flex-col justify-between bg-white-1 p-4">
      <div className="w-full flex items-center">
        <div className="w-[60px] h-[60px] mr-5 font-sans font-bold">
          {getBadgeIcon()}
        </div>
        <div className="flex flex-col">
          <span className="text-[14px] text-black-1/80 tracking-wider">
            {cardText}
          </span>
          <span className={`text-[28px] text-${color} font-bold mt-1`}>
            ₹{formatWithCommasWithDecimal(Amount)}
          </span>
        </div>
      </div>
      <span className="flex items-center text-[12px] text-black-1/60 tracking-wider mt-4">
        {cardType === "spent" ? (
          pendingOrder && dueDate ? (
            <p>
              You have&nbsp;
              <b className={`font-semibold text-${color} whitespace-nowrap`}>
                {dueDate} days
              </b>
              &nbsp;to pay the due amount of&nbsp;
              <b className={`font-semibold text-${color}`}>
                ₹{formatWithCommasWithDecimal(pendingOrder?.amount)}
              </b>
            </p>
          ) : (
            <p className={`font-semibold text-${color} whitespace-nowrap`}>
              You have no outstanding dues.
            </p>
          )
        ) : (
          <>
            {cardType === "credit" ? (
              <span className="whitespace-nowrap">
                Activated Feature on&nbsp;
                <b className={`font-semibold text-${color}`}>
                  {formatDate(updateDate)}
                </b>
              </span>
            ) : (
              <span className="whitespace-nowrap">
                Last Updated on&nbsp;
                <b className={`font-semibold text-${color}`}>
                  {formatDate(lastUpdate !== null ? lastUpdate : updateDate)}
                </b>
              </span>
            )}
          </>
        )}
      </span>
    </div>
  );
};

export default CreditCard;
