import React from "react";
import noCartLogo from "../img/no-cart-item.png";
import { useNavigate } from "react-router";
import { Button } from "@mui/material";

const CartNoProduct = ({ text }) => {
  const navigate = useNavigate();
  const handleAddToCart = () => {
    navigate("/");
  };
  return (
    <div className="w-full min-h-[78vh] h-full">
      <div className="h-full flex flex-col items-center justify-center mt-[15vh]">
        <img loading="lazy" src={noCartLogo} alt="no-cart-logo" className="h-[15vh]" />
        <h3 className="text-16 text-black-1/90 font-bold my-2">
          No Items Found!
        </h3>
        <span className="text-14 text-black-1/70 font-semibold my-2">
          Sorry mate... no items found inside your {text}
        </span>
        <Button
          variant="contained"
          sx={{
            height: "40px",
            outline: "none",
            borderRadius: "5px",
            backgroundColor: "purple",
            color: "white",
            fontWeight: 700,
            fontSize: "14px",
            marginTop: "8px",
          }}
          onClick={handleAddToCart}
        >
          Add items To {text}
        </Button>
      </div>
    </div>
  );
};

export default CartNoProduct;
